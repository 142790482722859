// data.js
export const llenarDatosCustomerUser = (id, email, password, adminUser, active) => {

    const jsonDataCustomerUser =
        {
            "customerUserId": id,
            "email": email,
            "password": password,
            "adminUser": adminUser,
            "active": active,
        };
    return jsonDataCustomerUser;
};

export const llenarDatosCustomerUserEdit = (
    id,
    email,
    password,
    customer,
    adminUser,
    active) => {

    const jsonDataCustomerUser =
        {
            "customerUserId": id,
            "email": email,
            "password": password,
            "customer": customer,
            "adminUser": adminUser,
            "active": active,
        };
    return jsonDataCustomerUser;
};