<template>
  <div
      class="card multisteps-form__panel"
      data-animation="FadeIn"
  >
    <div class="multisteps-form__content">
      <!-- Card body -->
      <div class="card-header border-1">
        <h5 class="font-weight-bolder mb-0">Vista previa de la lista  <span class="font-weight-600">{{ createdList.name }}</span></h5>
      </div>
      <div class="card-body table-responsive">
        <table  class="table align-items-center mb-0">
          <thead>
            <tr class="thead-light">
              <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Número</th>
              <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Correo</th>
              <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Segmento</th>
              <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Tags</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(row, index) in createdList.slice(0, 10)" :key="index">
              <td v-if="$store.state.individualNumero" class="align-middle text-center text-xs h6">{{ $store.state.individualNumero }}</td>
              <td v-else class="align-middle text-center text-xs h6">{{ row['msisdn'] }}</td>
              <td class="align-middle text-center text-xs h6">{{ row['email'] }}</td>
              <td class="align-middle text-center text-sm">
                <div class="badge badge-celcom-blue">{{$store.state.datoSegmento.segmentTagName}}</div>
              </td>
              <td class="align-middle text-center text-sm">
                <div class="badge badge-celcom-orange">{{$store.state.datoTag.map(tag => tag.segmentTagName).join(', ')}}</div>
              </td>
            </tr>
          </tbody>
        </table>
        <argon-button
            v-if="currentRoute === '/contactos/nueva-lista'"
            @click.prevent="finalizarAgregar"
            class="mt-4 d-block ms-auto">
          Finalizar <i class="fas fa-check"></i>
        </argon-button>
      </div>
    </div>
  </div>
</template>

<script>
import ArgonButton from "@/components/ArgonButton.vue";

export default {
  name: "ListaAdd3",
  components: {
    ArgonButton
  },
  props: {
    createdList: {
      type: Array,
      default: () => {
        return [];
      },
    },
    createdContact: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      currentRoute: this.$route.path,
      data: {
        customerFieldId: null,
        customerFieldName: ""
      }
    };
  },
  methods: {
    async finalizarAgregar() {
      await this.$parent.finalizarAgregar();
    },
  },
};
</script>
<style scoped>
.smaller-select {
  width: 100px; /* Ajusta el ancho según tus necesidades */
}
.invalid-select {
  border: 1px solid red;
  background-color: #ffe6e6; /* Cambia el color de fondo a rojo claro */
}
</style>