// api.js
import axios from 'axios';
import {decryptAES} from "@/config/servicios/campana/util";

const API_BASE_URL = 'https://automata2.celcom.cl/flex/api/';

const urlCustomerUserId = () => {
// Obtener el valor del local storage
    let encryptedToken = localStorage.getItem('user');
    const userDataString = decryptAES(encryptedToken);

// Convertir la cadena JSON a un objeto JavaScript
    const userData = JSON.parse(userDataString);

// Obtener el valor de customerId
    return userData.customerUserId;
};
export const listCustomerUserServ = () => {
    const API_LIST_CONT = 'customer/list/'+ urlCustomerUserId() + '/customer';
    return axios.post(`${API_BASE_URL}`+ API_LIST_CONT,1);
};

export const listCustomerUserAllServ = () => {
    const API_LIST_CONT = 'customer/list/all/'+ urlCustomerUserId() + '/admin';
    return axios.post(`${API_BASE_URL}`+ API_LIST_CONT,1);
};

export const agreCustomerUser = (datos) => {
    const API_LIST_ADD = 'customer/create/'+ urlCustomerUserId() + '/customerUser';
    return axios.post(`${API_BASE_URL}`+API_LIST_ADD, datos);
};

export const editCustomerUser = (datos) => {
    const API_LIST_EDIT = 'customer/edit/'+ urlCustomerUserId() + '/customerUser';
    return axios.post(`${API_BASE_URL}`+API_LIST_EDIT, datos);
};


// Agrega más funciones según tus necesidades