import { editCanal, editCanalUser } from "@/config/servicios/canales/servicesCanales";
import {llenarDatosCanal} from "@/config/servicios/canales/plantillaCanales";

export const editC = (channelId, channelName, active) => {
    const jsonDataCanal = llenarDatosCanal(channelId, channelName, active);

    return editCanal(jsonDataCanal)
        .then((response) => {
            console.log(response.data);
        })
        .catch((error) => {
            console.error("Error al enviar los datos:", error);
        });
};

export const editCU = (channelId, active) => {
    const jsonDataCanal =
        {
            "active": active,
        };

    return editCanalUser(channelId, jsonDataCanal)
        .then((response) => {
            console.log(response.data);
        })
        .catch((error) => {
            console.error("Error al enviar los datos:", error);
        });
};