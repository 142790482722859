<template>
  <div class="container-fluid py-4">
    <div class="row mt-4">
      <div class="col-12">
        <client-admin-table :user-data="responseCliente"/>
      </div>
    </div>
    <modal :is-visible="modalAddCustomer" close-modal="">
      <form>
        <h6 class="heading-small text-muted mb-4">Datos del Cliente</h6>

        <div class="row">
          <div class="col-sm-auto ms-sm-auto mt-sm-0 mt-3 d-flex">
            <label class="form-check-label mb-0 me-2">
              <small id="profileVisibility"
              ><b>{{ dataCustomer.active ? "Activo" : "No Activo" }}</b></small
              >
            </label>
            <argon-switch
                id="tipo-envio"
                ref="tipoEnvio"
                name="tipo-envio"
                class="form-check form-switch mb-0 ms-2"
                @change="onSwitchChange"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-lg-8">
            <label class="form-label">Nombre</label>
            <argon-input
                v-model="dataCustomer.name"
                class="multisteps-form__input"
                type="text"
            />
          </div>
          <div class="col-12 col-lg-4">
            <div class="form-check mt-4 pt-1">
              <input
                  id="estadoCheckboxEdit"
                  v-model="dataCustomer.concatenateMessage"
                  class="form-check-input"
                  type="checkbox"
              />
              <label class="form-check-label" for="estadoCheckboxEdit">
                {{ dataCustomer.concatenateMessage ? 'UDH' : 'No UDH' }}
              </label>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-lg-6">
            <label class="form-label">Grupo</label>
            <select
                id="opcionesCountry"
                v-model="dataCustomer.operatorGroupId.operatorGroupId"
                class="multisteps-form__select form-control form-select"
                name="opciones-ListCont"
            >
              <option value="" selected disabled>Grupos</option>
              <option
                  v-for="(group, index) in responseOperatorGroup"
                  :key="index"
                  :value="group.operatorGroupId"
              >
                {{ group.operatorGroupName }}
              </option>
            </select>

          </div>
          <div class="col-12 col-lg-6">
            <label class="form-label">País</label>
            <select
                id="opcionesCountry"
                v-model="dataCustomer.country"
                class="multisteps-form__select form-control form-select"
                name="opciones-ListCont"
            >
              <option value="" selected disabled>Países</option>
              <option
                  v-for="(tipo, index) in responseCountrys"
                  :key="index"
                  :value="tipo"
              >
                {{ tipo.name }}
              </option>
            </select>
          </div>
        </div>
        <hr>
        <div class="row">
          <div class="col-12 col-lg-8">
            <label class="form-label">Nombre</label>
            <argon-input
                v-model="dataCustomer.firstName"
                class="multisteps-form__input"
                type="text"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-lg-8">
            <label class="form-label">Apellido</label>
            <argon-input
                v-model="dataCustomer.lastName"
                class="multisteps-form__input"
                type="text"
            />
          </div>
          <div class="col-12 col-lg-4">
            <label class="form-label">Msisdn</label>
            <argon-input
                v-model="dataCustomer.msisdn"
                class="multisteps-form__input"
                type="text"
            />
          </div>
        </div>
        <hr>
        <div class="row">
          <div class="col-12 col-lg-4">
            <label class="form-label">RUT</label>
            <argon-input
                v-model="dataCustomer.rut"
                class="multisteps-form__input"
                type="text"
            />
          </div>
          <div class="col-12 col-lg-8">
            <label class="form-label">Adress</label>
            <argon-input
                v-model="dataCustomer.adress"
                class="multisteps-form__input"
                type="text"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-lg-8">
            <label class="form-label">Área</label>
            <argon-input
                v-model="dataCustomer.area"
                class="multisteps-form__input"
                type="text"
            />
          </div>
          <div class="col-12 col-lg-4">
            <label class="form-label">Creditos</label>
            <argon-input
                v-model="dataCustomer.flexCredits"
                class="multisteps-form__input"
                type="number"
            />
          </div>
        </div>
          <div class="modal-footer mt-lg-4 px-0 d-flex justify-content-between">
            <button class="btn m-0 bg-gradient-celcom-orange" @click.prevent="addCustomer">Agregar</button>
            <button class="btn m-0 bg-gradient-dark" @click.prevent="closeModal">Cancelar</button>
          </div>
      </form>

    </modal>
    <modal :is-visible="modalEditarCustomer" close-modal="">
      <form>
        <h6 class="heading-small text-muted mb-4">Datos del Cliente</h6>

        <div class="row">
          <div class="col-sm-auto ms-sm-auto mt-sm-0 mt-3 d-flex">
            <label class="form-check-label mb-0 me-2">
              <small id="profileVisibility"
              ><b>{{ dataCliente.active ? "Activo" : "No Activo" }}</b></small
              >
            </label>
            <argon-switch
                id="tipo-envio"
                ref="tipoEnvio"
                :checked="dataCliente.active"
                name="tipo-envio"
                class="form-check form-switch mb-0 ms-2"
                @change="onSwitchChangeEdit"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-lg-8">
            <label class="form-label">Nombre</label>
            <argon-input
                v-model="dataCliente.name"
                class="multisteps-form__input"
                type="text"
            />
          </div>
          <div class="col-12 col-lg-4">
            <div class="form-check mt-4 pt-1">
              <input
                  id="estadoCheckboxEditCl"
                  v-model="dataCliente.concatenateMessage"
                  class="form-check-input"
                  type="checkbox"
              />
              <label class="form-check-label" for="estadoCheckboxEditCl">
                {{ dataCliente.concatenateMessage ? 'UDH' : 'No UDH' }}
              </label>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-lg-6">
            <label class="form-label">Grupo</label>
            <select
                id="opcionesCountry"
                v-model="dataCliente.operatorGroupId"
                class="multisteps-form__select form-control form-select"
                name="opciones-ListCont"
            >
              <option value="" selected disabled>Grupos</option>
              <option
                  v-for="(group, index) in responseOperatorGroup"
                  :key="index"
                  :value="group"
              >
                {{ group.operatorGroupName }}
              </option>
            </select>

          </div>
          <div class="col-12 col-lg-6">
            <label class="form-label">País</label>
            <select
                id="opcionesCountry"
                v-model="dataCliente.country"
                class="multisteps-form__select form-control form-select"
                name="opciones-ListCont"
            >
              <option value="" selected disabled>Países</option>
              <option
                  v-for="(country, index) in responseCountrys"
                  :key="index"
                  :value="country"
              >
                {{ country.name }}
              </option>
            </select>
          </div>
        </div>
        <hr>
        <div class="row">
          <div class="col-12 col-lg-8">
            <label class="form-label">Nombre</label>
            <argon-input
                v-model="dataCliente.firstName"
                class="multisteps-form__input"
                type="text"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-lg-8">
            <label class="form-label">Apellido</label>
            <argon-input
                v-model="dataCliente.lastName"
                class="multisteps-form__input"
                type="text"
            />
          </div>
          <div class="col-12 col-lg-4">
            <label class="form-label">Msisdn</label>
            <argon-input
                v-model="dataCliente.msisdn"
                class="multisteps-form__input"
                type="text"
            />
          </div>
        </div>
        <hr>
        <div class="row">
          <div class="col-12 col-lg-4">
            <label class="form-label">RUT</label>
            <argon-input
                v-model="dataCliente.rut"
                class="multisteps-form__input"
                type="text"
            />
          </div>
          <div class="col-12 col-lg-8">
            <label class="form-label">Adress</label>
            <argon-input
                v-model="dataCliente.adress"
                class="multisteps-form__input"
                type="text"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-lg-8">
            <label class="form-label">Área</label>
            <argon-input
                v-model="dataCliente.area"
                class="multisteps-form__input"
                type="text"
            />
          </div>
          <div class="col-12 col-lg-4">
            <label class="form-label">Creditos</label>
            <argon-input
                v-model="dataCliente.flexCredits"
                class="multisteps-form__input"
                type="number"
            />
          </div>
        </div>
        <div class="modal-footer mt-lg-4 px-0 d-flex justify-content-between">
          <button class="btn m-0 bg-gradient-celcom-orange" @click.prevent="editCustomer">Editar</button>
          <button class="btn m-0 bg-gradient-dark" @click.prevent="closeModal">Cancelar</button>
        </div>
      </form>

    </modal>
  </div>
</template>

<script>
import ClientAdminTable from "@/views/admin/components/ClientAdminTable.vue";
import ArgonInput from "@/components/ArgonInput.vue";
import Modal from "@/components/Modal/Modal.vue";
import {encryptAES, getUser, getUserSub, horaAhora, onPermissionsUser} from "@/config/servicios/campana/util";
import swal from "sweetalert2";
import {
  available_countrys,
  custom_field,
  custom_field_add,
  custom_field_edit,
  operator_group
} from "@/config/servicios/admin/customer/serviciosCustomer";
import ArgonSwitch from "@/components/ArgonSwitch.vue";
export default {
  name: "UserAdmin",
  components: {
    ArgonSwitch,
    Modal, 
    ArgonInput,
    ClientAdminTable,
  },
  data() {
    return {
      switchValue: false,
      permisos: {
        create: onPermissionsUser('users.xhtml', 'create'),
        modify: onPermissionsUser('users.xhtml', 'modify'),
        destroy: onPermissionsUser('users.xhtml', 'destroy'),
      },
      responseCountrys: {},
      dataClienteOperatorGroupId: 0,
      responseCliente: {},
      responseOperatorGroup: {},
      modalAddCustomer: false,
      modalEditarCustomer: false,
      dataCliente: {},
      dataCustomer: {
        customerId: null,
        name: "",
        active: false,
        codeActivation: null,
        codeActivationReceive: null,
        operatorGroupId: {
          operatorGroupId: 0
        },
        country: {
        },
        concatenateMessage: false,
        msisdn: "",
        lastName: null,
        rut: "",
        adress: "",
        firstName: null,
        area: null,
        flexCredits: 0
      },

    };
  },
  mounted() {
    this.obtenerCustomerAdmin();
    this.obtenerOperatorGroup();
    this.obtenerCountrys();
  },
  methods: {
    async obtenerCustomerAdmin(){
      try {
        let user = (getUserSub()!=null) ? getUserSub() :getUser();

        let js = {
          time_expired: horaAhora(),
          "admin":encodeURIComponent(JSON.stringify(user.customer)),
        };

        var body = new URLSearchParams();
        body.append("TOKEN", encryptAES(js));


        const response = await custom_field(body);
        // Almacena la respuesta en la variable de datos
        if (response.data.response === "200") {

          this.responseCliente = response.data.data;
        } else {
          console.log("No se encontró lista");

        }
      } catch (error) {
        console.error("Error al obtener datos", error);
      }
    },
    async obtenerOperatorGroup(){
      try {
        let user = (getUserSub()!=null) ? getUserSub() :getUser();

        let js = {
          time_expired: horaAhora(),
          "admin":encodeURIComponent(JSON.stringify(user.customer)),
        };

        var body = new URLSearchParams();
        body.append("TOKEN", encryptAES(js));


        const response = await operator_group(body);
        // Almacena la respuesta en la variable de datos
        if (response.data.response === "200") {

          this.responseOperatorGroup = response.data.data;
        } else {
          console.log("No se encontró lista");

        }
      } catch (error) {
        console.error("Error al obtener datos", error);
      }
    },
    async obtenerCountrys(){
      try {
        let user = (getUserSub()!=null) ? getUserSub() :getUser();

        let js = {
          time_expired: horaAhora(),
          "admin":encodeURIComponent(JSON.stringify(user.customer)),
        };

        var body = new URLSearchParams();
        body.append("TOKEN", encryptAES(js));


        const response = await available_countrys(body);
        // Almacena la respuesta en la variable de datos
        if (response.data.response === "200") {
          this.responseCountrys = response.data.data;
        } else {
          console.log("No se encontró lista");

        }
      } catch (error) {
        console.error("Error al obtener datos", error);
      }
    },
    closeModal() {
      this.modalAddCustomer = false;
      this.modalEditarCustomer = false;
      this.dataCustomer = {
        customerId: null,
        name: "",
        active: false,
        codeActivation: null,
        codeActivationReceive: null,
        operatorGroupId: {
          operatorGroupId: 0
        },
        country: {
          countryId: null
        },
        concatenateMessage: false,
        msisdn: "",
        lastName: null,
        rut: "",
        adress: "",
        firstName: null,
        area: null,
        flexCredits: 0
      };
      this.dataCliente = {
        country: {}
      };
    },
    tipoSwitchChange() {
      this.switchValue = !this.switchValue;
    },
    onSwitchChange(event) {
      this.dataCustomer.active = event.target.checked;
    },
    onSwitchChangeEdit(event) {
      this.dataCliente.active = event.target.checked;
    },
    //Inicio de editar un Customer
    openModalEditarCustomer(row) {
      this.dataCliente = { ...row }; // Asigna todos los valores de row a dataCliente
      this.modalEditarCustomer = true;
    },
    async editCustomer(){
      let self = this;
      let user = getUser();

      let js = {
        "time_expired":horaAhora(),
        "admin":encodeURIComponent(JSON.stringify(user.customer)),
        "customer":encodeURIComponent(JSON.stringify(this.dataCliente))
      }

      var body = new URLSearchParams(); body.append('TOKEN', (encryptAES(js)));
      await custom_field_edit(body)
          .then(function (response) {
            if (response.data.response === "200") {

              self.responseCustomer = response.data.data;
              self.closeModal();

              swal.fire({
                title: '¡Cliente editado con éxito!',
                buttonsStyling: false,
                customClass: {
                  confirmButton: 'btn btn-celcom-blue',
                },
                type: 'success',
                icon: 'success',
              })

            } else {
              swal.fire({
                title: 'No se pudo editar el cliente',
                buttonsStyling: false,
                customClass: {
                  confirmButton: 'btn btn-celcom-orange',
                },
                type: 'error',
                icon: 'error'
              })
            }
          })
          .catch(function (error) {
            console.log("No se pudo crear el cliente",error);
            swal.fire({
              title: 'No se pudo editar el cliente',
              buttonsStyling: false,
              customClass: {
                confirmButton: 'btn btn-celcom-orange',
              },
              type: 'error',
              icon: 'error'
            })
          });

    },
    //Fin de editar un Customer

    //Inicio de add un Customer
    openModalAddCustomer() {
      this.modalAddCustomer = true;
    },
    async addCustomer(){
      let self = this;
      let user = getUser();

      if(this.dataCustomer.name === "" ||
          this.dataCustomer.operatorGroupId.operatorGroupId === "" ||
          this.dataCustomer.country.countryId === "" ||
          this.dataCustomer.firstName === "" ||
          this.dataCustomer.lastName === "" ||
          this.dataCustomer.msisdn === "" ||
          this.dataCustomer.rut === "" ||
          this.dataCustomer.area === "" ||
          this.dataCustomer.area === "" ||
          this.dataCustomer.flexCredits === ""){
        await swal.fire({
          title: "Error Debe completar todos los campos",
          buttonsStyling: false,
          customClass: {
            confirmButton: 'btn btn-celcom-orange',
          },
          icon: 'error',
        })
        return false;
      }

      let js = {
        "time_expired":horaAhora(),
        "admin":encodeURIComponent(JSON.stringify(user.customer)),
        "customer":encodeURIComponent(JSON.stringify(this.dataCustomer))
      }

      var body = new URLSearchParams(); body.append('TOKEN', (encryptAES(js)));
      await custom_field_add(body)
          .then(function (response) {
            if (response.data.response === "200") {

              self.responseCustomer = response.data.data;
              self.closeModal();

              swal.fire({
                title: '¡Cliente creado con éxito!',
                buttonsStyling: false,
                customClass: {
                  confirmButton: 'btn btn-celcom-blue',
                },
                type: 'success',
                icon: 'success',
              })

            } else {
              swal.fire({
                title: 'No se pudo agregar el cliente',
                buttonsStyling: false,
                customClass: {
                  confirmButton: 'btn btn-celcom-orange',
                },
                type: 'error',
                icon: 'error'
              })
            }
          })
          .catch(function (error) {
            console.log("No se pudo crear el cliente",error);
            swal.fire({
              title: 'No se pudo agregar el cliente',
              buttonsStyling: false,
              customClass: {
                confirmButton: 'btn btn-celcom-orange',
              },
              type: 'error',
              icon: 'error'
            })
          });

    },
    //Fin de add un Customer
},
};
</script>
<style scoped>
/* Agrega estilos según tus necesidades */
.modal {
  display: none;
}

.modal.is-active {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
}

.modal-close {
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
  background: none;
  border: none;
}
.buttons {
  margin: 8px;
}
</style>