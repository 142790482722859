import { agreContacto } from "@/config/servicios/contactos/services.js";
import { llenarDatosContacto } from "@/config/servicios/contactos/plantilla.js";

export const addContacto = (name, rut, email, empresa, msisdn, tag, birthdate, gender, country, sourceId) => {
    const jsonDataContacto = llenarDatosContacto(null,name, rut, email, empresa, msisdn, tag, birthdate, gender, country, convertirSourceId(sourceId));

    return agreContacto(jsonDataContacto)
        .then((response) => {
        console.log(response.data);
    })
        .catch((error) => {
        console.error("Error al enviar los datos:", error);
    });
};

function convertirSourceId(sourceId) {
    if (sourceId === "Web") {
        return 1;
    }
}