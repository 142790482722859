// api.js
import axios from 'axios';
import Config from "@/config/servicios/campana/config";
export const report_available = (body) => {
    return axios.post(Config.url_service + Config.report_available,body,{
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        }});
};
export const available_channels = (body) => {
    return axios.post(Config.url_service + Config.available_channels,body,{
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        }});
};

export const report_mo_available = (body) => {
    return axios.post(Config.url_service + Config.report_mo_report,body,{
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        }});
};
export const report_ejecutivo_grafica_doughnut = (body) => {
    return axios.post(Config.url_service + Config.report_ejecutivo_grafica_doughnut,body,{
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        }});
};
export const report_ejecutivo_grafica_doughnut_detallado = (body) => {
    return axios.post(Config.url_service + Config.report_ejecutivo_grafica_doughnut_detallado,body,{
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        }});
};
export const report_ejecutivo_grafica_doughnut_cemi = (body) => {
    return axios.post(Config.url_service + Config.report_ejecutivo_grafica_doughnut_cemi,body,{
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        }});
};
export const report_ejecutivo_grafica_bar = (body) => {
    return axios.post(Config.url_service + Config.report_ejecutivo_grafica_bar,body,{
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        }});
};
export const report_ejecutivo_grafica_map = (body) => {
    return axios.post(Config.url_service + Config.report_ejecutivo_grafica_map,body,{
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        }});
};
export const report_ejecutivo_cards = (body) => {
    return axios.post(Config.url_service + Config.report_ejecutivo_cards,body,{
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        }});
};
export const report_ejecutivo_cards_detallado = (body) => {
    return axios.post(Config.url_service + Config.report_ejecutivo_cards_detallado,body,{
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        }});
};
export const report_ejecutivo_grafica_line_detallada = (body) => {
    return axios.post(Config.url_service + Config.report_ejecutivo_grafica_line_detallada,body,{
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        }});
};