<template>

  <nav>
    <ul class="pagination justify-content-center">
      <li class="page-item prev-page" @click.prevent="getPreviousPage(filteredData)">
        <a class="page-link" href="#"><i class="fa fa-angle-left" aria-hidden="true"></i></a>
      </li>
      <li v-for="pag in getPaginasVecinas" :key="pag"
          :class="{'page-item': true, 'active': isActive(pag) }"
          @click.prevent="getDataPaginated(pag, filteredData)">
        <a class="page-link" href="#">{{pag}}</a>
      </li>
      <li class="page-item next-page" @click.prevent="getNextPage(filteredData)">
        <a class="page-link" href="#"><i class="fa fa-angle-right" aria-hidden="true"></i></a>
      </li>
    </ul>
  </nav>
</template>
<script>
export default {
  name: "BasePagination",
  props: {
    filteredData: {
      type: Array,
      required: true
    },
  },
  data() {
    return {
    };
  },
  computed: {
    getPaginasVecinas() {
      if (!this.$store.state.datosPaginados || this.$store.state.datosPaginados.length === 0) {
        this.getDataPaginated(1, this.filteredData);
      }
      return this.$store.getters.getPaginasVecinas(this.filteredData);
    },
  },
  methods: {
    getDataPaginated(pag, filteredData) {
      console.log(this.$store.getters.getPaginasVecinas(filteredData))
      this.$store.getters.getDataPaginated(pag, filteredData);
    },
    getPreviousPage(filteredData) {
      this.$store.getters.getPreviousPage(filteredData);
    },
    getNextPage(filteredData) {
      this.$store.getters.getNextPage(filteredData);
    },
    isActive(page) {
      return  this.$store.getters.isActive(page);
    },
  },
};
</script>
