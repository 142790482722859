<template>
  <div class="card z-index-2">
    <div class="pb-0 card-header mb-0">
      <h6>{{ chart.title }}</h6>
      <!-- eslint-disable-next-line vue/no-v-html -->
    </div>
    <div class="p-3 card-body">
      <div class="chart">
        <canvas :id="id" class="chart-canvas" :height="height"></canvas>
      </div>
    </div>
  </div>
</template>

<script>
import Chart from "chart.js/auto";

export default {
  name: "GradientBarChart",
  props: {
    id: {
      type: String,
      required: true,
    },
    height: {
      type: String,
      default: "150",
    },
    chart: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    const ctx = document.getElementById(this.id).getContext("2d");

    //const gradientStrokes = [];
    const datasets = [];

    // Definir gradientes de color para cada conjunto de datos
    for (let i = 0; i < this.chart.datasets.length; i++) {
      const backgroundColor = this.getBackgroundColor(i); // Obtiene el color de fondo para doughnut
      const borderColor = this.getBorderColor(i); // Obtiene el color de fondo para doughnut
      datasets.push({
        label: this.chart.datasets[i].label,
        backgroundColor: backgroundColor, // Color de fondo para doughnut
        borderColor: borderColor, // Color de fondo para doughnut
        data: this.chart.datasets[i].data,
        barThickness: 10, // Ajusta el grosor de la barra aquí
        maxBarThickness: 15, // Grosor máximo de las barras
        borderWidth: 1,
      });
    }

    let chartStatus = Chart.getChart(this.id);
    if (chartStatus != undefined) {
      chartStatus.destroy();
    }

    // Crear el gráfico Doughnut
    new Chart(ctx, {
      type: "bar", // Cambia a "doughnut"
      data: {
        labels: this.chart.labels,
        datasets: datasets,
      },
      options: {
        scales: {
          y: {
            beginAtZero: true
          }
        }
      },
    });
  },
  methods: {
    getBorderColor(index) {
      switch (index) {
        case 0:
          return  [
            'rgb(255, 99, 132)',
            'rgb(255, 159, 64)',
            'rgb(255, 205, 86)',
            'rgb(75, 192, 192)',
            'rgb(54, 162, 235)',
            'rgb(153, 102, 255)',
            'rgb(201, 203, 207)'
          ]  // Otros
        case 1:
          return 'rgb(54, 162, 235)'; // Segundo color
        case 2:
          return 'rgb(255, 205, 86)'; // Tercer color
        default:
          return 'rgb(115, 100, 90)'; // Color por defecto
      }
    },
    getBackgroundColor(index) {
      switch (index) {
        case 0:
          return  [
            'rgb(255, 99, 132)',
            'rgb(255, 159, 64)',
            'rgb(255, 205, 86)',
            'rgb(75, 192, 192)',
            'rgb(54, 162, 235)',
            'rgb(153, 102, 255)',
            'rgb(201, 203, 207)'
          ]  // Otros
        case 1:
          return [
            'rgba(255, 99, 132, 0.2)',
            'rgba(255, 159, 64, 0.2)',
            'rgba(255, 205, 86, 0.2)',
            'rgba(75, 192, 192, 0.2)',
            'rgba(54, 162, 235, 0.2)',
            'rgba(153, 102, 255, 0.2)',
            'rgba(201, 203, 207, 0.2)'
          ] ; // Segundo color
        case 2:
          return 'rgb(255, 205, 86)'; // Tercer color
        default:
          return 'rgb(115, 100, 90)'; // Color por defecto
      }
    },
  },
};

</script>
