import { agreCanal } from "@/config/servicios/canales/servicesCanales";
import { llenarDatosCanal} from "@/config/servicios/canales/plantillaCanales";

export const addCanal = ( channelName, active) => {
    const jsonDataCanal = llenarDatosCanal(null, channelName, active);

    return agreCanal(jsonDataCanal)
        .then((response) => {
        console.log(response.data);
    })
        .catch((error) => {
        console.error("Error al enviar los datos:", error);
    });
};