<template>
    <div class="container-fluid py-5">
      <div class="row">
        <div class="col-12">
          <div class="multisteps-form">
            <div class="row">
              <div class="col-12 col-lg-10 mx-auto mb-4">
                <div class="card">
                  <div class="card-body">
                    <div class="multisteps-form__progress">
                      <button
                        class="multisteps-form__progress-btn js-active"
                        type="button"
                        title="Carga de Datos"
                        :class="activeStep >= 0 ? activeClass : ''"
                      >
                        <span>1</span>
                      </button>
                      <button
                        class="multisteps-form__progress-btn"
                        type="button"
                        title="Cabeceras"
                        :class="activeStep >= 1 ? activeClass : ''"
                      >
                        2
                      </button>
                      <button
                          class="multisteps-form__progress-btn"
                          type="button"
                          title="Vista Previa"
                          :class="activeStep >= 2 ? activeClass : ''"
                      >
                        3
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--form panels-->
            <div class="row">
              <div class="col-12 col-lg-10 m-auto">
                <form class="multisteps-form__form">
                  <!--single form panel-->
                  <lista-add1
                      :ind-lista="indLista"
                      :valid-form="validForm"
                      :class="activeStep === 0 ? activeClass : ''"
                      @update-values="handleUpdateValues"
                      @contacto-agregado="handleContactoAgregado"/>
                  <!--single form panel-->
                  <lista-add2 ref="miComponenteHijo2" :created-contact="createdContact" :created-list="createdList" :class="activeStep === 1 ? activeClass : ''" @next="handleUpdateValues2"/>
                  <!--single form panel-->
                  <lista-add3 :created-contact="createdContact" :created-list="createdList" :class="activeStep === 2 ? activeClass : ''" />
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Loader ref="loader"/>
      <LoaderBarra ref="loaderBarra"/>
    </div>
  </template>
  
  <script>
  import ListaAdd1 from "@/views/contactsC/components/ListaAdd1.vue";
  import ListaAdd2 from "@/views/contactsC/components/ListaAdd2.vue";
  import ListaAdd3 from "@/views/contactsC/components/ListaAdd3.vue";
  import Loader from "@/components/Modal/Loader.vue"
  import LoaderBarra from "@/components/Modal/LoaderBarra.vue";
  import 'sweetalert2/src/sweetalert2.scss'
  import {encryptAES, getUser, getUserSub, horaAhora, onPermissionsUser} from "@/config/servicios/campana/util";
  import swal from "sweetalert2";
  import {
    contacts_planner,
    contact_group,
  } from "@/config/servicios/campana/contactos/servicesContNew";

  export default {
    name: "ListaAdd",
    components: {
      ListaAdd1,
      ListaAdd2,
      ListaAdd3,
      Loader,
      LoaderBarra,
    },
    data() {
      return {
        contactGroup: null,
        indLista: false,
        activeStep: 0,
        formSteps: 1,
        activeClass: "js-active position-relative",
        fileSingle: [],
        inputs: {
          file: []
        },
        radios: {
          radio1: 'checked'
        },
        checked: false,
        listaCreada: false,
        listaTerminada: false,
        contactSingle: {},
        importadosBien: false,
        barProgreso:0,
        barProgresoFin:0,
        validForm:{
          estado:false,
          field:"",
          listName:"",
          CodePhoneContact:getUser().customer.country.phoneCode,
          PhoneContact:"",
          listContact:"",
          dateProgramgContact:"",
          menssageContact:"",
          selectDraft:"",
          StepPreview:false,
          dateSend:"",
          sendingsms:"",
          contacts:[],
          childrenId:null,
          sendingTotal:0,
          sendingsmsId:null,
        },
        createdList: [],
        createdContact: [],
        cantidadContactos: [],
        header:"",
        interaccion: [],
      };
    },
    watch:{

    },
    created(){

    },
    async mounted() {
      this.$store.getters.limpiarVariables();
      await this.$store.dispatch('cambiarIndividual', false);
      if (this.$route.params.id){
        this.indLista = true;
      }
      if(!onPermissionsUser('contacts.xhtml', 'create')) {
        swal.fire({
          title: 'No tienes los permisos para acceder a esta página.',
          buttonsStyling: false,
          customClass: {
            confirmButton: 'btn btn-celcom-blue',
          },
          type: 'error',
          icon: 'error'
        }).then(function() {
          window.location.href="/dashboard";
        })
      }
      this.cargarDatos();
    },
    methods: {
      async cargarDatos(){
        await this.$store.getters.arrayCabeceras();
      },
      handleContactoAgregado(nuevoContacto) {
        // Actualiza createdContact en el componente padre
        this.createdContact = nuevoContacto;
      },
      handleUpdateValues({ msisdn, codigo, listaName}) {
        // Actualiza los valores en el componente padre según sea necesario
        this.validForm.PhoneContact = msisdn;
        this.validForm.CodePhoneContact = codigo;
        this.validForm.listName = listaName;


        this.$refs.miComponenteHijo2.initializeSelectedList();

        /*this.guardarNuevo();
        this.guardarNuevaLista();*/
        this.nextStep();
      },
      handleUpdateValues2() {
        this.AgregarContactos();
      },
      nextStep() {
        if (this.activeStep <= this.formSteps) {
          this.activeStep += 1;
        } else {
          this.activeStep -= 1;
        }
      },
      prevStep() {
        if (this.activeStep > 0) {
          this.activeStep -= 1;
        }
      },
      /*guardarNuevo() {

        this.$refs.loader.showLoader();

        let self = this;
        let user = (getUserSub()!=null) ? getUserSub() :getUser();

        let nuevoContacto = {
          "phoneCode": this.validForm.CodePhoneContact,
          "phone": this.validForm.PhoneContact,
          //"contactGroup": this.contactGroup,
          "field": "usuario1|21|23500||||||||||",
          "status": true,
          "selected": false
        };

        self.validForm.field = "";

        console.log(this.validForm.PhoneContact.toString().length);

        if (self.validForm.PhoneContact === "") {
          self.validForm.field+=",PhoneContact"; self.validForm.estado=true;

          self.$refs.loader.hideLoader();

          return false;

        } else if ( (user.customer.country.name === "Colombia" && self.validForm.PhoneContact.toString().length !== 9) || (user.customer.country.name === "Chile" && self.validForm.PhoneContact.toString().length !== 8) ) {

          self.validForm.field+=",PhoneContact"; self.validForm.estado=true;

          self.$refs.loader.hideLoader();

          return false;

        } else {

          this.createdContact.push(nuevoContacto);
          self.listaTerminada = true;

          self.$refs.loader.hideLoader();

        }

      },
      guardarNuevaLista() {

        this.$refs.loader.showLoader();

        let self = this;
        let user = (getUserSub()!=null) ? getUserSub() :getUser();

        let nuevaLista = {
          "customerId": user.customer.customerId,
          "name": this.validForm.listName,
          "header": self.header,
          "active":true,
          "validos": "1",
          "invalidos": "0"
        }

        console.log(nuevaLista);

        if(this.validForm.listName === ""){
          this.validForm.field+=",listName"; this.validForm.estado=true;

           self.$refs.loader.hideLoader();

        } else {

          this.createdList = nuevaLista;
          self.listaCreada = true;

           self.$refs.loader.hideLoader();
        }

      },*/
      async AgregarContactGroup(user) {
        let aux_contact_Group = {
          name: this.validForm.listName ? this.validForm.listName : null,
          removeDuplicates: this.$store.state.eliminarActive,
        };

        let js = {
          "time_expired": horaAhora(),
          "customer": encodeURIComponent(JSON.stringify(user.customer)),
          "contact_group": this.$store.state.individual ? encodeURIComponent("{}") : encodeURIComponent(JSON.stringify(aux_contact_Group)),
        };

        let body = new URLSearchParams();
        body.append('TOKEN', encryptAES(js));

        try {
          const response = await contact_group(body);
          if (response.data.response === "200") {
            this.contactGroup = response.data.data;
          }
        } catch (error) {
          console.log("No se pudo crear la lista", error);
          this.$refs.loaderBarra.hideLoader();
          swal.fire({
            title: 'Se encontraron errores al agregar los contactos',
            buttonsStyling: false,
            customClass: {
              confirmButton: 'btn btn-celcom-orange',
            },
            icon: 'warning'
          }).then(() => {
            this.$router.push({ name: "Contactos Lista" });
          });
        }
      },

      async AgregarContactos() {
        let self = this;

        // Mostrar el loader y permitir que el DOM se actualice
        this.$refs.loaderBarra.showLoader();

        // Esperar a que el DOM se actualice para mostrar el loader correctamente
        await this.$nextTick();
        await new Promise(resolve => setTimeout(resolve, 100)); // Esperar 100 ms antes de continuar

        // Inicializar barra de progreso
        self.$refs.loaderBarra.progress = 0;

        let user = (getUserSub() != null) ? getUserSub() : getUser();

        // Dependiendo del estado, cargar los contactos correspondientes
        if (!this.$store.state.individual) {
          await self.$store.getters.contactPlanner(self.createdContact, user);
          this.createdList = self.$store.state.contactPlannerList;
          this.createdContact = self.$store.state.contactPlannerList;
        } else if (this.$store.state.individual && !this.$store.state.editarContacto) {
          await self.$store.getters.contactPlannerIndividual(user);
          this.createdList = self.$store.state.contactPlannerList;
        } else if (this.$store.state.editarContacto) {
          await self.$store.getters.contactPlannerEditar(user);
          this.createdList = self.$store.state.dataContactPlannerEditar;
        }

        // Incrementar progreso después de planificar los contactos
        this.$refs.loaderBarra.progress += 10; // Aumentar el progreso en un 10%

        // Si no es individual, agregar el grupo de contacto
        if (!this.$store.state.individual) {
          await this.AgregarContactGroup(user);
        }

        const batchSize = 1000;
        let batchStart = 0;
        const totalBatches = Math.ceil(self.createdList.length / batchSize);

        // Progreso a distribuir entre los lotes
        const progressPerBatch = 95 / totalBatches;

        while (batchStart < self.createdList.length) {
          const batch = self.createdList.slice(batchStart, batchStart + batchSize);

          try {
            let js = {};
            if (!this.$store.state.editarContacto) {
              js = {
                "time_expired": horaAhora(),
                "customer": encodeURIComponent(JSON.stringify(user.customer)),
                "contactPlanner": encodeURIComponent(JSON.stringify(batch)),
                "tag": encodeURIComponent(JSON.stringify(self.$store.state.datoTag)),
                "segment": encodeURIComponent(JSON.stringify(self.$store.state.datoSegmento)),
                "contactGroup": this.$store.state.individual ? encodeURIComponent("{}") : encodeURIComponent(JSON.stringify(this.contactGroup)),
              };
            } else {
              js = {
                "time_expired": horaAhora(),
                "customer": encodeURIComponent(JSON.stringify(user.customer)),
                "contactPlanner": encodeURIComponent(JSON.stringify(self.createdList)),
                "tag": encodeURIComponent(JSON.stringify(self.$store.state.datoTag)),
                "segment": encodeURIComponent(JSON.stringify(self.$store.state.datoSegmento)),
              };
            }

            let body = new URLSearchParams();
            body.append('TOKEN', (encryptAES(js)));

            const response = await contacts_planner(body);

            if (response.data.response === "200" && response.data.message === "CREATE CONTACTS") {
              console.log("Lote procesado correctamente");

              // Actualizar el progreso después de cada lote
              this.$refs.loaderBarra.progress += progressPerBatch;
            } else {
              self.$refs.loaderBarra.hideLoader();  // Ocultar el loader si hay errores
              swal.fire({
                title: 'Se encontraron errores al agregar los contactos',
                buttonsStyling: false,
                customClass: {
                  confirmButton: 'btn btn-celcom-orange',
                },
                icon: 'warning',
              });
              break; // Detener el proceso si hay un error
            }

          } catch (error) {
            console.error("Error procesando el lote", error);
            self.$refs.loaderBarra.hideLoader();  // Ocultar el loader si hay errores
            swal.fire({
              title: 'Error procesando el lote de contactos',
              buttonsStyling: false,
              customClass: {
                confirmButton: 'btn btn-celcom-orange',
              },
              icon: 'warning',
            });
            break; // Detener el proceso si ocurre un error
          }

          batchStart += batchSize;  // Avanzar al siguiente lote
        }

        // Completar el progreso al 100%
        this.$refs.loaderBarra.progress = 100;

        // Ocultar el loader al finalizar
        self.$refs.loaderBarra.hideLoader();

        // Mostrar mensaje de éxito
        swal.fire({
          title: '¡Lista creada exitosamente!',
          text: 'A continuación se mostrará una vista previa de los contactos',
          buttonsStyling: false,
          customClass: {
            confirmButton: 'btn btn-celcom-blue',
          },
          icon: 'success',
        }).then(function() {
          self.nextStep();  // Avanzar al siguiente paso
        });
      },
      async finalizarAgregar() {
        this.$router.push({ name: "Contactos Lista" });
      },
      /*async finalizarAgregar() {
        let self = this;

        // Mostrar el loader y permitir que el DOM se actualice
        this.$refs.loader.showLoader();
        await this.$nextTick();  // Asegura que el DOM se actualice

        self.barProgresoFin = 4;

        let user = (getUserSub() != null) ? getUserSub() : getUser();

        if (!this.$store.state.individual) {
          await self.$store.getters.contactPlanner(self.createdContact, user);
          this.createdList = self.$store.state.contactPlannerList;
        } else if (this.$store.state.individual && !this.$store.state.editarContacto) {
          await self.$store.getters.contactPlannerIndividual(user);
          this.createdList = self.$store.state.contactPlannerList;
        } else if (this.$store.state.editarContacto) {
          await self.$store.getters.contactPlannerEditar(user);
          this.createdList = self.$store.state.dataContactPlannerEditar;
        }

        let contactGroup = {
          name: this.validForm.listName ? this.validForm.listName : null,
          removeDuplicates: this.$store.state.eliminarActive,
        }

        let js = {};

        // Construir el objeto 'js' dependiendo de si se está editando un contacto o no
        if (!this.$store.state.editarContacto) {
          js = {
            "time_expired": horaAhora(),
            "customer": encodeURIComponent(JSON.stringify(user.customer)),
            "contactPlanner": encodeURIComponent(JSON.stringify(self.createdList)),
            "tag": encodeURIComponent(JSON.stringify(self.$store.state.datoTag)),
            "segment": encodeURIComponent(JSON.stringify(self.$store.state.datoSegmento)),
            "contactGroup": this.$store.state.individual ? encodeURIComponent("{}") : encodeURIComponent(JSON.stringify(contactGroup)),
          };
        } else {
          js = {
            "time_expired": horaAhora(),
            "customer": encodeURIComponent(JSON.stringify(user.customer)),
            "contactPlanner": encodeURIComponent(JSON.stringify(self.createdList)),
            "tag": encodeURIComponent(JSON.stringify(self.$store.state.datoTag)),
            "segment": encodeURIComponent(JSON.stringify(self.$store.state.datoSegmento)),
          };
        }

        let body = new URLSearchParams();
        body.append('TOKEN', (encryptAES(js)));

        contacts_planner(body)
            .then(function (response) {
              if (response.data.response === "200" && response.data.message === "CREATE CONTACTS") {
                self.$refs.loader.hideLoader();  // Ocultar el loader al finalizar
                swal.fire({
                  text: '¡Lista creada exitosamente!',
                  buttonsStyling: false,
                  customClass: {
                    confirmButton: 'btn btn-celcom-blue',
                  },
                  icon: 'success',
                }).then(function() {
                  self.$router.push({ name: "Contactos Lista" });
                });
              } else {
                self.$refs.loader.hideLoader();
                swal.fire({
                  title: 'No se pudo crear la lista',
                  buttonsStyling: false,
                  customClass: {
                    confirmButton: 'btn btn-celcom-orange',
                  },
                  icon: 'warning',
                }).then(function() {
                  self.$router.push({ name: "Contactos Lista" });
                });
              }
            })
            .catch(function (error) {
              console.log("No se pudo crear la lista", error);
              self.$refs.loader.hideLoader();
              swal.fire({
                title: 'No se pudo crear la lista',
                buttonsStyling: false,
                customClass: {
                  confirmButton: 'btn btn-celcom-orange',
                },
                icon: 'warning'
              }).then(function() {
                self.$router.push({ name: "Contactos Lista" });
              });
            });
      },*/

      /*async createListContact(contacts,interation,current,total_contacts,contactGroupNew){
        var self = this;
        //LOGICA QUE CREA ARRAY DE CONTACTOS
        let nuevoContacto = contacts;
        //var c=0;

        let user = (getUserSub()!=null) ? getUserSub() :getUser();

        let js = {
          "time_expired":horaAhora(),
          "contact_group":encodeURIComponent(JSON.stringify(contactGroupNew)),
          "contact":encodeURIComponent(JSON.stringify(nuevoContacto)),
          "customer":encodeURIComponent(JSON.stringify(user.customer)),
        }

        console.log(js)

        console.log("enviado  nuevoContacto : ",nuevoContacto);

        var body = new URLSearchParams(); body.append('TOKEN',encryptAES(js));

        contact_add_contacts(body)
            .then(function (response) {

              console.log(response)

              if (response.data.response === "200") {

                console.log("contactos importados : ",response.data.data);


                console.log("slice nueva iteration  : ",interation[current]);


                //SI ESTA INDEFINIDAD LA LISTA ES MENOR A 4000 Y NO HACE RECURSIVIDAD
                if(interation.length >= 1){

                  let promedioProgreso = total_contacts.length/((Math.ceil(total_contacts.length/4000)));

                  console.log(" progresoBar  ",Number((promedioProgreso * current)*100/total_contacts.length ).toFixed(0) )

                  self.barProgresoFin=parseInt(Number((promedioProgreso * current)*100/total_contacts.length ).toFixed(0));

                  //VALIDA QUE TODOS LOS CONTACTOS SE IMPORTARAN Y TERMINA RECURSIVIDAD
                  if(interation[current].i >= (total_contacts.length+4000)){

                    self.importadosBien = true;
                    self.listaTerminada = true;

                    self.createdContact = response.data.data;

                    swal.fire
                    ({
                      text: '¡Lista creada exitosamente!',
                      confirmButtonClass: 'btn btn-success',
                      type: 'success',
                    }).then(function() {
                      window.location.href = "/contactos/lista-contactos";
                    })

                    self.$refs.loader.hideLoader();

                  }else{
                    //FUNCION RECURSIVA DE IMPORTAR CONTACTOS
                    self.createListContact(total_contacts.slice(interation[current].init,interation[current].i),interation,(current+1),total_contacts,contactGroupNew);

                  }

                }else{

                  self.importadosBien = true;
                  self.listaTerminada = true;

                  self.nuevaListaContact = response.data.data;

                  swal.fire({
                    text: '¡Lista creada exitosamente!',
                    confirmButtonClass: 'btn btn-success',
                    type: 'success',
                  }).then(function() {
                    window.location.href = "/contactos/lista-contactos";
                  })

                  self.$refs.loader.hideLoader();

                }

              } else {

                self.$refs.loader.hideLoader();

                console.log("No se pudo crear contacto");
                swal.fire({
                  title: "No se pudo crear contacto",
                  confirmButtonClass: 'btn btn-danger btn-fill'
                })
              }


            })
            .catch(function (error) {

              console.log("No se pudo agregar lista de contactos",error);

              self.$refs.loader.hideLoader();

              swal.fire({
                title: "Error no se pudo crear contacto",
                confirmButtonClass: 'btn btn-danger btn-fill'
              })

            });

      },*/
    },
  };
  </script>