<template>
  <div
    class="card multisteps-form__panel border-radius-xl bg-white"
    data-animation="FadeIn"
  >
    <div class="card-header border-1">
      <h5 class="font-weight-bolder mb-0">Envío de {{ $store.state.seleccion === 'SMS' ? 'SMS' : 'Whatsapp'}} </h5>
      <p class="mb-0 text-sm">Información obligatoria</p>
    </div>
    <div class="card-body multisteps-form__content">
      <div class="row">
        <div class="col-12 col-sm-6">
          <label>Campaña</label>
          <argon-input
            id="programadaName"
            v-model="campaingName"
            @input="reemplazarEspacios"
            class="multisteps-form__input"
            type="text"
            placeholder="Nombre de la campaña"
          />
          <div v-if="!validarCampanna" :class="{ 'is-invalid-d': !validarCampanna }">
            <p class="mb-0 text-sm">
              El nombre de la campaña solo puede incluir letras (mayúsculas y minúsculas), números y guiones bajos (_). No se permiten espacios ni caracteres especiales.
            </p>
          </div>
        </div>
        <div
          v-if="$store.state.seleccion === 'SMS'"
          class="col-12 col-sm-6"
          :style="{ 'pointer-events': responseSenderSmsCustomer[0] ? 'auto' : 'none' }"
        >
          <label>Seleccione Remitente </label>
          <select
            id="opcionesListCont"
            v-model="selectedSender"
            class="multisteps-form__select form-control form-select"
            name="opciones-ListCont"
          >
            <option value="" disabled selected>Remitentes</option>
            <option
              v-for="(sender, index) in responseSenderSmsCustomer"
              :key="index"
              :value="sender"
            >
              {{ sender.msisdn }}
            </option>
          </select>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12 col-sm-6">
          <label class="form-label mt-2">Tipo de Envío</label>
          <div class="row ms-1">
            <div class="col-xl-3 form-check">
              <input
                  id="individual"
                  v-model="seleccionTipoEnvio"
                  type="radio"
                  name="individual"
                  value="individual"
                  class="form-check-input"
                  @change="limpiarDatos"
              />
              <label for="individual" class="custom-control-label text-sm">Individual</label>
            </div>
            <div class="col-xl-3 form-check">
              <input
                  id="segmento"
                  v-model="seleccionTipoEnvio"
                  type="radio"
                  name="segmento"
                  value="segmento"
                  class="form-check-input"
                  @change="limpiarDatos"
              />
              <label for="segmento" class="custom-control-label text-sm">Segmento</label>
            </div>
            <div class="col-xl-3 form-check">
              <input
                  id="tags"
                  v-model="seleccionTipoEnvio"
                  type="radio"
                  name="tags"
                  value="tags"
                  class="form-check-input"
                  @change="limpiarDatos"
              />
              <label for="tags" class="custom-control-label text-sm">Tags</label>
            </div>
            <div class="col-xl-3 form-check">
              <input
                  id="lista"
                  v-model="seleccionTipoEnvio"
                  type="radio"
                  name="lista"
                  value="lista"
                  class="form-check-input"
                  @change="limpiarDatos"
              />
              <label for="lista" class="custom-control-label text-sm">Lista</label>
            </div>
          </div>
        </div>
        <div v-if="seleccionTipoEnvio === 'lista'" class="col-12 col-sm-3 align-self-end">
          <a class="btn btn-info me-2 mb-0" href="#!" data-toggle="tooltip" data-original-title="Importar lista"
             @click.prevent="abrirImportar">
             Cargar archivo <i class="fa fa-upload"></i>
          </a>
        </div>
      </div>
      <div v-if="seleccionTipoEnvio === 'individual'" class="row mt-3">
          <argon-input
              id="codigo"
              v-model="codigo"
              class="multisteps-form__input col-2"
              placeholder="+569"
          />
          <argon-input
              id="msisdn"
              v-model="msisdn"
              class="multisteps-form__input col-4"
              type="number"
              placeholder="12345678"
          />
      </div>
      <div v-else class="pt-4 table-responsive">
        <table v-if="seleccionTipoEnvio === 'segmento'" class="table align-items-center mb-0">
          <thead>
            <tr>
              <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Elija un Segmento
              </th>
              <th
                  class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 w-50"
              >
                Segmentos Elegidos
              </th>
            </tr>
          </thead>
          <tbody>
            <tr style="min-height: 3em">
              <td class="align-middle text-center text-sm">
                <div class="d-flex flex-column justify-content-center">
                  <select
                    :id="'selected2'"
                    v-model="selectedSeg"
                    class="multisteps-form__select form-control form-select"
                    :disabled="seleccionSegmentos.length > 0"
                    @change="actualizarSeleccionSegmentos"
                  >
                    <option value="" disabled selected>Segmentos</option>
                    <option
                      v-for="(seg, tagIndex) in $store.state
                        .responseSegmentos"
                      :key="tagIndex"
                      :value="seg"
                    >
                      {{ seg.segmentTagName }}
                    </option>
                  </select>
                </div>
              </td>
              <td class="align-middle text-start text-sm">
                <span
                    v-for="item in seleccionSegmentos"
                    :key="item.segmentTagName"
                    class="badge badge-celcom-orange me-1 clickable-badge text-wrap"
                    @click="quitarSeleccionSegmentos(item.segmentTagId)"
                > {{ item.segmentTagName }} </span>
              </td>
            </tr>
          </tbody>
        </table>
        <table v-if="seleccionTipoEnvio === 'tags'" class="table align-items-center mb-0">
          <thead>
            <tr>
              <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Elija sus Tags
              </th>
              <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 w-50"
              >
                Tags Elegidos
              </th>
            </tr>
          </thead>
          <tbody>
          <tr style="min-height: 3em">
            <td class="align-middle text-center text-sm">
              <div class="d-flex flex-column justify-content-center">
                <select
                  v-model="selectedTag"
                  class="multisteps-form__select form-control form-select"
                  @change="actualizarSeleccionTags"
                >
                  <option value="" disabled selected>Tags</option>
                  <option
                    v-for="(tag, tagIndex) in $store.state.responseTags"
                    :key="tagIndex"
                    :value="tag"
                  >
                    {{ tag.segmentTagName }}
                  </option>
                </select>
              </div>
            </td>
            <td class="align-middle text-start text-sm">
              <span
                  v-for="tag in seleccionTags"
                  :key="tag.segmentTagName"
                  class="badge badge-celcom-orange me-1 clickable-badge text-wrap"
                  @click="quitarseleccionTags(tag.segmentTagId)"
              > {{ tag.segmentTagName }} </span>
            </td>
          </tr>
          </tbody>
        </table>
        <table v-if="seleccionTipoEnvio === 'lista'" class="table align-items-center mb-0">
          <thead>
          <tr>
            <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
            >
              Seleccione una lista
            </th>
            <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 w-50"
            >
              Listas Elegidas
            </th>
          </tr>
          </thead>
          <tbody>
          <tr style="min-height: 3em">
            <td class="align-middle text-center text-sm">
              <div class="d-flex flex-column justify-content-center">
                <select
                    :id="'selected3'"
                    v-model="selectedList"
                    class="multisteps-form__select form-control form-select"
                    :disabled="seleccionListas.length > 0"
                    @change="addIdentList"
                >
                  <option value="" disabled selected>Listas</option>
                  <option
                      v-for="(group, tagIndex) in $store.state.responserContactGroups"
                      :key="tagIndex"
                      :value="group"
                  >
                    {{ group.name }}
                  </option>
                </select>
              </div>
            </td>
            <td class="align-middle text-start text-sm">
              <span
                  v-for="list in seleccionListas"
                  :key="list.name"
                  class="badge badge-celcom-orange me-1 clickable-badge text-wrap"
                  @click="quitarLista(list.contactGroupId)"
              > {{ list.name }} </span>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div v-if="seleccionTipoEnvio === 'lista' && $store.state.seleccion === 'SMS'" class="row">
              <div class="col-12 col-sm-6">
                <br>
                <div class="form-check">
                  <input
                      v-model="multiNumero"
                      class="form-check-input"
                      type="checkbox"
                      id="multiNumeroCheckbox"
                  />
                  <label class="form-check-label" for="multiNumeroCheckbox">
                    Desea utilizar multi-números
                  </label>
                </div>
              </div>
              <div class="col-md-6">
                <!--                <label>Tags Elegidos</label>-->
                <br>
                <span
                    :class="['badge', multiNumero ? 'badge-success' : 'badge-info', 'me-1', 'text-wrap']"
                > {{ multiNumero ? "Estará utilizando multi-números en el envio de la campaña" : "Solo utilizará un número para el envió de la campañana" }} </span>
              </div>
      </div>
    </div>
    <div class="card-footer border-1 d-flex">
      <argon-button
          v-if="$store.state.get_filtros[0]"
          type="button"
          color="light"
          variant="gradient"
          class="js-btn-prev"
          @click="limpiarDatos"
      ><i class="fas fa-chevron-left me-2"></i> Filtros
      </argon-button>
      <argon-button
        :disabled="!validarBoton"
        type="button"
        color="celcom-blue"
        class="text-white ms-auto mb-0 js-btn-next"
        @click="nextStepValor"
        >Siguiente <i class="fas fa-chevron-right ms-2"></i>
      </argon-button>
    </div>
    <modal class="modal-xl"
        :is-visible="modalImportar"
    >
      <vista-add-lista/>

      <div class="modal-footer border-1 d-flex justify-content-end mt-4 px-0">
        <button class="btn bg-gradient-dark mt-2 mb-0" @click.prevent="closeModal">Salir</button>
      </div>
    </modal>
  </div>
</template>

<script>
//import Dropzone from "dropzone";
//import * as XLSX from "xlsx";
import ArgonInput from "@/components/ArgonInput.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import {
  encryptAES,
  getUser,
  getUserSub,
  horaAhora,
} from "@/config/servicios/campana/util";
import { listaContactosCamp } from "@/config/servicios/campana/contactos/servicesContactosCamp";
import Swal from "sweetalert2";
import regular from "@/assets/iconos/circle-check-regular.svg";
import solid from "@/assets/iconos/circle-check-solid.svg";
import pen from "@/assets/iconos/pen-to-square-solid.svg";
import {soloLetras_Num} from "@/config/validaciones";
import Modal from "@/components/Modal/Modal.vue";
import VistaAddLista from "@/views/contactsC/ListaAdd.vue"

export default {
  name: "ProgramadaEditAdd1",
  components: {
    Modal,
    ArgonInput,
    ArgonButton,
    VistaAddLista,
  },
  props: {
    modelValue: {
      type: Number,
      default: 0,
    },
    listAnidadas: {
      type: Array,
      default: () => [],
    },
    sendingEditar: {
      type: Object,
      default: () => ({}),
    },
    editando: {
      type: Boolean,
      default: false,
    },
  },  
  emits: ["updateValues", "update:modelValue"],
  data() {
    return {
      multiNumero: false,
      msisdn: "",
      codigo: "",
      seleccionTipoEnvio: "",
      modalImportar: false,
      selectedList: "",
      selectedSeg: "",
      selectedTag: "",
      iconoSelect: {
        regular,
        solid,
        pen,
      },
      valorIcono: [],
      selectedIdentificador: null,
      seleccionSegmentos: [],
      seleccionTags: [],
      seleccionListas: [],
      identificadores: [],
      selectedCondicion: null,
      valor: "",
      search: "", // Variable para almacenar la entrada del buscador
      contactLista: [],
      archivoExcel: null,
      fileSingle: [],
      active: false,
      selectedSender: "",
      selectedListContValue: null,
      campaingName: this.sendingEditar.name,
      selectedListCont: [],
      responseContact: [],
      responseSenderSms: [],
      responseSenderSmsCustomer: [],
      responseContactGroups: [],
      switchValue: false,
      email: "",
      selectedCountry: "",
      selectedGenero: "",
    };
  },
  computed: {
    validarCampanna() {
      if (!this.campaingName || this.campaingName.trim() === '') {
        // El campo está vacío o solo contiene espacios en blanco
        return false;
      }

      // Luego, valida que solo contenga letras y números
      return soloLetras_Num(this.campaingName);
    },
    validarBoton() {
      if ((this.validarCampanna && (this.seleccionSegmentos[0] || this.identificadores[0] || this.seleccionTags[0]))){
        return true
      }else return !!(this.validarCampanna && this.seleccionTipoEnvio === "individual" && this.msisdn && this.codigo);
    },
    segment() {
      return this.seleccionSegmentos;
    },
    tags() {
      return this.seleccionTags;
    },
    ident() {
      return this.identificadores;
    },
    data() {
      return {
        tags: this.tags,
        segment: this.segment,
        filters: this.ident,
        configuration: [
          {
            id: null,
            type: "multisource",
            condition: this.multiNumero,
            value: null,
          }
        ],
      };
    },
    filteredData() {
      if (!Array.isArray(this.$store.state.get_filtros)) {
        return [];
      }

      return this.$store.state.get_filtros.filter((lista, index) => {
        const contactName = lista.firstName ?? "";
        const contact_last_name = lista.lastName ?? "";
        const msisdn = lista.msisdn ?? "";

        // Asignar el estado de los iconos para cada elemento filtrado
        this.valorIcono.push({
          estado: false,
          name: "icono_"+index,
        });

        return (
            contactName.toLowerCase().includes(this.search.toLowerCase()) ||
            contact_last_name.toLowerCase().includes(this.search.toLowerCase()) ||
            msisdn.toLowerCase().includes(this.search.toLowerCase())
        );
      });
    },
  },
  watch: {
    sendingEditar: {
      handler(newValidForm) {
        this.campaingName = newValidForm.name;
        this.msisdn = newValidForm.phone;
        this.codigo = newValidForm.phoneCode;

        const filterstr = newValidForm.filterstr || {};
        this.seleccionSegmentos = filterstr.segment || [];
        this.seleccionTags = filterstr.tags || [];
        this.identificadores = filterstr.filters || [];

        // Verifica si los arreglos tienen elementos
        if (Array.isArray(filterstr.segment) && filterstr.segment.length > 0) {
          this.seleccionTipoEnvio = "segmento";
        } else if (Array.isArray(filterstr.tags) && filterstr.tags.length > 0) {
          this.seleccionTipoEnvio = "tags";
        } else if (Array.isArray(filterstr.filters) && filterstr.filters.length > 0){
          this.seleccionTipoEnvio = "lista";
        } else {
          this.seleccionTipoEnvio = "";
        }
        this.obtenerContactosCamp();
        this.obtenerSmsSender();
        // Actualiza otras propiedades según sea necesario
      },
      immediate: true,
      deep: true,
    },
    '$store.state.seleccion': {
      handler(newValidForm) {
        if (newValidForm === "SMS") {
          this.seleccionTipoEnvio = "lista";
        }
      },
      immediate: true,
      deep: true,
    },
  },

  mounted() {
    this.obtenerContactosCamp();
    //this.obtenerSmsSender();
    this.$store.getters.arrayCabeceras();
    if (this.$store.state.seleccion === "SMS") {
      this.seleccionTipoEnvio = "lista";
    }
    /*Dropzone.autoDiscover = false;
    const dropzoneElement = document.getElementById("dropzone");

    const myDropzone = new Dropzone(dropzoneElement, {
      url: "/file-upload",
      acceptedFiles: ".csv,.xls,.xlsx",
      addRemoveLinks: true,
      dictDefaultMessage: "Arrastra tu archivo aquí.",
    });

    myDropzone.on("addedfile", (file) => {
      // Acción al agregar un archivo
      // Puedes acceder al archivo cargado usando el objeto 'file'

      this.cargarArchivo(file, myDropzone);
    });*/
  },
  methods: {
    reemplazarEspacios() {
      // Reemplazar los espacios con guiones bajos
      this.campaingName = this.campaingName.replace(/\s+/g, "_");
    },
    async closeModal() {
      await this.obtenerSmsSender();
      this.modalImportar = false;
    },
    abrirImportar() {
      this.modalImportar = true;
    },
    addToSelectedListCont() {
      // Verificar si el valor ya está en el arreglo antes de agregarlo
      if (this.selectedListCont.indexOf(this.selectedListContValue) === -1) {
        this.selectedListCont.push(this.selectedListContValue);
      }
    },
    editSelectedSender() {
      // Verificar si el valor ya está en el arreglo antes de agregarlo
      alert(JSON.stringify(this.selectedSender));
    },
    // Método que se llama cuando se hace clic en el botón "Siguiente"
    async nextStepValor() {
      if (this.seleccionTipoEnvio === "lista" && this.validarCampanna) {
        await this.obtenerContactos()
        if (this.$store.state.get_filtros[0]) {
          this.$emit("updateValues", {
            contactLista: this.contactLista,
            data: this.data,
            sender: this.selectedSender,
            campaingName: this.campaingName,
            seleccionTipoEnvio: this.seleccionTipoEnvio,
          });
          this.$parent.nextStep();
        }
      } else if ((this.seleccionTipoEnvio === "segmento" || this.seleccionTipoEnvio === "tags") && this.validarCampanna) {
        this.$emit("updateValues", {
          contactLista: this.contactLista,
          data: this.data,
          sender: this.selectedSender,
          campaingName: this.campaingName,
          seleccionTipoEnvio: this.seleccionTipoEnvio,
        });
        this.$parent.nextStep();
      } else if (this.seleccionTipoEnvio === "individual" && this.validarCampanna) {
        this.contactLista.push(
            {
          "contactPlannerId":null,
          "firstName":null,
          "lastName":null,
          "address":null,
          "msisdn":this.codigo + this.msisdn,
          "email":null,
          "birthDate":null,
          "company":null,
          "gender":null,
          "tax":null,
          "tags":null,
          "contactPlanerTagList":[
            {
              "contactPlannerTagId":null,
              "tagId":null
            }
          ],
          "segmentId":null,
          "active":true,
          "createdAt":null,
          "updatedAt":null,
          "country":null,
          "customerId":null,
          "contactGroupId":null,
          "customContactPlannerList":[
            {
              "customContactPlannerId":null,
              "contactHeader":null,
              "customerFieldId":{
                "customerFieldId":null,
                "customerFieldName":null
              }
            }
          ]
        });
        this.$emit("updateValues", {
          contactLista: this.contactLista,
          data: this.data,
          sender: this.selectedSender,
          campaingName: this.campaingName,
          seleccionTipoEnvio: this.seleccionTipoEnvio,
        });
        this.$parent.nextStep();
      }
    },
    /*cargarArchivo(file, myDropzone) {
      const input = document
        .getElementById("dropzone")
        .querySelector("input[type='file']");
      const selectedFile = file || input.files[0];
      if (selectedFile) {
        const reader = new FileReader();
        reader.onload = (e) => {
          try {
            const data = new Uint8Array(e.target.result);
            const workbook = XLSX.read(data, { type: "array" });
            const sheetName = workbook.SheetNames[0];
            const sheet = workbook.Sheets[sheetName];

            // Guardar la hoja en una variable de datos
            this.archivoExcel = XLSX.utils.sheet_to_json(sheet, { header: 1 });

            let user = getUserSub() != null ? getUserSub() : getUser();

            let header = "";
            let dataTotal = [];

            var cheader = 0;
            //crea header
            if (data.length > 0) {
              //SE ACTIVA LECTURA DE EXCEL DE ABERTIS
              if (
                user.customer.customerId === 210 ||
                user.customer.customerId === 209 ||
                user.customer.customerId === 219
              ) {
                header = "###idsmmcc###|*#*idms2*#*";

                //LIMPIO LA DATA POR ESPACIOS EN BLANCO O DATOS INVALIDOS
                this.archivoExcel.forEach(function (key) {
                  var phone = "";
                  var phoneCode = "569";
                  //var field = "";
                  phone = key[3];
                  //field+=(key[2])+"|";
                  //field+=(key[0])+"|";
                  //VALIDA QUE SEA SOLO NUMERO
                  if (
                    phone !== "" &&
                    /^([0-9])*$/.test(phone) &&
                    /^([0-9])*$/.test(phoneCode) &&
                    phone.toString().length > 7
                  ) {
                    // console.log("subir : "+phoneCode)
                    if (phone.toString().length > 8) {
                      key[3] = phone
                        .toString()
                        .substring(
                          phone.toString().length - 8,
                          phone.toString().length
                        );
                    }
                    dataTotal.push(key);
                  }
                });
              } else {
                this.archivoExcel[0].forEach(function (key) {
                  cheader++;
                  if (cheader > 2) {
                    header += key + "|";
                  }
                });

                //LIMPIO LA DATA POR ESPACIOS EN BLANCO O DATOS INVALIDOS
                this.archivoExcel.forEach(function (key) {
                  var phone = "";
                  var phoneCode = "";
                  //var field = "";
                  for (var j = 0; j < key.length; j++) {
                    if (j === 0) {
                      phoneCode = key[j];
                    }
                    if (j === 1) {
                      phone = key[j];
                    }
                    /!*if(j > 1){
                      field+=(key[j])+"|";
                    }*!/
                  }
                  //VALIDA QUE SEA SOLO NUMERO
                  if (
                    phone !== "" &&
                    /^([0-9])*$/.test(phone) &&
                    /^([0-9])*$/.test(phoneCode) &&
                    phone.toString().length > 7
                  ) {
                    // console.log("subir : "+phoneCode)
                    if (phone.toString().length > 8) {
                      key[1] = phone
                        .toString()
                        .substring(
                          phone.toString().length - 8,
                          phone.toString().length
                        );
                    }
                    dataTotal.push(key);
                  }
                });
              }
            }

            if (
              this.contadorContactImportados + dataTotal.length > 20000 &&
              user.customer.uploadActive === false
            ) {
              Swal.fire({
                title: "Error limite de contactos excedidos (20.000)",
                buttonsStyling: false,
                customClass: {
                  confirmButton: 'btn btn-celcom-orange',
                },
                icon: 'error'
              });
              this.$emit("update:modelValue", 0);
              myDropzone.removeAllFiles(true);
              return false;
            }

            this.$parent.guardarNuevaLista(
              header,
              selectedFile.name,
              dataTotal
            );
            myDropzone.removeAllFiles(true);
          } catch (error) {
            console.error("Error al procesar el archivo:", error);
          }
        };

        reader.readAsArrayBuffer(selectedFile);
      }
    },*/
    onSwitchChange(event) {
      this.switchValue = event.target.checked;
    },
    async obtenerContactosCamp() {
      try {
        let user = getUserSub() != null ? getUserSub() : getUser();

        let js = {
          time_expired: horaAhora(),
          customer_user: encodeURIComponent(JSON.stringify(user)),
          customer: encodeURIComponent(JSON.stringify(user.customer)),
        };

        var body = new URLSearchParams();
        body.append("TOKEN", encryptAES(js));

        const response = await listaContactosCamp(body);
        // Almacena la respuesta en la variable de datos
        if (response.data.response === "200") {
          this.responseContact = response.data.data;
          const lista = this.responseContact.find((list) => list.contactGroupId === this.identificadores[0].id);
          if (lista) {
            this.seleccionListas.push(lista);
          }
        } else {
          console.log("No se encontró lista");
        }
      } catch (error) {
        console.error("Error al obtener datos", error);
      }
    },
    async obtenerSmsSender(){
      await this.$store.getters.obtenerSenderSms();
      await this.$store.getters.obtenerContactGroups();
      this.responseSenderSms = this.$store.state.responseSenderSms;
      this.responseSenderSmsCustomer = this.$store.state.responseSenderSmsCustomer;
      if (this.$store.state.seleccion === "SMS") {
        this.selectedSender = this.responseSenderSmsCustomer.find(
            (sender) => sender.senderMsisdnId === this.sendingEditar.senderId
        );
      }
    },
    actualizarSeleccionTags() {
      // Verifica si el elemento ya existe en this.seleccionTags
      if (!this.seleccionTags.includes(this.selectedTag)){
          // Si no existe, agrégalo
        this.seleccionTags.push(this.selectedTag);
    }
    },
    quitarseleccionTags(id) {
      this.seleccionTags = this.seleccionTags.filter(
        (tag) => tag.segmentTagId !== id
      );
    },
    actualizarSeleccionSegmentos() {
      // Verifica si el elemento ya existe en this.seleccionSegmentos
      if (!this.seleccionSegmentos.includes(this.selectedSeg)) {
        // Si no existe, agrégalo
        this.seleccionSegmentos.push(this.selectedSeg);
      }
    },
    quitarSeleccionSegmentos(id) {
      this.seleccionSegmentos = this.seleccionSegmentos.filter(
        (seg) => seg.segmentTagId !== id
      );
    },
    deleteIdent(index) {
      if (index >= 0 && index < this.identificadores.length) {
        this.identificadores.splice(index, 1);
        // La función splice elimina 1 elemento en la posición 'index'
      } else {
        console.error("Índice fuera de rango");
      }
    },
    async obtenerContactos() {
     await this.$store.getters.getFiltro(this.data);
     if (!this.$store.state.get_filtros[0]){
       Swal.fire({
         title: "No existen contactos con esos datos",
         icon: "error",
         buttonsStyling: false,
         customClass: {
          confirmButton: 'btn btn-celcom-orange',
         },
       });
     }
    },
    addIdent() {
      // Agrega un identificador a la lista de identificadores
      this.identificadores.push({
        id: this.selectedIdentificador.id,
        type: this.$store.getters.getTipo(this.selectedIdentificador.name),
        condition: this.selectedCondicion,
        value: this.valor,
      });
      this.selectedIdentificador = "";
      this.valor = "";
      this.selectedCondicion = "";
      this.selectedList = '';
    },
    addIdentList() {
      // Agrega un identificador a la lista de identificadores
      if (!this.identificadores.includes(this.selectedList.contactGroupId)) {
        this.identificadores.push({
          id: this.selectedList.contactGroupId,
          type: "LIST",
          condition: "equals",
          value: null,
        });
      }
      if (!this.seleccionListas.includes(this.selectedList)) {
        this.seleccionListas.push(this.selectedList);
      }
      this.selectedIdentificador = "";
      this.valor = "";
      this.selectedCondicion = "";
      this.selectedList = '';
    },
    quitarLista(id) {
      this.seleccionListas = this.seleccionListas.filter((list) => list.contactGroupId !== id);
      this.identificadores = this.identificadores.filter((ident) => ident.id !== id);
    },
    cambiarSelect(lista, index) {
      if (lista && !this.valorIcono[index].estado) {
        this.contactLista.push(lista);
      }else if (lista && this.valorIcono[index].estado) {
        this.contactLista = this.contactLista.filter((item) => item !== lista);
      }
      this.valorIcono[index].estado = !this.valorIcono[index].estado;
    },
    limpiarDatos(){
      this.data = [];
      this.selectedSeg = "";
      this.selectedTag = "";
      this.seleccionSegmentos = [];
      this.seleccionTags = [];
      this.contactLista = [];
      this.$store.state.get_filtros = [];
      this.msisdn = "";
    }
  },
};
</script>
<style scoped>
.code {
  width: 70px;
  margin-bottom: 0;
}
/* Agrega estilos según tus necesidades */
.modal {
  display: none;
}

.modal.is-active {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
}

.modal-close {
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
  background: none;
  border: none;
}
</style>
