// api.js
import axios from 'axios';
import Config from "@/config/servicios/campana/config";
export const obtenerSender = (body) => {
    return axios.post(Config.url_service + Config.sender_available,body,{
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        }});
};
export const obtenerSenderCustomer = (body) => {
    return axios.post(Config.url_service + Config.sender_customer,body,{
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        }});
};
export const sender_enable = (body) => {
    return axios.post(Config.url_service + Config.sender_enable,body,{
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        }});
};
export const sender_disable = (body) => {
    return axios.post(Config.url_service + Config.sender_disable,body,{
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        }});
};
export const contact_groups = (body) => {
    return axios.post(Config.url_service + Config.contact_groups,body,{
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        }});
};
// Agrega más funciones según tus necesidades