import { quitCanal } from "@/config/servicios/canales/servicesCanales";


export const quitarCanal = ( channelId ) => {

    return quitCanal(channelId)
        .then((response) => {
        console.log(response.data);
    })
        .catch((error) => {
        console.error("Error al enviar los datos:", error);
    });
};