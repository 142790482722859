<template>
  <div
      class="card multisteps-form__panel"
      data-animation="FadeIn"
  >
    <div class="card-header border-1">
      <h5 class="font-weight-bolder m-0">Inserte datos del Contacto</h5>
    </div>
    <div class="card-body multisteps-form__content">
      <div class="row">
        <div class="col-12 col-sm-6">
          <label>Seleccione Tags</label>
          <select
              v-model="selectedTag"
              id="opcionesTags"
              class="multisteps-form__select form-control mb-2"
              name="opciones-Tags"
              @change="updateTags"
          >
            <option selected="selected" disabled value="">...</option>
            <option value="Perro">Perro</option>
            <option value="Perico">Perico</option>
          </select>
        </div>
        <div class="col-12 col-sm-6 mt-sm-0">
          <label>Rut</label>
          <argon-input
              id="contactRut"
              v-model="rut"
              class="multisteps-form__input mb-2"
              type="text"
              placeholder="11111111-1"
          />
          <div v-if="!validarRut" :class="{ 'is-invalid-d': !validarRut }">
            <p class="mb-0 text-sm">Debe tener el formato XXXXXXX-X</p>
          </div>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-12 col-sm-6">
          <label class="form-label mt-2">Tags</label>
          <input
              id="selTags"
              v-model="tags"
              class="form-control mb-2"
              type="text"
              placeholder="Enter something"
          />
          <div v-if="!validarVacios" :class="{ 'is-invalid-d': !validarVacios }">
            <p class="mb-0 text-sm">Debe elegir un Tag al menos</p>
          </div>
        </div>
          <div class="col-12 col-sm-6 mt-3 mt-sm-0">
          <label>Fuente</label>
          <select
              v-model="selectedSource"
              id="selectedSource"
              class="multisteps-form__select form-control"
              name="opciones-Source"
          >
            <option selected="selected" disabled value="">Elegir</option>
            <option value="Web">Web</option>
          </select>
        </div>
      </div>
    </div>
    <div class="card-footer border-1 d-flex mt-2">
      <argon-button
          type="button"
          color="light"
          variant="gradient"
          class="js-btn-prev"
          @click="$parent.prevStep"
      ><i class="fas fa-chevron-left me-2"></i> Anterior</argon-button>
      <argon-button
          :disabled="!(validarRut)"
          type="button"
          color="dark"
          variant="gradient"
          class="ms-auto js-btn-next"
          @click="$parent.addContactos"
      >Agregar</argon-button>
    </div>
  </div>
</template>

<script>
import ArgonInput from "@/components/ArgonInput.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import Choices from "choices.js";
import {validarRut, validarVacio} from "@/config/validaciones";
export default {
  name: "UserAddress",
  components: {
    ArgonInput,
    ArgonButton,
  },
  computed: {
    validarRut() {
      return validarRut(this.rut);
    },
    validarVacios() {
      return validarVacio(this.tags);
    },
  },
  data() {
    return {
      rut: "",
      selectedSource: "",
      selectedTag: "", // Nuevo dato para almacenar la opción seleccionada
      tags: "",       // Nuevo dato para almacenar los tags seleccionados
    };
  },
  methods: {
    validarVacio,
    updateTags() {
      // Agregar el tag seleccionado al string de tags
      if (this.selectedTag && this.selectedTag !== "..." && !this.tags.includes(this.selectedTag)) {
        if (this.tags.length > 0) {
          this.tags += `, ${this.selectedTag}`;
        } else {
          this.tags = this.selectedTag;
        }
      }
    },
  },
  mounted() {
    if (document.getElementById("opcionesTags")) {
      var element = document.getElementById("opcionesTags");
      new Choices(element, {
        searchEnabled: false,
        allowHTML: true,
      });
    }
  },
};
</script>
