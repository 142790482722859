<template>
  <div class="container-fluid py-5">
    <div class="row">
      <div class="col-12">
        <div class="multisteps-form">
          <!--form panels-->
          <div class="row">
            <div class="col-12 col-lg-10 m-auto">
              <form class="multisteps-form__form">
                <programados-table :programadoData="response"></programados-table>
              </form>
            </div>
          </div>
        </div>
      </div>
      <modal-delete :is-visible="modalVisible" close-modal="">
        <p class="text-center"><b>¿Estás seguro de que deseas eliminar?</b></p>
        <div class="mt-lg-4 d-flex justify-content-between">
          <button class="btn bg-gradient-danger mt-2 mb-0" @click="deleteProgramado">Eliminar</button>
          <button class="btn bg-gradient-dark mt-2 mb-0" @click="closeModal">Cancelar</button>
        </div>
      </modal-delete>
    </div>
  </div>
</template>

<script>

import {encryptAES, horaAhora, getUser, getUserSub} from "@/config/servicios/campana/util";
import {obtenerLista, deleteProgramado} from "@/config/servicios/campana/envios/servicesProgramados";
import {listaContactosCamp} from "@/config/servicios/campana/contactos/servicesContactosCamp";
import programadosTable from "@/views/envios/components/ProgramadosTable.vue";
import ModalDelete from "@/components/Modal/ModalDelete.vue";

export default {
  name: "Programados",
  components: {
    ModalDelete,
    programadosTable,
  },
  data() {
    return {
      response: {},
      responseContact: [],
      modalEditar: false,
      modalVisible: false,
      Programado: "",
    };
  },
  mounted() {
    this.cargarDatos();
    this.$store.dispatch('guardarProgramado', null);
  },
  methods: {
    openModal(programado) {
      this.Programado = programado;
      this.modalVisible = true;
    },
    closeModal() {
      this.modalVisible = false;
      this.modalEditar = false;
      this.idProgramado = "";
    },
    async deleteProgramado() {

      if (this.Programado) {
        try {

          let user = (getUserSub()!=null) ? getUserSub() :getUser();
          let js = {
            time_expired: horaAhora(),
            customer: encodeURIComponent(JSON.stringify(user.customer)),
            customer_user:encodeURIComponent(JSON.stringify(user)),
            sending_sms: encodeURIComponent(JSON.stringify(this.Programado)),
            "application_id":1,
          };

          var body = new URLSearchParams();
          body.append("TOKEN", encryptAES(js));

          const response = await deleteProgramado(body);

          if (response.data.response === "200") {
            console.log("Plantilla Eliminada ", response.data.data);
            this.closeModal();
            this.cargarDatos();
          } else {
            console.log("No se pudo eliminar plantilla");

          }
          console.log("Envio Programado eliminado" );
        } catch (error) {
          console.error("Error al eliminar Envio Programado", error);
        } finally {
          this.modalVisible = false;
          await this.cargarDatos();
        }
      }
    },
    cargarDatos(){
      this.obtenerLista();
      this.obtenerContactosCamp();
    },
    editarProgramda(programado) {
      this.$store.dispatch('guardarProgramado', programado);
      this.$router.push({
        name: "Programar Envio",
        query: {sending_sms: programado.sendingSmsId},
      });
    },
    async obtenerLista(){
      try {
        let user = (getUserSub()!=null) ? getUserSub() :getUser();

        let js = {
          "time_expired":horaAhora(),
          "customer_user":encodeURIComponent(JSON.stringify(user)),
          "customer":encodeURIComponent(JSON.stringify(user.customer))
        }

        var body = new URLSearchParams();
        body.append("TOKEN", encryptAES(js));


        const response = await obtenerLista(body);
        // Almacena la respuesta en la variable de datos
        if (response.data.response === "200") {
          this.response = response.data.data;
        } else {
          console.log("No se encontró lista");

        }
      } catch (error) {
        console.error("Error al obtener datos", error);
      }
    },
    async obtenerContactosCamp(){
      try {
        let user = (getUserSub()!=null) ? getUserSub() :getUser();

        let js = {
          "time_expired":horaAhora(),
          "customer_user":encodeURIComponent(JSON.stringify(user)),
          "customer":encodeURIComponent(JSON.stringify(user.customer))
        }

        var body = new URLSearchParams();
        body.append("TOKEN", encryptAES(js));


        const response = await listaContactosCamp(body);
        // Almacena la respuesta en la variable de datos
        if (response.data.response === "200") {

          this.responseContact = response.data.data;
        } else {
          console.log("No se encontró lista");

        }
      } catch (error) {
        console.error("Error al obtener datos", error);
      }
    },
    findCustomerByContactGroupId(targetContactGroupId) {
      this.foundCustomer = this.responseContact.find(
          (customer) => customer.contactGroupId === targetContactGroupId
      );

      if (this.foundCustomer) {
        const { name, validos } = this.foundCustomer;
        return { name, validos };
        // Realiza otras acciones con el Customer encontrado si es necesario.
      } else {
        console.log("No se encontró Customer con contactGroupId:", targetContactGroupId);
        return false;
      }
    },
  },
};
</script>
<style scoped>
/* Agrega estilos según tus necesidades */
.modal {
  display: none;
}

.modal.is-active {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
}

.modal-close {
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
  background: none;
  border: none;
}
.buttons {
  margin: 8px;
}
</style>