<template>
  <div
      class="card multisteps-form__panel"
      data-animation="FadeIn"
  >
    <div class="multisteps-form__content">
        <!-- Card body -->
      <div class="card-header border-1">
        <h5 class="font-weight-bolder m-0">Vista previa de la BlackList</h5>
      </div>
      <div class="card-body">
        <table class="table align-items-center mb-0">
          <thead>
          <tr class="thead-light">
            <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Código</th>
            <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Número</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(row, index) in createdContact" :key="index">
                <td class="align-middle text-left text-sm">{{ row.blacklistPK.codePhone }}</td>
                <td class="align-middle text-left text-sm">{{ row.blacklistPK.phone }}</td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="card-footer border-1 mt-4">
        <argon-button @click.prevent="finalizarAgregar" class="d-block ms-auto mb-0">
          Finalizar
        </argon-button>
      </div>
    </div>
  </div>
</template>

<script>
import ArgonButton from "@/components/ArgonButton.vue";

export default {
  name: "ListaAdd2",
  components: {
    ArgonButton
  },
  props: {
    createdList: {
      type: Array,
      default: () => {
        return [];
      },
    },
    createdContact: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  computed: {
  },
  data() {
    return {

    };
  },
  methods: {
    async nextStepValor() {
      await this.$parent.envioServices();
    },
    async finalizarAgregar() {
      await this.$parent.finalizarAgregar();
    },
  },
  mounted() {

  },
};
</script>
