<template>
  <div class="container-fluid py-5">
    <div class="row">
      <div class="col-12">
        <div class="multisteps-form">
          <!--form panels-->
          <div class="row">
            <div class="col-12 col-lg-10 m-auto">
              <form class="multisteps-form__form">
                <plantillas-table :table-data="tableData"/>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {encryptAES, horaAhora, getUser} from "@/config/servicios/campana/util";
import PlantillasTable from "@/views/envios/comprar-creditos/components/HistorialComprasTable.vue";
import {payment_gettransaction} from "@/config/servicios/Dashboard/servicesDashboard";

export default {
  name: "ListarHistorialCompras",
  components: {
    PlantillasTable,
  },
  data() {
    return {
      tableData: [],
    };
  },
  created() {
  },
  mounted() {
    this.obtenerLista();
  },
  methods: {
    async obtenerLista(){
      let self = this;
      let user = getUser();

      let js = {
        time_expired: horaAhora(),
        customer: encodeURIComponent(JSON.stringify(user.customer)),
        email: "",
        password: "",
        userPhone: "",
        plan: "",
        transaction: "",
      };

      var body = new URLSearchParams();
      body.append("TOKEN", encryptAES(js));

      await payment_gettransaction(body)
          .then(function (response) {
            console.log(response);

            if (response.data.response === "200") {
              self.tableData = response.data.data;
              self.tableData = self.tableData.filter(lista => lista.isFlex === true);
              console.log(response.data.data);
            } else {
              console.log("No se encontraron productos");
              alert("No se encontraron productos");
            }
          })
          .catch(function (error) {
            console.log("No se encontraron productos", error);
            alert("No se encontraron productos");
          });
    },
  },
};
</script>
<style scoped>
/* Agrega estilos según tus necesidades */
.modal {
  display: none;
}

.modal.is-active {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
}

.modal-close {
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
  background: none;
  border: none;
}
.buttons {
  margin: 8px;
}
.espacio{
  margin-bottom: 0;;
}
.espacio1{
  margin-top: 0;
  margin-left: 1px;
  margin-right: 1px;
}
label {
  margin-left: 0;
}
</style>