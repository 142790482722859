<template>
  <span @click="toggleEmojiPicker" class="emoji-button">😊</span>
  <div class="row">
    <Picker v-if="showEmojiPicker" :data="emojiIndex" set="apple" @select="showEmoji" />
  </div>
</template>

<script>
// Import data/twitter.json to reduce size, all.json contains data for
// all emoji sets.
import data from "emoji-mart-vue-fast/data/all.json";
// Import default CSS
import "emoji-mart-vue-fast/css/emoji-mart.css";

// Vue 3, import components from `/src`:
import { Picker, EmojiIndex } from "emoji-mart-vue-fast/src";

// Create emoji data index.
// We can change it (for example, filter by category) before passing to the component.
let emojiIndex = new EmojiIndex(data);

export default {
  name: "App",
  components: {
    Picker
  },
  props: {
      valueBody: {
        type: Boolean,
        default: false,
      },
  },
  emits: ['emojiSelected', 'emojiSelectedBody'],
  data() {
    return {
      showEmojiPicker: false, // Controla la visibilidad del selector de emojis
      emojiIndex: emojiIndex,
      emojisOutput: ""
    };
  },

  methods: {
    toggleEmojiPicker() {
      // Alternar la visibilidad del selector de emojis
      this.showEmojiPicker = !this.showEmojiPicker;
    },
    showEmoji(emoji) {
      if (this.valueBody) {
        this.$emit('emojiSelectedBody', emoji.native);
      } else {
        this.$emit('emojiSelected', emoji.native);
      }
    }
  }
};
</script>

<style>
/* Estilos para el botón de emoji */
.emoji-button {
  cursor: pointer;
  font-size: 18px; /* Ajusta esto según tus necesidades */
  border: none;
  background: none;
}
</style>