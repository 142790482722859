// api.js
import axios from 'axios';
import {decryptAES} from "@/config/servicios/campana/util";

const API_BASE_URL = 'https://automata2.celcom.cl/flex/api/';

const urlCustomerUserId = () => {
// Obtener el valor del local storage
    let encryptedToken = localStorage.getItem('user');
    const userDataString = decryptAES(encryptedToken);

// Convertir la cadena JSON a un objeto JavaScript
    const userData = JSON.parse(userDataString);

// Obtener el valor de customerId
    return userData.customerUserId;
};
export const listCanal = () => {
    const API_LIST_CONT = 'channel/admin/'+ urlCustomerUserId() +'/list';
    return axios.get(`${API_BASE_URL}`+ API_LIST_CONT);
};
export const listChannelByCustomer = () => {
    const API_LIST_CONT = 'channel/customer/'+ urlCustomerUserId() +'/all';
    return axios.get(`${API_BASE_URL}`+ API_LIST_CONT);
};
export const agreCanal = (datos) => {
    console.log(datos);
    const API_LIST_ADD = 'channel/customer/'+ urlCustomerUserId() +'/2/add';
    return axios.post(`${API_BASE_URL}`+API_LIST_ADD, datos);
};
export const asigCanal = (channelId) => {
    const API_LIST_ADD = 'channel/customer/'+ urlCustomerUserId() +'/'+ channelId +'/add';
    return axios.post(`${API_BASE_URL}`+API_LIST_ADD,{});
};
export const quitCanal = (channelId) => {
    const API_LIST_QUIT = `channel/customer/${urlCustomerUserId()}/${channelId}/delete`;
    return axios.post(`${API_BASE_URL}${API_LIST_QUIT}`, 1);
};
export const editCanal = (datos) => {
    const API_LIST_EDIT = 'channel/admin/'+ urlCustomerUserId() +'/edit';
    return axios.post(`${API_BASE_URL}`+API_LIST_EDIT, datos);
};
export const editCanalUser = (channelId, datos) => {
    const API_LIST_EDIT = 'channel/customer/' + urlCustomerUserId() + '/' + channelId + '/edit';
    return axios.post(`${API_BASE_URL}`+API_LIST_EDIT, datos);
};
export const deleteCanal = (datos) => {
    const API_LIST_DELETE = 'channel/customer/1/2/delete';
    return axios.post(`${API_BASE_URL}`+API_LIST_DELETE,datos);
};
// Agrega más funciones según tus necesidades