// api.js
import axios from 'axios';
import Config from "@/config/servicios/campana/config";
export const payment_plataforms = (body) => {
    return axios.post(Config.url_service+Config.payment_plataforms,body,{
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        }});
};
export const payment_init_payment = (body) => {
    return axios.post(Config.url_service+Config.payment_init_payment,body,{
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        }});
};
