import { editCustomerUser } from "@/config/servicios/customerUser/servicesCustomerUser"
import {llenarDatosCustomerUserEdit} from "@/config/servicios/customerUser/plantillaCustomerUser";

export const editCustomerU = (
    id,
    email,
    password,
    adminUser,
    active) => {
    const jsonDataCustomer = llenarDatosCustomerUserEdit(
        id,
        email,
        password,
        null,
        adminUser,
        active);

    return editCustomerUser(jsonDataCustomer)
        .then((response) => {
            console.log(response.data);
        })
        .catch((error) => {
            console.error("Error al enviar los datos:", error);
        });
};