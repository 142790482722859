// api.js
import axios from 'axios';
import {getTag} from "@/config/servicios/tag/servicesTag";
import {decryptAES} from "@/config/servicios/campana/util";

const API_BASE_URL = 'https://automata2.celcom.cl/flex/api/';

const urlCustomerId = () => {
// Obtener el valor del local storage
    let encryptedToken = localStorage.getItem('user');
    const userDataString = decryptAES(encryptedToken);

// Convertir la cadena JSON a un objeto JavaScript
    const userData = JSON.parse(userDataString);

// Obtener el valor de customerId
    return userData.customer.customerId;
};
export const testSftp = (host, user, password, port, path) => {
    console.log("testSftp")
    const API_LIST_CONT = 'contact/sftp/'+ urlCustomerId() + '/test';
    return axios.post(`${API_BASE_URL}`+ API_LIST_CONT,data(host, user, password, port, path));
};

export const addSftp = (host, user, password, port, path) => {
    console.log("addSftp")
    const API_LIST_CONT = 'contact/sftp/'+ urlCustomerId() + '/add';
    return axios.post(`${API_BASE_URL}`+ API_LIST_CONT,data(host, user, password, port, path));
};
export const listSourceConfiguration = () => {
    console.log("listSourceConfiguration")
    const API_LIST_CONT = 'contact/customerConfiguration/'+ urlCustomerId() + '/list';
    return axios.post(`${API_BASE_URL}`+ API_LIST_CONT,1);
};
export const getFiles = (id) => {
    console.log("listSourceConfiguration")
    const API_LIST_CONT = 'contact/sftp/'+ urlCustomerId() + '/' + id +'/files';
    return axios.post(`${API_BASE_URL}`+ API_LIST_CONT,1);
};
export const deleteOringen = (id) => {
    console.log("deleteOringen")
    const API_LIST_CONT = 'contact/sftp/'+ urlCustomerId() + '/' + id +'/delete';
    return axios.post(`${API_BASE_URL}`+ API_LIST_CONT,1);
};
export const editOringen = (id, host, user, password, port, path) => {
    console.log("editOringen")
    const API_LIST_CONT = 'contact/sftp/'+ urlCustomerId() + '/' + id +'/edit';
    return axios.post(`${API_BASE_URL}`+ API_LIST_CONT,dataEdit(id, host, user, password, port, path));
};
export const loadFile = async (id, file, tags, segmentId) => {

    // Obtener la lista completa de tags del servidor
        const tagsResponse = await getTag();
        const allTags = tagsResponse.data;

    // Crear un mapa para buscar fácilmente los IDs por nombre
    const tagMap = new Map();
    allTags.data.forEach(tag => {
        tagMap.set(tag.customerTagSegmentName, tag.customerTagSegmentId);
    });

    // Obtener los IDs correspondientes a los nombres en el array
    const tagIds = tags.split(", ").map(tagName => tagMap.get(tagName));

    let fileArray = Array.from(file);

    const API_LIST_CONT = 'contact/sftp/'+ urlCustomerId() + '/' + id[0]['configId'] +'/load';
    return axios.post(`${API_BASE_URL}`+ API_LIST_CONT,dataLoad(fileArray, tagIds, segmentId));
};
function data(host, user, password, port, path){
        return {
            "host": host,
            "user": user,
            "password": password,
            "port": port,
            "path": path
        };
};
function dataEdit(id, host, user, password, port, path){
    return  {
        "customerSourceConfigurationId": id,
        "host": host,
        "user": user,
        "password": password,
        "port": port,
        "path": path
    };
};
export function dataLoad(file, tags, segmentId) {
    return {
        "files": file.map(file => ({ "file": file })),
        "tags": tags.map(tag => ({ "tagId": tag })),
        "segmentId": segmentId
    };
}
// Agrega más funciones según tus necesidades