<template>
    <div class="container-fluid py-5">
      <div class="row">
        <div class="col-12">
          <div class="multisteps-form">
            <!--form panels-->
            <div class="row">
              <div class="col-12 col-lg-10 m-auto">
                <form class="multisteps-form__form">
                  <origenes-table :origenData="response.data" />
                </form>
              </div>
            </div>
          </div>
        </div>
        <modal-delete :is-visible="modalVisible">
          <p><b>¿Estás seguro de que deseas eliminar?</b></p>
          <button class="btn buttons mb-0 bg-gradient-danger btn-sm null null my-sm-auto mt-2 mb-0" @click="deleteOringen">Eliminar</button>
          <button class="btn buttons mb-0 bg-gradient-dark btn-sm null null my-sm-auto mt-2 mb-0" @click="closeModal">Cancelar</button>
        </modal-delete>
        <modal :is-visible="modalEditar">
          <p><b>Edite el Contacto</b></p>
          <div class="multisteps-form__content">
            <div class="row mt-3">
              <div class="col-12 col-sm-6">
                <label>Host</label>
                <argon-input
                    id="contactName"
                    v-model="hostValorEdit"
                    class="multisteps-form__input"
                    type="text"
                    placeholder="xxx.xxx.xxx.xxx"
                />
              </div>
              <div class="col-12 col-sm-6">
                <label>User</label>
                <argon-input
                    id="msisdn"
                    v-model="userValorEdit"
                    class="multisteps-form__input"
                    type="text"
                    placeholder="User"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-sm-6">
                <label>Password</label>
                <argon-input
                    id="empresa"
                    v-model="passwordValorEdit"
                    class="multisteps-form__input"
                    type="text"
                    placeholder="xxxxxxx"
                />
              </div>
              <div class="col-12 col-sm-6 mt-3 mt-sm-0">
                <label>Port</label>
                <argon-input
                    id="email"
                    v-model="portValorEdit"
                    class="multisteps-form__input"
                    type="email"
                    placeholder="22"
                />
              </div>
              <div class="col-12 col-sm-6 mt-3 mt-sm-0">
                <label>Path</label>
                <argon-input
                    id="email"
                    v-model="pathValorEdit"
                    class="multisteps-form__input"
                    type="text"
                    placeholder="uploads"
                />
              </div>
            </div>
            <div class="button-row d-flex mt-2">
              <div class="text-white ms-auto mb-0 js-btn-next">
                <button
                    v-if="tipo === 'SFTP' && testButton"
                    type="button"
                    name="button"
                    class="btn buttons mb-0 bg-light btn-sm null null mt-2 mb-0"
                    @click.prevent="testSftp"
                >
                  Test
                </button>
                <button
                    v-if="tipo === 'FTP' && testButton"
                    type="button"
                    name="button"
                    class="btn buttons mb-0 bg-light btn-sm null null mt-2 mb-0"
                    @click.prevent="testFtp"
                >
                  Test
                </button>
                <button v-if="!testButton" class="btn buttons mb-0 bg-gradient-success btn-sm null null mt-2 mb-0" @click="editOrigenes">Editar</button>
                <button class="btn buttons mb-0 bg-gradient-dark btn-sm null null mt-2 mb-0" @click="closeModal">Cancelar</button>
              </div>
            </div>
          </div>
        </modal>
      </div>
    </div>
  </template>
  
  <script>
  import OrigenesTable from "./table/OrigenesTable.vue";
  import {listSourceConfiguration, deleteOringen, editOringen, testSftp} from "@/config/servicios/sftp/servicesSftp";
  import ModalDelete from "@/components/Modal/ModalDelete.vue";
  import Modal from "@/components/Modal/Modal.vue";
 /* import ArgonInput from "@/components/ArgonInput.vue";*/
  import Choices from "choices.js";
  import ArgonInput from "@/components/ArgonInput.vue";
  import {testFtp} from "@/config/servicios/ftp/servicesFtp";

  export default {
    name: "ListarOrigenes",
    components: {
      ArgonInput,
      /*ArgonInput,*/
      OrigenesTable,
      ModalDelete,
      Modal,
    },
    data() {
      return {
        tipo: null,
        testButton: true,
        hostValorEdit: null,
        userValorEdit: null,
        passwordValorEdit: null,
        portValorEdit: null,
        pathValorEdit: null,
        modalEditar: false,
        modalVisible: false,
        idSource: null,
        response: {}, // Aquí almacenarás la respuesta del servicio
      };
    },
    methods: {
      async deleteOringen() {
        if (this.idSource) {
          try {
            await deleteOringen(this.idSource);
            // Almacena la respuesta en la variable de datos
            console.log("Orignen eliminado" );
          } catch (error) {
            console.error("Error al obtener datos", error);
          } finally {
            this.modalVisible = false;
            await this.listSourceConfiguration();
          }
        }
      },
      closeModal() {
        this.modalVisible = false;
        this.modalEditar = false;
        this.testButton = true;
      },
      openModal(id) {
        this.idSource = id;
        this.modalVisible = true;
      },
      openModalEditar(origen) {
        const origenData = JSON.parse(JSON.stringify(origen));

        this.tipo = origenData.contactSourceAvailable.sourceAvailableName;
        this.idSource = origenData.customerSourceConfigurationId;
        this.hostValorEdit = origenData.host;
        this.userValorEdit = origenData.user;
        this.passwordValorEdit = origenData.password;
        this.portValorEdit = origenData.port;
        this.pathValorEdit = origenData.path;

        this.modalEditar = true;
      },
      async editOrigenes() {
        try {
          const response = await editOringen(this.idSource, this.hostValorEdit, this.userValorEdit, this.passwordValorEdit, this.portValorEdit, this.pathValorEdit);
          // Almacena la respuesta en la variable de datos
          console.log("Origen Editado: " + response);
        } catch (error) {
          console.error("Error al obtener datos", error);
        } finally {
          this.modalEditar = false;
          this.testButton = true;
          await this.listSourceConfiguration();
        }
      },
      async listSourceConfiguration() {
        try {
          const response = await listSourceConfiguration();
          // Almacena la respuesta en la variable de datos
          this.response = response.data;
        } catch (error) {
          console.error("Error al obtener datos", error);
        }
      },
      async testSftp() {
        try {
          const response = await testSftp(
              this.hostValorEdit,
              this.userValorEdit,
              this.passwordValorEdit,
              this.portValorEdit,
              this.pathValorEdit
          );
          // Almacena la respuesta en la variable de datos
          this.testSftpVar = response.data;
          console.log("Test SFTP: ", this.testSftpVar.data);
          if (this.testSftpVar.data){
            console.log("asdas")
            this.testButton = false;
          }
        } catch (error) {
          console.error("Error al obtener datos", error);
        }
      },
      async testFtp() {
        try {
          const response = await testFtp(
              this.hostValorEdit,
              this.userValorEdit,
              this.passwordValorEdit,
              this.portValorEdit,
              this.pathValorEdit
          );
          // Almacena la respuesta en la variable de datos
          this.testFtpVar = response.data;
          console.log("Test FTP: ", response);
          if (this.testFtpVar.data){
            this.testButton = false;
          }
        } catch (error) {
          console.error("Error al obtener datos", error);
        }
      },
    },
    mounted() {
      if (document.getElementById("opcionesTags")) {
        var element = document.getElementById("opcionesTags");
        new Choices(element, {
          searchEnabled: false,
          allowHTML: true,
        });
      }
      this.listSourceConfiguration(); // Llama a la función al montar el componente
    },
  };
  </script>
<style scoped>
/* Agrega estilos según tus necesidades */
.modal {
  display: none;
}

.modal.is-active {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
}

.modal-close {
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
  background: none;
  border: none;
}
.buttons {
  margin: 8px;
}
</style>