<template>
  <div class="nav-wrapper position-relative end-0">
    <ul class="nav nav-pills nav-fill p-1" role="tablist">
      <li class="nav-item">
        <a
          class="nav-link mb-0 px-0 py-1 active active"
          @click="cambArch"
          data-bs-toggle="tab"
          href="#"
          role="tab"
          aria-selected="true"
          >Archivo</a
        >
      </li>
      <li class="nav-item">
        <a
          @click="cambSftp"
          class="nav-link mb-0 px-0 py-1"
          data-bs-toggle="tab"
          href="#"
          role="tab"
          aria-selected="false"
          >SFTP</a
        >
      </li>
      <li class="nav-item">
        <a
          @click="cambFtp"
          class="nav-link mb-0 px-0 py-1"
          data-bs-toggle="tab"
          href="#"
          role="tab"
          aria-selected="false"
          >FTP</a
        >
      </li>
    </ul>
  </div>
</template>

<script>
import setNavPills from "@/assets/js/nav-pills.js";

export default {
  name: "NavPillContacto",
  props: {
    archivo: {
      type: Boolean,
      required: true,
    },
    sftp: {
      type: Boolean,
      required: true,
    },
    ftp: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    cambArch(){
      this.$emit("cambiar-archivo")
    },
    cambSftp(){
      this.$emit("cambiar-sftp")
    },
    cambFtp(){
      this.$emit("cambiar-ftp")
    }
  },
  mounted() {
    setNavPills();
  },
};
</script>
