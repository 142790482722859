<template>
  <div class="container-fluid py-5">
    <div class="row">
      <div class="col-12">
        <div class="multisteps-form">
          <!--form panels-->
          <div class="row">
            <div class="col-12 m-auto">
              <form class="multisteps-form__form">
                <reportes-ejecutivo-table ></reportes-ejecutivo-table>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {onPermissionsUser} from "@/config/servicios/campana/util"
import reportesEjecutivoTable from "@/views/envios/Reportes/components/ReportesEjecutivoTable.vue";
import Swal from "sweetalert2";


export default {
  name: "ReporteEjecutivo",
  components: {
    reportesEjecutivoTable,
  },
  data() {
    return {

    };
  },
  mounted() {

    if(!onPermissionsUser('report.xhtml', 'view')) {
      Swal.fire({
        title: 'No tienes los permisos para acceder a esta página.',
        buttonsStyling: false,
        customClass: {
          confirmButton: 'btn btn-celcom-orange',
        },
        type: 'error',
        icon: 'error',
      }).then(function() {
        window.location.href="/dashboard";
      })

    }

  },
};
</script>
<style scoped>
/* Agrega estilos según tus necesidades */
.modal {
  display: none;
}

.modal.is-active {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
}

.modal-close {
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
  background: none;
  border: none;
}
.buttons {
  margin: 8px;
}
</style>