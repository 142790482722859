// api.js
import axios from 'axios';
import Config from "@/config/servicios/campana/config";
export const obtenerLista = (body) => {
    return axios.post(Config.url_service + Config.landing_available,body,{
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        }});
};

// Agrega más funciones según tus necesidades