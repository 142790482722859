<template>
  <div class="card-body">
    <!--form panels-->
    <div class="row gx-4">
      <div class="col-12 col-lg-10 m-auto">
        <div class="row d-flex align-items-stretch mb-md-4">
          <div
            v-for="product in productos"
            :key="product.productName"
            class="col-lg-3 col-md-4 col-12 mb-3 mb-md-0"
          >
            <card
              :productName="product.productName"
              :price="numberWithCommas(product.amount)"
              :producto="product"
              @product-selected="getProduct"
              :class="
                productoLock === product.payProductId ? 'seleccionado' : ''
              "
            />
          </div>
        </div>
        <div class="row justify-content-end mt-3">
          <div class="col-lg-4 col-md-6 mb-4 mb-md-0">
            <card-pago>
              <h5>Selecciona pasarela de pago</h5>
              <div class="row">
                <div
                  class="col-lg-12 mt-3"
                  v-for="plataforma in plataformas"
                  v-bind:key="plataforma.paymentPlatformName"
                >
                  <div v-if="plataforma.paymentPlatformName !== 'TRANSFERENCIA BANCARIA'" class="card form-control">
                    <div class="p-2">
                      <div class="form-check">
                        <input
                          v-model="radio.radio1"
                          type="radio"
                          name="programacion"
                          :value="plataforma.paymentPlatformName"
                          class="form-check-input"
                          :id="plataforma.paymentPlatformName"
                        />
                        <label :for="plataforma.paymentPlatformName" class="mb-0">{{
                          plataforma.paymentPlatformName
                        }}</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </card-pago>
          </div>
          <div class="col-md">
            <card-pago body-classes="pb-4">
              <h5>Detalle de tu compra</h5>
              <div class="row">
                <div class="col-lg">
                  <p>Este es el resumen de la compra que vas a realizar.</p>

                  <div>
                    <ul class="list-group list-group-flush mb-3">
<!--                      <li class="list-group-item">
                        Cantidad de SMS:
                        <span class="fw-bolder">
                          {{
                            productoSelect.credits == undefined
                              ? ""
                              : numberWithCommas(productoSelect.credits)
                          }}</span
                        >
                      </li>-->
                      <li class="list-group-item">
                        Total a pagar:
                        <span class="fw-bolder">
                          ${{
                            (productoSelect.amount === undefined || isNaN(monto))
                              ? "" :
                                (productoSelect.amount === 0 ? monto : numberWithCommas(productoSelect.amount))
                          }}</span
                        >
                      </li>
                      <li class="list-group-item">
                        Método de Pago:
                        <span class="fw-bolder">
                          {{ radio.radio1 }}
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>

                <div class="col-lg">
                  <form class="row">
                    <div class="form-check col ms-3">
                      <input
                        v-model="radio.radio2"
                        type="radio"
                        name="boleta"
                        value="boleta"
                        id="boleta"
                        class="form-check-input"
                      />
                      <label for="boleta">Boleta</label>
                    </div>
                    <div class="form-check col">
                      <input
                        v-model="radio.radio2"
                        type="radio"
                        name="factura"
                        value="factura"
                        id="factura"
                        class="form-check-input"
                      />
                      <label for="factura">Factura</label>
                    </div>
                    <label>Nombres</label>
                    <argon-input
                      v-model="transfer.nombre"
                      class="multisteps-form__input"
                      type="text"
                      placeholder="Ingresar nombre"
                    />
                    <label>Apellido</label>
                    <argon-input
                      v-model="transfer.apellido"
                      class="multisteps-form__input"
                      type="text"
                      placeholder="Ingresar apellido"
                    />
                    <div
                      class="tooltip-container"
                      @mouseover="showTooltip"
                      @mouseout="showTooltip"
                    >
                      <label
                        >RUT
                        <i
                          class="btn-tooltip fas fa-question-circle text-gray"
                        ></i>
                        <span v-if="showingTooltip" class="tooltip"
                          >Ingresar RUT sin guión</span
                        >
                      </label>
                    </div>
                    <argon-input
                      v-model="transfer.rut"
                      class="multisteps-form__input"
                      type="text"
                      placeholder="Ingresar RUT"
                    />
                    <label>Dirección</label>
                    <argon-input
                      v-model="transfer.direccion"
                      class="multisteps-form__input"
                      type="text"
                      placeholder="Ingresar dirección"
                    />
                    <label>Teléfono</label>
                    <argon-input
                      v-model="transfer.telefono"
                      class="multisteps-form__input"
                      type="number"
                      placeholder="Ingresar teléfono"
                    />

                    <div class="text-end">
                      <button
                        class="btn btn-celcom-orange"
                        @click.prevent="iniciarPago"
                      >
                        Comprar
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </card-pago>
          </div>
        </div>
      </div>
    </div>
    <Loader ref="loader" />
  </div>
</template>

<script>
import {
  encryptAES,
  horaAhora,
  getUser,
} from "@/config/servicios/campana/util";
import { payment_products } from "@/config/servicios/Dashboard/servicesDashboard";
import {
  payment_plataforms,
  payment_init_payment,
} from "@/config/servicios/campana/envios/creditos";
import Card from "@/views/envios/comprar-creditos/Cards/Card.vue";
import CardPago from "@/components/Cards/Card.vue";
import ArgonInput from "@/components/ArgonInput.vue";
import Swal from "sweetalert2";
import Loader from "@/components/Modal/Loader.vue";
export default {
  name: "Creditos",
  components: {
    Loader,
    ArgonInput,
    Card,
    CardPago,
  },
  data() {
    return {
      payProductId: "",
      monto: 0,
      showingTooltip: false,
      radio: {
        radio1: "",
        radio2: "radio3",
      },
      productos: {},
      productoLock: "",
      productoSelect: {},
      plataformas: [],
      transfer: {
        nombre: "",
        apellido: "",
        rut: "",
        direccion: "",
        telefono: "",
        boleta: true,
      },
    };
  },
  async mounted() {
    await this.obtenerProducts(); // Esperar a que this.obtenerProducts() termine
    this.obtenerPlataformas();
    let user = getUser();
    this.payProductId = parseInt(this.$route.params.payProductId);
    if (this.payProductId && this.productos) {
      const selectedProduct = this.productos.find(
          (x) => x.payProductId === this.payProductId
      );
      if (selectedProduct) {
        this.productoLock = this.payProductId;
        this.productoSelect = selectedProduct;
      }
    }
    if (user.monthlyLimit && !user.postpaid) {
      window.location.href = "/dashboard";
    }
  },

  created() {},
  methods: {
    showTooltip() {
      this.showingTooltip = true;
    },
    hideTooltip() {
      this.showingTooltip = false;
    },
    numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    async obtenerProducts() {
      let self = this;
      let user = getUser();

      let js = {
        time_expired: horaAhora(),
        customer: encodeURIComponent(JSON.stringify(user.customer)),
        email: "",
        password: "",
        userPhone: "",
        plan: "",
        transaction: "",
      };

      var body = new URLSearchParams();
      body.append("TOKEN", encryptAES(js));

      await payment_products(body)
        .then(function (response) {
          console.log(response);

          if (response.data.response === "200") {
            self.productos = response.data.data;
            console.log(response.data.data);
          } else {
            console.log("No se encontraron productos");
            alert("No se encontraron productos");
          }
        })
        .catch(function (error) {
          console.log("No se encontraron productos", error);
          alert("No se encontraron productos");
        });
    },
    getProduct(products) {
      let self = this;
        self.productoSelect = products.products;
        self.productoLock = products.products.payProductId;
        self.monto = parseInt(products.monto);
    },
    async obtenerPlataformas() {
      //this.$loading.show()   //delay default is 300ms

      let self = this;
      let user = getUser();

      let js = {
        time_expired: horaAhora(),
        customer: encodeURIComponent(JSON.stringify(user.customer)),
        email: "",
        password: "",
        userPhone: "",
        plan: "",
        transaction: "",
      };

      var body = new URLSearchParams();
      body.append("TOKEN", encryptAES(js));

      await payment_plataforms(body)
        .then(function (response) {
          console.log(response);

          if (response.data.response === "200") {
            self.plataformas = response.data.data;
            console.log(response.data.data);
          } else {
            console.log("No se encontraron plataformas");
            alert("No se encontraron plataformas");
          }
        })
        .catch(function (error) {
          console.log("No se encontraron plataformas", error);
          alert("No se encontraron plataformas");
        });
    },
    async iniciarPago() {
      this.$refs.loader.showLoader();

      let self = this;
      let user = getUser();

      let js = {
        email: user.email,
        password: user.password,
        userPhone: "false",
        trans_nombre: this.transfer.nombre,
        trans_apellido: this.transfer.apellido,
        trans_rut: this.transfer.rut,
        trans_telefono: this.transfer.telefono,
        trans_direccion: this.transfer.direccion,
        boleta: this.radio.radio2 === "factura" ? "false" : "true",
        payProductId: this.productoSelect.payProductId,
        plan: this.monto === 0 ? this.productoSelect.amount : this.monto,
        transaction: this.monto === 0 ? this.productoSelect.amount : this.monto,
        pasarela: this.radio.radio1,
        isFlex: true,
        time_expired: horaAhora(),
        customer: encodeURIComponent(JSON.stringify(user.customer)),
      };

      if (
        user.email === "" ||
        user.password === "" ||
        this.transfer.nombre === "" ||
        this.transfer.apellido === "" ||
        this.transfer.rut === "" ||
        this.transfer.telefono === "" ||
        this.transfer.direccion === "" ||
        this.productoSelect.credits === "" ||
        this.radio.radio1 === "" ||
        user.customer === ""
      ) {
        self.$refs.loader.hideLoader();
        Swal.fire({
          title: "Debe completar todos los campos",
          icon: "warning",
          buttonsStyling: false,
          customClass: {
            confirmButton: 'btn btn-celcom-blue',
          },
        });
        return false;
      }

      var body = new URLSearchParams();
      body.append("TOKEN", encryptAES(js));
      await payment_init_payment(body)
        .then(function (response) {
          console.log("*********************** ",JSON.stringify(response));

          if (response.data.response === "200") {
            console.log(response.data.data);
            window.location.href = response.data.data.urlRedirect;
          } else {
            console.log("No se pudo realizar pago");
            alert("No se pudo realizar pago");
          }

          self.$refs.loader.hideLoader();
        })
        .catch(function (error) {
          console.log("No se pudo realizar pago", error);
          alert("No se pudo realizar pago");
          self.$refs.loader.hideLoader();
        });
    },
  },
};
</script>
<style scoped>
/* Agrega estilos según tus necesidades */
.seleccionado {
  box-shadow: 0px 0px 0px 5px #ff9900;
  border-radius: 16px;
  transform: scale(1.05);
}
.tooltip-container {
  position: relative;
  display: inline-block;
}

.tooltip {
  position: absolute;
  bottom: 100%; /* Coloca el tooltip arriba del contenido */
  left: 50%;
  transform: translateX(-50%);
  padding: 5px;
  background-color: #333;
  color: #fff;
  border-radius: 3px;
  font-size: 12px;
  opacity: 0;
  transition: opacity 0.3s ease;
  pointer-events: none; /* Evita que el tooltip sea clickeable */
}

.tooltip-container:hover .tooltip {
  opacity: 1;
}
.list-group-item {
  font-size: 0.875rem;
}
</style>
