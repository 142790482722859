<template>
  <div
    class="card multisteps-form__panel"
    data-animation="FadeIn"
  >
    <div class="card-header border-1">
      <h6 class="mb-0 text-sm">Información de Usuario</h6>
    </div>
    <div class="card-body multisteps-form__content">
      <form>
        <div class="px-lg-4">
          <div v-if="dataUserActual.customer.superUserClient" class="row">
            <div class="col-lg-4">
              <label class="form-label mt-2">Clientes</label>
              <select
                  v-model="selects.simple_customer_admin"
                  class="form-control form-select"
              >
                <option value="" selected disabled>Clientes</option>
                <option
                    v-for="option in selects.customer_admin"
                    :key="option.customerId"
                    class="select-danger"
                    :value="option.customerId"
                    :label="option.name"></option>
              </select>
            </div>
          </div>
        </div>
        <br>
        <div class="row">
          <div class="col-lg">
            <label class="form-label">Nombre Corto</label>
            <argon-input
                id="nameShort"
                v-model="dataNewUser.shortName"
                type="text"/>
          </div>
          <div class="col-lg">
            <label class="form-label">Correo / Login</label>
            <argon-input
                id="email"
                v-model="dataNewUser.email"
                type="text"/>
          </div>
        </div>
        <div class="row"  >
          <div class="col-lg">
            <label class="form-label">Nombre</label>
            <argon-input
                id="firstname"
                v-model="dataNewUser.name"
                type="text"/>
          </div>
          <div class="col-lg">
            <label class="form-label">Apellidos</label>
            <argon-input
                id="lastname"
                v-model="dataNewUser.lastName"
                type="text"/>
          </div>
        </div>
        <hr>
        <div class="row">
          <div class="col-lg">
            <label class="form-label">Contraseña</label>
            <argon-input
                id="new-password"
                v-model="dataNewUser.password"
                type="password"
                placeholder="Password"
            />
          </div>
          <div class="col-lg">
            <label class="form-label">Verificar Contraseña</label>
            <argon-input
                id="confirm-password"
                v-model="dataNewUser.password"
                type="password"
                placeholder="Confirm password"
            />
          </div>
        </div>
        <hr>
        <div class="row">
          <div class="col-lg">
            <label class="form-label">País</label>
            <argon-input
                id="pais"
                v-model="subUserValue"
                style="pointer-events: none"
                type="text"/>
          </div>
          <div class="col-lg">
            <label class="form-label">Número</label>
            <div class="row">
              <div class="col">
                <argon-input
                    id="phoneCode"
                    v-model="subUserPhoneCodeValue"
                    style="pointer-events: none"
                    type="text"/>
              </div>
              <div class="col">
                <argon-input
                    id="phone"
                    v-model="dataNewUser.phone"
                    placeholder="*********"
                    type="text"/>
              </div>
            </div>
          </div>
        </div>
        <hr>
        <div class="row">
          <div class="col-lg">
            <div class="form-check">
              <input
                id="checkbox1"
                v-model="checkboxes.checked"
                class="form-check-input"
                type="checkbox"
              /><label>Activo</label>
            </div>
          </div>
          <div class="col-lg">
<!--              <input
                id="checkbox2"
                v-model="checkboxes.checked1"
                class="form-check-input"
                type="checkbox"
            /><label>Adm</label>-->
          </div>
        </div>
        <hr>
<!--        <h6 class="heading-small text-muted mb-4">Límite SMS</h6>-->
        <argon-alert
            class="alert alert-danger" role="alert"
        >Los créditos total disponibles: <strong>{{ numberWithCommas((dataUserActual.customer != null) ? dataUserActual.customer.flexCredits : 0) }}</strong>
        </argon-alert>
<!--        <div class="row">
          <div class="col-md-6 mb-md-5">
            <input
                v-model="radio.radio1"
                type="radio"
                name="limite"
                value="checked"
            /><label>Límite propio</label><i class="btn-tooltip fas fa-question-circle" title="Consumir créditos de tu límite propio"></i>
          </div>
          <div class="col-md-6">
            <argon-input
                v-model="dataNewUser.remainingSms"
                :style="radio.radio1==='unchecked' ? 'pointer-events: none' : ''"
                class="multisteps-form__input mb-0"
                type="text"
            /><small>Restantes</small>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <input
                v-model="radio.radio1"
                type="radio"
                name="limite"
                value="unchecked"
            /><label>Límite cuenta master</label>
          </div>
        </div>-->
      </form>
    </div>
    <div class="card-footer border-1 d-flex">
      <argon-button
          type="button"
          color="celcom"
          variant="gradient"
          class="ms-auto js-btn-next text-white"
          @click="guardarUsuario()"
      >Agregar</argon-button>
    </div>
  </div>
</template>

<script>
import ArgonInput from "@/components/ArgonInput.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import {encryptAES, getUser, getUserAdmin, horaAhora} from "@/config/servicios/campana/util";
import {costumer_user_add} from "@/config/servicios/campana/user/servicesUserCampanna"
import axios from "axios";
import Config from "@/config/servicios/campana/config";
import swal from "sweetalert2";
import moment from "moment";
import ArgonAlert from "@/components/ArgonAlert.vue";
export default {
  name: "InfoUser",
  components: {
    ArgonAlert,
    ArgonInput,
    ArgonButton,
  },
  data() {
    return {
      subUserValue: null,
      subUserPhoneCodeValue: null,
      dataUserActual: getUser(),
      SubUser: "",
      dataNewUser: {
        name:"",
        lastName:"",
        phone:"",
        password:"",
        shortName:"",
        remainingSms:"",
      },
      radio: {
        radio1: "checked",
      },
      checkboxes: {
        checked: true,
      },
      selects: {
        customer_admin:getUserAdmin(),
        simple_customer_admin:"",
        simple: '',
        paises: [],
        simpleIdioma: '',
        idioma: [],
        simpleZona: '',
        zonaHoraria: []
      },
    };
  },
  watch:{

    'selects.simple_customer_admin'(val){
      let self = this;


      self.selects.customer_admin.forEach(function(element) {
        if(element.customerId === val) {

          self.SubUser = element;
          self.selects.paises.push({'name':element.country.name})
          self.selects.zonaHoraria.push({'name':element.country.gmt_timezone_name+"|"+element.country.gmt_timezone_interval})
          self.selects.simple = element.country.name
          self.selects.simpleZona = element.country.gmt_timezone_name+"|"+element.country.gmt_timezone_interval
          self.selects.simpleIdioma = element.language.name

        }
      });

    }

  },
  mounted() {
    this.subUserValue = (this.SubUser==="") ? this.dataUserActual.customer.country.name : this.SubUser.country.name;
    this.subUserPhoneCodeValue = (this.SubUser==="") ? this.dataUserActual.customer.country.phoneCode: this.SubUser.country.phoneCode;
    this.obtenerInfoCuenta();
  },
  methods: {
    async obtenerInfoCuenta() {

      let self = this;
      let user = getUser();

      let js = {
        "time_expired":horaAhora(),
        "customer":encodeURIComponent(JSON.stringify(user.customer))
      }

      var body = new URLSearchParams(); body.append('TOKEN', (encryptAES(js)));

      await axios.post(Config.url_service+Config.costumer_user_available, body, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      })
          .then(function (response) {

            if (response.data.response === "200") {

              self.dataUser = response.data.data;


              self.dataUser.forEach(function(element) {
                if(element.customerUserId === user.customerUserId) {

                  self.selects.paises.push({'name':element.customer.country.name})
                  self.selects.zonaHoraria.push({'name':element.customer.country.gmt_timezone_name+"|"+element.customer.country.gmt_timezone_interval})
                  self.selects.simple = element.customer.country.name
                  self.selects.simpleZona = element.customer.country.gmt_timezone_name+"|"+element.customer.country.gmt_timezone_interval
                  self.selects.simpleIdioma = element.customer.language.name
                  self.adminSi = element;
                }
              })
            } else {
              console.log("No se encontró usuario");
              swal.fire({
                title: 'No se encontró usuario',
                buttonsStyling: false,
                customClass: {
                  confirmButton: 'btn btn-danger',
                },
                type: 'warning',
                icon: 'warning'
              })
            }
          })
          .catch(function (error) {
            console.log("No se encontró usuario",error);
            swal.fire({
              title: 'No se encontró usuario',
              buttonsStyling: false,
              customClass: {
                confirmButton: 'btn btn-danger',
              },
              type: 'warning',
              icon: 'warning'
            })
          });
    },
    fechaFormato(unixTimestamp) {
      return moment(unixTimestamp).format('YYYY-MM-DD HH:mm:ss');
    },
    numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    async guardarUsuario() {
      let self = this;

      var admin_customer = "";
      this.selects.customer_admin.forEach(function(element) {
        if(element.customerId === self.selects.simple_customer_admin) {
          admin_customer = element;
        }
      })

      let nuevoUsuario = {
        "name":this.dataNewUser.name,
        "credits":0,
        "remainingSms":this.dataNewUser.remainingSms,
        "lastName":this.dataNewUser.lastName,
        "email":this.dataNewUser.email,
        "phone":this.dataNewUser.phone,
        "password":this.dataNewUser.password,
        "shortName":this.dataNewUser.shortName,
        "monthlyShippingSms":this.dataUserActual.monthlyShippingSms,
        "customer":(this.dataUserActual.customer.superUserClient) ? admin_customer: (self.SubUser==="") ?   this.dataUserActual.customer : self.SubUser,
        "active":this.checkboxes.checked,
        "adminUser":false,
        "smsAdminUser":false,
        "contactGroup":this.dataUserActual.contactGroup
      };


      if(this.dataNewUser.name==="" || this.dataNewUser.lastName==="" || this.dataNewUser.email==="" || this.dataNewUser.phone==="" || this.dataNewUser.password==="" || this.dataNewUser.shortName==="" /*|| (this.dataNewUser.remainingSms==="" && self.radio.radio1==="checked")*/){
        swal.fire({
          title: "Error: Debe completar todos los campos",
          buttonsStyling: false,
          customClass: {
            confirmButton: 'btn btn-danger',
          },
          icon: 'warning',
        })
        return false;
      }

      nuevoUsuario.adminUser = self.checkboxes.checked1;
      this.dataUserActual.smsAdminUser = (self.radio.radio1==="unchecked") ? "true":"false"
      debugger;
      let js = {
        "customer":encodeURIComponent(JSON.stringify((this.dataUserActual.customer.superUserClient) ? admin_customer:(self.SubUser==="") ?   this.dataUserActual.customer : self.SubUser)),
        "time_expired":horaAhora(),
        "application":1,
        "customer_user":encodeURIComponent(JSON.stringify(nuevoUsuario)),
      }

      var body = new URLSearchParams(); body.append('TOKEN', (encryptAES(js)));

      await costumer_user_add(body)
          .then(function (response) {
            if (response.data.response === "200") {
              swal.fire({
                text: '¡Usuario creado exitosamente!',
                buttonsStyling: false,
                customClass: {
                  confirmButton: 'btn btn-celcom-blue',
                },
                type: 'success',
                icon: 'success',
              }).then(function() {
                window.location.href = "/cuenta/usuarios";
              })
            } else {
              console.log("No se pudo crear contacto");
              swal.fire({
                title: 'No se pudo crear contacto',
                buttonsStyling: false,
                customClass: {
                  confirmButton: 'btn btn-danger',
                },
                type: 'warning',
                icon: 'warning',
              })
            }
          })
          .catch(function (error) {
            console.log("No se pudo crear contacto",error);
            swal.fire({
              title: 'No se pudo crear contacto',
              buttonsStyling: false,
              customClass: {
                confirmButton: 'btn btn-danger',
              },
              type: 'warning',
              icon: 'warning',
            })
          });
    },
  },
};
</script>
