// api.js
import axios from 'axios';
import Config from "@/config/servicios/campana/config";
export const add_short_url = (body) => {
    return axios.post(Config.url_service + Config.add_short_url,body,{
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        }});
};
export const listUser = (body) => {
    return axios.post(Config.url_service + Config.costumer_user_available,body,{
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        }});
};
//Para obtener permisos del user
export const customer_user_access = (body) => {
    return axios.post(Config.url_service + Config.customer_user_access,body,{
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        }});
};
//Para editar los permisos de un User
export const customer_user_access_edit = (body) => {
    return axios.post(Config.url_service + Config.customer_user_access_edit,body,{
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        }});
};
//Para eliminar un User
export const customer_user_detele = (body) => {
    return axios.post(Config.url_service + Config.customer_user_detele,body,{
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        }});
};
//Para editar un User
export const costumer_user_edit = (body) => {
    return axios.post(Config.url_service + Config.costumer_user_edit,body,{
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        }});
};
//Para agregar un User
export const costumer_user_add = (body) => {
    return axios.post(Config.url_service + Config.costumer_user_add,body,{
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        }});
};
// Agrega más funciones según tus necesidades