<template>
  <div class="map-container">
    <vue-map-chart
        :country-data="dataMap"
        high-color="#0230c7"
        low-color="#2f50bd"
        country-stroke-color="#120e07"
        default-country-fill-color="#ffffff"
    />
  </div>
</template>

<script>
import VueMapChart from 'vue-map-chart';
import {object} from "yup";

export default {
  name: 'MapChartComponent',
  components: {
    VueMapChart
  },
  props: {
    dataMap: {
      type: object,
      required: true
    }
  },
};
</script>

<style scoped>
.map-container {
  height: 500px; /* Ajusta la altura como desees */
}
</style>
