<template>
  <div class="container-fluid py-5">
    <div class="row">
      <div class="col-12">
        <div class="multisteps-form">
          <!--form panels-->
          <div class="row">
            <div class="col-12 col-lg-10 m-auto">
              <form class="multisteps-form__form">
                <whatsapp-link-table :perfilData="response"></whatsapp-link-table>
              </form>
            </div>
          </div>
        </div>
      </div>
      <modal :is-visible="modalAdd">
        <p><b>Solicitar Comercialmente</b></p>
        <div class="multisteps-form__content">
          <div class="row mt-3">
            <div class="col-12 col-sm-6">
              <label>Nombre</label>
              <argon-input
                  id="NameAdd"
                  v-model="name"
                  class="multisteps-form__input"
                  type="text"
                  placeholder="Nombre"
              />
            </div>
            <div class="col-12 col-sm-6">
              <label>App</label>
              <argon-input
                  id="AppAdd"
                  v-model="app"
                  class="multisteps-form__input"
                  type="text"
                  placeholder="appprueba"
              />
            </div>
            <div class="col-12 col-sm-6">
              <label>Número</label>
              <argon-input
                  id="AppAdd"
                  v-model="src"
                  class="multisteps-form__input"
                  type="text"
                  placeholder="987654321"
              />
            </div>
            <div class="row">
              <div class="col-12 col-sm-12">
                <label>AppId</label>
                <argon-input
                    id="AppAdd"
                    v-model="appId"
                    class="multisteps-form__input mb-0"
                    type="text"
                    placeholder="1dasdwe2-asd-asd2d-as-asd33431assd"
                />
                <div v-if="!appId" :class="{ 'is-invalid-d': appId }">
                  <span class="text-danger mb-0 text-sm">Este Campo es obligatorio</span>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer border-1 d-flex justify-content-between mt-4 px-0">
            <button
                :disabled="appId === ''"
                class="btn bg-gradient-celcom-orange mt-2 mb-0"
                @click="addPerfil"
            >Agregar</button>
            <button class="btn bg-gradient-dark mt-2 mb-0" @click="closeModal">Cancelar</button>
          </div>
        </div>
      </modal>
      <modal :is-visible="modalEdit">
        <p><b>Editar Comercialmente</b></p>
        <div class="multisteps-form__content">
          <div class="row mt-3">
            <div class="col-12 col-sm-6">
              <label>Nombre</label>
              <argon-input
                  id="NameAdd"
                  v-model="perfil.whatsappName"
                  class="multisteps-form__input"
                  type="text"
                  placeholder="Nombre"
              />
            </div>
            <div class="col-12 col-sm-6">
              <label>App</label>
              <argon-input
                  id="AppAdd"
                  v-model="perfil.whatsappApp"
                  class="multisteps-form__input"
                  type="text"
                  placeholder="appprueba"
              />
            </div>
            <div class="col-12 col-sm-6">
              <label>Número</label>
              <argon-input
                  id="AppAdd"
                  v-model="perfil.whatsappSrc"
                  class="multisteps-form__input"
                  type="text"
                  placeholder="987654321"
              />
            </div>
            <div class="row">
            <div class="col-12 col-sm-12">
              <label>AppId</label>
              <argon-input
                  id="AppAdd"
                  v-model="perfil.appId"
                  class="multisteps-form__input"
                  type="text"
                  placeholder="987654321"
              />
            </div>
            </div>
          </div>
          <div class="modal-footer border-1 d-flex justify-content-between mt-4 px-0">
            <button
                :disabled="perfil.appId === ''"
                class="btn bg-gradient-celcom-orange mt-2 mb-0"
                @click="editPerfil">Editar</button>
            <button class="btn bg-gradient-dark mt-2 mb-0" @click="closeModal">Cancelar</button>
          </div>
        </div>
      </modal>
      <modal-delete :is-visible="modalEliminar">
        <p class="text-center"><b>¿Estás seguro de que deseas eliminar?</b></p>
        <div class="mt-lg-4 px-3 d-flex justify-content-between">
          <button class="btn m-0 bg-gradient-danger" @click="borrarPerfil">Eliminar</button>
          <button class="btn m-0 bg-gradient-dark" @click="closeModal">Cancelar</button>
        </div>
      </modal-delete>
    </div>
  </div>
</template>

<script>

import {encryptAES, horaAhora, getUser, getUserSub} from "@/config/servicios/campana/util";
import {
  CREATE_WHSTAPP_ACCOUNT_SERVICES,
  LIST_WSP_ACCOUNT_BY_CUSTOMER,
  EDIT_WHSTAPP_ACCOUNT_SERVICES
} from "@/config/servicios/campana/whatsapp/servicesWhatsapp";
import whatsappLinkTable from "@/views/envios/whatsapp/Whatsapp-linkTable.vue";
import Modal from "@/components/Modal/Modal.vue";
import ArgonInput from "@/components/ArgonInput.vue";
import ModalDelete from "@/components/Modal/ModalDelete.vue";
export default {
  name: "WhatsappLink",
  components: {
    ModalDelete,
    ArgonInput,
    Modal,
    whatsappLinkTable,
  },
  data() {
    return {
      perfil: {},
      name: "",
      app: "",
      appId: "",
      src: "",
      modalAdd: false,
      modalEdit: false,
      modalEliminar: false,
      response: {},
    };
  },
  mounted() {
    this.obtenerLista();
  },
  created() {
    this.$store.commit('RESET_Data_Paginada');
  },
    methods: {
      openModalAdd() {
        this.modalAdd = true;
      },
      openModal(perfil) {
        this.modalEliminar = true;
        this.perfil = perfil;
      },
      openModalEditar(perfil) {
        this.modalEdit = true;
        this.perfil = perfil;
      },
      closeModal() {
        this.obtenerLista();
        this.modalAdd = false;
        this.modalEdit = false;
        this.modalEliminar = false;
        this.name = "";
        this.app = "";
        this.src = "";
      },
      async obtenerLista(){
      try {
        let user = (getUserSub()!=null) ? getUserSub() :getUser();

        let js = {
          time_expired: horaAhora(),
          "customer":encodeURIComponent(JSON.stringify(user.customer)),
        };

        var body = new URLSearchParams();
        body.append("TOKEN", encryptAES(js));


        const response = await LIST_WSP_ACCOUNT_BY_CUSTOMER(body);
        // Almacena la respuesta en la variable de datos
        if (response.data.response === "200") {
          console.log(response.data.data);

          this.response = response.data.data;
        } else {
          console.log("No se encontró lista");

        }
      } catch (error) {
        console.error("Error al obtener datos", error);
      }
    },
      async addPerfil() {
        try {
          let user = (getUserSub()!=null) ? getUserSub() :getUser();
          let data = {
            "customerWhatsappId": null,
            "whatsappName": this.name,
            "whatsappSrc": "56" + this.src,
            "whatsappApp": this.app,
            "appId": this.appId,
          };

          let js = {
            time_expired: horaAhora(),
            "customer":encodeURIComponent(JSON.stringify(user.customer)),
            "customerWhatsapp": encodeURIComponent(JSON.stringify(data)),
          };

          var body = new URLSearchParams();
          body.append("TOKEN", encryptAES(js));


          const response = await CREATE_WHSTAPP_ACCOUNT_SERVICES(body);
          // Almacena la respuesta en la variable de datos
          if (response.data.response === "200") {
            console.log(response.data.data);
            await this.obtenerLista();
            this.closeModal();
          } else {
            console.log("No se pudo crear el Perfil");

          }
        } catch (error) {
          console.error("Error al obtener datos", error);
        }
      },
      async editPerfil() {
        try {
          let user = (getUserSub()!=null) ? getUserSub() :getUser();
          let data = {
            "customerWhatsappId": this.perfil.customerWhatsappId,
            "whatsappName": this.perfil.whatsappName,
            "whatsappSrc":  this.perfil.whatsappSrc,
            "whatsappApp": this.perfil.whatsappApp,
          };

          let js = {
            time_expired: horaAhora(),
            "customer":encodeURIComponent(JSON.stringify(user.customer)),
            "customerWhatsapp": encodeURIComponent(JSON.stringify(data)),
          };

          var body = new URLSearchParams();
          body.append("TOKEN", encryptAES(js));


          const response = await EDIT_WHSTAPP_ACCOUNT_SERVICES(body);
          // Almacena la respuesta en la variable de datos
          if (response.data.response === "200") {
            console.log(response.data.data);
            await this.obtenerLista();
            this.closeModal();
          } else {
            console.log("No se pudo editar el Perfil");
          }
        } catch (error) {
          console.error("Error al editar el perfil", error);
        }
      },
      async borrarPerfil() {
        let self = this;
        let user = getUser();

        let js = {
          "time_expired":horaAhora(),
          "customer":encodeURIComponent(JSON.stringify(user.customer)),
          "whatsappSender":encodeURIComponent(JSON.stringify(self.whatsappSender))
        }

        var body = new URLSearchParams(); body.append('TOKEN', (encryptAES(js)));

        /*await DELETE_TEMPLATEWHATSAPP_BY_CUSTOMER(body)
            .then(function (response) {
              console.log(response)

              if (response.data.response === "200") {
                console.log(response.data.data);

                self.response = response.data.data;
                self.modalEliminarUser = false;

              } else {
                console.log("No se pudo borrar la plantilla");
                swal.fire({
                  title: 'No se pudo borrar la plantilla',
                  buttonsStyling: false,
                  customClass: {
                    confirmButton: 'btn btn-celcom-orange',
                  },
                  type: 'error',
                  icon: 'error',
                })
              }

            })
            .catch(function (error) {
              console.log("No se pudo borrar usuario",error);
              swal.fire({
                title: 'No se pudo borrar usuario',
                buttonsStyling: false,
                customClass: {
                  confirmButton: 'btn btn-celcom-orange',
                },
                type: 'error',
                icon: 'error',
              })
            });*/

      },
  },
};
</script>
<style scoped>
/* Agrega estilos según tus necesidades */
.modal {
  display: none;
}

.modal.is-active {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
}

.modal-close {
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
  background: none;
  border: none;
}
.buttons {
  margin: 8px;
}
.espacio{
  margin-bottom: 0;;
}
.espacio1{
  margin-top: 0;
  margin-left: 1px;
  margin-right: 1px;
}
</style>