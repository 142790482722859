<template>
  <div>
    <div class="row">
      <div class="col">
        <div class="d-flex justify-content-between align-items-center">
          <h6 class="mb-0">Elija una Variable</h6>
          <div class="d-flex">
            <button
                class="btn btn-celcom-orange"
                data-toggle="tooltip"
                data-original-title="Agregar"
                @click.prevent="agregar()"
            >
              <i class="fas fa-plus"></i> Agregar
            </button>
          </div>
        </div>
        <select
            :id="'selected2'"
            v-model="selectedCab"
            :class="['multisteps-form__select', 'form-control', 'form-select']"
            @change="updateBoton"
        >
          <option value="" disabled selected>Variables</option>
          <option v-for="(cab, cabIndex) in $store.state.responseCustomer_field_available"
                  :key="cabIndex"
                  :value="cab"
          >{{ cab.customerFieldName }}</option>
        </select>
      </div>
  </div>
  <div class="row">
    <div v-if="$store.state.botonesCab[0]">
      <hr>
      <div v-for="(boton, index) in $store.state.botonesCab" :key="index">
        <div>
          <boton-cab
              @disminuirIdBotones="disminuirIdBotones"
              :boton="boton"/>
        </div>
      </div>
    </div>
  </div>
    <div class="row">
      <div v-if="$store.state.addCab[0]">
        <hr>
        <div v-for="(cabecera, index) in $store.state.addCab" :key="index">
          <div>
            <add-cab-ind
                @disminuirIdCab="disminuirIdCab"
                :cabecera="cabecera"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import {defineComponent} from "vue";
import BotonCab from "@/views/contactsC/components/BotonCab.vue";
import AddCabInd from "@/views/contactsC/components/AddCabInd.vue";


export default defineComponent({
  name: "AddContactInd2",
  components: {
    BotonCab,
    AddCabInd
  },
  data() {
    return {
      selectedCab: '',
      idBotones: 1,
      idCab: 1,
    }
  },
  computed: {
    reversedGenericFields() {
      // Crear una copia del array y luego invertirlo
      return [...this.$store.state.responseGeneric_field].reverse();
    },
  },
  mounted() {
    if (this.$store.state.editarContacto) {
      this.llenarBotonEditar();
    }
  },
  methods: {
    disminuirIdBotones() {
      this.idBotones = this.idBotones - 1;
    },
    disminuirIdCab() {
      this.idCab = this.idCab - 1;
    },
    async llenarBotonEditar() {
      if (this.$store.state.dataEditarContacto.customContactPlannerList) {
        for (const boton of this.$store.state.dataEditarContacto.customContactPlannerList) {
          const nuevoBoton = {
            id: this.idBotones,
            valor: boton.contactHeader,
            cabecera: boton.customerFieldId.customerFieldName,
          };
          await this.$store.dispatch('agregarNuevoBotonCab', nuevoBoton);
          this.idBotones += 1;
        }
      }
    },
    updateBoton(){
      const nuevoBoton = {
        id: this.idBotones,
        valor: "",
        cabecera: this.selectedCab.customerFieldName,
      };
      this.$store.dispatch('agregarNuevoBotonCab', nuevoBoton);
      this.idBotones = this.idBotones + 1;
      this.selectedCab = '';
    },
    agregar(){
        const nuevoBoton = {
          id: this.idCab,
          cabecera: "",
          valor: "",
        };
        this.$store.dispatch('agregarNuevoAddCab', nuevoBoton);
        this.idCab = this.idCab + 1;
    },
  },
})
</script>
<style scoped>
.invalid-select {
  border: 1px solid red;
  background-color: #ffe6e6; /* Cambia el color de fondo a rojo claro */
}
</style>