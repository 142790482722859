<template>
    <div class="container-fluid py-5">
      <div class="row">
        <div class="col-12">
          <div class="multisteps-form">
            <!--form panels-->
            <div class="row">
              <div class="col-12 col-lg-10 m-auto">
                <form class="multisteps-form__form">
                  <tag-table :tagData="response.data" />
                </form>
              </div>
            </div>
          </div>
        </div>
        <modal-delete :is-visible="modalVisible">
          <p><b>¿Estás seguro de que deseas eliminar?</b></p>
          <button class="btn buttons mb-0 bg-gradient-danger btn-sm null null my-sm-auto mt-2 mb-0" @click="deleteOringen">Eliminar</button>
          <button class="btn buttons mb-0 bg-gradient-dark btn-sm null null my-sm-auto mt-2 mb-0" @click="closeModal">Cancelar</button>
        </modal-delete>
        <modal :is-visible="modalEditar">
          <p><b>Edite la Etiqueta</b></p>
          <div class="multisteps-form__content">
            <div class="row mt-3">
              <div class="col-12 col-sm-6">
                <label>Name</label>
                <argon-input
                    id="tagName"
                    v-model="nameValorEdit"
                    class="multisteps-form__input"
                    type="text"
                    placeholder="Ingrese un nombre"
                />
              </div>
            </div>
            <div class="button-row d-flex mt-2">
              <div class="text-white ms-auto mb-0 js-btn-next">
                <button class="btn buttons mb-0 bg-gradient-success btn-sm null null mt-2 mb-0" @click="editTag">Editar</button>
                <button class="btn buttons mb-0 bg-gradient-dark btn-sm null null mt-2 mb-0" @click="closeModal">Cancelar</button>
              </div>
            </div>
          </div>
        </modal>
      </div>
    </div>
  </template>
  
  <script>
  import TagTable from "./table/TagTable.vue";
  import {deleteOringen} from "@/config/servicios/sftp/servicesSftp";
  import ModalDelete from "@/components/Modal/ModalDelete.vue";
  import Modal from "@/components/Modal/Modal.vue";
 /* import ArgonInput from "@/components/ArgonInput.vue";*/
  import Choices from "choices.js";
  import ArgonInput from "@/components/ArgonInput.vue";
  import {getTag, editTag} from "@/config/servicios/tag/servicesTag";

  export default {
    name: "ListarTag",
    components: {
      ArgonInput,
      /*ArgonInput,*/
      TagTable,
      ModalDelete,
      Modal,
    },
    data() {
      return {
        nameValorEdit: null,
        modalEditar: false,
        modalVisible: false,
        idTag: null,
        response: {}, // Aquí almacenarás la respuesta del servicio
      };
    },
    methods: {
      async deleteOringen() {
        if (this.idTag) {
          try {
            await deleteOringen(this.idTag);
            // Almacena la respuesta en la variable de datos
            console.log("Orignen eliminado" );
          } catch (error) {
            console.error("Error al obtener datos", error);
          } finally {
            this.modalVisible = false;
            await this.listTag();
          }
        }
      },
      closeModal() {
        this.modalVisible = false;
        this.modalEditar = false;
      },
      openModal(id) {
        this.idTag = id;
        this.modalVisible = true;
      },
      openModalEditar(tag) {
        const tagData = JSON.parse(JSON.stringify(tag));

        this.idTag = tagData.customerTagSegmentId;
        this.nameValorEdit = tagData.customerTagSegmentName;
        this.modalEditar = true;
      },
      async editTag() {
        try {
          const response = await editTag(this.idTag, this.nameValorEdit);
          // Almacena la respuesta en la variable de datos
          console.log("Tag Editado: " + response);
        } catch (error) {
          console.error("Error al obtener datos", error);
        } finally {
          this.modalEditar = false;
          await this.listTag();
        }
      },
      async listTag() {
        try {
          const response = await getTag();
          // Almacena la respuesta en la variable de datos
          this.response = response.data;
        } catch (error) {
          console.error("Error al obtener datos", error);
        }
      },
    },
    mounted() {
      if (document.getElementById("opcionesTags")) {
        var element = document.getElementById("opcionesTags");
        new Choices(element, {
          searchEnabled: false,
          allowHTML: true,
        });
      }
      this.listTag(); // Llama a la función al montar el componente
    },
  };
  </script>
<style scoped>
/* Agrega estilos según tus necesidades */
.modal {
  display: none;
}

.modal.is-active {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
}

.modal-close {
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
  background: none;
  border: none;
}
.buttons {
  margin: 8px;
}
</style>