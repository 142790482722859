// data.js
export const llenarDatosCanal = (channelId, channelName, active) => {

    const jsonDataCanal =
        {
            "channelId": channelId,
            "channelName": channelName,
            "active": active,
        };
    return jsonDataCanal;
};