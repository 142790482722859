<template>
  <div class="container-fluid py-5">
    <div class="row">
      <div class="col-12">
        <div class="multisteps-form">
          <!--form panels-->
          <div class="row">
            <div class="col-12 col-lg-10 m-auto">
              <form class="multisteps-form__form">
                <whatsapp-template-table :template-data="$store.state.LIST_TEMPLATE_WHATSPP_BY_CUSTOMER"></whatsapp-template-table>
              </form>
            </div>
          </div>
        </div>
      </div>
      <modal :is-visible="modalAdd">
        <p><b>Elije el Perfil</b></p>
        <div class="row mt-3">
          <div class="col-12 col-sm-6">
            <label>Perfil</label>
            <select
                id="selectedPerfilWhatsapp"
                v-model="selectedPerfilWhatsapp"
                class="multisteps-form__select form-control form-select"
                name="opciones-Genero"
            >
              <option value="" disabled selected>Perfiles</option>
              <option v-for=" (perfil, index) in responsePerfiles" :key="index" :value="perfil" >{{ perfil.whatsappName }}</option>
              <!-- Agrega más opciones según sea necesario -->
            </select>
          </div>
        </div>
        <div class="modal-footer border-1 d-flex justify-content-between mt-4 px-0">
          <button class="btn bg-gradient-celcom-orange mt-2 mb-0" @click="agregar">Siguiente</button>
          <button class="btn bg-gradient-dark mt-2 mb-0" @click="closeModal">Cancelar</button>
        </div>
      </modal>
      <modal-delete :is-visible="modalEliminarUser">
        <p class="text-center"><b>¿Estás seguro de que deseas eliminar?</b></p>
        <div class="mt-lg-4 px-3 d-flex justify-content-between">
          <button class="btn m-0 bg-gradient-danger" @click="borrarPlantilla">Eliminar</button>
          <button class="btn m-0 bg-gradient-dark" @click="closeModal">Cancelar</button>
        </div>
      </modal-delete>
    </div>
    <Loader ref="loader" />
  </div>
</template>

<script>

import {encryptAES, horaAhora, getUser, getUserSub} from "@/config/servicios/campana/util";
import {
  DELETE_TEMPLATEWHATSAPP_BY_CUSTOMER,
  LIST_WSP_ACCOUNT_BY_CUSTOMER,
} from "@/config/servicios/campana/whatsapp/servicesWhatsapp";
import whatsappTemplateTable from "@/views/envios/whatsapp/Whatsapp-templateTable.vue";
import Modal from "@/components/Modal/Modal.vue";
import ModalDelete from "@/components/Modal/ModalDelete.vue";
import swal from "sweetalert2";
import Loader from "@/components/Modal/Loader.vue";
export default {
  name: "WhatsappTemplatesLink",
  components: {
    Loader,
    ModalDelete,
    Modal,
    whatsappTemplateTable,
  },
  data() {
    return {
      intentos: 0,
      maxIntentos: 10,
      intervalo: null,
      selectedPerfilWhatsapp: "",
      whatsappSender: {},
      modalAdd: false,
      response: {},
      responsePerfiles: {},
      modalEliminarUser: false,
    };
  },
  mounted() {
    this.obtenerLista();
    this.obtenerListaPerfiles();
    this.intentos = 0;
  },
  created() {
    this.$store.commit('RESET_Data_Paginada');
  },
    methods: {
      agregar(){
        this.$store.dispatch('cambiarPerfil', this.selectedPerfilWhatsapp);
        this.$router.push({
          name: "Nuevo Template"
        });
      },
      openModalAdd() {
        this.modalAdd = true;
      },
      openModalEliminarTemplate(template) {
        this.modalEliminarUser = true;
        this.whatsappSender = template;
      },
      closeModal() {
        this.modalAdd = false;
        this.modalEliminarUser = false;
        this.selectedPerfilWhatsapp = "";
      },
      async borrarPlantilla() {
        let self = this;
        let user = getUser();
        self.modalEliminarUser = false;
        self.$refs.loader.showLoader();
        let js = {
          "time_expired":horaAhora(),
          "customer":encodeURIComponent(JSON.stringify(user.customer)),
          "whatsappSender":encodeURIComponent(JSON.stringify(this.whatsappSender))
        }

        var body = new URLSearchParams(); body.append('TOKEN', (encryptAES(js)));

        await DELETE_TEMPLATEWHATSAPP_BY_CUSTOMER(body)
            .then(function (response) {
              console.log(response)

              if (response.data.response === "200") {
                console.log(response.data.data);

                self.response = response.data.data;
                self.modalEliminarUser = false;
                self.obtenerLista();
                self.$refs.loader.hideLoader();
              } else {
                console.log("No se pudo borrar la plantilla");
                swal.fire({
                  title: 'No se pudo borrar la plantilla',
                  buttonsStyling: false,
                  customClass: {
                    confirmButton: 'btn btn-celcom-orange',
                  },
                  type: 'error',
                  icon: 'error',
                })
                self.$refs.loader.hideLoader();
              }

            })
            .catch(function (error) {
              console.log("No se pudo borrar usuario",error);
              swal.fire({
                title: 'No se pudo borrar usuario',
                buttonsStyling: false,
                customClass: {
                  confirmButton: 'btn btn-celcom-orange',
                },
                type: 'error',
                icon: 'error',
              })
            });

      },
      async obtenerListaPerfiles(){
        try {
          this.$refs.loader.showLoader();
          let user = (getUserSub()!=null) ? getUserSub() :getUser();

          let js = {
            time_expired: horaAhora(),
            "customer":encodeURIComponent(JSON.stringify(user.customer)),
          };

          var body = new URLSearchParams();
          body.append("TOKEN", encryptAES(js));


          const response = await LIST_WSP_ACCOUNT_BY_CUSTOMER(body);
          // Almacena la respuesta en la variable de datos
          if (response.data.response === "200") {
            console.log(response.data.data);

            this.responsePerfiles = response.data.data;
            this.$refs.loader.hideLoader();
          } else {
            console.log("No se encontró lista");
            this.$refs.loader.hideLoader();
          }
        } catch (error) {
          console.error("Error al obtener datos", error);
          this.$refs.loader.hideLoader();
        }
      },
      async obtenerLista(){
        await this.$store.getters.list_template_whatsapp();
        const tienePendiente = this.$store.state.LIST_TEMPLATE_WHATSPP_BY_CUSTOMER.some(item => item.statusWhatsapp === "PENDING");
        if (tienePendiente && this.intentos < this.maxIntentos) {
          this.intentos++;
          clearTimeout(this.intervalo);
          this.intervalo = setTimeout(() => {
            this.obtenerLista();
          }, 5000);
        }else {
          clearTimeout(this.intervalo);
          this.intentos = 0;
        }
    },
  },
};
</script>
<style scoped>
/* Agrega estilos según tus necesidades */
.modal {
  display: none;
}

.modal.is-active {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
}

.modal-close {
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
  background: none;
  border: none;
}
.buttons {
  margin: 8px;
}
.espacio{
  margin-bottom: 0;;
}
.espacio1{
  margin-top: 0;
  margin-left: 1px;
  margin-right: 1px;
}
</style>