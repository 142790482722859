<template>
  <div class="card">
    <div class="card-header border-1 d-flex justify-content-between align-items-center flex-column flex-md-row">
      <h6 class="mb-md-0">Historial de Compras</h6>
      <div class="d-flex">
        <button
            class="btn btn-celcom-orange m-0"
            data-toggle="tooltip"
            data-original-title="Agregar Contacto"
            @click.prevent="agregar"
        >
          <i class="fas fa-plus"></i> Comprar Créditos
        </button>
      </div>
    </div>

    <!-- Agrega un campo de entrada para el buscador -->
    <div class="pb-0 card-body">
      <input v-model="search" type="text" class="form-control mt-2" placeholder="Buscar...">
    </div>
    <div class="card-body table-responsive">
      <table class="table align-items-center mb-0">
        <thead>
        <tr>
          <th
              class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
          >
            Fecha
          </th>
          <th
              class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
          >
            ID Transacción
          </th>
          <th
              class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
          >
            Pasarela de Pago
          </th>
          <th
              class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
          >
            Producto
          </th>
          <th
              class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
          >
            Estado
          </th>
        </tr>
        </thead>
        <tbody>
        <!-- Utiliza v-for para recorrer el arreglo y generar filas -->
        <tr v-for="(row, index) in $store.state.datosPaginados" :key="index">
          <td class="align-middle text-center text-sm">
            <div class="d-flex flex-column justify-content-center">
              <h6 class="mb-0 text-xs">{{ formatoFecha(row.created) }}</h6>
            </div>
          </td>
          <td class="align-middle text-center text-sm">
            <div class="d-flex flex-column justify-content-center">
              <h6 class="mb-0 text-xs">{{ row.payTransactionId }}</h6>
            </div>
          </td>
          <td class="align-middle text-center text-sm">
            <div class="d-flex flex-column justify-content-center">
              <h6 class="mb-0 text-xs">{{  row.paymentPlatformId ? row.paymentPlatformId.paymentPlatformName : "Obteniendo Plataforma"}}</h6>
            </div>
          </td>
          <td class="align-middle text-center text-sm">
            <div class="d-flex flex-column justify-content-center">
              <h6 class="mb-0 text-xs">{{  row.payProductId ? row.payProductId.productName : "Obteniendo Producto" }}</h6>
            </div>
          </td>
          <td class="align-middle text-center text-sm">
            <div class="d-flex flex-column justify-content-center">
              <h6 class="mb-0 text-xs">{{  (row.status===true) ? "Pagado" : "Error" }}</h6>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <BasePagination :filtered-data="filteredData" />
  </div>
</template>

<script>
import {formatoFecha} from "@/config/config";
import BasePagination from "@/components/BasePagination.vue";

export default {
  name: "HistorialComprasTable",
  components: {
    BasePagination,
  },
  props: {
    tableData: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      modalVisible: false,
      search: "", // Variable para almacenar la entrada del buscador
    };
  },
  computed: {
    // Utiliza una propiedad computada para filtrar los datos según la búsqueda
    filteredData() {
      if (!Array.isArray(this.tableData)) {
        return [];
      }
      return this.tableData.filter(row => {
        // Asegúrate de que los objetos y sus propiedades existen antes de acceder a ellos
        const name = row.paymentPlatformId ? row.paymentPlatformId.paymentPlatformName : '';
        const lista = row.payProductId ? row.payProductId.productName : '';

        return (
            name.toLowerCase().includes(this.search.toLowerCase()) ||
            lista.toLowerCase().includes(this.search.toLowerCase())
        );
      });
    }
  },
    watch: {
    filteredData() {
      this.$store.getters.getDataPaginated(this.$store.state.paginaActual, this.filteredData);
    },
  },
  methods: {
    formatoFecha(fecha) {
      return formatoFecha(fecha);
    },
    agregar(){
      this.$router.push({ name: "Comprar Creditos" });
    },
  },
};
</script>

<style scoped>
.btn-plantilla{
  color: #fff;
  background-color: #e64477;
  border-color: #e64477;
}
/* Agrega estilos según tus necesidades */
.icono-rojo {
  color: red;
}
.modal {
  display: none;
}

.modal.is-active {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
}

.modal-close {
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
  background: none;
  border: none;
}
.buttons {
  margin: 8px;
}
</style>