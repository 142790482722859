import { asigCanal } from "@/config/servicios/canales/servicesCanales";


export const asignarCanal = ( channelId ) => {
console.log("asignarCanal channelId " + channelId);
    return asigCanal(channelId)
        .then((response) => {
        console.log(response.data);
    })
        .catch((error) => {
        console.error("Error al enviar los datos:", error);
    });
};