<template>
  <div
      class="card multisteps-form__panel p-3 border-radius-xl bg-white"
      data-animation="FadeIn"
  >
    <p class="mb-0 text-sm">Información obligatoria</p>
    <div class="multisteps-form__content">
      <div class="col-12 col-sm-6">
        <label>Correo</label>
        <argon-input
            id="customerUserEmailAdd"
            class="multisteps-form__input"
            type="email"
            placeholder="celcom@gmail.com"
            v-model="email"
            required
            pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
        />
        <div v-if="!EmailValid" :class="{ 'is-invalid-d': !EmailValid }">
          <p class="mb-0 text-sm">Ingresa un correo electrónico válido</p>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12 col-sm-6">
          <label class="form-label">Nueva contraseña</label>
          <argon-input
              id="new-password"
              type="password"
              v-model="newPassword"
              placeholder="New Password"
          />
          <div v-if="!ValidatePassword" :class="{ 'is-invalid-d': !ValidatePassword }">
          <p class="mb-0 text-sm">La contraseña no cumple los requisitos</p>
        </div>
        </div>
        <div class="col-12 col-sm-6">
          <label class="form-label">Confirme su contraseña</label>
          <argon-input
              id="confirm-password"
              type="password"
              v-model="confirmPassword"
              placeholder="Confirm password"
          />
          <div v-if="!ValidateConfirmPassword" :class="{ 'is-invalid-d': !ValidateConfirmPassword }">
          <p class="mb-0 text-sm">Las contraseñas no coinciden</p>
        </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12 col-sm-6">
          <label>Estado</label>
          <div class="form-check">
            <input
                class="form-check-input"
                type="checkbox"
                id="estadoCheckboxAdd"
                v-model="active"
            />
            <label class="form-check-label" for="estadoCheckbox">
              {{ active ? 'Activo' : 'No Activo' }}
            </label>
          </div>
        </div>
        <div class="col-12 col-sm-6">
          <label>Super Administrador</label>
          <div class="form-check">
            <input
                class="form-check-input"
                type="checkbox"
                id="adminEstadoCheckboxAdd"
                v-model="adminCustomer"
            />
            <label class="form-check-label" for="estadoCheckbox">
              {{ adminCustomer ? 'Activo' : 'No Activo' }}
            </label>
          </div>
        </div>
      </div>

      <div class="button-row d-flex mt-2">
        <argon-button
            :disabled="!(EmailValid && ValidatePassword && ValidateConfirmPassword)"
            type="button"
            color="dark"
            variant="gradient"
            class="ms-auto js-btn-next"
            @click="$parent.addCustomerUser"
        >Agregar</argon-button>
      </div>
    </div>
  </div>
</template>


<script>
import ArgonInput from "@/components/ArgonInput.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import { isEmailValid, validatePassword, validateConfirmPassword } from "@/config/validaciones"
export default {
  name: "CustomerInfo",
  components: {
    ArgonInput,
    ArgonButton,
  },
  computed: {
    EmailValid() {
      return isEmailValid(this.email);
    },
    ValidatePassword() {
      return validatePassword(this.newPassword);
    },
    ValidateConfirmPassword() {
      return validateConfirmPassword(this.newPassword, this.confirmPassword);
    },
  },
  data() {
    return {
      newPassword: '',
      confirmPassword: '',
      email: '',
      selectedCountry: "",
      active: false,
      adminCustomer: false,
    };
  },
};
</script>
<style scoped>
@import "@/assets/css/validaciones.css";
</style>