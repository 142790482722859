<template>
  <div class="card z-index-2">
    <div class="pb-0 card-header mb-0">
      <h6>{{ chart.title }}</h6>
      <!-- eslint-disable-next-line vue/no-v-html -->
      <p v-if="chart.description" class="text-sm" v-html="chart.description" />
    </div>
    <div class="p-3 card-body">
      <div class="chart">
        <canvas :id="id" class="chart-canvas" :height="height"></canvas>
      </div>
    </div>
  </div>
</template>

<script>
import Chart from "chart.js/auto";

export default {
  name: "GradientDoughnutChart",
  props: {
    id: {
      type: String,
      required: true,
    },
    height: {
      type: String,
      default: "300",
    },
    chart: {
      type: Object,
      required: true,
    },
    rotation: {
      type: Number,
      default: 0,
    },
    circumference: {
      type: Number,
      default: 360,
    },
  },
  mounted() {
    console.log(JSON.stringify(this.chart) + " - " + this.id);
    const ctx = document.getElementById(this.id).getContext("2d");

    //const gradientStrokes = [];
    const datasets = [];

    // Definir gradientes de color para cada conjunto de datos
    for (let i = 0; i < this.chart.datasets.length; i++) {
      const backgroundColor = this.getBackgroundColor(i); // Obtiene el color de fondo para doughnut
      datasets.push({
        label: this.chart.datasets[i].label,
        backgroundColor: backgroundColor, // Color de fondo para doughnut
        data: this.chart.datasets[i].data,
        hoverOffset: 4
      });
    }

    let chartStatus = Chart.getChart(this.id);
    if (chartStatus != undefined) {
      chartStatus.destroy();
    }

    // Crear el gráfico Doughnut
    new Chart(ctx, {
      type: "doughnut", // Cambia a "doughnut"
      data: {
        labels: this.chart.labels,
        datasets: datasets,
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: true,
            position: "bottom",
            align: "center",
            labels: {
              boxHeight: 8,
              usePointStyle: true,
              pointStyle: "circle",
              padding: 15,
            },
          },
          tooltip: {
            callbacks: {
              label: function (tooltipItem) {
                return `${tooltipItem.label}: ${tooltipItem.raw}`;
              },
            },
          },
        },
        cutout: '45%', // Ajusta el tamaño del agujero en el centro
        rotation: this.rotation, // Inicia la rotación en 0 grados
        circumference: this.circumference, // Limita la circunferencia a 180 grados
        interaction: {
          mode: "index",
        },
      },
    });
  },
  methods: {
    getBackgroundColor(index) {
      switch (index) {
        case 0:
          return  [
              'rgb(255, 99, 132)',  // Samsumg
              'rgb(54, 162, 235)',  // Xiaomi
              'rgb(255, 205, 86)',  // Motorola
              'rgb(75, 192, 192)',  // Apple
              'rgb(153, 102, 255)'
          ]  // Otros
        case 1:
          return 'rgb(54, 162, 235)'; // Segundo color
        case 2:
          return 'rgb(255, 205, 86)'; // Tercer color
        default:
          return 'rgb(115, 100, 90)'; // Color por defecto
      }
    },
  },
};

</script>
