<template>
  <div class="container-fluid py-5">
    <div class="row">
      <div class="col-12">
        <div class="multisteps-form">
          <!--form panels-->
          <div class="row">
            <div class="col-12 col-lg-8 m-auto">
              <form class="multisteps-form__form">
                <canales-table :canalData="response.data" :canalDataUser="responseUser.data" :tipoUser="tipoUser"/>
              </form>
            </div>
          </div>
        </div>
      </div>
      <modal :is-visible="modalEditarCanal">
        <p><b>Edite el Canal</b></p>
        <div class="multisteps-form__content">
          <div class="row mt-3">
            <div class="col-12 col-sm-6">
              <label>Nombre</label>
              <argon-input
                  id="contactNameEdit"
                  v-model="channelName"
                  class="multisteps-form__input"
                  type="text"
                  placeholder="Nombre canal"
              />
            </div>
            <div class="col-12 col-sm-6 mt-3">
              <label>Estado</label>
              <div class="form-check">
                <input
                    class="form-check-input"
                    type="checkbox"
                    id="estadoCheckbox"
                    v-model="active"
                />
                <label class="form-check-label" for="estadoCheckbox">
                  {{ active ? 'Activo' : 'No Activo' }}
                </label>
              </div>
            </div>
          </div>
          <div class="button-row d-flex mt-2">
            <div class="text-white ms-auto mb-0 js-btn-next">
              <button class="btn buttons mb-0 bg-gradient-success btn-sm null null mt-2 mb-0" @click="editCanales">Editar</button>
              <button class="btn buttons mb-0 bg-gradient-dark btn-sm null null mt-2 mb-0" @click="closeModal">Cancelar</button>
            </div>
          </div>
        </div>
      </modal>
      <modal :is-visible="modalEditarCanalUser">
        <p><b>Edite el Estado del Canal</b></p>
        <div class="multisteps-form__content">
          <div class="row mt-3">
            <div class="col-12 col-sm-6 mt-3">
              <label>Estado</label>
              <div class="form-check">
                <input
                    class="form-check-input"
                    type="checkbox"
                    id="estadoCheckbox"
                    v-model="activeUser"
                />
                <label class="form-check-label" for="estadoCheckbox">
                  {{ activeUser ? 'Activo' : 'No Activo' }}
                </label>
              </div>
            </div>
          </div>
          <div class="button-row d-flex mt-2">
            <div class="text-white ms-auto mb-0 js-btn-next">
              <button class="btn buttons mb-0 bg-gradient-success btn-sm null null mt-2 mb-0" @click="editCanalesUser">Editar</button>
              <button class="btn buttons mb-0 bg-gradient-dark btn-sm null null mt-2 mb-0" @click="closeModal">Cancelar</button>
            </div>
          </div>
        </div>
      </modal>
      <modal-delete :is-visible="modalVisible">
        <p><b>¿Estás seguro de que deseas eliminar?</b></p>
        <button class="btn buttons mb-0 bg-gradient-danger btn-sm null null my-sm-auto mt-2 mb-0" @click="deleteCanales">Eliminar</button>
        <button class="btn buttons mb-0 bg-gradient-dark btn-sm null null my-sm-auto mt-2 mb-0" @click="closeModal">Cancelar</button>
      </modal-delete>
    </div>
  </div>
</template>

<script>
import {listCanal, listChannelByCustomer} from "@/config/servicios/canales/servicesCanales";
import {editC, editCU} from "@/config/servicios/canales/editCanal";
import CanalesTable from "./components/CanalesTable.vue";
import ArgonInput from "@/components/ArgonInput.vue";
import Modal from "@/components/Modal/Modal.vue";
import ModalDelete from "@/components/Modal/ModalDelete.vue";
import {deleteC} from "@/config/servicios/canales/deleteCanal";
import {asignarCanal} from "@/config/servicios/canales/asigCanal";
import {quitarCanal} from "@/config/servicios/canales/quitCanal";

export default {
  name: "ProfileTeam",
  components: {
    ModalDelete,
    Modal, ArgonInput,
    CanalesTable
  },
  data() {
    return {
      tipoUser: "admin",
      modalEditarCanalUser: false,
      modalEditarCanal: false,
      modalVisible: false,
      channelId: null,
      channelName: null,
      active: null,
      activeUser: null,
      response: {},
      responseUser: {},
    };
  },
methods: {
  async asignarCanal(canalId) {
    try {
      const response = await asignarCanal(canalId);
      console.log(response);
      // Almacena la respuesta en la variable de datos
    } catch (error) {
      console.error("Error al obtener datos", error);
    }finally {
      await this.listCanal();
      await this.listChannelByCustomer();
    }
  },
  async quitarCanal(canalId) {
    try {
      const response = await quitarCanal(canalId);
      // Almacena la respuesta en la variable de datos
      console.log("Canal eliminado: " + response);
    } catch (error) {
      console.error("Error al obtener datos", error);
    }finally {
      await this.listCanal();
      await this.listChannelByCustomer();
    }
  },
  async editCanales() {
    try {
      const response = await editC(this.channelId, this.channelName, this.active);
      // Almacena la respuesta en la variable de datos
      console.log("Canal Editado: " + response);
    } catch (error) {
      console.error("Error al obtener datos", error);
    } finally {
      this.modalEditarCanal = false;
      await this.listCanal();
      await this.listChannelByCustomer();
    }
  },
  async editCanalesUser() {
    try {
      const response = await editCU(this.channelId, this.activeUser);
      // Almacena la respuesta en la variable de datos
      console.log("Canal Editado: " + response);
    } catch (error) {
      console.error("Error al obtener datos", error);
    } finally {
      this.modalEditarCanalUser = false;
      await this.listCanal();
      await this.listChannelByCustomer();
    }
  },
  openModalEditarCanal(canal) {
    const canalData = JSON.parse(JSON.stringify(canal));

    this.channelId = canalData.channelId;
    this.channelName = canalData.channelName;
    this.active = canalData.active;

    this.modalEditarCanal = true;
  },
  openModalEditarCanalUser(id, active) {
    this.channelId = id;
    this.activeUser = active;
    this.modalEditarCanalUser = true;
  },
  openModal(id) {
    this.channelId = id;
    this.modalVisible = true;
  },
  closeModal() {
    this.modalVisible = false;
    this.modalEditarCanal = false;
    this.modalEditarCanalUser = false;
  },
  async listCanal() {
    try {
      const response = await listCanal();
      // Almacena la respuesta en la variable de datos
      this.response = response.data;
    } catch (error) {
      console.error("Error al obtener datos", error);
    }
  },
  async listChannelByCustomer() {
    try {
      const response = await listChannelByCustomer();
      // Almacena la respuesta en la variable de datos
      this.responseUser = response.data;
    } catch (error) {
      console.error("Error al obtener datos", error);
    }
  },
  async deleteCanales() {
    if (this.channelId) {
      try {
        await deleteC(this.channelId);
        // Almacena la respuesta en la variable de datos
        console.log("Canal eliminado" );
      } catch (error) {
        console.error("Error al obtener datos", error);
      } finally {
        this.modalVisible = false;
        await this.listCont();
      }
    }
  },
  },
  mounted() {
    this.listChannelByCustomer();
    this.listCanal();
  },
};
</script>
<style scoped>
/* Agrega estilos según tus necesidades */
.modal {
  display: none;
}

.modal.is-active {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
}

.modal-close {
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
  background: none;
  border: none;
}
.buttons {
  margin: 8px;
}
</style>