<template>
  <div
    id="sidenav-collapse-main"
    class="collapse navbar-collapse w-auto h-auto h-100"
  >
    <ul class="navbar-nav">

      <li class="nav-item">
        <sidenav-collapse
          nav-text="Dashboard"
          :collapse="false"
          url="#"
          :aria-controls="''"
          :class="getRoute() === 'dashboard' ? 'active' : ''"
          @click="irDashboard"
        >
          <template #icon>
            <i
              class="ni ni-laptop text-sm opacity-10"
              :class="
                $store.state.sidebarType === 'bg-default' ||
                $store.state.darkMode
                  ? 'text-white'
                  : 'text-celcom-blue'
              "
            ></i>
          </template>
        </sidenav-collapse>
      </li>

      <li class="nav-item">
        <sidenav-collapse
            nav-text="Campañas"
            collapse-ref="Campañas"
            :class="[getRoute() === 'envios' ? 'active' : '', '']"
        >
          <template #icon>
            <i
                class="fa fa-line-chart text-sm opacity-10"
                :class="
                $store.state.sidebarType === 'bg-default' ||
                $store.state.darkMode
                  ? 'text-white'
                  : 'text-celcom-blue'
              "
            ></i>
          </template>
          <template #list>
            <ul class="nav ms-4">
              <!-- nav links -->
              <sidenav-collapse
                  nav-text="Envios"
                  collapse-ref="Envios"
                  :class="[getRoute() === 'envios' ? 'active' : '', '']"
              >
                <template #icon>
                  <i
                      class="fas fa-rocket text-sm opacity-10"
                      :class="
                      $store.state.sidebarType === 'bg-default' ||
                      $store.state.darkMode
                        ? 'text-white'
                        : 'text-celcom-blue'
                      "
                  ></i>
                </template>
                <template #list>
                <ul class="nav ms-4">
                  <!-- nav links -->
                  <sidenav-item
                      :to="{ name: 'Programar Envio' }"
                      mini-icon="NE"
                      text="Nuevo Envio"
                  />
                </ul>
                <ul class="nav ms-4">
                  <!-- nav links -->
                  <sidenav-item
                      :to="{ name: 'Envios Programados' }"
                      mini-icon="EP"
                      text="Programados"
                  />
                </ul>
<!--                <ul class="nav ms-4">
                  &lt;!&ndash; nav links &ndash;&gt;
                  <sidenav-item
                      :to="{ name: 'SMS Landing' }"
                      mini-icon="SL"
                      text="SMS Link"
                  />
                </ul>-->
                </template>
              </sidenav-collapse>
            </ul>
            <ul class="nav ms-4">
              <!-- nav links -->
              <sidenav-collapse
                  nav-text="Remitentes"
                  collapse-ref="Remitentes"
                  :class="[getRoute() === 'remitentes' ? 'active' : '', '']"
              >
                <template #icon>
                  <i
                      class="fas fa-rocket text-sm opacity-10"
                      :class="
                      $store.state.sidebarType === 'bg-default' ||
                      $store.state.darkMode
                        ? 'text-white'
                        : 'text-celcom-blue'
                      "
                  ></i>
                </template>
                <template #list>
                  <ul class="nav ms-4">
                    <!-- nav links -->
                    <sidenav-item
                        :to="{ name: 'Remitentes SMS' }"
                        mini-icon="RS"
                        text="Remitentes SMS"
                        :class="{ 'inactive-item': !isActive.sms }"
                    />
                  </ul>
                  <ul class="nav ms-4">
                    <!-- nav links -->
                    <sidenav-item
                        :to="{ name: 'Remitentes Email' }"
                        mini-icon="DE"
                        text="Dominios Email"
                        :class="{ 'inactive-item': !isActive.email }"
                    />
                  </ul>
                </template>
              </sidenav-collapse>
            </ul>
            <ul class="nav ms-4">
              <!-- nav links -->
              <sidenav-collapse
                  nav-text="Contactos"
                  collapse-ref="Contactos"
                  :class="[getRoute() === 'contactos' ? 'active' : '', '']"
              >
                <template #icon>
                  <i
                      class="fas fa-id-badge text-sm opacity-10"
                      :class="
                      $store.state.sidebarType === 'bg-default' ||
                      $store.state.darkMode
                        ? 'text-white'
                        : 'text-celcom-blue'
                      "
                  ></i>
                </template>
                <template #list>
                  <ul class="nav ms-4">
                    <!-- nav links -->
                    <sidenav-item
                        :to="{ name: 'Lista Add' }"
                        mini-icon="NL"
                        text="Nueva Lista"
                    />
                  </ul>
                  <ul class="nav ms-4">
                    <!-- nav links -->
                    <sidenav-item
                        :to="{ name: 'Contactos Lista' }"
                        mini-icon="CL"
                        text="Listas"
                    />
                  </ul>
<!--                  <ul class="nav ms-4">
                    &lt;!&ndash; nav links &ndash;&gt;
                    <sidenav-item
                        :to="{ name: 'BlackList' }"
                        mini-icon="BL"
                        text="Lista Negra"
                    />
                  </ul>-->
                </template>
              </sidenav-collapse>
            </ul>
            <ul class="nav ms-4">
              <sidenav-collapse
                  nav-text="Reportes"
                  collapse-ref="Reportes"
                  :class="[getRoute() === 'reportes' ? 'active' : '', '']"
              >
                <template #icon>
                  <i
                      class="ni ni-single-copy-04 text-sm opacity-10"
                      :class="
                $store.state.sidebarType === 'bg-default' ||
                $store.state.darkMode
                  ? 'text-white'
                  : 'text-celcom-blue'
              "
                  ></i>
                </template>
                <template #list>
                  <ul class="nav ms-4">
                    <!-- nav links -->
                    <sidenav-item
                        :to="{ name: 'Reporte MT' }"
                        mini-icon="RMT"
                        text="Reporte MT"
                    />
                  </ul>
                  <ul class="nav ms-4">
                    <!-- nav links -->
                    <sidenav-item
                        :to="{ name: 'Reporte MO' }"
                        mini-icon="RMO"
                        text="Reporte MO"
                    />
                  </ul>
                  <ul class="nav ms-4">
                    <!-- nav links -->
                    <sidenav-item
                        :to="{ name: 'Reporte Ejecutivo' }"
                        mini-icon="RE"
                        text="Reporte Ejecutivo"
                    />
                  </ul>
                </template>
              </sidenav-collapse>
            </ul>
            <ul class="nav ms-4">
              <sidenav-collapse
                  collapse-ref="pagesExamples"
                  nav-text="Cuenta"
                  :class="getRoute() === 'cuenta' ? 'active' : ''"
              >
                <template #icon>
                  <i class="ni ni-single-02 text-sm opacity-10"
                     :class="
                  $store.state.sidebarType === 'bg-default' ||
                  $store.state.darkMode
                    ? 'text-white'
                    : 'text-celcom-blue'
                "
                  ></i>
                </template>
                <template #list>
                  <ul class="nav ms-4">
                    <!-- nav links -->
                    <sidenav-item
                        :to="{ name: 'Settings' }"
                        mini-icon="P"
                        text="Perfil"
                    />
                    <sidenav-item
                        :to="{ name: 'Cuenta Usuarios' }"
                        mini-icon="E"
                        text="Usuarios"
                    />
<!--                    <sidenav-item
                        :to="{ name: 'Reports' }"
                        mini-icon="U"
                        text="Otro"
                    />-->
                    <sidenav-item
                        :to="{ name: 'Historial de Compras' }"
                        mini-icon="U"
                        text="Historial de Compras"
                    />
                  </ul>
                </template>
              </sidenav-collapse>
            </ul>
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item">
        <sidenav-collapse
            nav-text="Canales"
            collapse-ref="Canales"
            :class="[getRoute() === 'canal' ? 'active' : '', '']"
        >
          <template #icon>
            <i class="ni ni-key-25 text-sm opacity-10"
               :class="
                  $store.state.sidebarType === 'bg-default' ||
                  $store.state.darkMode
                    ? 'text-white'
                    : 'text-celcom-blue'
                "
            ></i>
          </template>
          <template #list>
            <ul class="nav ms-4">
              <!-- nav links -->
              <sidenav-collapse
                  nav-text="Whatsapp"
                  collapse-ref="Whatsapp"
                  :class="[getRoute() === 'whatsapp' ? 'active' : '',  !isActive.whatsapp ? 'inactive-item' : '']"
              >
                <template #icon>
                  <i
                      class="fa fa-whatsapp text-sm opacity-10"
                      :class="
                      $store.state.sidebarType === 'bg-default' ||
                      $store.state.darkMode
                        ? 'text-white'
                        : 'text-celcom-blue'
                      "
                  ></i>
                </template>
                <template #list>
                  <ul class="nav ms-4">
                    <!-- nav links -->
                    <sidenav-item
                        :to="{ name: 'Lista Perfiles' }"
                        mini-icon="LP"
                        text="Perfiles"
                    />
                  </ul>
                  <ul class="nav ms-4">
                    <!-- nav links -->
                    <sidenav-item
                        :to="{ name: 'Lista Templates' }"
                        mini-icon="LT"
                        text="Plantillas"
                    />
                  </ul>
                  <ul class="nav ms-4">
                    <!-- nav links -->
                    <sidenav-item
                        :to="{ name: 'Configuracion MO Whatsapp' }"
                        mini-icon="EP"
                        text="Configuración MO"
                    />
                  </ul>
                </template>
              </sidenav-collapse>
            </ul>
            <ul class="nav ms-4">
              <!-- nav links -->
              <sidenav-collapse
                  nav-text="SMS"
                  collapse-ref="SMS"
                  :class="[getRoute() === 'sms/envios/plantillas' ? 'active' : '',  !isActive.sms ? 'inactive-item' : '']"
              >
                <template #icon>
                  <i
                      class="fa fa-comments-o text-sm opacity-10"
                      :class="
                      $store.state.sidebarType === 'bg-default' ||
                      $store.state.darkMode
                        ? 'text-white'
                        : 'text-celcom-blue'
                      "
                  ></i>
                </template>
                <template #list>
                  <ul class="nav ms-4">
                    <!-- nav links -->
                    <sidenav-item
                        :to="{ name: 'Envio Plantilla' }"
                        mini-icon="EP"
                        text="Plantillas"
                    />
                  </ul>
                  <ul class="nav ms-4">
                    <!-- nav links -->
                    <sidenav-item
                        :to="{ name: 'Configuracion MO SMS' }"
                        mini-icon="EP"
                        text="Configuración MO"
                    />
                  </ul>
                </template>
              </sidenav-collapse>
            </ul>
            <ul class="nav ms-4">
              <!-- nav links -->
              <sidenav-collapse
                  nav-text="Email"
                  collapse-ref="Email"
                  :class="[getRoute() === 'email/envios/plantillas' ? 'active' : '', !isActive.email ? 'inactive-item' : '']"
              >
                <template #icon>
                  <i
                      class="fa fa-envelope-o text-sm opacity-10"
                      :class="
                      $store.state.sidebarType === 'bg-default' ||
                      $store.state.darkMode
                        ? 'text-white'
                        : 'text-celcom-blue'
                      "
                  ></i>
                </template>
                <template #list>
                  <ul class="nav ms-4">
                    <!-- nav links -->
                    <sidenav-item
                        :to="{ name: 'Envio Plantilla Email' }"
                        mini-icon="EP"
                        text="Plantillas"
                    />
                  </ul>
                </template>
              </sidenav-collapse>
            </ul>
          </template>
        </sidenav-collapse>
      </li>

      <li class="nav-item">
        <sidenav-collapse
            nav-text="Bot"
            collapse-ref="/dashboard"
            :class="[getRoute() === 'dashboard' ? 'active' : '', 'disabled-link' , 'nav-pronto']"
        >
          <template #icon>
            <i
                class="fa fa-robot text-sm opacity-10"
                :class="
                $store.state.sidebarType === 'bg-default' ||
                $store.state.darkMode
                  ? 'text-white'
                  : 'text-celcom-blue'
              "
            ></i>
          </template>
        </sidenav-collapse>
      </li>

<!--      <li class="nav-item">
        <sidenav-collapse
          collapse-ref="pagesExamples"
          nav-text="Contactos"
          :class="getRoute() === 'contactos' ? 'active' : ''"
        >
          <template #icon>
            <i class="fas fa-id-badge text-sm opacity-10"
              :class="
                  $store.state.sidebarType === 'bg-default' ||
                  $store.state.darkMode
                    ? 'text-white'
                    : 'text-celcom-blue'
                "
              ></i>
          </template>
          <template #list>
            <ul class="nav ms-4">
              &lt;!&ndash; nav links &ndash;&gt;
              <sidenav-item
                  :to="{ name: 'Lista Contactos' }"
                  mini-icon="LC"
                  text="Lista de Contactos"
              />
              <sidenav-item
                :to="{ name: 'Importar Lista' }"
                mini-icon="IL"
                text="Importar Lista"
              />
              <sidenav-item
                :to="{ name: 'Lista Tag' }"
                mini-icon="LT"
                text="Tags"
              />
              <sidenav-item
                :to="{ name: 'Lista Segment' }"
                mini-icon="LS"
                text="Segmentos"
              />
              <sidenav-item
                  :to="{ name: 'Lista Origenes' }"
                  mini-icon="LO"
                  text="Origin de Contactos"
              />
            </ul>
          </template>
        </sidenav-collapse>
      </li>-->

<!--      <li class="nav-item">
        <sidenav-collapse
          collapse-ref="pagesExamples"
          nav-text="Canales"
          :class="getRoute() === 'canales' ? 'active' : ''"
        >
          <template #icon>
            <i class="ni ni-tv-2 text-sm opacity-10"
              :class="
                  $store.state.sidebarType === 'bg-default' ||
                  $store.state.darkMode
                    ? 'text-white'
                    : 'text-celcom-blue'
                "
              ></i>
          </template>
          <template #list>
            <ul class="nav ms-4">
              &lt;!&ndash; nav links &ndash;&gt;
              <sidenav-item
                :to="{ name: 'Lista Canales' }"
                mini-icon="VC"
                text="Ver Canales"
              />
            </ul>
          </template>
        </sidenav-collapse>
      </li>

      <li class="nav-item">
        <sidenav-collapse
          collapse-ref="pagesExamples"
          nav-text="Integraciones"
          :class="getRoute() === 'integraciones' ? 'active' : ''"
        >
          <template #icon>
            <i class="ni ni-key-25 text-sm opacity-10"
              :class="
                $store.state.sidebarType === 'bg-default' ||
                $store.state.darkMode
                  ? 'text-white'
                  : 'text-celcom-blue'
              "
            ></i>
          </template>
          <template #list>
            <ul class="nav ms-4">
              &lt;!&ndash; nav links &ndash;&gt;
              <sidenav-item
                :to="{ name: 'All Projects' }"
                mini-icon="VI"
                text="Ver Integraciones"
              />
              <sidenav-item
                :to="{ name: 'Reports' }"
                mini-icon="NI"
                text="Nueva Integración"
              />
            </ul>
          </template>
        </sidenav-collapse>
      </li>-->

<!--      <li class="nav-item">
        <hr class="horizontal dark" />
        <sidenav-collapse
          nav-text="Reportes"
          :collapse="false"
          url="#"
          :aria-controls="''"
          collapse-ref=""
        >
          <template #icon>
            <i
              class="ni ni-single-copy-04 text-sm opacity-10"
              :class="
                $store.state.sidebarType === 'bg-default' ||
                $store.state.darkMode
                  ? 'text-white'
                  : 'text-celcom-blue'
              "
            ></i>
          </template>
        </sidenav-collapse>
      </li>-->

      <li class="nav-item">
        <sidenav-collapse
          collapse-ref="pagesExamples"
          nav-text="Workflow"
          :class="[getRoute() === 'workflow' ? 'active' : '', 'disabled-link', 'nav-pronto']"
        >
          <template #icon>
            <i class="ni ni-spaceship text-sm opacity-10"
              :class="
                  $store.state.sidebarType === 'bg-default' ||
                  $store.state.darkMode
                    ? 'text-white'
                    : 'text-celcom-blue'
                "
              ></i>
          </template>
          <template #list>
            <ul class="nav ms-4">
              <!-- nav links -->
              <sidenav-item
                :to="{ name: 'Timeline' }"
                mini-icon="VW"
                text="Ver Workflow"
              />
              <sidenav-item
                :to="{ name: 'Nuevo Workflow' }"
                mini-icon="NW"
                text="Nuevo Workflow"
              />
            </ul>
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item">
        <hr class="horizontal dark" />
        <sidenav-collapse
            v-if="tipoUsuarioUserClient"
            nav-text="Admin Flex"
            collapse-ref="Admin Flex"
            :class="[getRoute() === 'admin' ? 'active' : '', '']"
        >
          <template #icon>
            <i
                class="fa fa-user-secret text-sm opacity-10"
                :class="
                $store.state.sidebarType === 'bg-default' ||
                $store.state.darkMode
                  ? 'text-white'
                  : 'text-celcom-blue'
              "
            ></i>
          </template>
          <template #list>
            <ul class="nav ms-4">
              <!-- nav links -->
              <sidenav-collapse
                  nav-text="Clientes"
                  collapse-ref="Clientes"
                  :class="[getRoute() === 'envios' ? 'active' : '', '']"
              >
                <template #icon>
                  <i
                      class="fa fa-address-book-o text-sm opacity-10"
                      :class="
                      $store.state.sidebarType === 'bg-default' ||
                      $store.state.darkMode
                        ? 'text-white'
                        : 'text-celcom-blue'
                      "
                  ></i>
                </template>
                <template #list>
                  <ul class="nav ms-4">
                    <!-- nav links -->
                    <sidenav-item
                        :to="{ name: 'Admin Customer' }"
                        mini-icon="GC"
                        text="Gestionar Clientes"
                    />
                  </ul>
                </template>
              </sidenav-collapse>
            </ul>
            <ul class="nav ms-4">
              <!-- nav links -->
              <sidenav-collapse
                  nav-text="Precios"
                  collapse-ref="Precios"
                  :class="[getRoute() === 'precios' ? 'active' : '', '']"
              >
                <template #icon>
                  <i
                      class="fa fa-credit-card text-sm opacity-10"
                      :class="
                      $store.state.sidebarType === 'bg-default' ||
                      $store.state.darkMode
                        ? 'text-white'
                        : 'text-celcom-blue'
                      "
                  ></i>
                </template>
                <template #list>
                  <ul class="nav ms-4">
                    <!-- nav links -->
                    <sidenav-item
                        :to="{ name: 'Precio' }"
                        mini-icon="CP"
                        text="Gestión Precios"
                    />
                  </ul>
                </template>
              </sidenav-collapse>
            </ul>
          </template>
        </sidenav-collapse>
      </li>
<!--      <li class="nav-item">
        <sidenav-collapse
          collapse-ref="pagesExamples"
          nav-text="Cuenta"
          :class="getRoute() === 'cuenta' ? 'active' : ''"
        >
          <template #icon>
            <i class="ni ni-single-02 text-sm opacity-10"
              :class="
                  $store.state.sidebarType === 'bg-default' ||
                  $store.state.darkMode
                    ? 'text-white'
                    : 'text-celcom-blue'
                "
              ></i>
          </template>
          <template #list>
            <ul class="nav ms-4">
              &lt;!&ndash; nav links &ndash;&gt;
              <sidenav-item
                :to="{ name: 'Settings' }"
                mini-icon="P"
                text="Perfil"
              />
              <sidenav-item
                v-if="tipoUsuario"
                :to="{ name: 'Customer' }"
                mini-icon="E"
                text="Clientes"
              />
              <sidenav-item
                  :to="{ name: 'Reports' }"
                  mini-icon="U"
                  text="Usuarios"
              />
            </ul>
          </template>
        </sidenav-collapse>
      </li>

      <li class="nav-item">
        <sidenav-collapse
          nav-text="Ayuda"
          :collapse="false"
          url="#"
          :aria-controls="''"
          collapse-ref="/"
          :class="getRoute() === 'ayuda' ? 'active' : ''"
        >
          <template #icon>
            <i
              class="fas fa-question-circle text-sm opacity-10"
              :class="
                $store.state.sidebarType === 'bg-default' ||
                $store.state.darkMode
                  ? 'text-white'
                  : 'text-celcom-blue'
              "
            ></i>
          </template>
        </sidenav-collapse>
      </li>-->
    </ul>
  </div>
  
</template>
<script>
import SidenavItem from "./SidenavItem.vue";
import SidenavCollapse from "./SidenavCollapse.vue";
import { tipoUser, tipoSuperUserClient } from "@/config/config";
export default {
  name: "SidenavList",
  components: {
    SidenavItem,
    SidenavCollapse,
  },
  data() {
    return {
      tipoUsuario: null,
      tipoUsuarioUserClient: null,
    };
  },
  computed: {
    isActive() {
      const estadoCanales = this.$store.state.estado_canales || [];
      const canalesActivos = {
        whatsapp: false,
        email: false,
        sms: false
      };

      estadoCanales.forEach(canal => {
        if (canal.channel.channel_name === "WHATSAPP" && canal.channel.active && canal.active) {
          canalesActivos.whatsapp = true;
        } else if (canal.channel.channel_name === "EMAIL" && canal.channel.active && canal.active) {
          canalesActivos.email = true;
        } else if (canal.channel.channel_name === "SMS" && canal.channel.active && canal.active) {
          canalesActivos.sms = true;
        }
      });

      return canalesActivos;
    }
  },
  async mounted() {
    await this.tipoUsers();
    await this.tipoUsersClient();
    await this.$store.getters.obtenerEstadoCanales();
  },
  methods: {
    irDashboard() {
      this.$router.push({ name: 'Dashboard' });
    },
    getRoute() {
      const routeArr = this.$route.path.split("/");
      return routeArr[1];
    },
    async tipoUsers() {
      try {
        // Almacena la respuesta en la variable de datos
        this.tipoUsuario = await tipoUser();
      } catch (error) {
        console.error("Error al obtener datos", error);
      }
    },
    async tipoUsersClient() {
      try {
        // Almacena la respuesta en la variable de datos
        this.tipoUsuarioUserClient = await tipoSuperUserClient();
      } catch (error) {
        console.error("Error al obtener datos", error);
      }
    },
  },
};
</script>
<style>
.disabled-link {
  pointer-events: none; /* Deshabilita interacción con el enlace */
  opacity: 0.5; /* Ajusta la opacidad según tus necesidades */
}
.nav-pronto .nav-link-text::after {
  content: "Pronto";
  font-weight: 900;
  text-transform: uppercase;
  font-size: .6125rem;
  background-color: var(--bs-gray-500);
  color: var(--bs-gray-dark);
  padding: 0.125rem .25rem;
  border-radius: .25rem;
  margin-left: .5rem;
}
.inactive-item {
  opacity: 0.5; /* Visualmente inactivo */
  pointer-events: none; /* No permite clics */
}
</style>