<template>
  <div class="card">
    <div class="card-header border-1 d-flex justify-content-between align-items-center flex-column flex-md-row">
      <h6 class="mb-md-0">Blacklist</h6>
      <div class="d-flex">
        <button
            class="btn btn-celcom-orange m-0"
            data-toggle="tooltip"
            data-original-title="Agregar Contacto"
            @click.prevent="agregar"
        >
          <i class="fas fa-plus"></i> Nuevo Blacklist
        </button>
      </div>
    </div>

    <!-- Agrega un campo de entrada para el buscador -->
    <div class="pb-0 card-body mb-0">
      <input v-model="search" type="text" class="form-control mt-2" placeholder="Buscar...">
    </div>
    <div class="card-body">
      <div class="table-responsive">
        <table class="table align-items-center mb-0">
          <thead>
          <tr>
            <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder"
            >
              Código de Área
            </th>
            <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder ps-2"
            >
            Teléfono
            </th>
            <th class="text-secondary"></th>
          </tr>
          </thead>
          <tbody>
          <!-- Utiliza v-for para recorrer el arreglo y generar filas -->
          <tr v-for="(lista, index) in $store.state.datosPaginados" :key="index" style="min-height: 3em;">
            <td class="align-middle text-center text-sm">
              <div class="d-flex flex-column" style="white-space: normal; overflow: hidden; height: 3em;">
                <h6 class="mb-0 text-xs">
                  <a href="#!" @click.prevent="$parent.onShowList(lista,true)" class="font-weight-600 mb-0 text-sm text-celcomblue">{{lista.name}}</a>
                  <span class="font-weight-600 mb-0 text-sm text-celcomblue">{{lista.blacklistPK.codePhone}}</span>
                </h6>
              </div>
            </td>
            <td class="align-middle text-center text-sm">
              <div class="d-flex flex-column justify-content-center">
                <h6 class="mb-0 text-xs">{{ lista.blacklistPK.phone }}</h6>
              </div>
            </td>
            <td class="align-middle text-center text-sm">
              <button
                  class="btn btn-danger"
                  data-toggle="tooltip"
                  data-original-title="Eliminar user"
                  @click.prevent="$parent.openModal(lista.blacklistPK)"
              >
                <i class="fas fa-duotone fa-trash"></i>
              </button>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="row mt-3">
        <BasePagination class="col-12" :filtered-data="filteredData" />
        <div class="col-12 d-flex justify-content-sm-end align-items-center mt-sm-0">
          <button
              class="btn btn-celcom-blue mb-0"
              data-toggle="tooltip"
              data-original-title="Agregar Contacto"
              v-show="filteredData.length > 0"
              @click.prevent="descargarReport()"
          >
            <i class="fas fa-download me-2"></i>Descargar Reportes
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {getUser, getUserSub} from "@/config/servicios/campana/util";
import BasePagination  from "@/components/BasePagination.vue";
import * as XLSX from "xlsx";
export default {
  name: "BlackListTable",
  components: {
    BasePagination,
  },
  props: {
    listaData: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      arrayRepo: [],
      showingTooltip: false,
      dataUserActual: getUser(),
      getUserSub : getUserSub(),
      modalVisible: false,
      search: "", // Variable para almacenar la entrada del buscador
    };
  },
  computed: {
    // Utiliza una propiedad computada para filtrar los datos según la búsqueda
    filteredData() {
      if (!Array.isArray(this.listaData)) {
        return [];
      }

      return this.listaData.filter(lista => {
        const codePhone = lista.blacklistPK.codePhone ?? '';
        const phone = lista.blacklistPK.phone;
        this.$store.getters.getDataPaginated(this.$store.state.paginaActual, this.filteredData);
        return (
            (codePhone !== null && codePhone !== undefined && codePhone.toString().includes(this.search.toLowerCase())) ||
            (phone !== null && phone !== undefined && phone.toString().includes(this.search.toLowerCase()))
        );
      });
    },
  },
  created() {
    this.$store.commit('RESET_Data_Paginada');
  },
  mounted() {
  },
  methods: {
    agregar(){
      this.$router.push({ name: "BlackList Add" });
      console.log("Agregar");
    },
    descargarReport() {
      var tbl = this.listaData.map(item => ({
          code: Number(item.blacklistPK.codePhone), // o parseInt(item.blacklistPK.codePhone, 10)
          phone: Number(item.blacklistPK.phone) // o parseInt(item.blacklistPK.phone, 10)
        }));

      var ws = XLSX.utils.json_to_sheet(tbl);

      var wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "BlackList");

      XLSX.writeFile(wb, 'BlackList.xlsx');

    }
  },

};
</script>

<style scoped>
.btn-plantilla{
  color: #fff;
  background-color: #e64477;
  border-color: #e64477;
}
/* Agrega estilos según tus necesidades */
.icono-rojo {
  color: red;
}
.modal {
  display: none;
}

.modal.is-active {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
}

.modal-close {
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
  background: none;
  border: none;
}
.buttons {
  margin: 8px;
}
.tooltip-container {
  position: relative;
  display: inline-block;
}

.tooltip {
  position: absolute;
  bottom: 100%; /* Coloca el tooltip arriba del contenido */
  left: 50%;
  transform: translateX(-50%);
  padding: 5px;
  background-color: #333;
  color: #fff;
  border-radius: 3px;
  font-size: 12px;
  opacity: 0;
  transition: opacity 0.3s ease;
  pointer-events: none; /* Evita que el tooltip sea clickeable */
}

.tooltip-container:hover .tooltip {
  opacity: 1;
}
</style>