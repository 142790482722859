<template>
  <div>
    <small class="badge badge-secondary text-celcom-blue fw-bolder me-2">
      <i class="fas fa-clock me-1"></i>{{timeServe}}
    </small>
    <small class="badge badge-secondary text-celcom-blue fw-bolder me-2">
      <i class="fas fa-calendar-alt me-1"></i>{{ momentDate }}
    </small>
  </div>
</template>
<script>
import moment from 'moment';
import {getTimeServe} from "@/config/servicios/campana/util";
export default {
  name: "Reloj",
  data() {
    return {
      timeServe:moment().format('HH:mm:ss A'),
    };
  },
  computed: {
    momentDate() {
      moment.locale('es')
      return moment().format('L');
    },
  },
  created(){
    this.getTime();
  },
  mounted() {
    setInterval(() => {
      this.$store.commit('setTimeActual', moment().format('HH:mm:ss A'));
    }, 1000);
  }, methods: {
    getTime() {
      setTimeout(async () => {  // Usando función de flecha aquí
        this.timeServe = await getTimeServe();
        this.getTime(); // Llama recursivamente con el contexto correcto
      }, 1000);
    },
  },
};
</script>