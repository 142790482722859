<template>
  <div class="container-fluid py-5">
    <div class="row">
      <div class="col-12">
        <div class="multisteps-form">
          <!--form panels-->
          <div class="row">
            <div class="col-12 col-lg-10 m-auto">
              <form class="multisteps-form__form" style="max-width: 100%;">
                <black-list-table :listaData="response"></black-list-table>
              </form>
            </div>
          </div>
        </div>
      </div>
      <modal-delete :is-visible="modals.modalVisible">
        <p class="text-center"><b>¿Estás seguro de que deseas eliminar?</b></p>
        <div class="mt-lg-4 d-flex justify-content-between">
          <button class="btn bg-gradient-danger my-sm-auto mt-2 mb-0" @click="deleteContacto">Eliminar</button>
          <button class="btn bg-gradient-dark my-sm-auto mt-2 mb-0" @click="closeModal">Cancelar</button>
        </div>
      </modal-delete>
    </div>
    <Loader ref="loader"/>
  </div>
</template>

<script>

import {encryptAES, horaAhora, getUser, getUserSub, onPermissionsUser} from "@/config/servicios/campana/util";
import {
 blacklist_available, blacklist_delete_blacklist
} from "@/config/servicios/campana/contactos/servicesContactosCamp";
import BlackListTable from "@/views/contactsC/components/BlackListTable.vue";
import ModalDelete from "@/components/Modal/ModalDelete.vue";
import Swal from "sweetalert2";
import Loader from "@/components/Modal/Loader.vue";
import dataTables from "@/views/applications/DataTables.vue";


export default {
  name: "BlackList",
  components: {
    Loader,
    ModalDelete,
    BlackListTable,
  },
  computed: {
    dataTables() {
      return dataTables
    }
  },
  data() {
    return {
      contactSingle: {},
      response: {},
      responseContact: [],
      modals: {
        modalVisible: false,
      },
      permisos: {
        create: onPermissionsUser('contacts.xhtml', 'create'),
        modify: onPermissionsUser('contacts.xhtml', 'modify'),
        destroy: onPermissionsUser('contacts.xhtml', 'destroy'),
      },
    };
  },
  methods: {
    openModal(lista) {
        this.contactSingle = lista;
        this.modals.modalVisible = true;
    },
    closeModal() {
      this.modals.modalVisible = false;
      this.contactSingle = {};
    },
    async deleteContacto() {

      this.$refs.loader.showLoader();

      let self = this;
      let user = (getUserSub()!=null) ? getUserSub() :getUser();

      let js = {
        "time_expired":horaAhora(),
        "customer_user":encodeURIComponent(JSON.stringify(user)),
        "blacklist": encodeURIComponent(JSON.stringify({blacklistPK: this.contactSingle})),
      }

      var body = new URLSearchParams(); body.append('TOKEN', (encryptAES(js)));

      await blacklist_delete_blacklist(body)
          .then(function (response) {
            console.log(response)
            if (response.data.response === "200") {
              console.log(response.data);
              self.$refs.loader.hideLoader();
              self.closeModal();
              Swal.fire({
                title: 'Contacto eliminado de forma Correcta',
                icon: 'success',
                buttonsStyling: false,
                customClass: {
                  confirmButton: 'btn btn-celcom-blue',
                },
                type: 'success',
              }).then((result) => {
                if (result.value) {
                  location.reload();
                }});
            } else {
              console.log("No se pudo borrar contacto");
              Swal.fire({
                title: 'No se pudo borrar contacto',
                icon: 'error',
                buttonsStyling: false,
                customClass: {
                  confirmButton: 'btn btn-celcom-orange',
                },
                type: 'error',
              })
            }
          })
          .catch(function (error) {
            console.log("No se pudo borrar contacto",error);
            Swal.fire({
              title: 'No se pudo borrar contacto',
              icon: 'error',
              buttonsStyling: false,
              customClass: {
                confirmButton: 'btn btn-celcom-orange',
              },
              type: 'error',
            })
            self.$refs.loader.hideLoader();
          });

      self.$refs.loader.hideLoader();

    },
    cargarDatos(){
      this.obtenerLista();
    },
    async obtenerLista(){
      try {
        let user = (getUserSub()!=null) ? getUserSub() :getUser();

        let js = {
          "time_expired":horaAhora(),
          "customer_user":encodeURIComponent(JSON.stringify(user)),
        }

        var body = new URLSearchParams();
        body.append("TOKEN", encryptAES(js));


        const response = await blacklist_available(body);
        // Almacena la respuesta en la variable de datos
        if (response.data.response === "200") {

          this.response = response.data.data;
        } else {
          console.log("No se encontró lista");

        }
      } catch (error) {
        console.error("Error al obtener datos", error);
      }
    },
  },
  mounted() {
    this.cargarDatos();
  },
  created() {

  }
};
</script>
<style scoped>
/* Agrega estilos según tus necesidades */
.modal {
  display: none;
}

.modal.is-active {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
}

.modal-close {
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
  background: none;
  border: none;
}
.buttons {
  margin: 8px;
}
</style>