<template>
  <div class="card">
    <div class="card-header pb-0">
      <div class="row">
        <h6 class="col-md-4 col-lg-5 col-xl">Reporte Ejecutivo</h6>
        <div class="d-flex flex-column flex-md-row col-md-8 justify-content-end">
          <date-picker
              class="multisteps-form__input me-md-2 m-md-0"
              :value="valuePlaceholder1"
              @fecha-seleccionada="fechaInicio"
          />
          <date-picker
              class="multisteps-form__input me-md-2 m-md-0"
              :value="valuePlaceholder2"
              @fecha-seleccionada="fechaFin"
          />
          <button
              class="btn btn-celcom-orange m-0"
              data-toggle="tooltip"
              data-original-title="Agregar Contacto"
              @click.prevent="cargarDatos"
          >
            <i class="fas fa-filter me-2"></i>Filtrar
          </button>
        </div>
      </div>
    </div>
    <div class="card-body">
      <div v-if="dataCard" class="row">
        <div class="col-4">
          <label>Campañas</label>
          <CardReport
              :data="dataCard.find(item => item.title === 'Campañas enviadas') || {}"
          />
        </div>
        <div class="col-4">
          <label>Tasa de Respuesta</label>
          <CardReport
              :data="dataCard.find(item => item.title === 'Enviados')|| {}"
          />
        </div>
        <div class="col-4 pt-1">
          <label> </label>
          <CardReport
              :data="dataCard.find(item => item.title === 'Entregados')|| {}"
          />
        </div>
      </div>
      <div class="row align-items-stretch">
        <div class="col-8">
          <GradientBarChart
              v-if="dataBar"
              id="chart-bar"
              :key="dataBar"
              class="borde-bar"
              :chart="dataBar"
          />
        </div>
        <div v-if="dataCard" class="col-4">
          <CardReport
              :data="dataCard.find(item => item.title === 'Fallidos')|| {}"
          />
          <CardReport
              :data="dataCard.find(item => item.title === 'Pendientes')|| {}"
          />
          <CardReport
              :data="dataCard.find(item => item.title === 'Clics de Usuarios')|| {}"
          />
          <CardReport
              :data="dataCard.find(item => item.title === 'Clics Únicos')|| {}"
          />
        </div>
      </div>
      <div class="row">
        <div v-for="(row, index) in dataDoughnut" :key="row.uniqueKey || index" class="col-4">
          <GradientDoughnutChart
              :id="'chart-doughnut-' + index"
              :key="row"
              :chart="row"
              class="borde-bar"
          />
        </div>
      </div>
      <div class="row mt-2">
        <div class="col">
          <div class="map-estilo">
            <label class="borde-bar label-map">Ubicación</label>
            <hr class="m-0 mb-2">
            <country-usage-map
                :key="dataMap"
                :data-map="dataMap"
                class="mb-2"
            />
          </div>
        </div>
      </div>
    </div>
    <Loader ref="loader"/>
  </div>
</template>

<script>
import {
  report_ejecutivo_grafica_doughnut,
  report_ejecutivo_grafica_bar,
  report_ejecutivo_grafica_map,
  report_ejecutivo_cards
} from "@/config/servicios/campana/envios/Reportes";
import {
  encryptAES,
  horaAhora2,
  getUser,
  getUserSub, horaAhora,
} from "@/config/servicios/campana/util";
import Swal from "sweetalert2";
import Loader from "@/components/Modal/Loader.vue";
import GradientDoughnutChart from "@/examples/Charts/GradientDoughnutChart.vue";
import GradientBarChart from "@/examples/Charts/GradientBarChart.vue";
import CountryUsageMap from "@/examples/Charts/CountryUsageMap.vue";
import CardReport from "@/views/envios/Reportes/components/CardReport.vue";
import DatePicker from "@/views/envios/Reportes/components/DatePicker.vue";
import moment from "moment/moment";
export default {
  name: "ReportesEjecutivoTable",
  components: {
    DatePicker,
    GradientDoughnutChart,
    GradientBarChart,
    Loader,
    CardReport,
    CountryUsageMap,
  },
  data() {
    return {
      valuePlaceholder1: "Fecha Inicio",
      valuePlaceholder2: "Fecha Fin",
      dataCard: null,
      dataMap: {},
      dataDoughnut: null,
      dataBar: null,
      dates: {
        inicioRep: horaAhora2(),
        finRep: horaAhora2()
      },
    };
  },
  mounted() {
    this.cargarDatos();
  },
  methods: {
    async cargarDatos(){
      await this.obtenerGraficosDoughnut();
      await this.obtenerGraficosMap();
      await this.obtenerGraficosBar();
      await this.obtenerCards();
    },
    fechaInicio(fecha) {
      this.dates.inicioRep = fecha;
    },
    fechaFin(fecha) {
      this.dates.finRep = fecha;
    },
    async obtenerGraficosDoughnut() {
      try {
        this.$refs.loader.showLoader();

        let user = (getUserSub() != null) ? getUserSub() : getUser();
        let js = {
          "start_date": moment(this.dates.inicioRep).valueOf().toString(),
          "end_date": moment(this.dates.finRep).valueOf().toString(),
          "customer": encodeURIComponent(JSON.stringify(user.customer)),
          "time_expired": horaAhora(),
        };

        var body = new URLSearchParams();
        body.append('TOKEN', encryptAES(js));

        let response = await report_ejecutivo_grafica_doughnut(body);

        if (response.data.response === "200") {
          this.dataDoughnut = response.data.data;  // Usar `this` directamente
        } else {
          console.log("No se encontraron registros");
          Swal.fire({
            title: 'No se encontraron registros',
            buttonsStyling: false,
            customClass: {
              confirmButton: 'btn btn-celcom-orange',
            },
            icon: 'warning'
          });
        }
      } catch (error) {
        console.log("Error al obtener los datos:", error);
        Swal.fire({
          title: 'No se encontraron registros',
          buttonsStyling: false,
          customClass: {
            confirmButton: 'btn btn-celcom-orange',
          },
          icon: 'warning'
        });
      } finally {
        this.$refs.loader.hideLoader();
      }
    },
    async obtenerGraficosMap() {

      this.$refs.loader.showLoader();

      let self = this;
      let user = (getUserSub()!=null) ? getUserSub() :getUser();

      let js = {
        "start_date":moment(this.dates.inicioRep).valueOf().toString(),
        "end_date":moment(this.dates.finRep).valueOf().toString(),
        "customer": encodeURIComponent(JSON.stringify(user.customer)),
        "time_expired":horaAhora(),
      }

      var body = new URLSearchParams(); body.append('TOKEN', (encryptAES(js)));

      await report_ejecutivo_grafica_map(body)
          .then(function (response) {

            if (response.data.response === "200") {
              self.dataMap = response.data.data[0];
            } else {
              console.log("No se encontraron registros1");
              Swal.fire({
                title: 'No se encontraron registros',
                buttonsStyling: false,
                customClass: {
                  confirmButton: 'btn btn-celcom-orange',
                },
                type: 'warning',
                icon: 'warning'
              })
            }

            self.$refs.loader.hideLoader();

          })
          .catch(function (error) {
            console.log("No se encontraron registros2",error);
            Swal.fire({
              title: 'No se encontraron registros',
              buttonsStyling: false,
              customClass: {
                confirmButton: 'btn btn-celcom-orange',
              },
              type: 'warning',
              icon: 'warning'
            })
            self.$refs.loader.hideLoader();
          });

    },
    async obtenerGraficosBar() {

      this.$refs.loader.showLoader();

      let self = this;
      let user = (getUserSub()!=null) ? getUserSub() :getUser();

      let js = {
        "start_date":moment(this.dates.inicioRep).valueOf().toString(),
        "end_date":moment(this.dates.finRep).valueOf().toString(),
        "customer": encodeURIComponent(JSON.stringify(user.customer)),
        "time_expired":horaAhora(),
      }

      var body = new URLSearchParams(); body.append('TOKEN', (encryptAES(js)));

      await report_ejecutivo_grafica_bar(body)
          .then(function (response) {
            if (response.data.response === "200") {
              self.dataBar = response.data.data[0];
            } else {
              console.log("No se encontraron registros1");
              Swal.fire({
                title: 'No se encontraron registros',
                buttonsStyling: false,
                customClass: {
                  confirmButton: 'btn btn-celcom-orange',
                },
                type: 'warning',
                icon: 'warning'
              })
            }

            self.$refs.loader.hideLoader();

          })
          .catch(function (error) {
            console.log("No se encontraron registros2",error);
            Swal.fire({
              title: 'No se encontraron registros',
              buttonsStyling: false,
              customClass: {
                confirmButton: 'btn btn-celcom-orange',
              },
              type: 'warning',
              icon: 'warning'
            })
            self.$refs.loader.hideLoader();
          });

    },
    async obtenerCards() {

      this.$refs.loader.showLoader();

      let self = this;
      let user = (getUserSub()!=null) ? getUserSub() :getUser();

      let js = {
        "start_date":moment(this.dates.inicioRep).valueOf().toString(),
        "end_date":moment(this.dates.finRep).valueOf().toString(),
        "customer": encodeURIComponent(JSON.stringify(user.customer)),
        "time_expired":horaAhora(),
      }

      var body = new URLSearchParams(); body.append('TOKEN', (encryptAES(js)));

      await report_ejecutivo_cards(body)
          .then(function (response) {
            if (response.data.response === "200") {
              self.dataCard = response.data.data.map(item => ({
                title: item.title,
                value: item.value.toLocaleString(), // Formatea el número con comas
                porcentaje: item.porcentaje,
              }));
            }else {
              console.log("No se encontraron registros1");
              Swal.fire({
                title: 'No se encontraron registros',
                buttonsStyling: false,
                customClass: {
                  confirmButton: 'btn btn-celcom-orange',
                },
                type: 'warning',
                icon: 'warning'
              })
            }

            self.$refs.loader.hideLoader();

          })
          .catch(function (error) {
            console.log("No se encontraron registros2",error);
            Swal.fire({
              title: 'No se encontraron registros',
              buttonsStyling: false,
              customClass: {
                confirmButton: 'btn btn-celcom-orange',
              },
              type: 'warning',
              icon: 'warning'
            })
            self.$refs.loader.hideLoader();
          });

    },
  },
};
</script>

<style scoped>
.label-map {
  font-size: 1.0rem;
  color: #333;
}
.map-estilo {
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}
.borde-bar {
  margin: 8px;
}
.borde-bar-1 {
  margin: 5px;
}
.btn-plantilla{
  color: #fff;
  background-color: #e64477;
  border-color: #e64477;
}
.buttons {
  margin: 8px;
}
.tooltip-container {
  position: relative;
  display: inline-block;
}

.tooltip {
  position: absolute;
  bottom: 100%; /* Coloca el tooltip arriba del contenido */
  left: 50%;
  transform: translateX(-50%);
  padding: 5px;
  background-color: #333;
  color: #fff;
  border-radius: 3px;
  font-size: 12px;
  opacity: 0;
  transition: opacity 0.3s ease;
  pointer-events: none; /* Evita que el tooltip sea clickeable */
}

.tooltip-container:hover .tooltip {
  opacity: 1;
}
</style>