// api.js
import axios from 'axios';
import Config from "@/config/servicios/campana/config";
export const add_short_url = (body) => {
    return axios.post(Config.url_service + Config.add_short_url,body,{
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        }});
};
export const available_short_url = (body) => {
    return axios.post(Config.url_service + Config.available_short_url,body,{
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        }});
};

// Agrega más funciones según tus necesidades