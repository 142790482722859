<template>
  <div class="row">
    <argon-input
        v-model="botonLocal.texto"
        class="multisteps-form__input col-12 col-sm-5"
        type="text"
        placeholder="Escriba el título"
        @input="actualizarTexto"
    />
    <argon-input
        v-model="botonLocal.valorNumerico"
        class="multisteps-form__input col-12 col-sm-5"
        type="text"
        placeholder="Escriba el valor numérico"
        @input="actualizarTexto"
    />
    <span
        type="button"
        class="btn btn-outline-danger col-12 col-sm-2"
        title="Haz click para eliminar el boton"
        @click="eliminarBoton">X</span>
  </div>
</template>

<script>
import ArgonInput from "@/components/ArgonInput.vue";

export default {
  components: {ArgonInput},
  props: {
    boton:{
      type: Object,
      required: true,
    },
  },
  emits: ['actualizar-texto', 'eliminar-boton'],
  data() {
    return {
      botonLocal: { ...this.boton }, // Crear una copia local del botón
    };
  },
  methods: {
    actualizarTexto() {
      // Emitir un evento al componente padre (BotonRR) con el nuevo texto
      this.$emit('actualizar-texto', {
        id: this.boton.id,
        nuevoTexto: this.botonLocal.texto,
        nuevoValorNumerico: this.botonLocal.valorNumerico,
      });
    },
    eliminarBoton() {
      // Emitir un evento al componente padre (BotonRR) con el id a eliminar
      this.$emit('eliminar-boton', { id: this.boton.id, tipo: this.boton.tipo });
    },
  },
};
</script>

<style scoped>

</style>
