<template>
  <div class="card">
    <div class="pb-0 card-header mb-0 d-flex justify-content-between align-items-center">
      <h6 class="mb-0">Lista de Clientes</h6>
      <div class="d-flex">
        <button
            class="btn font-weight-bold"
            data-toggle="tooltip"
            data-original-title="Agregar Contacto"
            @click.prevent="agregar"
        >
          <i class="fas fa-plus"></i> Agregar
        </button>
      </div>
    </div>
    <!-- Agrega un campo de entrada para el buscador -->
    <div class="pb-0 card-header mb-0">
      <input v-model="search" type="text" class="form-control mt-2" placeholder="Buscar...">
    </div>
    <div class="table-responsive">
      <table class="table align-items-center mb-0">
        <thead>
          <tr>
            <th
              class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
            >
              Nombre
            </th>
            <th
              class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
            >
              País
            </th>
            <th
              class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
            >
              Estado
            </th>
            <th class="text-secondary opacity-7"></th>
          </tr>
        </thead>
        <tbody>
        <tr v-for="(customer, index) in filteredData" :key="index">
            <td>
              <div class="d-flex px-2 py-1">
                <argon-avatar
                  :img="img1"
                  class="me-3"
                  size="sm"
                  alt="avatar image"
                  border-radius="lg"
                />
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm">{{customer.customerName}}</h6>
                </div>
              </div>
            </td>
            <td>
              <p class="text-sm text-secondary mb-0">{{ customer.country }}</p>
            </td>
            <td>
              <span class="badge badge-dot">
                <i :class="{ 'bg-info': customer.active, 'bg-dark': !customer.active }"></i>
                <span class="text-dark text-xs">{{customer.active ? "Activo" : "No activo"}}</span>
              </span>
            </td>
          <td class="align-middle text-center text-sm">
            <button
                class="btn font-weight-bold"
                data-toggle="tooltip"
                data-original-title="Edit customer"
                @click.prevent="$parent.openModalEditarCustomer(customer)"
            >
              <i class="fas fa-pencil-alt me-1"></i> <!-- Ícono de lápiz -->
            </button>
<!--            <button
                v-if="tipoUser !== 'admin'"
                class="btn font-weight-bold"
                data-toggle="tooltip"
                data-original-title="Eliminar user"
                @click.prevent="$parent.openModal(canal.channelId)"
            >
              <i class="fas fa-minus-circle icono-rojo"></i> &lt;!&ndash; Ícono de menos para eliminar &ndash;&gt;
            </button>-->
          </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import ArgonAvatar from "@/components/ArgonAvatar.vue";
import customer from "../Customer.vue";
import img1 from "@/assets/img/team-2.jpg";

export default {
  name: "CustomerTable",
  components: {
    ArgonAvatar,
  },
  props: {
    customerData: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      img1,
      search: "", // Variable para almacenar la entrada del buscador
    };
  },
  computed: {
    customer() {
      return customer
    },
    // Utiliza una propiedad computada para filtrar los datos según la búsqueda
    filteredData() {
      if (!Array.isArray(this.customerData)) {
        return [];
      }
      return this.customerData.filter(customer => {
        const name = customer.customerName ?? '';
        const country = customer.country ?? '';

        return (
            name.toLowerCase().includes(this.search.toLowerCase()) ||
            country.toLowerCase().includes(this.search.toLowerCase())
        );
      });
    },
  },
  methods: {
    agregar(){
      this.$router.push({ name: "Nuevo Customer" });
      console.log("Agregar");
    },
  },
};
</script>
