<template>
    <div class="mb-3 accordion-item">
      <h6 :id="accordionId" class="accordion-header">
        <button
          class="accordion-button font-weight-bold"
          type="button"
          data-bs-toggle="collapse"
          :data-bs-target="`#${collapseId}`"
          :aria-expanded="active ? 'true' : 'false'"
          :aria-controls="collapseId"
        >
          <slot name="header" />
          <i
            class="pt-1 text-xs collapse-close fas fa-chevron-up position-absolute end-0 me-3"
          ></i>
          <i
            class="pt-1 text-xs collapse-open fas fa-chevron-down position-absolute end-0 me-3"
          ></i>
        </button>
      </h6>
      <div
        :id="collapseId"
        class="accordion-collapse collapse"
        :class="active ? 'show' : ''"
        :aria-labelledby="accordionId"
      >
        <div class="text-sm accordion-body">
          <slot name="body" />
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "AccordionItem",
    props: {
      accordionId: {
        type: String,
        default: "",
      },
      collapseId: {
        type: String,
        default: "",
      },
      active: {
        type: Boolean,
        default: false,
      },
    },
  };
  </script>
  