<template>
    <div class="container-fluid py-5">
      <div class="row">
        <div class="col-12">
          <div class="multisteps-form">
            <div class="row">
              <div class="col-12 col-lg-10 mx-auto mb-4">
                <div class="card">
                  <div class="card-body">
                    <div class="multisteps-form__progress">
                      <button
                        class="multisteps-form__progress-btn js-active"
                        type="button"
                        title="User Info"
                        :class="activeStep >= 0 ? activeClass : ''"
                        @click="activeStep = 0"
                      >
                        <span>1</span>
                      </button>
                      <button
                        class="multisteps-form__progress-btn"
                        type="button"
                        title="Address"
                        :class="activeStep >= 1 ? activeClass : ''"
                        @click="activeStep = 1"
                      >
                        2
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--form panels-->
            <div class="row">
              <div class="col-12 col-lg-10 m-auto">
                <form class="multisteps-form__form">
                  <!--single form panel-->
                  <user-info :class="activeStep === 0 ? activeClass : ''" />
                  <!--single form panel-->
                  <user-address :class="activeStep === 1 ? activeClass : ''" />
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import UserInfo from "./components/UserInfo.vue";
  import UserAddress from "./components/UserAddress.vue";
  import { addContacto } from "@/config/servicios/contactos/addContacto";

  export default {
    name: "Nuevo Contacto",
    components: {
      UserInfo,
      UserAddress,
    },
    data() {
      return {
        showMenu: false,
        activeClass: "js-active position-relative",
        activeStep: 0,
        formSteps: 3,
      };
    },
    methods: {
      async addContactos() {
        let name = document.getElementById("contactName").value;
        let rut = document.getElementById("contactRut").value;
        let email = document.getElementById("email").value;
        let empresa = document.getElementById("empresa").value;
        let msisdn = document.getElementById("msisdn").value;
        let tag = document.getElementById("selTags").value;
        let birthdate = document.getElementById("birthdate").value;
        let gender = document.getElementById("selectedGenero").value;
        let country = document.getElementById("selectedCountry").value;
        let sourceId = document.getElementById("selectedSource").value;
        try {
          const response = await addContacto(name, rut, email, empresa, msisdn, tag, birthdate, gender, country, sourceId);
          // Almacena la respuesta en la variable de datos
          console.log("Contacto Agregado: " + response);
        } catch (error) {
          console.error("Error al obtener datos", error);
        } finally {
          this.$router.push({ name: "Lista Contactos" });
        }
      },
      nextStep() {
        if (this.activeStep < this.formSteps) {
          this.activeStep += 1;
        } else {
          this.activeStep -= 1;
        }
      },
      prevStep() {
        if (this.activeStep > 0) {
          this.activeStep -= 1;
        }
      },
    },
  };
  </script>
  