<template>
  <div class="container-fluid py-4">
    <div class="row">
    <div class="row mt-4">
      <div class="col-12">
        <customer-table :customerData="response.data"/>
      </div>
    </div>
      <modal :is-visible="modalEditarCustomer">
        <p><b>Edite la Empresa</b></p>
        <div class="multisteps-form__content">
          <div class="row mt-3">
            <div class="multisteps-form__content">
              <div class="row mt-3">
                <div class="col-12 col-sm-6">
                  <label>Empresa</label>
                  <argon-input
                      id="customerNameAdd"
                      v-model="name"
                      class="multisteps-form__input"
                      type="text"
                      placeholder="Celcom"
                  />
                </div>
              </div>
              <div class="col-12 col-sm-4 mt-3 mt-sm-0">
                <label>País</label>
                <select
                    v-model="country"
                    id="selectedCountryCustomer"
                    class="multisteps-form__select form-control form-select"
                    name="opciones-Country"
                >
                  <option selected="selected" disabled value="">Países</option>
                  <option value="CHILE">CHILE</option>
                </select>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-12 col-sm-6">
                <label>Estado</label>
                <div class="form-check">
                  <input
                      class="form-check-input"
                      type="checkbox"
                      id="estadoCheckboxAddCustomer"
                      v-model="active"
                  />
                  <label class="form-check-label" for="estadoCheckbox">
                    {{ active ? 'Activo' : 'No Activo' }}
                  </label>
                </div>
              </div>
              <div class="col-12 col-sm-6">
                <label>Super Administrador</label>
                <div class="form-check">
                  <input
                      class="form-check-input"
                      type="checkbox"
                      id="adminEstadoCheckboxAdd"
                      v-model="adminCustomer"
                  />
                  <label class="form-check-label" for="estadoCheckbox">
                    {{ adminCustomer ? 'Activo' : 'No Activo' }}
                  </label>
                </div>
              </div>
            </div>
          <div class="button-row d-flex mt-2">
            <div class="text-white ms-auto mb-0 js-btn-next">
              <button class="btn mb-0 bg-gradient-celcom-orange mt-2 mb-0" @click="editCustomer">Editar</button>
              <button class="btn mb-0 bg-gradient-dark mt-2 mb-0" @click="closeModal">Cancelar</button>
            </div>
          </div>
        </div>
        </div>
      </modal>
  </div>
  </div>
</template>

<script>
import CustomerTable from "./components/CustomerTable.vue";
import {listCustomerServ} from "@/config/servicios/customer/servicesCustomer";
import ArgonInput from "@/components/ArgonInput.vue";
import Modal from "@/components/Modal/Modal.vue";
import {editCu} from "@/config/servicios/customer/editCustomer";

export default {
  name: "Customer",
  components: {
    Modal, ArgonInput,
    CustomerTable,
  },
  data() {
    return {
      id: null,
      name: null,
      adminCustomer: null,
      active: null,
      country: null,
      modalEditarCustomer: false,
      response: {}, // Aquí almacenarás la respuesta del servicio
    };
  },
  methods: {
    async editCustomer() {
      try {
        const response = await editCu(this.id, this.name, this.adminCustomer, this.active, this.country);
        // Almacena la respuesta en la variable de datos
        console.log("Customer Editado: " + response);
      } catch (error) {
        console.error("Error al obtener datos", error);
      } finally {
        this.modalEditarCustomer = false;
        await this.listCustomer();
      }
    },
    openModalEditarCustomer(customer) {
      const customerData = JSON.parse(JSON.stringify(customer));

      this.id = customerData.customerId;
      this.name = customerData.customerName;
      this.adminCustomer = customerData.adminCustomer;
      this.active = customerData.active;
      this.country = customerData.country;

      this.modalEditarCustomer = true;
    },
    closeModal() {
      this.modalEditarCustomer = false;
    },
    async listCustomer() {
      try {
        const response = await listCustomerServ();
        // Almacena la respuesta en la variable de datos
        this.response = response.data;
      } catch (error) {
        console.error("Error al obtener datos", error);
      }
    },
  },
  mounted() {
    this.listCustomer();
  }
};
</script>
<style scoped>
/* Agrega estilos según tus necesidades */
.modal {
  display: none;
}

.modal.is-active {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
}

.modal-close {
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
  background: none;
  border: none;
}
.buttons {
  margin: 8px;
}
</style>