<template>
  <div
    class="card multisteps-form__panel"
    data-animation="FadeIn"
  >
    <div class="card-header border-1">
      <h5 class="font-weight-bolder mb-0">Inserte datos del Contacto</h5>
      <p class="mb-0 text-sm">Información obligatoria</p>
    </div>
    <div class="card-body multisteps-form__content">
      <div class="row">
        <div class="col-12 col-sm-6">
          <label>Nombre</label>
          <argon-input
            id="contactName"
            v-model="contactName"
            class="multisteps-form__input mb-2"
            type="text"
            placeholder="Nombre contacto"
          />
          <div v-if="!soloLetra" :class="{ 'is-invalid-d': !soloLetra }">
            <p class="mb-0 text-sm">Solo puede contener letras y un mínimo de 3 caracteres</p>
          </div>
        </div>
        <div class="col-12 col-sm-6">
          <label>Teléfono</label>
          <argon-input
              id="msisdn"
              v-model="msisdn"
              class="multisteps-form__input mb-2"
              type="text"
              placeholder="Creative Tim"
          />
          <div v-if="!validateMsisdn" :class="{ 'is-invalid-d': !validateMsisdn }">
            <p class="mb-0 text-sm">Debe tener el formato 56XXXXXXXXX</p>
          </div>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-12 col-sm-6">
          <label>Empresa</label>
          <argon-input
            id="empresa"
            v-model="empresa"
            class="multisteps-form__input mb-2"
            type="text"
            placeholder="Creative Tim"
          />
          <div v-if="!soloLetraEmpresa" :class="{ 'is-invalid-d': !soloLetraEmpresa }">
            <p class="mb-0 text-sm">Solo puede contener letras y un mínimo de 3 caracteres</p>
          </div>
        </div>
        <div class="col-12 col-sm-6 mt-3 mt-sm-0">
          <label>Dirección de Email</label>
          <argon-input
            id="email"
            v-model="email"
            class="multisteps-form__input mb-2"
            type="email"
            placeholder="argon@dashboard.com"
          />
          <div v-if="!isEmailValid" :class="{ 'is-invalid-d': !isEmailValid }">
            <p class="mb-0 text-sm">Ingresa un correo electrónico válido</p>
          </div>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-12 col-sm-4 mt-3 mt-sm-0">
          <label class="form-label mt-2">Fecha de Nacimiento</label>
          <argon-input
              id="birthdate"
              type="date"
              v-model="birthdate"
              class="multisteps-form__input"
              placeholder="Selecciona una fecha"
          />
        </div>
        <div class="col-12 col-sm-4 mt-3 mt-sm-0">
          <label>Género</label>
          <select
              v-model="selectedGenero"
              id="selectedGenero"
              class="multisteps-form__select form-control form-select"
              name="opciones-Genero"
          >
            <option selected="selected" disabled value="">Elegir</option>
            <option value="Mujer">Mujer</option>
            <option value="Hombre">Hombre</option>
          </select>
        </div>
        <div class="col-12 col-sm-4 mt-3 mt-sm-0">
          <label>País</label>
          <select
              v-model="selectedCountry"
              id="selectedCountry"
              class="multisteps-form__select form-control form-select"
              name="opciones-Country"
          >
            <option selected="selected" disabled value="">Elegir</option>
            <option value="Chile">Chile</option>
            <option value="Cuba">Cuba</option>
            <option value="Uruguay">Uruguay</option>
          </select>
        </div>
      </div>
    </div>
    <div class="card-footer border-1 d-flex mt-2">
      <argon-button
        :disabled="!(soloLetra && validateMsisdn && isEmailValid && soloLetraEmpresa)"
        type="button"
        color="celcom"
        variant="gradient"
        class="text-white ms-auto mb-0 js-btn-next"
        @click="$parent.nextStep"
        >Siguiente <i class="fas fa-chevron-right ms-2"></i></argon-button
      >
    </div>
  </div>
</template>

<script>
import ArgonInput from "@/components/ArgonInput.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import {soloLetras, validateMsisdn, isEmailValid} from "@/config/validaciones";

export default {
  name: "UserInfo",
  components: {
    ArgonInput,
    ArgonButton,
  },
  computed: {
    soloLetra() {
      return soloLetras(this.contactName);
    },
    soloLetraEmpresa() {
      return soloLetras(this.empresa);
    },
    validateMsisdn() {
      return validateMsisdn(this.msisdn);
    },
    isEmailValid() {
      return isEmailValid(this.email);
    },
  },
  data() {
    return {
      email: "",
      msisdn: "",
      empresa: "",
      contactName: "",
      selectedCountry: "",
      birthdate: null,
      selectedGenero: "",
    };
  },
};
</script>
