<template>
    <div class="py-4 container-fluid">
      <div class="row">
        <div class="row">
          <div class="col-lg-4 col-sm-8 mx-auto">
            <NavPillContacto
                :archivo="valorArchivo"
                :sftp="valorSFTP"
                :ftp="valorFTP"
                @cambiar-archivo="cambiarArchivo"
                @cambiar-sftp="cambiarSftp"
                @cambiar-ftp="cambiarFtp"
            />
          </div>
        </div>
        <div v-if="valorArchivo" class="mx-auto col-lg-10 col-12">
          <div class="mt-4 card">
            <div class="card-header border-1">
              <h6 class="mb-0">Subir archivo</h6>
            </div>
            <div class="card-body">
              <div
                id="dropzone"
                class="form-control dropzone"
              >
                <div class="fallback">
                  <input name="file" type="file" ref="fileInput" />
                </div>
              </div>
              <div class="mt-4 d-flex justify-content-end">
                <button
                    type="button"
                    name="button"
                    class="m-0 text-white btn bg-gradient-celcom ms-2"
                    @click.prevent="procesarArchivo"
                >
                  Crear Lista
                </button>
              </div>
            </div>
          </div>
        </div>

        <div v-if="valorSFTP" class="mx-auto col-lg-10 col-12">
          <div class="mt-4 card">
            <div class="card-header border-1 d-flex justify-content-between align-items-center">
              <h6 class="mb-0">Datos SFPT</h6>
              <button
                    class="btn btn-celcom-orange mb-0"
                    data-toggle="tooltip"
                    data-original-title="Agregar Contacto"
                    @click.prevent="mostrarModalSourcesSftp"
                >
                  Obtener Ficheros
              </button>
            </div>
            <div class="card-body">            
              <div class="multisteps-form__content">
                <div class="row mt-3">
                  <div class="col-12 col-sm-6">
                    <label>Host</label>
                    <argon-input
                        id="contactName"
                        v-model="hostValorSftp"
                        class="multisteps-form__input"
                        type="text"
                        placeholder="xxx.xxx.xxx.xxx"
                    />
                  </div>
                  <div class="col-12 col-sm-6">
                    <label>User</label>
                    <argon-input
                        id="msisdn"
                        v-model="userValorSftp"
                        class="multisteps-form__input"
                        type="text"
                        placeholder="User"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col-12 col-sm-6">
                    <label>Password</label>
                    <argon-input
                        id="empresa"
                        v-model="passwordValorSftp"
                        class="multisteps-form__input"
                        type="text"
                        placeholder="xxxxxxx"
                    />
                  </div>
                  <div class="col-12 col-sm-6 mt-3 mt-sm-0">
                    <label>Port</label>
                    <argon-input
                        id="email"
                        v-model="portValorSftp"
                        class="multisteps-form__input"
                        type="email"
                        placeholder="22"
                    />
                  </div>
                  <div class="col-12 col-sm-6 mt-3 mt-sm-0">
                    <label>Path</label>
                    <argon-input
                        id="email"
                        v-model="pathValorSftp"
                        class="multisteps-form__input"
                        type="text"
                        placeholder="uploads"
                    />
                  </div>
                </div>

                <div class="mt-4 d-flex justify-content-end">
                  <button
                      type="button"
                      name="button"
                      class="m-0 btn btn-light"
                      @click.prevent="testSftp"
                  >
                    Test
                  </button>
                  <button
                      :disabled="testButtonSftp"
                      type="button"
                      name="button"
                      class="m-0 text-white btn bg-gradient-celcom ms-2"
                      @click.prevent="addSftp"
                  >
                    Salvar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="valorFTP" class="mx-auto col-lg-10 col-12">
          <div class="mt-4 card">
            <div class="card-header border-1 d-flex justify-content-between align-items-center">
              <h6 class="mb-0">Datos FPT</h6>
              <button
                    class="btn btn-celcom-orange mb-0"
                    data-toggle="tooltip"
                    data-original-title="Agregar Contacto"
                    @click.prevent="mostrarModalSourcesFtp"
                >
                  Obtener Ficheros
              </button>
            </div>
            <div class="card-body">
              <div class="multisteps-form__content">
                <div class="row mt-3">
                  <div class="col-12 col-sm-6">
                    <label>Host</label>
                    <argon-input
                        id="contactName"
                        v-model="hostValorFtp"
                        class="multisteps-form__input"
                        type="text"
                        placeholder="xxx.xxx.xxx.xxx"
                    />
                  </div>
                  <div class="col-12 col-sm-6">
                    <label>User</label>
                    <argon-input
                        id="msisdn"
                        v-model="userValorFtp"
                        class="multisteps-form__input"
                        type="text"
                        placeholder="FTP"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col-12 col-sm-6">
                    <label>Password</label>
                    <argon-input
                        id="empresa"
                        v-model="passwordValorFtp"
                        class="multisteps-form__input"
                        type="text"
                        placeholder="xxxxxxx"
                    />
                  </div>
                  <div class="col-12 col-sm-6 mt-3 mt-sm-0">
                    <label>Port</label>
                    <argon-input
                        id="email"
                        v-model="portValorFtp"
                        class="multisteps-form__input"
                        type="email"
                        placeholder="22"
                    />
                  </div>
                  <div class="col-12 col-sm-6 mt-3 mt-sm-0">
                    <label>Path</label>
                    <argon-input
                        id="email"
                        v-model="pathValorFtp"
                        class="multisteps-form__input"
                        type="text"
                        placeholder="uploads"
                    />
                  </div>
                </div>

                <div class="mt-4 d-flex justify-content-end">
                  <button
                      type="button"
                      name="button"
                      class="m-0 btn btn-light"
                      @click.prevent="testFtp"
                  >
                    Test
                  </button>
                  <button
                      :disabled="testButtonFtp"
                      type="button"
                      name="button"
                      class="m-0 text-white btn bg-gradient-celcom ms-2"
                      @click.prevent="addFtp"
                  >
                    Salvar
                  </button>
                </div>
              </div>
            </div>
            </div>
          </div>
        </div>
      <modal :is-visible="modalSources">
        <div class="">
          <p v-if="paso===0" class="header mb-0"><b>Elija la fuente</b></p>
          <p v-if="paso===1" class="header mb-0"><b>Elija los archivos</b></p>
          <p v-if="paso===2" class="header mb-0"><b>Archivos Seleccionados</b></p>
          <div v-if="paso === 0" class="multisteps-form__content">
            <ul class="list-unstyled">
              <li v-for="host in matchingHosts" :key="host.host">
                <input type="checkbox" v-model="selectedHosts" :value="host.configId"> {{ host.host }}
              </li>
            </ul>
          </div>
          <div v-if="paso === 1" class="multisteps-form__content">
            <!-- Agrega un campo de entrada para el buscador -->
            <div class="pb-0 card-header mb-0 header">
              <input v-model="search" type="text" class="form-control mt-2" placeholder="Buscar...">
            </div>
            <ul class="list-unstyled">
              <!-- Utiliza la propiedad computada filteredData directamente en v-for -->
              <li v-for="arch in filteredData" :key="arch">
                <input type="checkbox" v-model="selectArchivos" :value="arch"> {{ arch }}
              </li>
              <!-- Mostrar mensaje si la lista está vacía -->
              <li v-if="filteredData.length === 0">No se encontraron archivos.</li>
            </ul>
          </div>
          <load  v-if="paso === 2" @update-tag="updateTag" @update-segment="updateSegment" :selectArchivos="selectArchivos"/>
          <div class="modal-footer mt-lg-4 d-flex justify-content-between js-btn-next">
            <button v-if="paso===0 || paso===1" class="btn bg-gradient-success mt-2 mb-0" @click.prevent="obtenerArchivos()">Seleccionar</button>
            <button v-if="paso===2" class="btn bg-gradient-success mt-2 mb-0" @click.prevent="loadFile()">Cargar</button>
            <button class="btn bg-gradient-dark mt-2 mb-0" @click="ocultarModalSources">Cancelar</button>
          </div>

        </div>
      </modal>

    </div>
  </template>
  
  <script>
  import Dropzone from "dropzone";
  import Choices from "choices.js";
  import Quill from "quill";
  import * as XLSX from "xlsx";
  import {procesarExcel} from "@/config/config";
  import NavPillContacto from "@/views/flex/components/NavPillContacto.vue";
  import ArgonInput from "@/components/ArgonInput.vue";
  import {testSftp, addSftp, listSourceConfiguration, getFiles, loadFile} from "@/config/servicios/sftp/servicesSftp";
  import {testFtp, addFtp, getFilesFtp} from "@/config/servicios/ftp/servicesFtp";
  import Modal from "@/components/Modal/Modal.vue";
  import Load from "@/views/flex/components/Load.vue";
  export default {
    name: "importarLista",
    components: {
      Modal,
      ArgonInput,
      NavPillContacto,
      Load,
    },
    data() {
      return {
        search: "", // Variable para almacenar la entrada del buscador
        testButtonFtp: true,
        testButtonSftp: true,
        tipo: null,
        paso: 0,
        archivos: [],
        selectArchivos: [],
        selectedHosts: [], // Lista para rastrear los hosts seleccionados
        modalSources: false,
        matchingHosts: [],
        responseSftp: {},
        responseLoad: {},
        addSftpVar: null,
        addFtpVar: null,
        hostValorSftp: null,
        userValorSftp: null,
        passwordValorSftp: null,
        portValorSftp: null,
        pathValorSftp: null,
        hostValorFtp: null,
        userValorFtp: null,
        passwordValorFtp: null,
        portValorFtp: null,
        pathValorFtp: null,
        valorArchivo: true,
        valorSFTP: false,
        valorFTP: false,
        archivoExcel: null,
        date: "",
        endDate: "",
        Idsegment: "",
        tags: "",
        config: {
          allowInput: true,
        },
      };
    },
    computed: {
      // Utiliza una propiedad computada para filtrar los datos según la búsqueda
      filteredData() {
        if (!Array.isArray(this.archivos.data)) {
          return [];
        }
        return this.archivos.data.filter(archivo => {
          const name = archivo ?? '';
          return name.toLowerCase().includes(this.search.toLowerCase());
        });
      }
    },
    methods: {
      async updateTag(value) {
        this.tags = value;
      },
      async updateSegment(value){
        this.Idsegment = value;
      },
      async obtenerArchivos() {
        if (this.paso===0) {
          this.paso = 1;
          try {
            // Utilizar Promise.all y map para realizar las llamadas en paralelo
            switch (this.tipo) {
              case 'FTP':
                await Promise.all(this.selectedHosts.map(async (element) => {
                  const response = await getFilesFtp(element);
                  this.archivos = response.data;
                }));
                break;

              case 'SFTP':
                await Promise.all(this.selectedHosts.map(async (element) => {
                  const response = await getFiles(element);
                  this.archivos = response.data;
                }));
                break;

              default:
                console.log('Acción no reconocida');
                // Lógica para acciones no reconocidas
            }

          } catch (error) {
            console.error("Error al obtener datos", error);
          }
        }else if (this.paso===1){
          this.paso = 2;
        }
      },
      mostrarModalSourcesSftp() {
        this.tipo = "SFTP";
        this.listSourceConfiguration("SFTP");
        this.modalSources = true;
        this.paso = 0;
      },
      mostrarModalSourcesFtp() {
        this.tipo = "FTP";
        this.listSourceConfiguration("FTP");
        this.modalSources = true;
        this.paso = 0;
      },
      ocultarModalSources() {
        this.modalSources = false;
        this.selectArchivos = [];
      },
      cargarArchivo(file) {
        const input = document.getElementById("dropzone").querySelector("input[type='file']");
        const selectedFile = file || input.files[0];
        if (selectedFile) {
          const reader = new FileReader();
          reader.onload = (e) => {
            try {
              const data = new Uint8Array(e.target.result);
              const workbook = XLSX.read(data, { type: 'array' });
              const sheetName = workbook.SheetNames[0];
              const sheet = workbook.Sheets[sheetName];

              // Guardar la hoja en una variable de datos
              this.archivoExcel = XLSX.utils.sheet_to_json(sheet, { header: 1 });
            } catch (error) {
              console.error('Error al procesar el archivo:', error);
            }
          };

          reader.readAsArrayBuffer(selectedFile);
        }
      },
      async procesarArchivo() {
        await procesarExcel(this.archivoExcel);
        this.$router.push({ name: "Lista Contactos" });
      },
      async listSourceConfiguration(tipo) {
        try {
          const response = await listSourceConfiguration();
          // Almacena la respuesta en la variable de datos
          this.responseSftp = response.data;
          console.log(this.responseSftp.data);
          await this.sourceHost(tipo);
          console.log(this.matchingHosts);
        } catch (error) {
          console.error("Error al obtener datos", error);
        }
      },
      async testSftp() {
        try {
          const response = await testSftp(
              this.hostValorSftp,
              this.userValorSftp,
              this.passwordValorSftp,
              this.portValorSftp,
              this.pathValorSftp
          );
          // Almacena la respuesta en la variable de datos
          this.testSftpVar = response.data;
          if (this.testSftpVar.data){
            this.testButtonSftp = false;
          }
        } catch (error) {
          console.error("Error al obtener datos", error);
        }
      },
      async addSftp() {
        try {
          const response = await addSftp(
              this.hostValorSftp,
              this.userValorSftp,
              this.passwordValorSftp,
              this.portValorSftp,
              this.pathValorSftp
          );
          // Almacena la respuesta en la variable de datos
          this.addSftpVar = response.data;
          console.log(this.addSftpVar.data);
        } catch (error) {
          console.error("Error al obtener datos", error);
        }
      },
      async testFtp() {
        try {
          const response = await testFtp(
              this.hostValorFtp,
              this.userValorFtp,
              this.passwordValorFtp,
              this.portValorFtp,
              this.pathValorFtp
          );
          // Almacena la respuesta en la variable de datos
          this.testFtpVar = response.data;
          if (this.testFtpVar.data){
            this.testButtonFtp = false;
          }
        } catch (error) {
          console.error("Error al obtener datos", error);
        }
      },
      async addFtp() {
        try {
          const response = await addFtp(
              this.hostValorFtp,
              this.userValorFtp,
              this.passwordValorFtp,
              this.portValorFtp,
              this.pathValorFtp
          );
          // Almacena la respuesta en la variable de datos
          this.addFtpVar = response.data;
          console.log(this.addFtpVar.data);
        } catch (error) {
          console.error("Error al obtener datos", error);
        }
      },
      async sourceHost(sourceNameToSearch) {
        this.matchingHosts = [];
        this.selectedHosts = [];
        for (const entry of this.responseSftp.data) {
          if (entry.contactSourceAvailable.sourceAvailableName === sourceNameToSearch) {
            this.matchingHosts.push({
              host: entry.host,
              configId: entry.customerSourceConfigurationId
            });
          }
        }

        return this.matchingHosts;
      },
      // Un método que se puede llamar para cambiar los valores cuando sea necesario
      cambiarArchivo() {
        this.valorArchivo = true; // Cambia el valor de archivo
        this.valorSFTP = false; // Cambia el valor de sftp
        this.valorFTP = false; // Cambia el valor de sftp
      },
      cambiarSftp() {
        this.valorArchivo = false; // Cambia el valor de archivo
        this.valorSFTP = true; // Cambia el valor de sftp
        this.valorFTP = false; // Cambia el valor de sftp
      },
      cambiarFtp() {
        this.valorArchivo = false; // Cambia el valor de archivo
        this.valorSFTP = false; // Cambia el valor de sftp
        this.valorFTP = true; // Cambia el valor de sftp
      },
      async loadFile() {
        try {
          // Verificar que loadFile está definida y es una función válida
          if (typeof loadFile !== 'function') {
            console.error('La función loadFile no está definida o no es válida.');
            return;
          }

          // Asegurarse de que los parámetros necesarios estén definidos
          if (!this.archivos || !this.tags || !this.Idsegment) {
            console.error('Faltan parámetros requeridos para llamar a loadFile.');
            return;
          }

          const response = await loadFile(this.matchingHosts, this.selectArchivos, this.tags, this.Idsegment);

          // Almacena la respuesta en la variable de datos
          this.responseLoad = response.data;
          console.log(this.responseLoad)
        } catch (error) {
          // Proporcionar más detalles sobre el error
          console.error('Error al obtener datos:', error.message || error.stack || error);
        }finally {
          this.modalSources = false;
          this.selectArchivos = [];
        }
      },
    },
    mounted() {
      if (document.getElementById("editor")) {
        new Quill("#editor", {
          theme: "snow", // Specify theme in configuration
        });
      }
  
      if (document.getElementById("choices-multiple-remove-button")) {
        var element = document.getElementById("choices-multiple-remove-button");
        const example = new Choices(element, {
          removeItemButton: true,
          allowHTML: true,
        });
  
        example.setChoices(
          [
            {
              value: "One",
              label: "Label One",
              disabled: true,
            },
            {
              value: "Two",
              label: "Label Two",
              selected: true,
            },
            {
              value: "Three",
              label: "Label Three",
            },
          ],
          "value",
          "label",
          false
        );
      }

      Dropzone.autoDiscover = false;
      const dropzoneElement = document.getElementById("dropzone");

      const myDropzone = new Dropzone(dropzoneElement, {
        url: "/file-upload",
        acceptedFiles: ".csv,.xls,.xlsx",
        addRemoveLinks: true,
        dictDefaultMessage: "Arrastra tu archivo aquí.",
      });

      myDropzone.on("addedfile", (file) => {
        // Acción al agregar un archivo
        // Puedes acceder al archivo cargado usando el objeto 'file'
        this.cargarArchivo(file);
      });
    },
  };
  </script>
<style scoped>
.modal-content {
  max-height: 80vh; /* Ajusta la altura máxima según tus necesidades */
  overflow-y: auto; /* Permite desplazamiento vertical si el contenido es más grande que la altura máxima */
}

.header {
  position: sticky;
  top: 0;
  background-color: #fff; /* Ajusta el color de fondo según tus necesidades */
  padding: 10px; /* Ajusta el relleno según tus necesidades */
  z-index: 1; /* Asegura que el encabezado permanezca sobre el contenido desplazable */
}

.button-row {
  position: sticky;
  bottom: 0;
  background-color: #fff; /* Ajusta el color de fondo según tus necesidades */
  padding: 10px; /* Ajusta el relleno según tus necesidades */
  z-index: 1; /* Asegura que el pie de página permanezca sobre el contenido desplazable */
}
/* Agrega estilos según tus necesidades */
.modal {
  display: none;
}

.modal.is-active {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
}

.modal-close {
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
  background: none;
  border: none;
}
.buttons {
  margin: 8px;
}
</style>