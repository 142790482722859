<template>
  <div class="card">
    <div class="card-header border-1 d-flex justify-content-between align-items-center flex-column flex-md-row">
      <h6 class="mb-md-0">SMS - Landing</h6>
      <div class="d-flex">
        <button
            class="btn btn-celcom-orange m-0"
            data-toggle="tooltip"
            data-original-title="Agregar Contacto"
            @click.prevent="agregar"
        >
          <i class="fas fa-plus"></i> Nueva Landing
        </button>
      </div>
    </div>

    <!-- Agrega un campo de entrada para el buscador -->
    <div class="pb-0 card-body mb-0">
      <input v-model="search" type="text" class="form-control mt-2" placeholder="Buscar...">
    </div>
    <div class="card-body table-responsive">
      <table class="table align-items-center mb-0">
        <thead>
        <tr>
          <th
              class="text-center text-secondary text-xxs font-weight-bolder opacity-7"
          >
            ID
          </th>
          <th
              class="text-center text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
          >
            URl
          </th>
          <th
              class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
          >
            Nombre
          </th>
          <th class="text-secondary opacity-7"></th>
        </tr>
        </thead>
        <tbody>
        <!-- Utiliza v-for para recorrer el arreglo y generar filas -->
        <tr v-for="(plantilla, index) in filteredData" :key="index">
          <td class="align-middle text-center text-sm">
            <div class="d-flex flex-column justify-content-center">
              <h6 class="mb-0 text-xs">{{ plantilla.name }}</h6>
            </div>
          </td>
          <td class="align-middle text-center text-sm">
            <div class="d-flex flex-column justify-content-center">
              <h6 class="mb-0 text-xs">{{ formatoFecha(plantilla.created) }}</h6>
            </div>
          </td>
          <td class="align-middle text-center text-sm">
            <div class="d-flex flex-column justify-content-center">
              <h6 class="mb-0 text-xs">{{  plantilla.contactGroup.name }}</h6>
            </div>
          </td>
          <td class="align-middle text-center text-sm">
            <button
                class="btn font-weight-bold"
                data-toggle="tooltip"
                data-original-title="Edit user"
                @click.prevent="$parent.openModalEditar(plantilla)"
            >
              <img src="../../../assets/iconos/pen-to-square-solid.svg" alt="Cesto de basura" class="icono-borrar">
            </button>
            <button
                class="btn font-weight-bold"
                data-toggle="tooltip"
                data-original-title="Eliminar user"
                @click.prevent="$parent.openModal(plantilla)"
            >
              <i class="fas fa-duotone fa-trash"></i>
            </button>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import {formatoFecha} from "@/config/config";

export default {
  name: "SmsLinkTable",
  components: {

  },
  props: {
    plantillaData: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      modalVisible: false,
      search: "", // Variable para almacenar la entrada del buscador
    };
  },
  computed: {
    // Utiliza una propiedad computada para filtrar los datos según la búsqueda
    filteredData() {
      if (!Array.isArray(this.plantillaData)) {
        return [];
      }
      return this.plantillaData.filter(plantilla => {
        const name = plantilla.name ?? '';
        const lista = plantilla.contactGroup.name ?? '';
        const contactos = plantilla.contactGroup.validos;

        return (
            name.toLowerCase().includes(this.search.toLowerCase()) ||
            lista.toLowerCase().includes(this.search.toLowerCase()) ||
            (contactos !== undefined && contactos.toString().includes(this.search.toLowerCase()))
        );
      });
    },
  },
  methods: {
    /*changePage(page) {
      this.currentPage = page;
    },*/
    formatoFecha(fecha) {
      return formatoFecha(fecha);
    },
    openModal() {
      this.modalVisible = true;
    },
    closeModal() {
      this.modalVisible = false;
    },
    agregar(){
      this.$router.push({ name: "Nuevo Contacto" });
      console.log("Agregar");
    },
  },
};
</script>

<style scoped>
.btn-plantilla{
  color: #fff;
  background-color: #e64477;
  border-color: #e64477;
}
/* Agrega estilos según tus necesidades */
.icono-rojo {
  color: red;
}
.modal {
  display: none;
}

.modal.is-active {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
}

.modal-close {
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
  background: none;
  border: none;
}
.buttons {
  margin: 8px;
}
</style>