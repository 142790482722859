import CryptoJS from "crypto-js";
import axios from 'axios';
import Config from "@/config/servicios/campana/config";
import swal from "sweetalert2";
import moment from "moment";
import {decryptAES, horaAhora} from "@/config/servicios/campana/util";
export function encryptAES(json_gen) {
    let secret_key = "vFeLfR2MnXgXQStZWglFO6AffhROonTq"
    let token = CryptoJS.AES.encrypt(JSON.stringify(json_gen), secret_key);
    return token;
}

export function setUser(obj){
    localStorage.setItem('user', encryptAES(obj));
}
export function setUserAdmin(obj){
    localStorage.setItem('user_admin', encryptAES(obj));
}
export function getUser(){
    let encryptedToken = localStorage.getItem('user');
    return decryptAES(encryptedToken);
}
export async function initPermisos() {
    let user = getUser();

    let permisosUser = {};

    let js = {
        "time_expired":horaAhora(),
        "customer_user":encodeURIComponent(JSON.stringify(user))
    }

    var body = new URLSearchParams(); body.append('TOKEN', (encryptAES(js)));

    await axios.post(Config.url_service+Config.customer_user_access, body, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
    })
        .then(function (response) {
            if (response.data.response === "200") {
                console.log(response.data.data);

                permisosUser = response.data.data;

                localStorage.setItem('user_permisos', encryptAES(permisosUser));


            } else {
                console.log("No se pudo obtener permisos");
                swal.fire({
                    title: 'No se pudo obtener permisos',
                    buttonsStyling: false,
                    customClass: {
                        confirmButton: 'btn btn-celcom-orange',
                    },
                    type: 'error',
                    icon: 'error'
                })

            }


        })
        .catch(function (error) {
            console.log("No se pudo obtener permisos",error);
            swal.fire({
                title: 'No se pudo obtener permisos',
                buttonsStyling: false,
                customClass: {
                  confirmButton: 'btn btn-celcom-orange',
                },
                type: 'error',
                icon: 'error'
            })

        });

}
export async function obtenerReporteEnvio() {
    let user = getUser();

    //var arr=[];

    let js = {
        "start_date":moment( moment(Date.now() - 7 * 24 * 3600 * 1000).format('YYYY-MM-DD h:mm:ss')).valueOf().toString(),
        "end_date": moment(moment().format('YYYY-MM-DD h:mm:ss')).valueOf().toString() ,
        "global_report":false,
        "time_expired":horaAhora(),
        "customer_user":encodeURIComponent(JSON.stringify(user)),
    }

    console.log(js);

    var body = new URLSearchParams(); body.append('TOKEN', (encryptAES(js)));

    await axios.post(Config.url_service+Config.report_available, body, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
    })
        .then(function (response) {
            console.log(response)

            if (response.data.response === "200") {
                console.log(response.data.data);

                var info = response.data.data.sort((a, b) => (a.idSending < b.idSending) ? 1 : -1)

                localStorage.setItem('enviosreport',JSON.stringify(info))


            } else {
                console.log("No se encontraron registros envios para reporte home");

            }

        })
        .catch(function (error) {
            console.log("No se encontraron registros envios para reporte home",error);

        });

}