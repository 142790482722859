<template>
  <div class="product-card">
    <div class="card bg-gradient-celcom border-white">
      <div class="card-body text-center text-white">
        <p class="card-title">Canal</p>
        <h4 class="card-text text-celcom-orange">
          {{ canalName.name }}
        </h4>
        <button @click="addToCart" class="btn btn-celcom-orange">
          Seleccionar
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    canalName: {
      type: String,
      required: true,
    },
  },
  emits: ["canal-selected"], // Declarar el evento en emits
  methods: {
    addToCart() {
      this.$emit("canal-selected", {
        canalName: this.canalName,
      });
    },
  },
};
</script>

<style scoped>
.product-card {
  margin-bottom: 20px; /* Ajusta según sea necesario */
}

.card {
  width: 100%; /* Ajusta según sea necesario */
}

.card-body {
  padding: 15px; /* Ajusta según sea necesario */
}
</style>
