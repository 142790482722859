<template>
  <div class="w-100" style="height:500px;">
    <div class="wrapper">
      <div class="whatsapp-message-preview">
        <span class="heading" >Preview</span>
        <div class="content-wrapper">
            <div class="header">
              <span v-if="header">{{header}}</span>
            </div>
          <div v-if="media.default">
            <br>
            <img v-if="media.default.value === 'IMG'" :src="media.previewImage ? media.previewImage : media.default.img" class="w-100 alto-media" alt="Preview Image">
            <img v-if="media.default.value === 'VIDEO' && !media.previewVideo" :src="media.default.img" class="w-100 alto-media" alt="Preview Image">
            <img v-if="media.default.value === 'LOCATION'" :src="media.default.img" class="w-100 alto-media" alt="Preview Image">
            <video v-if="media.previewVideo" class="w-100 alto-media" controls>
              <source :src="media.previewVideo ? media.previewVideo : media.default.img" type="video/mp4">
              Your browser does not support the video tag.
            </video>
            <div v-if="media.default.value === 'DOCUMENT'" class="pdf-container">
              <iframe :src="media.previewPdf ? media.previewPdf : media.default.img" frameborder="0" width="100%" height="100px"></iframe>
            </div>
          </div>
          <div class="body" >
            {{ body }}
          </div>
          <div class="footer" >
          {{footer}}
          </div>
          <div class="time-stamp">
            <span >{{ getCurrentTime() }}</span>
          </div>
        </div>
        <div v-if="botones">
          <div v-for="(boton, index) in botones" :key="index" class="action-buttons" :title="boton.title">
            <img :src="boton.img" alt="Vista previa de botones">
            <span>{{ boton.texto }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    header: {
      type: String,
      required: true,
    },
    media: {
      type: Object,
      required: true,
    },
    body: {
      type: String,
      required: true,
    },
    footer: {
      type: String,
      required: true,
    },
    botones: {
      type: Array,
      required: true,
    },
  },
  methods: {
    getCurrentTime() {
      const now = new Date();
      let hours = now.getHours();
      const minutes = now.getMinutes();
      const ampm = hours >= 12 ? 'PM' : 'AM';

      // Convertir el formato de 24 horas a 12 horas
      hours = hours % 12 || 12;

      // Agregar ceros a la izquierda para un solo dígito de minutos
      const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

      return `${hours}:${formattedMinutes} ${ampm}`;
    },
  },
};
</script>

<style scoped>
.wrapper {
  min-height: 170px;
  height: 100%;
  background-image: url(https://gs-upload.gupshup.io/whatsapp/template-preview-bg.webp);
  padding: 16px 12px;
  border-radius: 8px;
  overflow: auto;
  text-align: left;
}
.heading {
  font-weight: bold;
  margin-bottom: 12px;
  margin-left: 4px;
  display: inline-block;
  font-family: Poppins, sans-serif;
  color: #464646;
}
.content-wrapper {
  background-color: #fff;
  border-radius: 4px;
  padding: 12px 8px;
}
.header {
  font-size: 16px;
  font-weight: bold;
  padding: 6px 7px 0px 9px;
  overflow-wrap: break-word;
}
.body {
  display: -webkit-box;
  font-size: 14px;
  color: #3f3f3f;
  overflow-wrap: break-word;
  word-break: break-word;
  max-width: 100%;
  overflow-y: auto;
  max-height: 400px;
  padding: 8px 8px 6px;
  white-space: pre-line;
}
.footer {
  font-size: 16px;
  font-weight: bolder;
  line-height: 16px;
  padding: 0px 7px 8px 9px;
  color: #5f6880;
  overflow-wrap: break-word;
  word-break: break-word;
  display: -webkit-box;
  max-width: 100%;
  max-height: 48px;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 4px;
}
.time-stamp {
  text-align: end;
  margin-right: 12px;
  font-size: 12px;
  color: #5f6880;
}
.action-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fefefe;
  margin: 8px 0px;
  padding: 8px 16px;
  text-align: center;
  color: #00a5f4;
  cursor: pointer;
  flex-grow: 1;
  border-radius: 6px;
  box-shadow: 0 1px 3px 0 var(--gs-shadow-xs);
  word-break: break-word;
}
.action-buttons img {
  width: 1.6rem;
  margin-right: 4px;
}
.alto-media {
  max-height: 100px;
}
</style>
