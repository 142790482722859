<template>
  <div class="product-card h-100">
    <div class="card bg-gradient-celcom border-white h-100">
      <div class="card-body align-content-around text-center text-white">
        <p class="card-title">{{ productName }}</p>
        <div v-if="price!== '0'">
          <h4 class="card-text text-celcom-orange mb-2">
            Precio: {{ "$" + numberWithCommas(price) }}
          </h4>
        </div>
        <div v-else>
          <h4 class="card-text text-celcom-orange">
            Ingrese Monto
          </h4>

            <argon-input
                id="monto"
                v-model="monto"
                type="number"
                class="multisteps-form__input code"
                placeholder="1000"
                @input="addToCart"
            />

        </div>
        <button class="btn btn-celcom-orange mb-0" @click="addToCart">
          Agregar al carrito
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import ArgonInput from "@/components/ArgonInput.vue";

export default {
  components: {ArgonInput},
  props: {
    productName: {
      type: String,
      required: true,
    },
    price: {
      type: Number,
      required: true,
    },
    producto: {
      type: Object,
      required: true,
    },
  },
  emits: ["product-selected"], // Declarar el evento en emits
  data() {
    return {
      monto: 0,
    };
  },
  methods: {
    addToCart() {
      this.$emit("product-selected", {
        products: this.producto,
        monto: this.monto,
      });
    },
    numberWithCommas(value) {
      // Función para formatear el precio con comas
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
  },
};
</script>

<style scoped>
.product-card {
  transition: transform .25s;
}
.card {
  width: 100%; /* Ajusta según sea necesario */
}
.card-body {
  padding: 15px; /* Ajusta según sea necesario */
}
</style>
