<template>
  <div class="container-fluid py-4">
    <div class="row">
      <div class="col-md-10 mx-auto">
        <div class="card mt-4">
          <div class="card-header p-3">
            <h5 class="mb-0">Nuevo Workflow</h5>
          </div>
          <div class="card-body p-3">
            <div class="div_all">
              <div id="stencil" class="div_left"></div>
              <div id="paper" class="div_right"></div>
              <div class="clear"></div>
            </div>
            <argon-button
              id="guardarWF"
              color="celcom"
              size="md"
              variant="gradient"
              class="text-white"
            >
              Guardar Workflow
            </argon-button>
          </div>

          <modal :is-visible="modalTrigger">
            <div class="">
              <div class="modal-header justify-content-end">
                <button
                  class="modal-close btn-celcom-blue btn m-0"
                  aria-label="close"
                  @click="closeModal"
                >
                  <i class="fas fa-times text-white"></i>
                </button>
              </div>
              <div class="modal-body">
                <label class="text-uppercase mb-0">Elegir Trigger</label>
                <select
                  id="tipo-trigger"
                  class="form-control form-select"
                  name="tipo-trigger"
                ></select>
              </div>
              <div class="modal-footer">
                <argon-button
                  id="cambiarTrigger"
                  color="celcom"
                  size="md"
                  variant="gradient"
                  class="text-white"
                >
                  Guardar cambios
                </argon-button>
              </div>
            </div>
          </modal>

          <modal :is-visible="modalAccionCanales">
            <div class="">
              <div class="modal-header justify-content-end">
                <button
                  class="modal-close btn-celcom-blue btn m-0"
                  aria-label="close"
                  @click="closeModal"
                >
                  <i class="fas fa-times text-white"></i>
                </button>
              </div>
              <div class="modal-body">
                <label class="text-uppercase mb-0">Elegir Acción</label>
                <select
                  id="tipo-accion"
                  class="form-control form-select"
                  name="tipo-accion"
                ></select>

                <label class="fw-bolder small text-uppercase mb-0"
                  >Elegir Canales</label
                >
                <select
                  id="tipo-canal"
                  class="form-control form-select"
                  name="tipo-canal"
                ></select>
              </div>
              <div class="modal-footer">
                <argon-button
                  id="cambiarAcciones"
                  color="celcom"
                  size="md"
                  variant="gradient"
                  class="text-white"
                >
                  Guardar cambios
                </argon-button>
              </div>
            </div>
          </modal>

          <modal :is-visible="modalCondicion">
            <div class="">
              <div class="modal-header justify-content-end">
                <button
                  class="modal-close btn-celcom-blue btn m-0"
                  aria-label="close"
                  @click="closeModal"
                >
                  <i class="fas fa-times text-white"></i>
                </button>
              </div>
              <div class="modal-body">
                <label class="fw-bolder small text-uppercase mb-0"
                  >Elegir Condición</label
                >
                <select
                  id="tipo-condicion"
                  class="form-control form-select"
                  name="tipo-condicion"
                ></select>
              </div>
              <div class="modal-footer">
                <argon-button
                  id="cambiarCondicion"
                  color="celcom"
                  size="md"
                  variant="gradient"
                  class="text-white"
                >
                  Guardar cambios
                </argon-button>
              </div>
            </div>
          </modal>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Modal from "@/components/Modal/Modal.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import Choices from "choices.js";

import $ from "jquery";
import * as joint from "jointjs";

window.joint = joint;

import Config from "@/config/config.js";
import CryptoJS from "crypto-js";
import { getUser } from "@/util/util.js";

export default {
  name: "Nuevo Workflow",
  components: {
    Modal,
    ArgonButton,
  },
  data() {
    return {
      snackbar: null,
      modalNuevoElemento: false,
      modalTrigger: false,
      modalAccionCanales: false,
      modalCondicion: false,
      nombreNuevoElemento: "",
    };
  },
  methods: {
    closeSnackbar() {
      this.snackbar = null;
    },
    abrirModalPrueba() {
      this.modalNuevoElemento = true;
    },
    abrirModalTrigger() {
      this.modalTrigger = true;
    },
    abrirModalAccionCanales() {
      this.modalAccionCanales = true;
    },
    abrirModalCondicion() {
      this.modalCondicion = true;
    },
    closeModal() {
      this.modalTrigger = false;
      this.modalAccionCanales = false;
      this.modalCondicion = false;
    },
    creacionPapers() {
      // Canvas where sape are dropped
      var graph = new joint.dia.Graph(),
        paper = new joint.dia.Paper({
          el: $("#paper"),
          model: graph,
          defaultLink: new joint.dia.Link({
            attrs: { ".marker-target": { d: "M 10 0 L 0 5 L 10 10 z" } },
          }),
          validateConnection: function (
            cellViewS,
            magnetS,
            cellViewT,
            magnetT
          ) {
            // Prevent linking from input ports.
            if (magnetS && magnetS.getAttribute("port-group") === "in")
              return false;
            // Prevent linking from output ports to input ports within one element.
            if (cellViewS === cellViewT) return false;
            // Prevent linking to input ports.
            return magnetT && magnetT.getAttribute("port-group") === "in";
          },
          // Enable marking available cells & magnets
          markAvailable: true,
          // Enable link snapping within 75px lookup radius
          snapLinks: { radius: 75 },
        });

      var stencilGraph = new joint.dia.Graph(),
        stencilPaper = new joint.dia.Paper({
          el: $("#stencil"),
          width: 300,
          model: stencilGraph,
          interactive: false,
        });

      var portsIn = {
        position: {
          name: "left",
        },
        attrs: {
          portBody: {
            magnet: "passive",
            r: 10,
            fill: "#0033a0",
            stroke: "#ffffff",
          },
        },
        label: {
          position: {
            name: "left",
            args: { y: 0 },
          },
          markup: [
            {
              tagName: "text",
              selector: "label",
              className: "label-text",
            },
          ],
        },
        markup: [
          {
            tagName: "circle",
            selector: "portBody",
          },
        ],
      };

      var portsOut = {
        position: {
          name: "right",
        },
        attrs: {
          portBody: {
            magnet: true,
            r: 10,
            fill: "#0033a0",
            stroke: "#ffffff",
          },
        },
        label: {
          position: {
            name: "right",
            args: { y: 0 },
          },
          markup: [
            {
              tagName: "text",
              selector: "label",
              className: "label-text",
            },
          ],
        },
        markup: [
          {
            tagName: "circle",
            selector: "portBody",
          },
        ],
      };

      var CustomElement = joint.dia.Element.define(
        "examples.CustomElement",
        {
          attrs: {
            body: {
              width: "calc(w)",
              height: "calc(h)",
              strokeWidth: 2,
              stroke: "black",
              fill: "white",
            },
            label: {
              textVerticalAnchor: "middle",
              textAnchor: "middle",
              x: "calc(0.5*w)",
              y: "calc(0.5*h)",
              fontSize: 14,
              fill: "black",
            },
            button: {
              cursor: "pointer",
              ref: "buttonLabel",
              width: "calc(1.5*w)",
              height: "calc(1.5*h)",
              x: "calc(x-calc(0.25*w))",
              y: "calc(y-calc(0.25*h))",
            },
            buttonLabel: {
              pointerEvents: "none",
              x: "calc(w)",
              y: 0,
              textAnchor: "middle",
              textVerticalAnchor: "middle",
            },
          },
        },
        {
          markup: [
            {
              tagName: "rect",
              selector: "body",
            },
            {
              tagName: "text",
              selector: "label",
            },
            {
              tagName: "text",
              selector: "labelname",
            },
            {
              tagName: "rect",
              selector: "button",
            },
            {
              tagName: "text",
              selector: "buttonLabel",
            },
          ],
        }
      );

      var element = new CustomElement({
        attrs: {
          label: {
            pointerEvents: "none",
            visibility: "visible",
            text: "Element",
            class: "element-label",
            textAnchor: "left",
            fill: "#000",
            x: "calc(x)",
            y: "calc(y-10)",
            fontWeight: "bold",
            fontVariant: "small-caps",
          },
          labelname: {
            pointerEvents: "none",
            visibility: "visible",
            textAnchor: "middle",
            text: "Elegir tipo...",
            textWrap: {
              width: -10, // reference width minus 10
              height: "50%", // half of the reference height
              ellipsis: true, // could also be a custom string, e.g. '...!?'
            },
            fill: "#0033a0",
            fontSize: 13,
            x: "calc(x+calc(0.5*w))",
            y: "calc(y+calc(0.575*h))",
          },
          body: {
            cursor: "default",
            visibility: "visible",
            stroke: "#0033a0",
            strokeWidth: 1,
            rx: 5,
            ry: 5,
          },
          button: {
            event: "element:button:pointerdown",
            fill: {
              type: "linearGradient",
              stops: [
                { offset: "0%", color: "#0033a0" },
                { offset: "50%", color: "#002169" },
              ],
            },
            rx: 10,
            ry: 10,
          },
          buttonLabel: {
            text: "Modificar", // fullwidth underscore
            fill: "#fff",
            fontSize: 10,
          },
          root: {
            // Don't allow the root of the element to be target of a connection
            magnet: false,
          },
        },
        ports: {
          groups: {
            in: portsIn,
            out: portsOut,
          },
        },
      });
      element.position(100, 50);
      element.resize(100, 75);

      element.addPorts([
        {
          group: "in",
          attrs: {
            label: {
              text: "entrada",
              fontSize: 12,
            },
          },
        },
        {
          group: "in",
          attrs: {
            label: {
              text: "entrada 2",
              fontSize: 12,
            },
          },
        },
        {
          group: "out",
          attrs: {
            label: {
              text: "salida",
              fontSize: 12,
            },
          },
        },
      ]);

      var trigger = element.clone();

      trigger.attr("label/text", "Trigger");
      trigger.attr("labelname/fill", "#FFFFFF");
      trigger.attr("body/fill", "#0033a0");
      trigger.attr("body/stroke", "#0033a0");
      trigger.attr("button/stroke", "#FFFFFF");
      trigger.attr("button/event", "trigger:button:pointerdown");

      trigger.position(100, 160);
      trigger.resize(120, 75);

      trigger.attr("markup/tagName", "trigger");

      trigger.removePorts();

      trigger.addPorts([
        {
          group: "in",
          attrs: {
            label: {
              text: "entrada",
              fontSize: 12,
            },
            portBody: {
              fill: "#ffffff",
              stroke: "#0033a0",
            },
          },
        },
        {
          group: "out",
          attrs: {
            label: {
              text: "salida",
              fontSize: 12,
            },
            portBody: {
              fill: "#ffffff",
              stroke: "#0033a0",
            },
          },
        },
      ]);

      var accion = element.clone();

      accion.attr("label/text", "Acción");
      accion.attr("labelname/fill", "#FFFFFF");
      accion.attr("body/fill", "#f5365c");
      accion.attr("body/stroke", "#f5365c");
      accion.attr("button/stroke", "#FFFFFF");
      accion.attr("button/event", "accion:button:pointerdown");

      accion.attr("markup/tagName", "accion");

      accion.position(100, 270);
      accion.resize(120, 75);

      accion.removePorts();

      accion.addPorts([
        {
          group: "in",
          attrs: {
            label: {
              text: "entrada",
              fontSize: 12,
            },
            portBody: {
              fill: "#ffffff",
              stroke: "#f5365c",
            },
          },
        },
        {
          group: "out",
          attrs: {
            label: {
              text: "salida",
              fontSize: 12,
            },
            portBody: {
              fill: "#ffffff",
              stroke: "#f5365c",
            },
          },
        },
      ]);

      var condicion = element.clone();

      condicion.attr("label/text", "Condición");
      condicion.attr("labelname/fill", "#FFFFFF");
      condicion.attr("body/fill", "#f90");
      condicion.attr("body/stroke", "#f90");
      condicion.attr("button/stroke", "#FFFFFF");
      condicion.attr("button/event", "condicion:button:pointerdown");

      condicion.attr("markup/tagName", "condicion");

      condicion.position(100, 380);
      condicion.resize(120, 75);

      condicion.removePorts();

      condicion.addPorts([
        {
          group: "in",
          attrs: {
            label: {
              text: "entrada",
              fontSize: 12,
            },
            portBody: {
              fill: "#ffffff",
              stroke: "#f90",
            },
          },
        },
        {
          group: "out",
          attrs: {
            label: {
              text: "salida",
              fontSize: 12,
            },
            portBody: {
              fill: "#ffffff",
              stroke: "#f90",
            },
          },
        },
      ]);

      stencilGraph.addCells([element, trigger, accion, condicion]);

      var rect = new joint.shapes.standard.Rectangle();
      rect.position(25, 25);
      rect.resize(100, 40);
      rect.attr({
        label: {
          text: "Guardar",
          fill: "#FFF",
        },
        body: {
          width: "calc(w)",
          height: "calc(h)",
          fill: {
            type: "linearGradient",
            stops: [
              { offset: "0%", color: "#0033a0" },
              { offset: "50%", color: "#002169" },
            ],
          },
          strokeWidth: "0",
          stroke: "#0033a0",
          rx: 8,
          ry: 8,
        },
      });
      rect.addTo(graph);

      //graph.findViewByModel(rect).options.interactive = false;

      stencilPaper.on("cell:pointerdown", function (cellView, e, x, y) {
        $("body").append(
          '<div id="flyPaper" style="position:fixed;z-index:100;opacity:.5;pointer-event:none;"></div>'
        );
        var flyGraph = new joint.dia.Graph(),
          flyShape = cellView.model.clone(),
          pos = cellView.model.position(),
          offset = {
            x: x - pos.x,
            y: y - pos.y,
          };

        flyShape.position(0, 0);
        flyGraph.addCell(flyShape);
        $("#flyPaper").offset({
          left: e.pageX - offset.x,
          top: e.pageY - offset.y,
        });
        $("body").on("mousemove.fly", function (e) {
          $("#flyPaper").offset({
            left: e.pageX - offset.x,
            top: e.pageY - offset.y,
          });
        });
        $("body").on("mouseup.fly", function (e) {
          var x = e.pageX,
            y = e.pageY,
            target = paper.$el.offset();

          // Dropped over paper ?
          if (
            x > target.left &&
            x < target.left + paper.$el.width() &&
            y > target.top &&
            y < target.top + paper.$el.height()
          ) {
            var s = flyShape.clone();
            s.position(x - target.left - offset.x, y - target.top - offset.y);
            graph.addCell(s);
          }
          $("body").off("mousemove.fly").off("mouseup.fly");
          flyShape.remove();
          $("#flyPaper").remove();
        });
      });

      paper.on("element:mouseenter", function (elementView) {
        var boundaryTool = new joint.elementTools.Boundary();
        var removeButton = new joint.elementTools.Remove();

        var toolsView = new joint.dia.ToolsView({
          tools: [boundaryTool, removeButton],
        });

        elementView.addTools(toolsView);
        elementView.showTools();
      });

      paper.on("cell:pointerclick", function (cellView, evt) {
        evt.stopPropagation();
        console.log(cellView.model.prop());

        if (cellView.model.get("type") === "standard.Rectangle") {
          console.log("clickyyy");
          // Esto se debe guardar en la base de datos... cómo? No sé.
          console.log(graph.toJSON());
        }
      });

      paper.on("link:connect", (linkView) => {
        const link = linkView.model;
        const source = link.source();
        const target = link.target();
        console.log(
          "paper<link:connect>",
          `
            ${link.id} now goes from
            ${source.port}
            of
            ${source.id}
            to port
            ${target.port}
            of
            ${target.id}.
          `
        );
      });

      var self = this;

      paper.on("trigger:button:pointerdown", function (elementView) {
        self.abrirModalTrigger();
        localStorage.setItem("id-elemento", elementView.model.id);
        var elementoApp = graph.toJSON(elementView.model);
        localStorage.setItem("elemento", JSON.stringify(elementoApp));

        self.traerTrigger();
      });

      paper.on("accion:button:pointerdown", function (elementView) {
        self.abrirModalAccionCanales();
        localStorage.setItem("id-elemento", elementView.model.id);
        var elementoApp = graph.toJSON(elementView.model);
        localStorage.setItem("elemento", JSON.stringify(elementoApp));

        self.traerAcciones();
        self.traerCanales();
      });

      paper.on("condicion:button:pointerdown", function (elementView) {
        self.abrirModalCondicion();
        localStorage.setItem("id-elemento", elementView.model.id);
        var elementoApp = graph.toJSON(elementView.model);
        localStorage.setItem("elemento", JSON.stringify(elementoApp));

        self.traerCondiciones();
      });

      $("#cambiarTrigger").click(function () {
        var tipoTrigger = $("#tipo-trigger").find(":selected").val();
        var idElemento = localStorage.getItem("id-elemento");
        var obtenerElemento = JSON.parse(localStorage.getItem("elemento"));

        obtenerElemento.cells.forEach((cell) => {
          if (cell.id === idElemento) {
            cell.attrs.labelname.text = tipoTrigger;
          }
          localStorage.setItem("elemento", JSON.stringify(obtenerElemento));

          graph.fromJSON(obtenerElemento);
          console.log(graph.toJSON());
        });

        self.modalTrigger = false;
      });
      $("#cambiarAcciones").click(function () {
        var tipoAccion = $("#tipo-accion").find(":selected").val();
        var tipoCanal = $("#tipo-canal").find(":selected").val();
        var idElemento = localStorage.getItem("id-elemento");
        var obtenerElemento = JSON.parse(localStorage.getItem("elemento"));

        obtenerElemento.cells.forEach((cell) => {
          if (cell.id === idElemento) {
            cell.attrs.labelname.text = tipoAccion + " + " + tipoCanal;
          }
          localStorage.setItem("elemento", JSON.stringify(obtenerElemento));

          graph.fromJSON(obtenerElemento);
          console.log(graph.toJSON());
        });

        self.modalAccionCanales = false;
      });
      $("#cambiarCondicion").click(function () {
        var tipoCondicion = $("#tipo-condicion").find(":selected").val();
        var idElemento = localStorage.getItem("id-elemento");
        var obtenerElemento = JSON.parse(localStorage.getItem("elemento"));

        obtenerElemento.cells.forEach((cell) => {
          if (cell.id === idElemento) {
            cell.attrs.labelname.text = tipoCondicion;
          }
          localStorage.setItem("elemento", JSON.stringify(obtenerElemento));

          graph.fromJSON(obtenerElemento);
          console.log(graph.toJSON());
        });

        self.modalCondicion = false;
      });
    },
    traerTrigger() {
      const axios = require("axios");

      var json_login = {
        user: getUser().email,
        password: getUser().password,
      };

      var token = CryptoJS.AES.encrypt(
        JSON.stringify(json_login),
        "vFeLfR2MnXgXQStZWglFO6AffhROonTq"
      ).toString();

      let data = {
        token: token,
      };

      let config = {
        method: "post",
        url: Config.url_service + Config.triggerTipo,
        data: data,
      };

      axios
        .request(config)
        .then((response) => {
          var trigger = [];
          response.data.data.forEach((res) => {
            trigger[res.triggerTypeId] = res.triggerTypeName;
          });

          var triggers = document.getElementById("tipo-trigger");
          new Choices(triggers, {
            searchEnabled: false,
            allowHTML: true,
            itemSelectText: "Seleccionar",
          }).setValue(trigger);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    traerCondiciones() {
      const axios = require("axios");

      var json_login = {
        user: getUser().email,
        password: getUser().password,
      };

      var token = CryptoJS.AES.encrypt(
        JSON.stringify(json_login),
        "vFeLfR2MnXgXQStZWglFO6AffhROonTq"
      ).toString();

      let data = {
        token: token,
      };

      let config = {
        method: "post",
        url: Config.url_service + Config.obtenerCondicion,
        data: data,
      };

      axios
        .request(config)
        .then((response) => {
          var condition = [];
          response.data.data.forEach((res) => {
            condition[res.conditionId] = res.conditionName;
          });

          var conditions = document.getElementById("tipo-condicion");
          new Choices(conditions, {
            searchEnabled: false,
            allowHTML: true,
            itemSelectText: "Seleccionar",
          }).setValue(condition);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    traerAcciones() {
      const axios = require("axios");

      var json_login = {
        user: getUser().email,
        password: getUser().password,
      };

      var token = CryptoJS.AES.encrypt(
        JSON.stringify(json_login),
        "vFeLfR2MnXgXQStZWglFO6AffhROonTq"
      ).toString();

      let data = {
        token: token,
      };

      let config = {
        method: "post",
        url: Config.url_service + Config.accionDisponible,
        data: data,
      };

      axios
        .request(config)
        .then((response) => {
          var action = [];
          response.data.data.forEach((res) => {
            action[res.actionId] = res.actionName;
          });

          var actions = document.getElementById("tipo-accion");
          new Choices(actions, {
            searchEnabled: false,
            allowHTML: true,
            itemSelectText: "Seleccionar",
          }).setValue(action);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    traerCanales() {
      const axios = require("axios");

      var json_login = {
        user: getUser().email,
        password: getUser().password,
      };

      var token = CryptoJS.AES.encrypt(
        JSON.stringify(json_login),
        "vFeLfR2MnXgXQStZWglFO6AffhROonTq"
      ).toString();

      let data = {
        token: token,
      };

      let config = {
        url: Config.url_service + Config.listaCanales,
        data: data,
      };

      axios
        .request(config)
        .then((response) => {
          var canal = [];
          response.data.data.forEach((res) => {
            canal[res.channelId] = res.channelName;
          });

          var canales = document.getElementById("tipo-canal");
          new Choices(canales, {
            searchEnabled: false,
            allowHTML: true,
            itemSelectText: "Seleccionar",
          }).setValue(canal);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    this.creacionPapers();
  },
};
</script>

<style scoped>
/* port styling */
.available-magnet {
  fill: #5da271;
}

/* element styling */
.available-cell rect {
  stroke-dasharray: 5, 2;
}

.div_all {
  display: flex;
}

#paper {
  background: #fffacd;
}

#stencil {
  background: #b0e0e6;
}

.div_left {
  float: left;
}

.div_right {
  float: right;
  margin-left: 10px;
}
</style>
