import axios from 'axios';
import Config from "@/config/servicios/campana/config";
export const custom_field = (body) => {
    return axios.post(Config.url_service+Config.custom_field,body,{
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        }});
};
export const operator_group = (body) => {
    return axios.post(Config.url_service+Config.operator_group,body,{
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        }});
};
export const custom_field_add = (body) => {
    return axios.post(Config.url_service+Config.custom_field_add,body,{
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        }});
};
export const custom_field_edit = (body) => {
    return axios.post(Config.url_service+Config.custom_field_edit,body,{
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        }});
};
export const available_countrys = (body) => {
    return axios.post(Config.url_service + Config.available_countrys,body,{
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        }});
};