<template>
  <div
      class="card multisteps-form__panel"
      data-animation="FadeIn"
  >
    <div class="card-header border-1">
      <h5 class="font-weight-bolder mb-0">Crear plantilla</h5>
    </div>
    <div class="card-body multisteps-form__content">
      <div class="row">
        <div class="col-12 col-sm-6">
          <label>Cabecera</label>
          <select
              id="selectedHeaderWhatsapp"
              v-model="selectedHeader"
              class="multisteps-form__select form-control form-select"
              name="opciones-Header"
              @change="updateVariable"
          >
            <option value="Ninguna">Ninguna</option>
            <option value="Text">Texto</option>
            <option value="Media">Medios de Comunicación</option>
          </select>
          <br>
          <div class="row">
            <div v-if="selectedHeader === 'Text'" class="col-12 col-sm-6">
              <label>Texto de Cabecera
                <span v-if="contHeader === 0"
                      type="button"
                      class="badge badge-celcom-orange"
                      title="Haz clic para agregar una variable"
                      @click="addVariable">
                      Agregar nuevo <i class="fas fa-plus fa-xl"></i>
                </span>
              </label>
              <argon-input
                  id="textoCabeceraWhatsapp"
                  v-model="textoCabecera"
                  class="multisteps-form__input"
                  type="text"
                  placeholder="Nombre de variable"
              />
            </div>
            <div v-if="selectedHeader === 'Text' && contHeader===1" class="col-12 col-sm-6">
              <label>Ejemplo de variable</label>
              <argon-input
                  id="textoCabecera"
                  v-model="textoMuestra"
                  class="multisteps-form__input"
                  type="text"
                  placeholder="Texto de variable"
              />
            </div>
          </div>
            <div v-if="selectedHeader === 'Media'" class="row">
                <div v-for="(boton, index) in medioBotones" :key="index" class="col-12 col-md-3 action-buttons" :title="boton.title">
                  <div :class="{ 'selected': boton.value === selectedRadio, 'text-center': true }">
                    <img :src="boton.img" alt="Vista previa de botones" @click="selectRadio(index)">
                    <input
                        :id="'radioBoton' + index"
                        v-model="selectedRadio"
                        :value="boton.value"
                        :name="'grupoRadio'"
                        type="radio"
                    />
                  </div>
                </div>
            </div>
          <div v-if="selectedRadio && selectedHeader === 'Media'">
            <br>
            <input v-if="selectedRadio !== 'LOCATION'" ref="fileInput" type="file" @change="previewFile">
            <div>
              <br>
              <img v-if="media.previewImage" :src="media.previewImage" class="w-100 alto-media" alt="Preview Image">
              <video v-if="media.previewVideo" class="w-100 alto-media" controls>
                <source :src="media.previewVideo" type="video/mp4">
                Your browser does not support the video tag.
              </video>
              <div v-if="media.previewPdf" class="pdf-container">
                <iframe :src="media.previewPdf" frameborder="0" width="100%" height="100px"></iframe>
              </div>
            </div>
            <div v-if="selectedRadio !== 'LOCATION'" class="formato-text">
              <strong>Nota:</strong> A continuación se muestran los formatos de archivo y sus respectivos tamaños que son aceptados para la carga.
              <ol>
                <li>Imágenes: JPEG y PNG (Tamaño máximo 2 MB)</li>
                <li>Videos: MP4 (Tamaño máximo 20 MB)</li>
                <li>Documentos: (Tamaño máximo 2 MB)</li>
              </ol>
            </div>
          </div>
          <div v-if="selectedRadio === 'LOCATION'">
            <label>Datos de Localizacion</label>
            <div class="row">
              <div class="col-12 col-sm-12">
               <label>Nombre</label>
                <argon-input
                    id="textoNameLocation"
                    v-model="dataLocation.name"
                    class="multisteps-form__input"
                    type="text"
                    placeholder="Nombre del propietario"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-sm-12">
                <label>Direccion</label>
                <argon-input
                    id="textoNameAddress"
                    v-model="dataLocation.address"
                    class="multisteps-form__input"
                    type="text"
                    placeholder="Calles, número, comuna, ciudad, país"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-sm-6">
                <label>Longitud</label>
                <argon-input
                    id="textoNameLongitude"
                    v-model="dataLocation.longitude"
                    class="multisteps-form__input"
                    type="text"
                    placeholder="-33.45842548323886"
                />
              </div>
              <div class="col-12 col-sm-6">
                <label>Latitud</label>
                <argon-input
                    id="textoNameLatitude"
                    v-model="dataLocation.latitude"
                    class="multisteps-form__input"
                    type="text"
                    placeholder="-70.74215035957431"
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-sm-12">
              <label>Cuerpo
                <span type="button"
                      class="badge badge-celcom-orange"
                      title="Haz clic para agregar una variable"
                      @click="addVariableBody">
                      Agregar nuevo <i class="fas fa-plus fa-xl"></i>
                </span>
              </label>
              <textarea
                  id="messageBodyWhatsapp"
                  ref="messageBody"
                  v-model="messageBody"
                  class="multisteps-form__select form-control"
                  rows="3"
                  placeholder="Cuerpo del mensaje"
              />
              <div class="text-end">
                <Emoji @emoji-selected="handleEmojiSelectedBody"/>
              </div>
            </div>
            <div v-if="contBody>=1" class="col-12 col-sm-12">
              <br>
              <label>Definir la muestra y el valor para la variable</label>
              <div v-for="(variable, index) in variablesMuestras" :key="index">
                <div class="row">
                  <div class="col-12 col-sm-6">
                      <argon-input
                          :id="'variable_' + index"
                          v-model="variable.muestra"
                          class="multisteps-form__input"
                          type="text"
                          :placeholder="'Muestra ' + (index + 1)"
                      />
                  </div>
                  <div class="col-12 col-sm-6 mb-3 mb-md-0">
                    <select
                        :id="'selected1_' + index"
                        v-model="variable.valor"
                        :class="['multisteps-form__select', 'form-control', 'form-select']"
                    >
                      <option selected="selected" disabled value="">Valor variable</option>
                      <option v-for="(tag, tagIndex) in $store.state.responseCustomer"
                              :key="tagIndex"
                              :value="tag.name"
                      >{{ tag.name }}</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-sm-6">
              <label>Pie de página
                <span
                      class="ms-auto mb-0 js-btn-next"
                      title="Añade un pie de página de 60 caracteres a tu mensaje. No se admiten variables en el pie de página."></span>
              </label>
              <argon-input
                  id="textoCabecera"
                  v-model="messageFooter"
                  class="multisteps-form__input"
                  type="text"
                  placeholder="Texto a pie de página"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-sm-8">
              <label>Añadir Botón</label>
              <select
                  id="selectedBotones"
                  v-model="selectedBotones"
                  class="multisteps-form__select form-control form-select"
                  name="opciones-Botones"
                  @change="updateBotones"
              >
                <option selected="selected" disabled value="">Botones</option>
                <option value="RR">Respuesta Rápida</option>
                <option value="URL">URL</option>
                <option value="NT">Número de Teléfono</option>
                <option value="CC">CC</option>
              </select>
            </div>
          </div>
          <div class="row">
            <div v-if="$store.state.botones[0]">
              <label v-if="$store.state.labelRR > 0">Respuesta rápida</label>
              <div v-for="(boton, index) in $store.state.botones" :key="index">
                <div>
                  <BotonRR
                      v-if="boton.tipo === 'QUICK_REPLY'"
                      :boton="boton"
                      @actualizar-texto="actualizarTextoBotonEnStore"
                      @eliminar-boton="eliminarBotonEnStore"/>
                </div>
              </div>
              <label  v-if="$store.state.labelURL > 0">Haz click para iniciar</label>
              <div v-for="(boton, index) in $store.state.botones" :key="index">
                <BotonURL
                    v-if="boton.tipo === 'URL'"
                    :boton="boton"
                    @actualizar-texto="actualizarTextoBotonEnStore"
                    @eliminar-boton="eliminarBotonEnStore"/>
                <BotonNT
                    v-if="boton.tipo === 'PHONE_NUMBER'"
                    :boton="boton"
                    @actualizar-texto="actualizarTextoBotonEnStore"
                    @eliminar-boton="eliminarBotonEnStore"/>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-6 d-flex mt-2">
          <WhatsappMessagePreview
              :header="textoCabecera.replace('{{1}}', textoMuestra)"
              :media="media ? media : ''"
              :body="messageBodyWithVariables"
              :footer="messageFooter"
              :botones="$store.state.botones"
          />
        </div>
      </div>

      <!-- Componente de WhatsApp siempre al lado derecho -->
    </div>
    <div class="card-footer border-1 d-flex mt-4">
      <argon-button
          type="button"
          color="light"
          variant="gradient"
          class="js-btn-prev"
          @click="$parent.prevStep"
      ><i class="fas fa-chevron-left me-2"></i> Anterior</argon-button>
      <argon-button
          type="button"
          color="celcom"
          variant="gradient"
          class="text-white ms-auto mb-0 js-btn-next"
          @click="addPlantilla"
      >Siguiente <i class="fas fa-chevron-right ms-2"></i></argon-button>
    </div>
  </div>
</template>

<script>
import ArgonInput from "@/components/ArgonInput.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import WhatsappMessagePreview from "@/views/envios/whatsapp/Add/components/VistaPrevia.vue"
import BotonRR from "@/views/envios/whatsapp/Add/components/BotonRR.vue"
import BotonURL from "@/views/envios/whatsapp/Add/components/BotonURL.vue"
import BotonNT from "@/views/envios/whatsapp/Add/components/BotonNT.vue"
import Emoji from "@/views/envios/whatsapp/Add/components/Emoji.vue";
import Swal from "sweetalert2";
export default {
  name: "Whatsapp2",
  components: {
    ArgonInput,
    ArgonButton,
    WhatsappMessagePreview,
    BotonRR,
    BotonURL,
    BotonNT,
    Emoji
  },
  data() {
    return {
      selectedCab: '',
      selectedHeader: 'Ninguna',
      textoMuestra: '',
      senderHeader: '',
      textoCabecera: '',
      contHeader: 0,

      messageBody: '',
      contBody: 0,
      variablesMuestras:[],
      messageFooter: '',
      selectedBotones: '',
      tiposBotones: [
        { value: "RR", img: "https://gs-upload.gupshup.io/whatsapp/reply.svg", tipo: "QUICK_REPLY" },
        { value: "URL", img: "https://gs-upload.gupshup.io/whatsapp/preview_call2.webp", tipo: "URL" },
        { value: "NT", img: "https://gs-upload.gupshup.io/whatsapp/preview_call1.webp", tipo: "PHONE_NUMBER" },
        { value: "CC", img: "https://gs-upload.gupshup.io/whatsapp/copy_code.svg", tipo: "QUICK_REPLY" },
      ],
      medioBotones: [
        { value: "IMG", img: "https://gs-upload.gupshup.io/whatsapp/media_image.svg", tipo: "MEDIA_IMG" },
        { value: "VIDEO", img: "https://gs-upload.gupshup.io/whatsapp/media_video.svg", tipo: "MEDIA_VIDEO" },
        { value: "DOCUMENT", img: "https://gs-upload.gupshup.io/whatsapp/media_document.svg", tipo: "DOCUMENT" },
        { value: "LOCATION", img: "https://gs-upload.gupshup.io/whatsapp/media_location.svg", tipo: "LOCATION" },
      ],
      selectedRadio: '',
      media: {
        default: null,
        previewImage: null,
        previewVideo: null,
        previewPdf: null,
      },
      dataLocation: {
        name: null,
        address: null,
        longitude: null,
        latitude: null,
      },
      //botones: [],
      idBotones: 1,
      fileData: "",
    };
  },
  computed: {
    messageBodyWithVariables() {
      // Itera sobre variablesMuestras y reemplaza las instancias en messageBody
      let messageBodyWithVariables = this.messageBody;
      this.variablesMuestras.forEach((variable) => {
        messageBodyWithVariables = messageBodyWithVariables.replace(variable.id, variable.muestra);
      });
      return messageBodyWithVariables;
    },
  },
  watch: {
    textoCabecera(newValue) {
      if(!this.textoCabecera.includes("{{1}}")){
        this.contHeader = 0;
        this.textoMuestra = '';
      }else if(this.textoCabecera.includes("{{1}}")){
        this.contHeader = 1;
      }
      this.senderHeader = newValue;
    },
    messageBody(newValue) {
      this.variablesMuestras.forEach((variable) => {
       if (!newValue.includes(variable.id)){
          this.eliminarVariable(variable.id);
       }
      });
    },
  },
  mounted() {
    this.$store.getters.arrayCabeceras();
  },
  methods: {
    handleEmojiSelectedBody(emoji) {
      this.messageBody = this.messageBody + emoji;
    },
    previewFile() {
      this.fileData = this.$refs.fileInput.files[0]; // Obtenemos el archivo

      if (!this.fileData) return; // Verificamos si hay un archivo seleccionado

      // Validación de tipo de archivo y tamaño
      const validImageTypes = ['image/jpeg', 'image/png'];
      const validVideoType = 'video/mp4';
      const validDocumentType = 'application/pdf';

      const maxImageSize = 5 * 1024 * 1024; // 5 MB en bytes
      const maxVideoSize = 16 * 1024 * 1024; // 16 MB en bytes
      const maxDocumentSize = 5 * 1024 * 1024; // 5 MB en bytes

      const reader = new FileReader();

      reader.onload = () => {
        if (validImageTypes.includes(this.fileData.type)) {
          if (this.fileData.size > maxImageSize) {
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: `El tamaño de la imagen no puede ser mayor a 5 MB.`,
              showConfirmButton: false,
              timer: 4000, // Cerrar automáticamente el alert después de 4 segundos
            });
            this.$refs.fileInput.value = ''; // Limpiar el input
            this.media.previewImage = null; // Limpiar la vista previa
          } else {
            this.media.previewImage = reader.result;
            this.media.previewVideo = null; // Limpiar vista previa de video si existe
            this.media.previewPdf = null; // Limpiar vista previa de PDF si existe
          }
        } else if (this.fileData.type === validVideoType) {
          if (this.fileData.size > maxVideoSize) {
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: `El tamaño del video no puede ser mayor a 16 MB.`,
              showConfirmButton: false,
              timer: 4000, // Cerrar automáticamente el alert después de 4 segundos
            });
            this.$refs.fileInput.value = ''; // Limpiar el input
            this.media.previewVideo = null; // Limpiar la vista previa
          } else {
            this.media.previewVideo = reader.result;
            this.media.previewImage = null; // Limpiar vista previa de imagen si existe
            this.media.previewPdf = null; // Limpiar vista previa de PDF si existe
          }
        } else if (this.fileData.type === validDocumentType) {
          if (this.fileData.size > maxDocumentSize) {
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: `El tamaño del documento no puede ser mayor a 5 MB.`,
              showConfirmButton: false,
              timer: 4000, // Cerrar automáticamente el alert después de 4 segundos
            });
            this.$refs.fileInput.value = ''; // Limpiar el input
            this.media.previewPdf = null; // Limpiar la vista previa
          } else {
            this.media.previewPdf = URL.createObjectURL(this.fileData);
            this.media.previewImage = null; // Limpiar vista previa de imagen si existe
            this.media.previewVideo = null; // Limpiar vista previa de video si existe
          }
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: `Tipo de archivo no válido. Por favor, selecciona un archivo JPEG, PNG, MP4 o PDF.`,
            showConfirmButton: false,
            timer: 4000, // Cerrar automáticamente el alert después de 4 segundos
          });
          this.$refs.fileInput.value = ''; // Limpiar el input
          this.media.previewImage = null; // Limpiar vista previa
          this.media.previewVideo = null; // Limpiar vista previa
          this.media.previewPdf = null; // Limpiar vista previa
        }
      };

      // Iniciar la lectura del archivo
      reader.readAsDataURL(this.fileData);
    },
    selectRadio(index) {
      this.selectedRadio = this.medioBotones[index].value;
      this.media.default = this.medioBotones[index];
      this.media.previewVideo = null;
      this.media.previewImage = null;
      this.media.previewPdf = null;
      this.dataLocation.name = null;
      this.dataLocation.address = null;
      this.dataLocation.longitude = null;
      this.dataLocation.latitude = null;
    },
    typeOfMedia() {
      if (this.selectedHeader === 'Text') {
        return 'TEXT';
      } else if (this.selectedHeader === 'Media') {
        if (this.selectedRadio === 'IMG') {
          return 'IMAGE';
        }else if (this.selectedRadio === 'VIDEO') {
          return 'VIDEO';
        } else if (this.selectedRadio === 'DOCUMENT') {
          return 'DOCUMENT';
        } else if (this.selectedRadio === 'LOCATION') {
          return 'LOCATION';
        }
      }else {
        return 'TEXT';
      }
    },
    addPlantilla(){
      let header = this.textoCabecera.replace('{{1}}', this.textoMuestra);
      this.$store.dispatch('tomarVariablesMuestras', this.variablesMuestras);
      this.$parent.addPlantillaWhatsapp(
          this.messageBodyWithVariables,
          header,
          this.messageFooter,
          this.typeOfMedia(),
          this.dataLocation.name ? JSON.stringify(this.dataLocation) : "",
          this.fileData,
      );
    },
    eliminarVariable(idToDelete) {
      const indexToDelete = this.variablesMuestras.findIndex(variable => variable.id === idToDelete);

      if (indexToDelete !== -1) {
        // Elimina la variable con el id especificado
        this.variablesMuestras.splice(indexToDelete, 1);

        // Ajusta los índices de las variables restantes
        this.variablesMuestras.forEach((variable, index) => {
          variable.id = `{{${index + 1}}}`;
        });

        // Reemplaza las instancias de las variables en el messageBody
        this.variablesMuestras.forEach((variable, index) => {
          if (this.messageBody.includes(`{{${index + 1}}}`)) {
            this.messageBody = this.messageBody.replace(`{{${index + 1}}}`, variable.id);
          }else if (this.messageBody.includes(`{{${index + 2}}}`)){
            this.messageBody = this.messageBody.replace(`{{${index + 2}}}`, variable.id);
          }
        });

        this.contBody = this.contBody - 1;
      }
    },
    addVariable() {
      if (this.contHeader === 0)
        this.textoCabecera = this.textoCabecera + ' {{1}} ';
      this.contHeader = this.contHeader + 1;
    },
    addVariableBody() {
      this.contBody = this.contBody + 1;

      // Obtén la posición actual del cursor en el messageBody
      const cursorPosition = this.$refs.messageBody.selectionStart;

      // Dividir el messageBody en dos partes: antes y después del cursor
      const beforeCursor = this.messageBody.substring(0, cursorPosition);
      const afterCursor = this.messageBody.substring(cursorPosition);

      // Agregar la nueva variable en el lugar del cursor
      this.variablesMuestras.push({
        id: `{{${this.contBody}}}`,
        muestra: '',
        valor: '',
      });

      // Actualizar el messageBody con la nueva variable en su posición
      this.messageBody = `${beforeCursor}{{${this.contBody}}}${afterCursor}`;
    },
    addEmoji(emoji) {
      // Obtén la posición actual del cursor en el messageBody
      const cursorPosition = this.$refs.messageBody.selectionStart;

      // Dividir el messageBody en dos partes: antes y después del cursor
      const beforeCursor = this.messageBody.substring(0, cursorPosition);
      const afterCursor = this.messageBody.substring(cursorPosition);

      // Actualizar el messageBody con la nueva variable en su posición
      this.messageBody = `${beforeCursor} ${emoji} ${afterCursor}`;
    },
    updateVariable() {
      this.textoCabecera = '';
      this.selectedRadio = '';
      this.media.default = null;
      this.media.previewImage = null;
      this.media.previewVideo = null;
      this.media.previewPdf = null;
    },
    actualizarTextoBotonEnStore({ id, nuevoTexto, nuevoValorNumerico }) {
      // Llamar a la acción para actualizar el texto en el store
      this.$store.dispatch('actualizarTextoBoton', { id, nuevoTexto, nuevoValorNumerico });
    },
    eliminarBotonEnStore({ id, tipo}) {
      console.log(id);
      // Llamar a la acción para eliminar el botón en el store
      if (this.$store.state.labelRR > 0 && tipo === 'RR'){
        this.$store.dispatch('cambiarLabelRR',( this.$store.state.labelRR - 1));
      }else if (this.$store.state.labelURL > 0 && (tipo === 'URL' || tipo === 'NT')){
        this.$store.dispatch('cambiarLabelURL',(this.$store.state.labelURL - 1));
      }
      this.$store.dispatch('eliminarBoton', { id });
    },
    updateBotones(){
      const nuevoBoton = {
        id: this.idBotones,
        img: this.tiposBotones.find(tipo => tipo.value === this.selectedBotones).img,
        title: "",
        texto: "",
        valorNumerico: "",
        tipo: this.tiposBotones.find(tipo => tipo.value === this.selectedBotones).tipo,
      };
      this.$store.dispatch('agregarNuevoBoton', nuevoBoton);
      if (this.selectedBotones === 'RR'){
        this.$store.dispatch('cambiarLabelRR', this.$store.state.labelRR + 1);
      }else if (this.selectedBotones === 'URL' || this.selectedBotones === 'NT'){
        this.$store.dispatch('cambiarLabelURL', this.$store.state.labelURL + 1);
      }
      this.idBotones = this.idBotones + 1;
      this.selectedBotones = '';
    },
  },
};
</script>
<style scoped>
.selected {
  border: 2px solid #83839a; /* Cambia el color y estilo del borde como desees */
  border-radius: 5px; /* Ajusta el redondeo del borde como desees */
}
.alto-media {
  max-height: 130px;
}
.formato-text {
  background-color: #fff6e6;
  padding: 20px;
  font-size: var(--gs-text-sm);
  font-weight: 400;
  color: var(--gs-black);
  font-family: sans-serif;
  margin: 0;
}
</style>
