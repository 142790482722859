<template>
  <div class="card-body">
    <!--form panels-->
    <div class="row pt-4">
      <div class="col-12 col-lg-10 m-auto">
        <div class="row d-flex align-items-center">
          <div
            v-for="canal in canales"
            :key="canal.id"
            class="col-md-4 col-12"
          >
            <card
              :canal-name="canal"
              :class="
                canalLock === canal.id ? 'seleccionado' : ''
              "
              @canal-selected="getCanal"
            />
          </div>
        </div>
        <div class="row justify-content-end">
          <div class="col-lg-4 col-md-6 pb-4 py-md-0">
            <card-pago v-if="productosFiltrados[0]">
              <h6 class="mb-2">Elige el tipo de mensaje</h6>
              <hr>
              <select
                  id="opcionesListCont"
                  v-model="selectedTipo"
                  class="multisteps-form__select form-control form-select"
                  :style="{ 'pointer-events': productosFiltrados[0] ? 'auto' : 'none' }"
                  name="opciones-ListCont"
              >
                <option value="" selected disabled>Tipo de mensajes</option>
                <option
                    v-for="(tipo, index) in productosFiltrados"
                    :key="index"
                    :value="tipo"
                >
                  {{ canalSelect === "sms" ? tipo.name : tipo.messageType }}
                </option>
              </select>
            </card-pago>
          </div>
          <div class="col-md">
            <card-pago v-if="selectedTipo">
              <h6 class="mb-2">Detalles</h6>
              <hr>
              <div class="row">
                <div class="col-12 col-sm-4">
                    <h6 class="mb-1 font-weight-bolder">
                      {{canalSelect === "sms" ? "Nombre" : "Canal"}}
                    </h6>
                    <label v-if="canalSelect !== 'sms'">{{selectedTipo.channel ? selectedTipo.channel : "Valor del Canal"}}</label>
                    <label v-else>{{selectedTipo.name ? selectedTipo.name : "Valor del Nombre"}}</label>
                </div>
                <div v-if="canalSelect !== 'sms'" class="col-12 col-sm-4">
                    <h6 class="mb-1 font-weight-bolder">
                      Tipo de Mesaje
                    </h6>
                    <label>{{selectedTipo.messageType ? selectedTipo.messageType : "Valor del Mensaje"}}</label>
                </div>
                <div v-if="canalSelect !== 'sms'" class="col-12 col-sm-4">
                  <h6 class="mb-1 font-weight-bolder">
                    País de destino
                  </h6>
                  <label>{{selectedTipo.countryId === 4 ? "Chile" : "Pais"}}</label>
                </div>
             </div>
              <div class="row">
                <div class="col-12 col-sm-4">
                  <h6 class="mb-1 font-weight-bolder">
                    Precio:
                  </h6>
                  <label>{{selectedTipo.price ? selectedTipo.price : "Valor del Precio"}}</label>
                </div>
                <div class="col-12 col-sm-4">
                  <h6 class="mb-1 font-weight-bolder">
                    Tipo de Mesaje
                  </h6>
                  <label>{{selectedTipo.acquisitionCost ? selectedTipo.acquisitionCost : "Valor del Costo de adquisición"}}</label>
                </div>
              </div>
              <hr>
              <div
                  class="row"
                  :style="{ 'pointer-events': (selectedTipo && editarBoton) ? 'auto' : 'none' }">
                <div class="col-12 col-sm-6">
                  <label>Precio:</label>
                  <argon-input
                      id="Price"
                      v-model="selectedTipo.price"
                      class="multisteps-form__input"
                      type="number"
                      placeholder="Precio"
                  />
                </div>
                <div class="col-12 col-sm-6">
                  <label>Costo de adquisición:</label>
                  <argon-input
                      id="acquisitionCost"
                      v-model="selectedTipo.acquisitionCost"
                      class="multisteps-form__input"
                      type="number"
                      placeholder="Costo de adquisición"
                  />
                </div>
              </div>
              <div class="border-1 d-flex">
                <argon-button
                    v-if="!editarBoton"
                    :disabled="!selectedTipo"
                    type="button"
                    color="celcom"
                    variant="gradient"
                    class="js-btn-prev text-white"
                    @click="modificarBoton"
                ><i class="fa fa-pencil me-2"></i> Modificar
                </argon-button>
                <argon-button
                    v-if="editarBoton"
                    type="button"
                    color="celcom"
                    variant="gradient"
                    class="text-white ms-auto mb-0 js-btn-next"
                    @click="canalSelect !== 'sms' ? updatePrice() : updatePriceSms()"
                >Editar <i class="fa fa-floppy-o"></i>
                </argon-button>
              </div>
            </card-pago>
          </div>
        </div>
      </div>
    </div>
    <Loader ref="loader" />
  </div>
</template>

<script>
import {
  encryptAES,
  horaAhora,
  getUser, getUserSub,
} from "@/config/servicios/campana/util";
import Card from "@/views/admin/precios/Cards/Card.vue";
import CardPago from "@/components/Cards/Card.vue";
import ArgonInput from "@/components/ArgonInput.vue";
import Loader from "@/components/Modal/Loader.vue";
import {
  sender_available_precios,
  sender_edit_precios,
  sender_available_precios_sms,
  sender_edit_precios_sms
} from "@/config/servicios/admin/precios/serviciosPrecios";
import ArgonButton from "@/components/ArgonButton.vue";
import Swal from "sweetalert2";
export default {
  name: "Precios",
  components: {
    ArgonButton,
    Loader,
    ArgonInput,
    Card,
    CardPago,
  },
  data() {
    return {
      canales: [
        {
          id: 1,
          name: "SMS",
          channel: "sms"
        },
        {
          id: 2,
          name: "Whatsapp",
          channel: "WSP",
        },
        {
          id: 3,
          name: "Email",
          channel: "EMAIL",
        },
      ],
      selectedTipo: "",
      tiposMensajes: [],
      productos: {},
      productos_sms: {},
      canalLock: 0,
      canalSelect: "",
      productosFiltrados: [],
      editarBoton: false,
    };
  },
  mounted() {
    this.obtenerPrecios();
    this.obtenerPreciosSms();
  },
  created() {},
  methods: {
    modificarBoton() {
      this.editarBoton = true;
    },
    getCanal(canalNameObj) {
      this.editarBoton = false;
      this.selectedTipo = "";
      const canalName = canalNameObj.canalName;
      this.canalLock = canalName.id;
      this.canalSelect = canalName.channel;
      if (this.canalSelect === "sms") {
        this.productosFiltrados = this.productos_sms;
      } else {
        this.productosFiltrados = this.productos.filter(producto => producto.channel === this.canalSelect);
      }
    },
    async obtenerPrecios(){
      try {
        let user = (getUserSub()!=null) ? getUserSub() :getUser();

        let js = {
          time_expired: horaAhora(),
          "admin":encodeURIComponent(JSON.stringify(user.customer)),
        };

        var body = new URLSearchParams();
        body.append("TOKEN", encryptAES(js));


        const response = await sender_available_precios(body);
        // Almacena la respuesta en la variable de datos
        if (response.data.response === "200") {

          this.productos = response.data.data;
        } else {
          console.log("No se encontró lista");

        }
      } catch (error) {
        console.error("Error al obtener datos", error);
      }
    },
    async obtenerPreciosSms(){
      try {
        let user = (getUserSub()!=null) ? getUserSub() :getUser();

        let js = {
          time_expired: horaAhora(),
          "admin":encodeURIComponent(JSON.stringify(user.customer)),
        };

        var body = new URLSearchParams();
        body.append("TOKEN", encryptAES(js));


        const response = await sender_available_precios_sms(body);
        // Almacena la respuesta en la variable de datos
        if (response.data.response === "200") {

          this.productos_sms = response.data.data;
        } else {
          console.log("No se encontró lista");

        }
      } catch (error) {
        console.error("Error al obtener datos", error);
      }
    },
    async updatePrice() {
      this.editarBoton = false;
      try {
        let user = (getUserSub()!=null) ? getUserSub() :getUser();

        let js = {
          time_expired: horaAhora(),
          "price": encodeURIComponent(JSON.stringify(this.selectedTipo)),
          "admin":encodeURIComponent(JSON.stringify(user.customer)),
        };

        var body = new URLSearchParams();
        body.append("TOKEN", encryptAES(js));


        const response = await sender_edit_precios(body);
        // Almacena la respuesta en la variable de datos
        if (response.data.response === "200") {
          this.productos = response.data.data;
          Swal.fire({
            title: "Datos Modificados Correctamente",
            icon: "success",
            buttonsStyling: false,
            customClass: {
              confirmButton: 'btn btn-celcom-blue',
            },
          });
        } else {
          console.log("Error al Modificar los datos");
          Swal.fire({
              title: "Error al Modificar los datos",
              icon: "error",
              buttonsStyling: false,
              customClass: {
                confirmButton: 'btn btn-celcom-orange',
              },
            });
        }
      } catch (error) {
        console.error("Error al Modificar los datos", error);
        Swal.fire({
              title: "Error al Modificar los datos",
              icon: "error",
              buttonsStyling: false,
              customClass: {
                confirmButton: 'btn btn-celcom-orange',
              },
            });
      }
    },
    async updatePriceSms() {
      this.editarBoton = false;
      try {
        let user = (getUserSub()!=null) ? getUserSub() :getUser();

        let js = {
          time_expired: horaAhora(),
          "price": encodeURIComponent(JSON.stringify(this.selectedTipo)),
          "admin":encodeURIComponent(JSON.stringify(user.customer)),
        };

        var body = new URLSearchParams();
        body.append("TOKEN", encryptAES(js));


        const response = await sender_edit_precios_sms(body);
        // Almacena la respuesta en la variable de datos
        if (response.data.response === "200") {
          this.productos = response.data.data;
          Swal.fire({
            title: "Datos Modificados Correctamente",
            icon: "success",
            buttonsStyling: false,
            customClass: {
              confirmButton: 'btn btn-celcom-blue',
            },
          });
        } else {
          console.log("Error al Modificar los datos");
          Swal.fire({
              title: "Error al Modificar los datos",
              icon: "error",
              buttonsStyling: false,
              customClass: {
                confirmButton: 'btn btn-celcom-orange',
              },
            });
        }
      } catch (error) {
        console.error("Error al Modificar los datos", error);
        Swal.fire({
              title: "Error al Modificar los datos",
              icon: "error",
              buttonsStyling: false,
              customClass: {
                confirmButton: 'btn btn-celcom-orange',
              },
            });
      }
    },
  },
};
</script>
<style scoped>
/* Agrega estilos según tus necesidades */
.seleccionado {
  box-shadow: 0px 0px 0px 5px #ff9900;
  border-radius: 16px;
}
.tooltip-container {
  position: relative;
  display: inline-block;
}

.tooltip {
  position: absolute;
  bottom: 100%; /* Coloca el tooltip arriba del contenido */
  left: 50%;
  transform: translateX(-50%);
  padding: 5px;
  background-color: #333;
  color: #fff;
  border-radius: 3px;
  font-size: 12px;
  opacity: 0;
  transition: opacity 0.3s ease;
  pointer-events: none; /* Evita que el tooltip sea clickeable */
}

.tooltip-container:hover .tooltip {
  opacity: 1;
}
.list-group-item {
  font-size: 0.875rem;
}
</style>
