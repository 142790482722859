// api.js
import axios from 'axios';
import Config from "@/config/servicios/campana/config";
export const obtenerLista = (body) => {
    return axios.post(Config.url_service + Config.draft_available,body,{
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        }});
};
export const editPlantilla = (body) => {
    return axios.post(Config.url_service + Config.draft_edit,body,{
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        }});
};
export const addPlantilla = (body) => {
    return axios.post(Config.url_service + Config.draft_add,body,{
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        }});
};
export const deletePlantilla = (body) => {
    return axios.post(Config.url_service + Config.draft_delete,body,{
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        }});
};
// Agrega más funciones según tus necesidades