<template>
  <div>
      <div class="row">
        <argon-input
            v-model="cabeceraLocal.cabecera"
            class="multisteps-form__input col-12 col-sm-5"
            type="text"
            :placeholder="'Cabecera '+ cabeceraLocal.id"
            @input="actualizarTexto"
        />
        <argon-input
            v-model="cabeceraLocal.valor"
            class="multisteps-form__input col-12 col-sm-5"
            type="text"
            placeholder="Valor"
            @input="actualizarTexto"
        />
        <span
            type="button"
            class="btn btn-danger col-12 col-sm-1"
            title="Haz click para eliminar el boton"
            @click="eliminarAddCabecera">X</span>
      </div>
  </div>
</template>

<script>
import ArgonInput from "@/components/ArgonInput.vue";

export default {
  components: {ArgonInput},
  props: {
    cabecera:{
      type: Object,
      required: true,
    },
  },
  emits: ['disminuirIdCab'],
  data() {
    return {
     // cabeceraLocal: { ...this.cabecera }, // Crear una copia local del botón
    };
  },
  computed: {
    // Crear una copia local del botón
    cabeceraLocal() {
      return { ...this.cabecera };
    },
  },
  methods: {
    actualizarTexto() {
      // Emitir un evento al componente padre (BotonRR) con el nuevo texto
      this.$store.dispatch('actualizarTextoAddCab', { id: this.cabeceraLocal.id, cabecera: this.cabeceraLocal.cabecera, valor: this.cabeceraLocal.valor });
    },
    eliminarAddCabecera() {
      // Emitir un evento al componente padre (BotonRR) con el id a eliminar
      this.$emit('disminuirIdCab');
      this.$store.dispatch('eliminarAddCab', { id: this.cabeceraLocal.id });
    },
  },
};
</script>

<style scoped>

</style>
