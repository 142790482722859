// api.js
import axios from 'axios';
import {decryptAES} from "@/config/servicios/campana/util";

const API_BASE_URL = 'https://automata2.celcom.cl/flex/api/';

const urlCustomerId = () => {
// Obtener el valor del local storage
    let encryptedToken = localStorage.getItem('user');
    const userDataString = decryptAES(encryptedToken);

// Convertir la cadena JSON a un objeto JavaScript
    const userData = JSON.parse(userDataString);

// Obtener el valor de customerId
    return userData.customer.customerId;
};
export const getTag = () => {
    const API_LIST_TAG = 'tag/customer/'+ urlCustomerId() + '/all';
    return axios.get(`${API_BASE_URL}`+ API_LIST_TAG);
};

export const addTag = (name) => {
    const API_ADD_TAG = 'tag/customer/'+ urlCustomerId() + '/create';
    return axios.post(`${API_BASE_URL}`+ API_ADD_TAG,data(name));
};
export const editTag = (id, name) => {
    const API_EDIT_TAG = 'tag/customer/'+ urlCustomerId() + '/' + id +'/edit';
    return axios.post(`${API_BASE_URL}`+ API_EDIT_TAG,data(name));
};
function data(name){
    return  {
        "name": name,
    };
};
// Agrega más funciones según tus necesidades