<template>
    <div class="container-fluid py-5">
      <div class="row">
        <div class="col-12">
          <div class="multisteps-form">
            <!--form panels-->
            <div class="row">
              <div class="col-12 col-lg-10 m-auto">
                <form class="multisteps-form__form">
                  <user-table :userData="response.data" />
                </form>
              </div>
            </div>
          </div>
        </div>
        <modal-delete :is-visible="modalVisible">
          <p><b>¿Estás seguro de que deseas eliminar?</b></p>
          <div class="mt-lg-4 d-flex justify-content-between">
            <button class="btn bg-gradient-danger mt-2 mb-0" @click="deleteCont">Eliminar</button>
            <button class="btn bg-gradient-dark mt-2 mb-0" @click="closeModal">Cancelar</button>
          </div>
        </modal-delete>
        <modal :is-visible="modalEditar">
          <p><b>Edite el Contacto</b></p>
          <div class="multisteps-form__content">
            <div class="row mt-3">
              <div class="col-12 col-sm-6">
                <label>Nombre</label>
                <argon-input
                    id="contactNameEdit"
                    v-model="contactNameEdit"
                    class="multisteps-form__input"
                    type="text"
                    placeholder="Nombre del contacto"
                />
              </div>
              <div class="col-12 col-sm-6">
                <label>Teléfono</label>
                <argon-input
                    id="msisdnEdit"
                    v-model= "msisdnEdit"
                    class="multisteps-form__input"
                    type="text"
                    placeholder="Creative Tim"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-sm-6">
                <label>Empresa</label>
                <argon-input
                    id="empresaEdit"
                    v-model="empresaEdit"
                    class="multisteps-form__input"
                    type="text"
                    placeholder="Creative Tim"
                />
              </div>
              <div class="col-12 col-sm-6 mt-3 mt-sm-0">
                <label>Dirección de Email</label>
                <argon-input
                    id="emailEdit"
                    v-model="emailEdit"
                    class="multisteps-form__input"
                    type="email"
                    placeholder="argon@dashboard.com"
                />
              </div>
            </div>
            <div class="col-sm-8">
              <div class="row">
                <div class="col-12 col-sm-6 mt-3 mt-sm-0">
                  <label class="form-label mt-2">Fecha de Nacimiento</label>
                  <argon-input
                      id="birthdateEdit"
                      type="date"
                      v-model="birthdate"
                      class="multisteps-form__input"
                      placeholder="Selecciona una fecha"
                  />
                </div>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-12 col-sm-6">
                <label>Seleccione Tags</label>
                <select
                    v-model="selectedTag"
                    id="opcionesTags"
                    class="multisteps-form__select form-control"
                    name="opciones-Tags"
                    @change="updateTags"
                >
                  <option selected="selected" disabled value="">...</option>
                  <option value="Perro">Perro</option>
                  <option value="Perico">Perico</option>
                </select>
              </div>
              <div class="col-md-6">
                <label class="form-label mt-2">Tags</label>
                <input
                    id="selTagsEdit"
                    class="form-control"
                    type="text"
                    v-model="tags"
                    placeholder="Enter something"
                />
              </div>
            </div>
            <div class="col-12 col-sm-6 mt-3 mt-sm-0">
              <label>Rut</label>
              <argon-input
                  id="contactRutEdit"
                  v-model="taxIdEdit"
                  class="multisteps-form__input"
                  type="text"
                  placeholder="11111111-1"
              />
            </div>
            <div class="button-row d-flex mt-2">
              <div class="modal-footer mt-lg-4 d-flex justify-content-between">
                <button class="btn bg-gradient-success mt-2 mb-0" @click="editContactos">Editar</button>
                <button class="btn bg-gradient-dark mt-2 mb-0" @click="closeModal">Cancelar</button>
              </div>
            </div>
          </div>
        </modal>
      </div>
    </div>
  </template>
  
  <script>
  import UserTable from "./table/UserTable.vue";
  import { listContServ } from "@/config/servicios/contactos/services.js";
  import { deleteContact} from "@/config/servicios/contactos/deleteContacto";
  import ModalDelete from "@/components/Modal/ModalDelete.vue";
  import Modal from "@/components/Modal/Modal.vue";
  import ArgonInput from "@/components/ArgonInput.vue";
  import Choices from "choices.js";
  import {editContact} from "@/config/servicios/contactos/editContacto.js";
  export default {
    name: "ListarContacto",
    components: {
      ArgonInput,
      UserTable,
      ModalDelete,
      Modal,
    },
    data() {
      return {
        selectedTag: "", // Nuevo dato para almacenar la opción seleccionada
        tags: "",       // Nuevo dato para almacenar los tags seleccionados
        activeClass: "js-active position-relative",
        activeStep: 0,
        formSteps: 3,
        modalVisible: false,
        modalEditar: false,
        idContacto: null,
        contactNameEdit: null,
        taxIdEdit: null,
        customerSegmentIdEdit: null,
        customerTagEdit: null,
        emailEdit: null,
        empresaEdit: null,
        msisdnEdit: null,
        birthDateEdit: null,
        genderEdit: null,
        countryEdit: null,
        sourceIdEdit: null,
        response: {}, // Aquí almacenarás la respuesta del servicio
      };
    },
    mounted() {
      if (document.getElementById("opcionesTags")) {
        var element = document.getElementById("opcionesTags");
        new Choices(element, {
          searchEnabled: false,
          allowHTML: true,
        });
      }
      this.listCont(); // Llama a la función al montar el componente
    },
    methods: {
      updateTags() {
        // Agregar el tag seleccionado al string de tags
        if (this.selectedTag && this.selectedTag !== "..." && !this.tags.includes(this.selectedTag)) {
          if (this.tags.length > 0) {
            this.tags += `, ${this.selectedTag}`;
          } else {
            this.tags = this.selectedTag;
          }
        }
      },
      async listCont() {
        try {
          const response = await listContServ();
          // Almacena la respuesta en la variable de datos
          this.response = response.data;
        } catch (error) {
          console.error("Error al obtener datos", error);
        }
      },
      openModal(id) {
        this.idContacto = id;
        this.modalVisible = true;
      },
      async editContactos() {
        try {
          const response = await editContact(this.idContacto, this.contactNameEdit, this.taxIdEdit, this.emailEdit, this.empresaEdit, this.msisdnEdit, this.tags, this.birthDateEdit);
          // Almacena la respuesta en la variable de datos
          console.log("Contacto Editado: " + response);
        } catch (error) {
          console.error("Error al obtener datos", error);
        } finally {
          this.modalEditar = false;
          await this.listCont();
        }
      },
      openModalEditar(contact) {
        const contactData = JSON.parse(JSON.stringify(contact));

        this.idContacto = contactData.contactId;
        this.contactNameEdit = contactData.contactName;
        this.taxIdEdit = contactData.taxId;
        this.customerSegmentIdEdit = contactData.customerSegmentId;
        this.tags = contactData.customerTag;
        this.emailEdit = contactData.email;
        this.empresaEdit = contactData.empresa;
        this.msisdnEdit = contactData.msisdn;
        this.birthDateEdit = contactData.birthDate;
        this.genderEdit = contactData.gender;
        this.countryEdit = contactData.country;
        this.sourceIdEdit = contactData.sourceId;

        this.modalEditar = true;
      },
      closeModal() {
        this.modalVisible = false;
        this.modalEditar = false;
      },
      async deleteCont() {
        if (this.idContacto) {
          try {
            await deleteContact(this.idContacto);
            // Almacena la respuesta en la variable de datos
            console.log("Contacto eliminado" );
          } catch (error) {
            console.error("Error al obtener datos", error);
          } finally {
            this.modalVisible = false;
            await this.listCont();
          }
        }
      },
      nextStep() {
        if (this.activeStep < this.formSteps) {
          this.activeStep += 1;
        } else {
          this.activeStep -= 1;
        }
      },
      prevStep() {
        if (this.activeStep > 0) {
          this.activeStep -= 1;
        }
      },
    },
  };
  </script>
<style scoped>
/* Agrega estilos según tus necesidades */
.modal {
  display: none;
}

.modal.is-active {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
}

.modal-close {
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
  background: none;
  border: none;
}
.buttons {
  margin: 8px;
}
</style>