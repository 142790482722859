import { IconAccessPoint, IconAccessPointOff, IconMailPlus, IconTrashX, IconEdit, IconCopy, IconCopyOff } from '@tabler/icons-vue';

export const iconMapper = {
    TestOn: IconAccessPoint,
    TestOff: IconAccessPointOff,
    Sender: IconMailPlus,
    Delete: IconTrashX,
    Edit: IconEdit,
    Copy: IconCopy,
    CopyOff: IconCopyOff,
};