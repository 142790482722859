<template>
    <div class="container-fluid py-5">
      <div class="row">
        <div class="col-12">
          <div class="multisteps-form">
            <div class="row">
              <div class="col-12 col-lg-8 mx-auto mb-4">
                <div class="card">
                  <div class="card-body">
                    <div class="multisteps-form__progress">
                      <h5 class="font-weight-bolder mb-0">Inserte datos del Segmento</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--form panels-->
            <div class="row">
              <div class="col-12 col-lg-8 m-auto">
                <form class="multisteps-form__form">
                  <!--single form panel-->
                  <segment-info :class="activeStep === 0 ? activeClass : ''" />
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import SegmentInfo from "./add/SegmentInfo.vue";
  import { addSegment } from "@/config/servicios/segment/servicesSegment";

  export default {
    name: "NuevoSegment",
    components: {
      SegmentInfo,
    },
    data() {
      return {
        showMenu: false,
        activeClass: "js-active position-relative",
        activeStep: 0,
      };
    },
    methods: {
      async addSegment() {
        let name = document.getElementById("segmentAdd").value;

        try {
          const response = await addSegment(name);
          // Almacena la respuesta en la variable de datos
          console.log("Segment Agregado: " + response);
        } catch (error) {
          console.error("Error al obtener datos", error);
        } finally {
          this.$router.push({ name: "Lista Segment" });
        }
      },
    },
  };
  </script>
  