<template>
  <div>
    <div id="grapesjs-editor-envio" style="height: 100vh; border: 1px solid #ddd;"></div>
  </div>
</template>

<script>
// Importar el editor desde el archivo donde se inicializa
import grapesjs from 'grapesjs';
import 'grapesjs/dist/css/grapes.min.css';
import grapesjsPresetWebpage from 'grapesjs-preset-webpage';
import grapesjsBlocksBasic from 'grapesjs-blocks-basic';
import grapesjsPluginForms from 'grapesjs-plugin-forms';
import grapesjsNavbar from 'grapesjs-navbar';
import esTranslations from '@/assets/locale/es';

let editor = null;

export default {
  name: 'GrapesEditorEnvio',
  props: {
    responseTags: {
      type: Array,
      required: true,
    },
    editorVisible: {
      type: Boolean,
      default: false,
    },
    project: {
      type: String,
      default: '',
    },
    cargarPlantilla: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update-body-html'],
  async mounted() {
    await this.initializeEditor();
    this.clearEditor();
  },
  beforeUnmount() {
    // Asegurarse de destruir el editor para evitar errores
    if (editor) {
      editor.destroy();
      editor = null;
    }
  },
  methods: {
    saveTemplateMethod() {
      try {
        const project = JSON.stringify(editor.getProjectData());
        const htmlWithCss = '<style>' + editor.getCss() + '</style>' + editor.getHtml();
        console.log('Plantilla guardada:', htmlWithCss);
        const dataEdit = {
          project: project,
          vista: htmlWithCss
        };

        // Usa this.$emit para emitir el evento en Vue
        this.$emit('update-body-html', dataEdit);
      } catch (error) {
        console.error('Error al guardar la plantilla:', error);
        alert('Error al guardar la plantilla.');
      }
    },
    initializeEditor() {
      // Usa nextTick para asegurar que el DOM está completamente renderizado
      this.$nextTick(() => {
        editor = grapesjs.init({
          container: '#grapesjs-editor-envio',
          height: '100vh',
          width: 'auto',
          fromElement: true,
          plugins: [
            grapesjsPresetWebpage,
            grapesjsBlocksBasic,
            grapesjsPluginForms,
            grapesjsNavbar,
          ],
          pluginsOpts: {
            'grapesjs-preset-webpage': {},
            'grapesjs-blocks-basic': {},
            'grapesjs-plugin-forms': {},
            'grapesjs-navbar': {},
          },
          i18n: {
            locale: 'es',
            messages: {
              es: esTranslations,
            },
          },
        });
        // Configurar el título del botón `gjs-open-import-webpage`
        const importButton = editor.Panels.getButton('options', 'gjs-open-import-webpage');
        if (importButton) {
          importButton.set('attributes', { title: 'Importar Página Web' });
        }
        this.setupEditor();
      });
    },
    setupEditor() {
      const rte = editor.RichTextEditor;
      rte.add('selectTag', {
        icon: `<span style="font-size: 16px; cursor: pointer;margin-right: 1rem;" title="Insertar Variable">📝</span>`,
        event: 'click',
        result: async (rte, action) => {
          const selectHTML = this.generateTagSelectHTML();
          const selectElement = document.createElement('div');
          selectElement.innerHTML = selectHTML;

          selectElement.firstChild.addEventListener('change', (event) => {
            const selectedTag = event.target.value;
            if (selectedTag) {
              rte.insertHTML(selectedTag);
              event.target.value = "elegir-tag"; // Resetear el select después de la inserción
            }
          });

          action.btn.appendChild(selectElement.firstChild);
          var divsToRemove = document.getElementsByClassName("gjs-tags");
          for (var i = divsToRemove.length - 1; i >= 1; i--) {
            divsToRemove[i].remove();
          }
        },
      });
      // Añadir los botones y comandos personalizados aquí
      editor.Panels.removeButton('options', [
        'export-template',
        //'gjs-open-import-webpage',
        'open-layers',
        'open-blocks',
      ]);

      /*editor.Panels.addButton('options', {
        id: 'save-template',
        className: 'fa fa-floppy-o',
        command: 'save-template-command',
        attributes: {title: 'Salvar Plantilla'},
        active: false,
      });

      // Registrar comandos personalizados
      editor.Commands.add('save-template-command', {
        run: this.saveTemplateMethod,
      });*/
    },
    clearEditor() {
      if (editor) {
        // Limpiar el contenido del editor de forma segura
        try {
          editor.DomComponents.clear();
          // Añadir un contenido inicial básico
          //this.editor.addComponents('<div>Nuevo contenido inicial</div>'); // Opcional
        } catch (error) {
          console.error('Error al limpiar el editor:', error);
        }
      }
    },
    generateTagSelectHTML() {
      if (this.responseTags && this.responseTags.entries.length) {
        let selectHTML = '<select class="gjs-tags"><option value="elegir-tag" disabled selected>Elegir Variables</option>';
        this.responseTags.entries.forEach(tag => {
          selectHTML += `<option value="${tag.value}">${tag.label}</option>`;
        });
        selectHTML += '</select>';
        return selectHTML;
      }
      return '<select class="gjs-tags"><option value="">No Hay Variables</option></select>';
    },
  },
};
</script>

<style>
#grapesjs-editor-envio .gjs-one-bg {
  background-color: #0033a0 !important; /* Aumenta la especificidad */
}
#grapesjs-editor-envio {
  height: 100vh;
  border: 1px solid #ddd;
}
#grapesjs-editor-envio .gjs-editor .gjs-rte-action {
  justify-content: flex-start;
}
.gjs-rte-action .gjs-tags {
  border-radius: 50px;
  font-size: 0.875rem;
  padding: .125rem .75rem;
  text-transform: capitalize;
  line-height: 1.4rem;
  color: #495057;
  background-color: #fff;
  border: 1px solid #d2d6da;
  margin-right: .5rem;
}
</style>
