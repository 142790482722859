// api.js
import axios from 'axios';
import Config from "@/config/servicios/campana/config";
export const payment_products = (body) => {
    return axios.post(Config.url_service+Config.payment_products,body,{
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        }});
};
export const payment_gettransaction = (body) => {
    return axios.post(Config.url_service+Config.payment_gettransaction,body,{
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        }});
};

export const blacklist_available = (body) => {
    return axios.post(Config.url_service+Config.blacklist_available,body,{
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        }});
};

export const report_available = (body) => {
    return axios.post(Config.url_service+Config.report_available,body,{
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        }});
};

export const smsGetProgramado = (body) => {
    return axios.post(Config.url_service+Config.sending_sms_get,body,{
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        }});
};

export const contact_availableProgramado = (body) => {
    return axios.post(Config.url_service+Config.contact_available,body,{
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        }});
};

export const draft_availableProgramado = (body) => {
    return axios.post(Config.url_service+Config.draft_available,body,{
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        }});
};

export const landing_availableProgramado = (body) => {
    return axios.post(Config.url_service+Config.landing_available,body,{
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        }});
};

export const contact_available_contact_preview = (body) => {
    return axios.post(Config.url_service+Config.contact_available_contact_preview,body,{
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        }});
};
export const udh_validate_credits = (body) => {
    return axios.post(Config.url_service+Config.udh_validate_credits,body,{
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        }});
};
export const sending_sms_send = (body) => {
    return axios.post(Config.url_service+Config.sending_sms_send,body,{
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        }});
};
// Agrega más funciones según tus necesidades