<template>
  <div class="card">
    <div class="card-header border-1 d-flex justify-content-between align-items-center flex-column flex-md-row">
      <h6 class="mb-md-0">Lista de Dominios</h6>
      <div class="d-flex">
        <button
            class="btn btn-celcom-orange m-0"
            data-toggle="tooltip"
            data-original-title="Agregar Contacto"
            @click.prevent="agregar"
        >
          <i class="fas fa-plus"></i> Agregar Dominio
        </button>
      </div>
    </div>
    <!-- Agrega un campo de entrada para el buscador -->
    <div class="pb-0 card-body mb-0">
      <input v-model="search" type="text" class="form-control mt-2" placeholder="Buscar...">
    </div>
    <div class="card-body table-responsive">
      <table class="table align-items-center mb-0">
        <thead>
        <tr>
          <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Nombre del Dominio</th>
          <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-center">DKIM</th>
          <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-center">SPF</th>
          <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-center">Validar</th>
          <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-center">Remitentes</th>
          <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-center">Eliminar</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(row, index) in filteredData" :key="index">
          <!-- Nombre del Dominio -->
          <td>
            <div class="d-flex px-2 py-1">
              <div class="d-flex flex-column justify-content-center">
                <h6 class="mb-0 text-sm">{{ row.domainName }}</h6>
              </div>
            </div>
          </td>
          <!-- DKIM -->
          <td class="align-middle text-center text-sm">
            <span class="badge badge-dot">
              <i :class="{ 'bg-info': row.dkim, 'bg-dark': !row.dkim }"></i>
              <span class="text-dark text-xs">{{ row.dkim ? "Activo" : "Inactivo" }}</span>
            </span>
          </td>
          <!-- SPF -->
          <td class="align-middle text-center text-sm">
            <span class="badge badge-dot">
              <i :class="{ 'bg-info': row.spf, 'bg-dark': !row.spf }"></i>
              <span class="text-dark text-xs">{{ row.spf ? "Activo" : "Inactivo" }}</span>
            </span>
          </td>
          <!-- Validar -->
          <td class="align-middle text-center text-sm">
            <a
                class="btn btn-warning m-0 me-2 px-3"
                href="#!"
                title="Prueba de Conexión"
                @click.prevent="statusDomain(row)"
            >
              <component :is="iconMapper[(row.isVerified && row.isDnsActive) ? 'TestOn' : 'TestOff']" :size="size" color="#FFF" />
            </a>
          </td>
          <td class="align-middle text-center text-sm">
            <a
                class="btn btn-success m-0 me-2 px-3"
                href="#!"
                :class="{ 'disabled': !(row.isVerified && row.isDnsActive) }"
                :title="(row.isVerified && row.isDnsActive) ? 'Agregar Remitente' : 'Dominio no configurado'"
                @click.prevent="(row.isVerified && row.isDnsActive) && $parent.openModalAddSender(row)"
            >
              <component :is="iconMapper['Sender']" :size="size" color="#FFF" />
            </a>
          </td>
          <td class="align-middle text-center text-sm">
            <a
                class="btn btn-danger m-0 px-3"
                href="#!"
                title="Eliminar Dominio"
                @click.prevent="$parent.openModalEliminarDomain(row)"
            >
              <component :is="iconMapper['Delete']" :size="size" color="#FFF" />
            </a>
          </td>
        </tr>
        </tbody>
      </table>

    </div>
  </div>
</template>

<script>
import { iconMapper } from './iconMapper.js';
export default {
  name: "DomainEmailCampannaTable",
  components: {

  },
  props: {
    domainEmailData: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      size: 24,
      iconoConexiones: true,
      search: "", // Variable para almacenar la entrada del buscador
    };
  },
  computed: {
    iconMapper() {
      return iconMapper
    },
    // Utiliza una propiedad computada para filtrar los datos según la búsqueda
    filteredData() {
      if (!Array.isArray(this.domainEmailData)) {
        return [];
      }
      return this.domainEmailData.filter(domain => {
        const domainName = domain.domainName ?? '';

        return (
            domainName.toLowerCase().includes(this.search.toLowerCase())
        );
      });
    },
  },
  methods: {
    statusDomain(row){
      this.$parent.statusDomainEmail(row);
    },
    agregar(){
      this.$parent.openModalAddDomain();
    },
  },
};
</script>
<style scoped>
.disabled-link {
  pointer-events: none; /* Deshabilita los eventos de clic */
  opacity: 0.6; /* Da apariencia visual de deshabilitado */
  cursor: not-allowed; /* Cambia el cursor para indicar que está deshabilitado */
}
</style>
