<template>
    <div class="container-fluid py-5">
      <div class="row">
        <div class="col-12">
          <div class="multisteps-form">
            <div class="row">
              <div class="col-12 col-lg-10 mx-auto mb-4">
                <div class="card">
                  <div class="card-body">
                    <div class="multisteps-form__progress">
                      <button
                        class="multisteps-form__progress-btn js-active"
                        type="button"
                        title="User Info"
                        :class="activeStep >= 0 ? activeClass : ''"
                        @click="activeStep = 0"
                      >
                        <span>1</span>
                      </button>
                      <button
                        class="multisteps-form__progress-btn"
                        type="button"
                        title="Address"
                        :class="activeStep >= 1 ? activeClass : ''"
                        @click="activeStep = 1"
                      >
                        2
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--form panels-->
            <div class="row">
              <div class="col-12 col-lg-10 m-auto">
                <form class="multisteps-form__form">
                  <!--single form panel-->
                  <black-list-add1 :valid-form="validForm" :class="activeStep === 0 ? activeClass : ''" @update-values="handleUpdateValues" @contacto-agregado="handleContactoAgregado"/>
                  <!--single form panel-->
                  <black-list-add2 :created-contact="createdContact" :created-list="createdList" :class="activeStep === 1 ? activeClass : ''" />
                  <!--single form panel-->
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Loader ref="loader"/>
    </div>
  </template>
  
  <script>
  import BlackListAdd1 from "@/views/contactsC/components/BlackListAdd1.vue";
  import BlackListAdd2 from "@/views/contactsC/components/BlackListAdd2.vue";
  import Loader from "@/components/Modal/Loader.vue"
  import 'sweetalert2/src/sweetalert2.scss'
  import {encryptAES, getUser, getUserSub, horaAhora, onPermissionsUser} from "@/config/servicios/campana/util";
  import swal from "sweetalert2";
  import {blacklist_add_blacklists} from "@/config/servicios/campana/contactos/servicesContactosCamp";
  export default {
    name: "BlackListAdd",
    components: {
      Loader,
      BlackListAdd1,
      BlackListAdd2,
    },
    data() {
      return {
        activeStep: 0,
        formSteps: 1,
        activeClass: "js-active position-relative",
        fileSingle: [],
        inputs: {
          file: []
        },
        radios: {
          radio1: 'checked'
        },
        checked: false,
        listaCreada: false,
        listaTerminada: false,
        contactGroup: {},
        contactSingle: {},
        importadosBien: false,
        barProgreso:0,
        barProgresoFin:0,
        validForm:{
          CodePhoneContact:getUser().customer.country.phoneCode,
          PhoneContact:"",
          field:"",
        },
        createdList: [],
        createdContact: [],
        cantidadContactos: [],
        header:"",
        interaccion: [],
      };
    },
    watch:{

    },
    created(){

    },
    mounted() {
      if(!onPermissionsUser('contacts.xhtml', 'create')) {
        swal.fire({
          title: 'No tienes los permisos para acceder a esta página.',
          buttonsStyling: false,
          customClass: {
            confirmButton: 'btn btn-celcom-orange',
          },
          type: 'error',
        }).then(function() {
          window.location.href="/dashboard";
        })

      }
    },
    methods: {
      handleContactoAgregado(nuevoContacto) {
        // Actualiza createdContact en el componente padre
        this.createdContact = nuevoContacto;
      },
      handleUpdateValues({ msisdn, codigo}) {
        // Actualiza los valores en el componente padre según sea necesario
        this.validForm.PhoneContact  = msisdn;
        this.validForm.CodePhoneContact = codigo;
        this.guardarNuevo();
        this.guardarNuevaLista();
        this.nextStep();
      },
      nextStep() {
        if (this.activeStep < this.formSteps) {
          this.activeStep += 1;
        } else {
          this.activeStep -= 1;
        }
      },
      prevStep() {
        if (this.activeStep > 0) {
          this.activeStep -= 1;
        }
      },
      guardarNuevo() {

        this.$refs.loader.showLoader();

        let self = this;
        let user = (getUserSub()!=null) ? getUserSub() :getUser();

        let nuevoContacto = {
          "blacklistPK" : {
            "codePhone": this.validForm.CodePhoneContact,
            "phone": this.validForm.PhoneContact,
            "customerUserId": user.customerUserId,
          },
          "customerUser":user,
        };

        self.validForm.field = "";

        console.log(this.validForm.PhoneContact.toString().length);

        if (self.validForm.PhoneContact === "") {
          self.validForm.field+=",PhoneContact"; self.validForm.estado=true;

          self.$refs.loader.hideLoader();

          return false;

        } else if ( (user.customer.country.name === "Colombia" && self.validForm.PhoneContact.toString().length !== 9) || (user.customer.country.name === "Chile" && self.validForm.PhoneContact.toString().length !== 8) ) {

          self.validForm.field+=",PhoneContact"; self.validForm.estado=true;

          self.$refs.loader.hideLoader();

          return false;

        } else {

          this.createdContact.push(nuevoContacto);
          self.listaTerminada = true;

          self.$refs.loader.hideLoader();

        }

      },
      guardarNuevaLista() {

        this.$refs.loader.showLoader();

        let self = this;
        let user = (getUserSub()!=null) ? getUserSub() :getUser();

        let nuevaLista = {
          "customerId": user.customer.customerId,
          "name": this.validForm.listName,
          "header": self.header,
          "active":true,
          "validos": "1",
          "invalidos": "0"
        }

        console.log(nuevaLista);

        if(this.validForm.listName === ""){
          this.validForm.field+=",listName"; this.validForm.estado=true;

           self.$refs.loader.hideLoader();

        } else {

          this.createdList = nuevaLista;
          self.listaCreada = true;

           self.$refs.loader.hideLoader();
        }

      },
      async finalizarAgregar() {

        this.$refs.loader.showLoader();

        let self = this;

        self.barProgresoFin=4;

        let user = (getUserSub()!=null) ? getUserSub() :getUser();

        let js = {
          "time_expired":horaAhora(),
          "customer_user":encodeURIComponent(JSON.stringify(user)),
          "blacklist": (JSON.stringify(this.createdContact)),
        }

        console.log(js);

        var body = new URLSearchParams(); body.append('TOKEN', (encryptAES(js)));

        blacklist_add_blacklists(body)
            .then(function (response) {
              console.log(response)

              if (response.data.response === "200") {
                console.log(response.data.data);

                if (response.data.response === "200") {
                  console.log(response.data.data);
                  self.$refs.loader.hideLoader();
                  swal.fire({
                    text: '¡Lista creada exitosamente!',
                    buttonsStyling: false,
                    customClass: {
                      confirmButton: 'btn btn-celcom-blue',
                    },
                    type: 'success',
                    icon: 'success'
                  }).then(function() {
                    window.location.href = "/contactos/lista-negra";
                  })
                } else {
                  console.log("No se pudo crear contacto");
                  self.$refs.loader.hideLoader();
                  swal.fire({
                    text: 'No se pudo crear contacto',
                    buttonsStyling: false,
                    customClass: {
                      confirmButton: 'btn btn-celcom-orange',
                    },
                    type: 'warning',
                    icon: 'warning'
                  })
                }

              } else {
                console.log("No se pudo crear lista");
                alert("No se pudo crear lista");
              }

            })

            .catch(function (error) {
              console.log("No se pudo crear lista",error);
              swal.fire({
                title: 'No se pudo crear lista',
                buttonsStyling: false,
                customClass: {
                  confirmButton: 'btn btn-celcom-orange',
                },
                type: 'warning',
              })
              self.$refs.loader.hideLoader();
            });

      },
      /*async createListContact(contacts,interation,current,total_contacts,contactGroupNew){

        var self = this;
        //LOGICA QUE CREA ARRAY DE CONTACTOS
        let nuevoContacto = contacts;
        //var c=0;

        let user = (getUserSub()!=null) ? getUserSub() :getUser();

        let js = {
          "time_expired":horaAhora(),
          "contact_group":encodeURIComponent(JSON.stringify(contactGroupNew)),
          "contact":encodeURIComponent(JSON.stringify(nuevoContacto)),
          "customer":encodeURIComponent(JSON.stringify(user.customer)),
        }

        console.log(js)

        console.log("enviado  nuevoContacto : ",nuevoContacto);

        var body = new URLSearchParams(); body.append('TOKEN',encryptAES(js));

        contact_add_contacts(body)
            .then(function (response) {

              console.log(response)

              if (response.data.response === "200") {

                console.log("contactos importados : ",response.data.data);


                console.log("slice nueva iteration  : ",interation[current]);


                //SI ESTA INDEFINIDAD LA LISTA ES MENOR A 4000 Y NO HACE RECURSIVIDAD
                if(interation.length >= 1){

                  let promedioProgreso = total_contacts.length/((Math.ceil(total_contacts.length/4000)));

                  console.log(" progresoBar  ",Number((promedioProgreso * current)*100/total_contacts.length ).toFixed(0) )

                  self.barProgresoFin=parseInt(Number((promedioProgreso * current)*100/total_contacts.length ).toFixed(0));

                  //VALIDA QUE TODOS LOS CONTACTOS SE IMPORTARAN Y TERMINA RECURSIVIDAD
                  if(interation[current].i >= (total_contacts.length+4000)){

                    self.importadosBien = true;
                    self.listaTerminada = true;

                    self.createdContact = response.data.data;

                    swal.fire
                    ({
                      text: '¡Lista creada exitosamente!',
                      confirmButtonClass: 'btn btn-success',
                      type: 'success',
                    }).then(function() {
                      window.location.href = "/contactos/lista-contactos";
                    })

                    self.$refs.loader.hideLoader();

                  }else{
                    //FUNCION RECURSIVA DE IMPORTAR CONTACTOS
                    self.createListContact(total_contacts.slice(interation[current].init,interation[current].i),interation,(current+1),total_contacts,contactGroupNew);

                  }

                }else{

                  self.importadosBien = true;
                  self.listaTerminada = true;

                  self.nuevaListaContact = response.data.data;

                  swal.fire({
                    text: '¡Lista creada exitosamente!',
                    confirmButtonClass: 'btn btn-success',
                    type: 'success',
                  }).then(function() {
                    window.location.href = "/contactos/lista-contactos";
                  })

                  self.$refs.loader.hideLoader();

                }

              } else {

                self.$refs.loader.hideLoader();

                console.log("No se pudo crear contacto");
                swal.fire({
                  title: "No se pudo crear contacto",
                  confirmButtonClass: 'btn btn-danger btn-fill'
                })
              }


            })
            .catch(function (error) {

              console.log("No se pudo agregar lista de contactos",error);

              self.$refs.loader.hideLoader();

              swal.fire({
                title: "Error no se pudo crear contacto",
                confirmButtonClass: 'btn btn-danger btn-fill'
              })

            });

      },*/
    },
  };
  </script>
  