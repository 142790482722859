<template>
  <div
      class="card multisteps-form__panel"
      data-animation="FadeIn"
  >
    <div class="card-body multisteps-form__content">
      <div class="row">
        <div class="col-12 col-sm-6">
          <input
              id="individual"
              v-model="seleccion"
              type="radio"
              name="individual"
              value="individual"

          />
          <label>Individual</label>
        </div>
      </div>
      <div class="row" :style="{ 'pointer-events': seleccion === 'individual' ? 'auto' : 'none' }">
        <div class="col-12 col-sm-6">
          <argon-input
              id="codigo"
              v-model="codigo"
              class="multisteps-form__input code"
              placeholder="Creative Tim"
          />
        </div>
        <div class="col-12 col-sm-6 mt-3 mt-sm-0">
          <argon-input
              id="msisdn"
              v-model="msisdn"
              class="multisteps-form__input"
              type="number"
              placeholder="12345678"
          />
        </div>
      </div>
      <div class="col-12 col-sm-6">
        <input
            id="lista"
            v-model="seleccion"
            type="radio"
            name="lista"
            value="lista"
        />
        <label class="form-label mt-2">Importar lista (csv, excel, etc)</label>
      </div>
      <div class="row mt-3" :style="{ 'pointer-events': seleccion === 'lista' ? 'auto' : 'none' }">
        <div class="m-3 card card-body">
          <div
              id="dropzone"
              class="form-control dropzone"
              :style="{ 'pointer-events': seleccion === 'lista' ? 'auto' : 'none' }"
          >
            <div class="fallback">
              <input name="file" type="file" ref="fileInput" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card-footer border-1 d-flex mt-2">
      <argon-button
        type="button"
        color="celcom"
        variant="gradient"
        class="text-white ms-auto mb-0 js-btn-next"
        @click="nextStepValor"
      >Siguiente <i class="fas fa-chevron-right ms-2"></i>
      </argon-button>
    </div>
  </div>
</template>

<script>
import Dropzone from "dropzone";
import * as XLSX from "xlsx";
import ArgonInput from "@/components/ArgonInput.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import {getUser, getUserSub} from "@/config/servicios/campana/util";
import Swal from "sweetalert2";

export default {
  name: "ListaAdd1",
  components: {
    ArgonInput,
    ArgonButton,
  },
  props: {
    createdContact: {
      type: Array,
      default: () => {
        return [];
      },
    },
    validForm: {
      type: Object,
      default: () => ({})
    },
  },
  emits: ['update-values', 'contacto-agregado'],
  data() {
    return {
      createdContactAux: [],
      seleccion:"individual",
      archivoExcel: null,
      msisdn: this.validForm.PhoneContact || null,
      codigo: this.validForm.CodePhoneContact,
    };
  },
  watch: {
    validForm: {
      handler(newValidForm) {
        this.msisdn = newValidForm.PhoneContact;
        this.codigo = newValidForm.CodePhoneContact;
      },
      immediate: true,
      deep: true,
    },
  },
  mounted() {

    Dropzone.autoDiscover = false;
    const dropzoneElement = document.getElementById("dropzone");

    const myDropzone = new Dropzone(dropzoneElement, {
      url: "/file-upload",
      acceptedFiles: ".csv,.xls,.xlsx",
      addRemoveLinks: true,
      dictDefaultMessage: "Arrastra tu archivo aquí.",
    });

    myDropzone.on("addedfile", (file) => {
      // Acción al agregar un archivo
      // Puedes acceder al archivo cargado usando el objeto 'file'

      this.cargarArchivo(file, myDropzone);
    });
  },
  methods: {
    agregarContacto(createdContactAux) {
      // Emitir el evento para informar al padre sobre la modificación
      this.$emit('contacto-agregado', createdContactAux);
    },
    // Método que se llama cuando se hace clic en el botón "Siguiente"
    nextStepValor() {
      this.$emit('update-values', {
        msisdn: this.msisdn,
        codigo: this.codigo,});
    },
    cargarArchivo(file, myDropzone) {
      const input = document.getElementById("dropzone").querySelector("input[type='file']");
      const selectedFile = file || input.files[0];
      if (selectedFile) {
        const reader = new FileReader();
        reader.onload = (e) => {
          try {
            const data = new Uint8Array(e.target.result);
            const workbook = XLSX.read(data, { type: 'array' });
            const sheetName = workbook.SheetNames[0];
            const sheet = workbook.Sheets[sheetName];

            // Guardar la hoja en una variable de datos
            this.archivoExcel = XLSX.utils.sheet_to_json(sheet, { header: 1 });

            let user = (getUserSub()!=null) ? getUserSub() :getUser();


            let header = "";
            let dataTotal = [];

            var cheader = 0;
            //crea header
            if(data.length > 0){


              //SE ACTIVA LECTURA DE EXCEL DE ABERTIS
              if(user.customer.customerId===210 || user.customer.customerId===209 || user.customer.customerId===219){

                header="###idsmmcc###|*#*idms2*#*";

                //LIMPIO LA DATA POR ESPACIOS EN BLANCO O DATOS INVALIDOS
                this.archivoExcel.forEach((key) => {

                  var phone = "";
                  var phoneCode = "569";
                  //var field = "";
                  phone=key[3];
                  /*field+=(key[2])+"|";
                  field+=(key[0])+"|";*/
                  //VALIDA QUE SEA SOLO NUMERO
                  if (phone!=="" && /^([0-9])*$/.test(phone) && /^([0-9])*$/.test(phoneCode)  && phone.toString().length > 7  ){
                    // console.log("subir : "+phoneCode)
                    if (phone.toString().length > 8  ){
                      key[3]  = phone.toString().substring((phone.toString().length-8), phone.toString().length);
                    }
                    dataTotal.push(key);

                    this.createdContactAux.push({
                      "blacklistPK" : {
                        "codePhone": phoneCode,
                        "phone": key[3],
                        "customerUserId": user.customerUserId,
                      },
                      "customerUser":user,
                    });
                  }
                });

              }else{

                this.archivoExcel[0].forEach(function(key){
                  cheader++;
                  if(cheader>2){
                    header+=key+"|";
                  }
                });



                //LIMPIO LA DATA POR ESPACIOS EN BLANCO O DATOS INVALIDOS
                this.archivoExcel.forEach((key) => {

                  var phone = "";
                  var phoneCode = "";
                  //var field = "";
                  for(var j = 0 ; j < key.length ; j++){

                    if(j === 0){
                      phoneCode=key[j];
                    }
                    if(j === 1){
                      phone=key[j];
                    }
                    /*if(j > 1){
                      field+=(key[j])+"|";
                    }*/
                  }
                  //VALIDA QUE SEA SOLO NUMERO
                  if (phone!=="" && /^([0-9])*$/.test(phone) && /^([0-9])*$/.test(phoneCode)  && phone.toString().length > 7  ){
                    // console.log("subir : "+phoneCode)
                    if (phone.toString().length > 8  ){
                      key[1]  = phone.toString().substring((phone.toString().length-8), phone.toString().length);
                    }
                    dataTotal.push(key);
                    this.createdContactAux.push({
                      "blacklistPK" : {
                        "codePhone": phoneCode,
                        "phone": phone,
                        "customerUserId": user.customerUserId,
                      },
                      "customerUser":user,
                    });
                  }
                });

              }

            }

            if( (this.contadorContactImportados+dataTotal.length) > 20000 && user.customer.uploadActive===false){
              Swal.fire({
                title: "Error limite de contactos excedidos (20.000)",
                buttonsStyling: false,
                customClass: {
                  confirmButton: 'btn btn-celcom-orange',
                },
              })
              myDropzone.removeAllFiles(true);
              return false;
            }

            console.log("datos importados header : ",header);
            console.log("datos importados header 2: ",dataTotal);
            //this.$parent.guardarNuevaLista(header,selectedFile.name,dataTotal);
            this.agregarContacto(this.createdContactAux);
            myDropzone.removeAllFiles(true);
          } catch (error) {
            console.error('Error al procesar el archivo:', error);
          }
        };

        reader.readAsArrayBuffer(selectedFile);
      }
    },
  },
};
</script>
<style scoped>
.code {
  width: 70px;
}
</style>
