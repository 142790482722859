<template>
  <div class="cardReport">
    <div class="cardReport-body">
      <label class="value-text-title">{{ data?.title || 'Título no disponible' }}</label>
      <hr class="m-0">
      <p class="text-end body-text mt-2 mb-0">
        <span class="value-text"><b>{{ data?.value || '0' }}</b></span>
        <template v-if="data?.porcentaje !== undefined && data.porcentaje !== null && data.porcentaje !== ''">
          <span class="percentage-text"> ({{ data.porcentaje }}%)</span>
        </template>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "CardReport",
  props: {
    data: {
      type: Object,
      default: () => ({})
    },
  },
  computed: {
    percentageText() {
      return this.data?.porcentaje !== undefined && this.data.porcentaje !== null && this.data.porcentaje !== ''
          ? ` (${this.data.porcentaje}%)`
          : '';
    }
  },
};
</script>

<style scoped>
.cardReport {
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  max-width: 500px;
  margin: 8px;
}

.cardReport-body {
  padding: 8px;
}

.body-text {
  font-size: 0.8rem;
  color: #333;
}

.value-text {
  color: #616161;
}

.value-text-title {
  color: #303030;
}

.percentage-text {
  font-size: 0.7em; /* Ajusta el tamaño según lo necesites */
  border-bottom: 1px dashed gray; /* Línea discontinua debajo */
  padding-bottom: 0px; /* Ajusta el espacio entre el texto y la línea */
  color: #646464;
}
</style>
