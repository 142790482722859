import { agreCustomerUser } from "@/config/servicios/customerUser/servicesCustomerUser";
import { llenarDatosCustomerUser} from "@/config/servicios/customerUser/plantillaCustomerUser";

export const addCustomerUser = (email, password, adminUser, active) => {
    const jsonDataCustomerUser = llenarDatosCustomerUser(null, email, password, adminUser, active);

    return agreCustomerUser(jsonDataCustomerUser)
        .then((response) => {
        console.log(response.data);
    })
        .catch((error) => {
        console.error("Error al enviar los datos:", error);
    });
};