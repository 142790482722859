<template>
  <div class="container-fluid py-5">
    <div class="row">
      <div class="col-12">
        <div class="multisteps-form">
          <!--form panels-->
          <div class="row">
            <div class="col-12 col-lg-10 m-auto">
              <form class="multisteps-form__form">
                <ConfiguracionMoTable :response-mo="responseMo"/>
              </form>
            </div>
          </div>
        </div>
      </div>
      <modal class="modal-lg" :is-visible="modalAdd">
        <div class="row">
          <div class="col-12 col-sm-6">
            <div class="row">
              <div class="col">
                <p><b>Datos de la Configuración</b></p>
                <div class="form-check">
                  <input
                      v-model="addMO.active"
                      class="form-check-input"
                      type="checkbox"
                      id="estadoCheckboxAdd"
                  />
                  <label class="form-check-label" for="estadoCheckboxAdd">
                    {{ dataMo.active === true ? "Activo" : "Inactivo" }}
                  </label>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-sm-6">
                <label>Tipo</label>
                <argon-input
                    id="typeEdit"
                    v-model="addMO.type"
                    class="mb-2"
                    type="text"
                    :is-disabled="isDisabled"
                    placeholder="Tipo"
                />
              </div>
              <div class="col-12 col-sm-6">
                <label>Source</label>
                <select
                    id="opcionesSource"
                    v-model="addMO.source"
                    class="multisteps-form__select form-control form-select"
                    name="opciones-source"
                >
                  <option value="" disabled selected>Sources</option>
                  <option
                      v-for="(sender, index) in responseSenderSmsCustomer"
                      :key="index"
                      :value="sender.msisdn"
                  >
                    {{ sender.msisdn }}
                  </option>
                </select>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <label>Edite mensaje</label>
                <textarea
                    id="mensajeEdit"
                    v-model="addMO.message"
                    class="form-control espacio"
                    placeholder="Mensaje"
                />
                <p class="text-xs text-end mt-1" :class="{ 'is-invalid-d':  addMO.message.length > 160}">{{addMO.message.length +"/160"}}</p>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-sm-6">

              </div>
            </div>
          </div>
          <div class="col-12 col-sm-6">
            <vista-previa-sms
                :body="addMO.message"
                :header="headerAdd"/>
          </div>
        </div>
        <div class="modal-footer px-0 mt-lg-4 d-flex justify-content-between">
          <button
              class="btn bg-gradient-celcom-orange mb-0"
              :disabled="addMO.message.length > 160"
              @click="addConfiguracionMo">Agregar</button>
          <button class="btn bg-gradient-dark mb-0" @click="closeModal">Cancelar</button>
        </div>
      </modal>
      <modal class="modal-lg" :is-visible="modalEditar">
        <div class="row">
          <div class="col-12 col-sm-6">
        <div class="row">
          <div class="col">
            <p><b>Edite la Configuración</b></p>
            <div class="form-check">
              <input
                  v-model="dataMo.active"
                  class="form-check-input"
                  type="checkbox"
                  id="estadoCheckbox"
              />
              <label class="form-check-label" for="estadoCheckbox">
                {{ dataMo.active === true ? "Activo" : "Inactivo" }}
              </label>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-sm-6">
            <label>Tipo</label>
            <argon-input
                disabled="disabled"
                id="typeEdit"
                v-model="dataMo.type"
                class="mb-2"
                type="text"
                :is-disabled="isDisabled"
                placeholder="Tipo"
            />
          </div>
          <div class="col-12 col-sm-6">
            <label>Source</label>
            <select
                id="opcionesSource"
                v-model="dataMo.source"
                class="multisteps-form__select form-control form-select"
                name="opciones-source"
            >
              <option value="" disabled selected>Sources</option>
              <option
                  v-for="(sender, index) in responseSenderSmsCustomer"
                  :key="index"
                  :value="sender.msisdn"
              >
                {{ sender.msisdn }}
              </option>
            </select>
        </div>
        </div>
        <div class="row">
          <div class="col-12">
            <label>Edite mensaje</label>
            <textarea
                id="mensajeEdit"
                v-model="dataMo.message"
                class="form-control espacio"
                placeholder="Mensaje"
            />
            <p class="text-xs text-end mt-1" :class="{ 'is-invalid-d': isMessageInvalid }">
              {{ messageLength + "/160" }}
            </p>
          </div>
        </div>
          </div>
          <div class="col-12 col-sm-6">
            <vista-previa-sms
                :body="dataMo.message"
                :header="headerEdit"/>
          </div>
        </div>
        <div class="modal-footer px-0 mt-lg-4 d-flex justify-content-between">
          <button
              class="btn bg-gradient-celcom-orange mb-0"
              @click="editarConfiguracionMo">Editar</button>
          <button class="btn bg-gradient-dark mb-0" @click="closeModal">Cancelar</button>
        </div>
      </modal>
      <modal-delete :is-visible="modalDelete">
        <p class="text-center"><b>¿Estás seguro de que deseas eliminar?</b></p>
        <div class="mt-lg-4 d-flex justify-content-between">
          <button class="btn bg-gradient-danger mb-0" @click="deleteConfiguracionMo">Eliminar</button>
          <button class="btn bg-gradient-dark mb-0" @click="closeModal">Cancelar</button>
        </div>
      </modal-delete>
    </div>
  </div>
</template>

<script>

import {encryptAES, horaAhora, getUser, getUserSub} from "@/config/servicios/campana/util";
import {mo_response, mo_delete_sms, mo_edit_sms, mo_add_sms} from "@/config/servicios/campana/configuracionMo/servicesConfiguracionMo";
import ConfiguracionMoTable from "@/views/channels/components/ConfiguracionMoSmsTable.vue";
import Modal from "@/components/Modal/Modal.vue";
import ArgonInput from "@/components/ArgonInput.vue";
import ModalDelete from "@/components/Modal/ModalDelete.vue";
import swal from "sweetalert2";
import VistaPreviaSms from "@/views/envios/components/VistaPreviaSms.vue";

export default {
  name: "ConfiguracionMoSMS",
  components: {
    ModalDelete,
    ArgonInput, Modal,
    ConfiguracionMoTable,
    VistaPreviaSms,
  },
  data() {
    return {
      responseSenderSmsCustomer: [],
      isDisabled: true,
      responseMo: [],
      dataMo: {},
      addMO: {
        customerMoResponseId: null,
        customerId: null,
        type: "SMS",
        buttonId: null,
        message: "",
        hdmid: null,
        active: false,
        customerWhatsappId: null,
        source: "",
        channelId: 1,
      },
      modalDelete: false,
      modalEditar: false,
      modalAdd: false,
    };
  },
  computed: {
    headerAdd() {
      return {
        msisdn: this.addMO.source,
      };
    },
    headerEdit() {
      return {
        msisdn: this.dataMo.source,
      };
    },
    messageLength() {
      return this.dataMo && this.dataMo.message ? this.dataMo.message.length : 0;
    },
    isMessageInvalid() {
      return this.messageLength > 160;
    }
  },
  watch: {
    message(newValue) {
      console.log('Valor de message:', newValue);
    },
  },
  created() {
    this.$store.commit('RESET_Data_Paginada');
  },
  async mounted() {
    this.obtenerConfiguracionMo();
    await this.$store.getters.obtenerSenderSms();
    this.responseSenderSmsCustomer = this.$store.state.responseSenderSmsCustomer;
  },
  methods: {
    openModal(dataMo) {
      this.dataMo = dataMo;
      this.modalDelete = true;
    },
    openModalAdd() {
      this.modalAdd = true;
    },
    openModalEditar(dataMo) {
      this.dataMo = dataMo;
      this.modalEditar = true;
    },
    async closeModal() {
      await this.obtenerConfiguracionMo();
      this.modalDelete = false;
      this.modalEditar = false;
      this.modalAdd = false;
      this.dataMo = {};
    },
    async obtenerConfiguracionMo(){
      try {
        let user = (getUserSub()!=null) ? getUserSub() :getUser();
        let js = {
          time_expired: horaAhora(),
          customer: encodeURIComponent(JSON.stringify(user.customer))
        };

        var body = new URLSearchParams();
        body.append("TOKEN", encryptAES(js));


        const response = await mo_response(body);
        // Almacena la respuesta en la variable de datos
        if (response.data.response === "200") {
          this.responseMo = response.data.data.filter(item => item.channelId === 1);
        } else {
          console.log("No se encontró lista");

        }
      } catch (error) {
        console.error("Error al obtener datos", error);
      }
    },
    async addConfiguracionMo() {
      try {
        let user = (getUserSub()!=null) ? getUserSub() :getUser();
        this.addMO.customerId = user.customer.id;

        let js = {
          time_expired: horaAhora(),
          customer: encodeURIComponent(JSON.stringify(user.customer)),
          customerMoResponse: encodeURIComponent(JSON.stringify(this.addMO))
        };

        var body = new URLSearchParams();
        body.append("TOKEN", encryptAES(js));


        const response = await mo_add_sms(body);
        // Almacena la respuesta en la variable de datos
        if (response.data.response === "200") {
          this.mo_response = response.data.data;
          swal.fire({
            text: '¡Configuración creada exitosamente!',
            buttonsStyling: false,
            customClass: {
              confirmButton: 'btn btn-celcom-blue',
            },
            icon: 'success',
          })
          this.closeModal();
          await this.obtenerConfiguracionMo();
        } else {
          swal.fire({
            text: '¡Error al Crear la Configuración!',
            buttonsStyling: false,
            customClass: {
              confirmButton: 'btn btn-danger',
            },
            icon: 'error',
          })
          this.closeModal();
        }
      } catch (error) {
        swal.fire({
          text: '¡Error al Crear la Configuración!',
          buttonsStyling: false,
          customClass: {
            confirmButton: 'btn btn-danger',
          },
          icon: 'error',
        })
        this.closeModal();
      }
    },
    async editarConfiguracionMo() {
      try {
        let user = (getUserSub()!=null) ? getUserSub() :getUser();
        let js = {
          time_expired: horaAhora(),
          customer: encodeURIComponent(JSON.stringify(user.customer)),
          customerMoResponse: encodeURIComponent(JSON.stringify(this.dataMo))
        };

        var body = new URLSearchParams();
        body.append("TOKEN", encryptAES(js));


        const response = await mo_edit_sms(body);
        // Almacena la respuesta en la variable de datos
        if (response.data.response === "200") {
          this.mo_response = response.data.data;
          swal.fire({
            text: '¡Configuración editada exitosamente!',
            buttonsStyling: false,
            customClass: {
              confirmButton: 'btn btn-celcom-blue',
            },
            icon: 'success',
          })
          this.closeModal();
          await this.obtenerConfiguracionMo();
        } else {
          swal.fire({
            text: '¡Error al Editar la Configuración!',
            buttonsStyling: false,
            customClass: {
              confirmButton: 'btn btn-danger',
            },
            icon: 'error',
          })
          this.closeModal();
        }
      } catch (error) {
        swal.fire({
          text: '¡Error al Editar la Configuración!',
          buttonsStyling: false,
          customClass: {
            confirmButton: 'btn btn-danger',
          },
          icon: 'error',
        })
        this.closeModal();
      }
    },
    async deleteConfiguracionMo() {
      if (this.dataMo) {
        try {
          let user = (getUserSub()!=null) ? getUserSub() :getUser();
          let js = {
            time_expired: horaAhora(),
            customer: encodeURIComponent(JSON.stringify(user.customer)),
            customerMoResponse: encodeURIComponent(JSON.stringify(this.dataMo))
          };
          var body = new URLSearchParams();
          body.append("TOKEN", encryptAES(js));

          const response = await mo_delete_sms(body);

          if (response.data.response === "200") {
            console.log("Configuración Eliminada ", response.data.data);
            this.closeModal();
          } else {
            console.log("No se pudo eliminar la Configuración");
          }
          console.log("Configuración eliminada" );
        } catch (error) {
          console.error("Error al eliminar la Configuración", error);
        } finally {
          this.modalDelete = false;
          await this.obtenerConfiguracionMo();
        }
      }
    },
  },
};
</script>
<style scoped>
/* Agrega estilos según tus necesidades */
.modal {
  display: none;
}

.modal.is-active {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
}

.modal-close {
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
  background: none;
  border: none;
}
.buttons {
  margin: 8px;
}
.espacio{
  margin-bottom: 0;;
}
.espacio1{
  margin-top: 0;
  margin-left: 1px;
  margin-right: 1px;
}
label {
  margin-left: 0;
}
</style>