import { editCustomer } from "@/config/servicios/customer/servicesCustomer";
import {llenarDatosCustomer} from "@/config/servicios/customer/plantillaCustomer";

export const editCu = (id, name, adminCustomer, active, country) => {
    const jsonDataCustomer = llenarDatosCustomer(id, name, adminCustomer, active, country);

    return editCustomer(jsonDataCustomer)
        .then((response) => {
            console.log(response.data);
        })
        .catch((error) => {
            console.error("Error al enviar los datos:", error);
        });
};