<template>
  <div class="container-fluid py-5">
    <div class="row">
      <div class="col-12">
        <div class="multisteps-form">
          <!--form panels-->
          <div class="row">
            <div class="col-12 col-lg-10 m-auto">
              <form class="multisteps-form__form" style="max-width: 100%;">
                <contactos-campana-table
                    v-if="!tabContact"
                    :tab-contact="tabContact"
                    :permisos="permisos"
                    :lista-data="responseContact"></contactos-campana-table>
                <contactos-lista-table
                    v-if="tabContact"
                    :tab-contact="tabContact"
                    :cambiar-nombre="cambiarNombre"
                    :permisos="permisos"
                    :contact-group="contactGroup"
                    :lista-data="tableData"></contactos-lista-table>
              </form>
            </div>
          </div>
        </div>
      </div>
      <modal-delete :is-visible="modals.modalVisible">
        <p class="text-center"><b>¿Estás seguro de que deseas eliminar?</b></p>
        <div class="mt-lg-4 d-flex justify-content-between">
          <button class="btn bg-gradient-danger my-sm-auto mt-2 mb-0" @click="deleteLista">Eliminar</button>
          <button class="btn bg-gradient-dark my-sm-auto mt-2 mb-0" @click="closeModal">Cancelar</button>
        </div>
      </modal-delete>
      <modal :is-visible="modals.modalEditar">
        <p><b>Datos de la Lista</b></p>
        <div class="row mt-3">
          <div class="col-12 col-sm-6">
            <label>Nombre</label>
            <argon-input
                id="ListaName"
                v-model="contactGroup.name"
                class="multisteps-form__input"
                type="text"
                placeholder="Nombre de lista"
            />
          </div>
        </div>
        <div class="modal-footer border-1 d-flex justify-content-between mt-4 px-0">
          <button class="btn bg-gradient-celcom-orange mt-2 mb-0" @click="editarLista">Editar</button>
          <button class="btn bg-gradient-dark mt-2 mb-0" @click="closeModal">Cancelar</button>
        </div>
      </modal>
    </div>
    <Loader ref="loader"/>
  </div>
</template>

<script>

import {encryptAES, horaAhora, getUser, getUserSub, onPermissionsUser} from "@/config/servicios/campana/util";
import {
  listaContactosCamp,
  contact_delete_contact,
  contact_edit_contact,
  contact_edit_contact_group, contact_add_contact,
} from "@/config/servicios/campana/contactos/servicesContactosCamp";
import contactosCampanaTable from "@/views/contactsC/components/ContactosCampanasTable.vue";
import contactosListaTable from "@/views/contactsC/components/ContactosListaTable.vue";
import ModalDelete from "@/components/Modal/ModalDelete.vue";
import Swal from "sweetalert2";
import Loader from "@/components/Modal/Loader.vue";
import dataTables from "@/views/applications/DataTables.vue";
import Modal from "@/components/Modal/Modal.vue";
import ArgonInput from "@/components/ArgonInput.vue";

export default {
  name: "Listas",
  components: {
    ArgonInput,
    Modal,
    Loader,
    ModalDelete,
    contactosCampanaTable,
    contactosListaTable,
  },
  data() {
    return {
      data: {},
      cambiarNombre: false,
      currentContactGroup:"",
      listaNombre: "",
      tableData: [],
      contactGroup: {},
      contactSingle: {},
      tabContact: false,
      responseContact: [],
      modals: {
        modalEditar: false,
        modalVisible: false,
        agregarContacto: false,
        agregarMasivo: false
      },
      permisos: {
        create: onPermissionsUser('contacts.xhtml', 'create'),
        modify: onPermissionsUser('contacts.xhtml', 'modify'),
        destroy: onPermissionsUser('contacts.xhtml', 'destroy'),
      },
      validForm:{
        estado:false,
        field:"",
        listName:"",
        CodePhoneContact:getUser().customer.country.phoneCode,
        PhoneContact:"",
        listContact:"",
        dateProgramgContact:"",
        menssageContact:"",
        selectDraft:"",
        StepPreview:false,
        dateSend:"",
        sendingsms:"",
        contacts:[],
        childrenId:null,
        sendingTotal:0,
        sendingsmsId:null,
      },
    };
  },
  computed: {
    dataTables() {
      return dataTables
    }
  },
  mounted() {
    this.$store.getters.limpiarVariables();
    let listcontact_init = this.$store.state.editarContacto;

    if(listcontact_init){
      this.$refs.loader.showLoader();
      this.onShowList(listcontact_init,false);
      this.$refs.loader.hideLoader();
    }else{
      this.cargarDatos();
    }
    if(!onPermissionsUser('contacts.xhtml', 'view')) {
      Swal.fire({
        title: 'No tienes los permisos para acceder a esta página.',
        buttonsStyling: false,
        customClass: {
          confirmButton: 'btn btn-celcom-orange',
        },
        type: 'error',
        icon: 'error'
      }).then(function() {
        window.location.href="/dashboard";
      })

    }
  },
  created() {
  },
  methods: {
    openModal(lista) {
      this.contactGroup = lista;
      this.modals.modalVisible = true;
    },
    editarListaModal(lista) {
      this.contactGroup = lista;
      this.modals.modalEditar = true;
    },
    async editarLista() {
      await this.$store.getters.edit_contanct(this.contactGroup);
      this.closeModal();
      this.cargarDatos();
    },
    closeModal() {
      this.modals.modalVisible = false;
      this.modals.modalEditar = false;
      this.modals.agregarContacto = false;
    },
    cambiarNomb(){
      this.cambiarNombre = !this.cambiarNombre;
    },
    openModalEditar(lista){
      if (this.tabContact) {
        this.contactSingle = lista;
      }
      this.modals.modalEditar = true;
    },
    abrirAgregar() {
      let self = this;
      self.validForm.field="";
      self.modals.agregarContacto = true;

    },
    async deleteLista() {
      this.$refs.loader.showLoader();
      await this.$store.getters.delete_contanct(this.contactGroup);
      this.closeModal();
      this.cargarDatos();
      this.$refs.loader.hideLoader();
    },
    async borrarContacto() {

      this.$refs.loader.showLoader();

      let self = this;
      //let user = (getUserSub()!=null) ? getUserSub() :getUser();

      let js = {
        "time_expired":horaAhora(),
        "contact_group":encodeURIComponent(JSON.stringify(this.contactGroup)),
        "contact":encodeURIComponent(JSON.stringify(this.contactSingle)),
      }

      var body = new URLSearchParams(); body.append('TOKEN', (encryptAES(js)));

      await contact_delete_contact(body)
          .then(function (response) {
            console.log(response)

            if (response.data.response === "200") {
              console.log(response.data.data);
              self.closeModal();
              self.tableData = response.data.data;

            } else {
              console.log("No se pudo borrar contacto");
              Swal.fire({
                title: 'No se pudo borrar contacto',
                buttonsStyling: false,
                customClass: {
                  confirmButton: 'btn btn-celcom-orange',
                },
                type: 'error',
                icon: 'error'
              })
            }

            self.$refs.loader.hideLoader();

          })
          .catch(function (error) {
            console.log("No se pudo borrar contacto",error);
            Swal.fire({
              title: 'No se pudo borrar contacto',
              buttonsStyling: false,
              customClass: {
                confirmButton: 'btn btn-celcom-orange',
              },
              type: 'error',
              icon: 'error'
            })
            self.$refs.loader.hideLoader();
          });

      self.$refs.loader.hideLoader();

    },
    async guardarEdicion() {

      this.$refs.loader.showLoader();

      let self = this;
      let user = (getUserSub()!=null) ? getUserSub() :getUser();

      let js = {
        "time_expired":horaAhora(),
        "contact_group":encodeURIComponent(JSON.stringify(this.contactGroup)),
        "contact":encodeURIComponent(JSON.stringify(this.contactSingle)),
        "customer":encodeURIComponent(JSON.stringify(user.customer)),
      }

      if (self.contactSingle.phone === "") {
        self.validForm.field+=",PhoneContact"; self.validForm.estado=true;

        self.$refs.loader.hideLoader();

        return false;

      } else if ( (user.customer.country.name === "Colombia" && self.contactSingle.phone.toString().length !== 9) || (user.customer.country.name === "Chile" && self.contactSingle.phone.toString().length !== 8) ) {

        self.validForm.field+=",PhoneContact"; self.validForm.estado=true;

        self.$refs.loader.hideLoader();

        return false;

      } else {

        var body = new URLSearchParams(); body.append('TOKEN', (encryptAES(js)));

        await contact_edit_contact(body)
            .then(function (response) {
              console.log(response)

              if (response.data.response === "200") {
                console.log(response.data.data);

                self.tableData = response.data.data;
                self.modalEditar = false;

                Swal.fire({
                  title: 'Contacto editado con éxito',
                  buttonsStyling: false,
                  customClass: {
                    confirmButton: 'btn btn-celcom-blue',
                  },
                  type: 'success',
                  icon: 'success'
                })
                self.closeModal();
              } else {
                console.log("No se pudo editar contacto");
                Swal.fire({
                  title: 'No se pudo editar contacto',
                  buttonsStyling: false,
                  customClass: {
                    confirmButton: 'btn btn-celcom-orange',
                  },
                  type: 'error',
                  icon: 'error'
                })
              }

              self.$refs.loader.hideLoader();

            })
            .catch(function (error) {
              console.log("No se pudo editar contacto",error);
              Swal.fire({
                title: 'No se pudo editar contacto',
                buttonsStyling: false,
                customClass: {
                  confirmButton: 'btn btn-celcom-orange',
                },
                type: 'error',
                icon: 'error'
              })
              self.$refs.loader.hideLoader();
            });

      }

    },
    async guardarNuevo() {

      this.$refs.loader.showLoader();

      let self = this;
      let user = (getUserSub()!=null) ? getUserSub() :getUser();

      let nuevoContacto = {
        "phoneCode": this.validForm.CodePhoneContact,
        "phone": this.validForm.PhoneContact,
        //"contactGroup": this.contactGroup,
        "field": "usuario1|21|23500||||||||||",
        "status": true,
        "selected": false
      };

      let js = {
        "time_expired":horaAhora(),
        "contact_group":encodeURIComponent(JSON.stringify(this.contactGroup)),
        "contact":encodeURIComponent(JSON.stringify(nuevoContacto)),
        "customer":encodeURIComponent(JSON.stringify(user.customer)),
      }

      if (self.validForm.PhoneContact === "") {
        self.validForm.field+=",PhoneContact"; self.validForm.estado=true;

        self.$refs.loader.hideLoader();

        return false;

      } else if ( (user.customer.country.name === "Colombia" && self.validForm.PhoneContact.toString().length !== 9) || (user.customer.country.name === "Chile" && self.validForm.PhoneContact.toString().length !== 8) ) {

        self.validForm.field+=",PhoneContact"; self.validForm.estado=true;

        self.$refs.loader.hideLoader();

        return false;

      } else {

        var body = new URLSearchParams(); body.append('TOKEN', (encryptAES(js)));

        await contact_add_contact(body)
            .then(function (response) {
              console.log(response)

              if (response.data.response === "200") {
                console.log(response.data.data);

                self.tableData = response.data.data;
                self.modals.agregarContacto = false;

                Swal.fire({
                  title: '¡Contacto agregado!',
                  buttonsStyling: false,
                  customClass: {
                    confirmButton: 'btn btn-celcom-blue',
                  },
                  type: 'success',
                  icon: 'success'
                })

              } else {
                console.log("No se pudo crear contacto");
                Swal.fire({
                  title: 'No se pudo crear contacto',
                  buttonsStyling: false,
                  customClass: {
                    confirmButton: 'btn btn-celcom-orange',
                  },
                  type: 'error',
                  icon: 'error'
                })
              }

              self.$refs.loader.hideLoader();

            })

            .catch(function (error) {
              console.log("No se pudo crear contacto",error);
              Swal.fire({
                title: 'No se pudo crear contacto',
                buttonsStyling: false,
                customClass: {
                  confirmButton: 'btn btn-celcom-orange',
                },
                type: 'error',
                icon: 'error'
              })
              self.$refs.loader.hideLoader();
            });

        self.$refs.loader.hideLoader();

      }

    },
    async guardarEdicionGroupName() {

      this.$refs.loader.showLoader();

      let self = this;
      let user = (getUserSub()!=null) ? getUserSub() :getUser();

      let js = {
        "time_expired":horaAhora(),
        "contact_group":encodeURIComponent(JSON.stringify(this.contactGroup)),
        "customer":encodeURIComponent(JSON.stringify(user.customer)),
      }

      var body = new URLSearchParams(); body.append('TOKEN', (encryptAES(js)));

      await contact_edit_contact_group(body)
          .then(function (response) {

            if (response.data.response === "200") {

              self.cambiarNombre = false;

            } else {
              console.log("No se pudo editar nombre lista");
              Swal.fire({
                title: 'No se pudo editar nombre lista',
                buttonsStyling: false,
                customClass: {
                  confirmButton: 'btn btn-celcom-orange',
                },
                type: 'error',
                icon: 'error'
              })
            }

            self.$refs.loader.hideLoader();

          })
          .catch(function (error) {
            console.log("No se pudo editar nombre lista",error);
            Swal.fire({
              title: 'No se pudo editar nombre lista',
              buttonsStyling: false,
              customClass: {
                confirmButton: 'btn btn-celcom-orange',
              },
              type: 'error',
              icon: 'error'
            })
            self.$refs.loader.hideLoader();
          });

    },
    cargarDatos(){
      //this.obtenerLista();
      this.obtenerContactosCamp();
      this.$store.getters.arrayCabeceras();
    },
   /* async obtenerLista(){
      try {
        let user = (getUserSub()!=null) ? getUserSub() :getUser();

        let js = {
          "time_expired":horaAhora(),
          "customer_user":encodeURIComponent(JSON.stringify(user)),
          "customer":encodeURIComponent(JSON.stringify(user.customer))
        }

        var body = new URLSearchParams();
        body.append("TOKEN", encryptAES(js));


        const response = await listaContactosCamp(body);
        // Almacena la respuesta en la variable de datos
        if (response.data.response === "200") {

          this.response = response.data.data;
        } else {
          console.log("No se encontró lista");

        }
      } catch (error) {
        console.error("Error al obtener datos", error);
      }
    },*/
    async obtenerContactosCamp(){
      try {
        let user = (getUserSub()!=null) ? getUserSub() :getUser();

        let js = {
          "time_expired":horaAhora(),
          "customer_user":encodeURIComponent(JSON.stringify(user)),
          "customer":encodeURIComponent(JSON.stringify(user.customer))
        }

        var body = new URLSearchParams();
        body.append("TOKEN", encryptAES(js));


        const response = await listaContactosCamp(body);
        // Almacena la respuesta en la variable de datos
        if (response.data.response === "200") {
          this.tabContact = false;
          this.responseContact = response.data.data;
          this.responseContact = this.listaData.filter(lista => lista.isFlex === true);
        } else {
          console.log("No se encontró lista");

        }
      } catch (error) {
        console.error("Error al obtener datos", error);
      }
    },
    findCustomerByContactGroupId(targetContactGroupId) {
      this.foundCustomer = this.responseContact.find(
          (customer) => customer.contactGroupId === targetContactGroupId
      );

      if (this.foundCustomer) {
        const { name, validos } = this.foundCustomer;
        return { name, validos };
        // Realiza otras acciones con el Customer encontrado si es necesario.
      } else {
        console.log("No se encontró Customer con contactGroupId:", targetContactGroupId);
        return false;
      }
    },
  },
};
</script>
<style scoped>
/* Agrega estilos según tus necesidades */
.modal {
  display: none;
}

.modal.is-active {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
}

.modal-close {
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
  background: none;
  border: none;
}
.buttons {
  margin: 8px;
}
</style>