// data.js
export const llenarDatosContacto = (id, name, rut, email, empresa, msisdn, tag, birthdate, gender, country, sourceId) => {

    const jsonDataContacto =
        {
            "contactId": id,
            "contactName": name,
            "taxId": rut,
            "customerSegmentId": null,
            "customerTag": tag,
            "email": email,
            "empresa": empresa,
            "msisdn": msisdn,
            "birthDate": birthdate,
            "gender": gender,
            "country": country,
            "sourceId": sourceId
        };
    return jsonDataContacto;
};