<template>
  <div
      class="card multisteps-form__panel p-3 border-radius-xl bg-white"
      data-animation="FadeIn"
  >
    <p class="mb-0 text-sm">Información obligatoria</p>
    <div class="multisteps-form__content">
      <div class="col-12 col-sm-6">
        <label>Nombre</label>
        <argon-input
            id="segmentAdd"
            class="multisteps-form__input"
            type="text"
            placeholder="nuevo segmento"
            v-model="name"
            required
            pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
        />
        <div v-if="!SoloLetras" :class="{ 'is-invalid-d': !SoloLetras}">
          <p class="mb-0 text-sm">Ingrese un segmento válida</p>
        </div>
      </div>

      <div class="button-row d-flex mt-2">
        <argon-button
            :disabled="!(SoloLetras)"
            type="button"
            color="dark"
            variant="gradient"
            class="ms-auto js-btn-next"
            @click="$parent.addSegment"
        >Agregar</argon-button>
      </div>
    </div>
  </div>
</template>


<script>
import ArgonInput from "@/components/ArgonInput.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import { soloLetras } from "@/config/validaciones"
export default {
  name: "SegmentoInfo",
  components: {
    ArgonInput,
    ArgonButton,
  },
  computed: {
    SoloLetras() {
      return soloLetras(this.name);
    },
  },
  data() {
    return {
      name: '',
    };
  },
};
</script>
<style scoped>
@import "@/assets/css/validaciones.css";
</style>