<template>
  <div class="card">
    <div class="card-header border-1 d-flex justify-content-between align-items-center flex-column flex-md-row">
      <h6 class="mb-md-0">Plantillas - Disponibles - WhatSapp</h6>
      <div class="d-flex">
        <button
            class="btn btn-celcom-orange mb-0"
            data-toggle="tooltip"
            data-original-title="Agregar Plantilla"
            @click.prevent="$parent.openModalAdd()"
        >
          <i class="fas fa-plus"></i> Nueva Plantilla
        </button>
      </div>
    </div>

    <!-- Agrega un campo de entrada para el buscador -->
    <div class="pb-0 card-header mb-0">
      <input v-model="search" type="text" class="form-control mt-2" placeholder="Buscar...">
    </div>
    <div class="card-body table-responsive">
      <table class="table align-items-center mb-0">
        <thead>
        <tr>
          <th
              class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
          >
            Nombre
          </th>
          <th
              class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
          >
            Categoria
          </th>
          <th
              class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
          >
            Ejemplo
          </th>
          <th
              class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
          >
            Estado
          </th>
          <th class="text-center text-secondary opacity-7"></th>
        </tr>
        </thead>
        <tbody>
        <!-- Utiliza v-for para recorrer el arreglo y generar filas -->
        <tr v-for="(template, index) in $store.state.datosPaginados" :key="index">
          <td>
            <div class="text-center">
              <h6 class="mb-0 text-celcom-blue text-xs">{{ template.templateName }}</h6>
            </div>
          </td>
          <td>
            <div class="text-center">
              <h6 class="mb-0 text-xs">{{ template.category }}</h6>
            </div>
          </td>
          <td class="px-4">
            <p class="text-wrap fw-bold text-xs bg-light p-2 rounded-2">
              {{  template.bodyExample }}
            </p>
          </td>
          <td>
            <span :class="'badge '+ tipoEstado(template.statusWhatsapp).class">
              {{  tipoEstado(template.statusWhatsapp).text }}
            </span>
          </td>
          <td class="align-middle text-center text-sm">
<!--            <button
                class="btn btn-info mb-0 me-2"
                data-toggle="tooltip"
                data-original-title="Edit user"
                @click.prevent="$parent.openModalEditar(template)"
            >
              <img src="../../../assets/iconos/pen-to-square-solid.svg" alt="Cesto de basura" class="icono-borrar">
            </button>-->
            <button
                class="btn btn-danger mb-0"
                data-toggle="tooltip"
                data-original-title="Eliminar user"
                @click.prevent="$parent.openModalEliminarTemplate(template)"
            >
              <i class="fas fa-duotone fa-trash"></i>
            </button>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <BasePagination :filtered-data="filteredData" />
  </div>
</template>

<script>

import BasePagination from "@/components/BasePagination.vue";

export default {
  name: "WhatsappTemplateTable",
  components: {
    BasePagination,
  },
  props: {
    templateData: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      modalVisible: false,
      search: "", // Variable para almacenar la entrada del buscador
    };
  },
  computed: {
    // Utiliza una propiedad computada para filtrar los datos según la búsqueda
    filteredData() {
      if (!Array.isArray(this.$store.state.LIST_TEMPLATE_WHATSPP_BY_CUSTOMER)) {
        return [];
      }
      return this.$store.state.LIST_TEMPLATE_WHATSPP_BY_CUSTOMER.filter(perfil => {
        const name = perfil.templateName ?? '';
        const category = perfil.category ?? '';
        const bodyExample = perfil.bodyExample;
        
        return (
            name.toLowerCase().includes(this.search.toLowerCase()) ||
            category.toLowerCase().includes(this.search.toLowerCase()) ||
            bodyExample.toLowerCase().includes(this.search.toLowerCase())
        );
      });
    },
  },
  watch: {
    filteredData() {
      this.$store.getters.getDataPaginated(this.$store.state.paginaActual, this.filteredData);
    },
  },
  methods: {
    tipoEstado(estado) {
      switch (estado) {
        case 'PENDING':
          return {
            text: 'PENDIENTE',
            class: 'badge-light'
          };
        case 'APPROVED':
          return {
            text: 'APROBADO',
            class: 'badge-success'
          };
        default:
          return {
            text: 'RECHAZADO',
            class: 'badge-danger'
          };
      }
    },
    openModal() {
      this.modalVisible = true;
    },
    closeModal() {
      this.modalVisible = false;
    },
  },
};
</script>

<style scoped>
.btn-plantilla{
  color: #fff;
  background-color: #e64477;
  border-color: #e64477;
}
/* Agrega estilos según tus necesidades */
.icono-rojo {
  color: red;
}
.modal {
  display: none;
}

.modal.is-active {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
}

.modal-close {
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
  background: none;
  border: none;
}
.buttons {
  margin: 8px;
}
</style>