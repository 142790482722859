<template>
  <div
      class="card multisteps-form__panel border-radius-xl bg-white"
      data-animation="FadeIn"
  >
    <div class="card-header border-1">
      <h5 class="font-weight-bolder mb-0">Envío de Correo</h5>
      <p class="mb-0 text-sm">Fecha de Envío</p>
    </div>
    <div class="card-body multisteps-form__content">
      <div class="row mt-3 ms-1">
        <div class="col-12 col-sm-6 form-check">
          <input
              id="ahoraEmail"
              v-model="seleccionProgramacion"
              type="radio"
              name="programacion"
              value="ahora"
              class="form-check-input"
          />
          <label for="ahoraEmail">Ahora</label>
        </div>
        <div class="col-12 col-sm-6 form-check">
          <input
              id="programarEmail"
              v-model="seleccionProgramacion"
              type="radio"
              name="programacion"
              value="programar"
              class="form-check-input"
          />
          <label for="programarEmail">Programar</label>
          <div :style="{ 'pointer-events': (seleccionProgramacion === 'programar') ? 'auto' : 'none' }">
            <argon-input
                id="datetime-local"
                v-model="fecha"
                type="datetime-local"
                class="multisteps-form__input"
                placeholder="Selecciona una fecha"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="card-footer border-1 mt-4 d-flex">
      <argon-button
        type="button"
        color="light"
        variant="gradient"
        class="js-btn-prev"
        @click="$parent.prevStep"
      ><i class="fas fa-chevron-left me-2"></i> Anterior
      </argon-button>
      <argon-button
        type="button"
        color="celcom"
        variant="gradient"
        class="text-white ms-auto mb-0 js-btn-next"
        @click="nextStepValor"
      >Siguiente <i class="fas fa-chevron-right ms-2"></i>
      </argon-button>
    </div>
  </div>
</template>

<script>
import ArgonButton from "@/components/ArgonButton.vue";
import ArgonInput from "@/components/ArgonInput.vue";
import {formatoFecha} from "@/config/config";

export default {
  name: "ProgramadaEditEmailAdd3",
  components: {
    ArgonInput,
    ArgonButton,
  },
  props: {
    sendingEditar: {
      type: Object,
      default: () => ({}),
    },
    editando: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['updateValues3'],
  data() {
    return {
      fecha: "",
      seleccionProgramacion: "ahora",
      rut: "",
      selectedSource: "",
      selectedTag: "", // Nuevo dato para almacenar la opción seleccionada
      tags: "",       // Nuevo dato para almacenar los tags seleccionados
    };
  },
  watch: {
    sendingEditar: {
      handler(newValidForm) {
        if (newValidForm) {
          if (newValidForm.calendar !== null) {
            this.fecha = this.fecha_Formato(newValidForm.calendar);
            //this.seleccionProgramacion = "programar";
          }
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    fecha_Formato(fecha) {
      return formatoFecha(fecha);
    },
   async nextStepValor() {
      // Puedes emitir un evento para notificar al componente padre sobre los cambios
      this.$emit('updateValues3', {
        fecha: this.fecha,
      });
     if (this.$store.state.seleccion === 'Email') {
        await this.$parent.envioEmail();
      }
    },
  },
};
</script>
