<template>
  <div class="card">
    <div class="card-header pb-0">
      <div class="row">
        <h6 class="col-md-4 col-lg-5 col-xl">Reporte Detallado</h6>
        <div class="d-flex flex-column flex-md-row col-md-8 justify-content-end">
          <button
              class="btn btn-gradient-dark m-0"
              data-toggle="tooltip"
              data-original-title="Agregar Contacto"
              @click.prevent="goBack"
          >
            <i class="fas fa-arrow-left me-2"></i>Volver
          </button>
        </div>
      </div>
    </div>
    <div class="card-body">
      <div class="row align-items-stretch">
        <div v-for="(row, index) in dataDoughnutCemi" :key="index" class="col-6">
          <GradientDoughnutChart
              id="'chart-doughnut-cemi'"
              class="borde-bar"
              :chart="row"
              :rotation="-90"
              :circumference="180"
          />
        </div>
        <div v-if="dataCard" class="col-3">
          <CardReport
              :data="dataCard.find(item => item.title === 'Total Cargados')|| {}"
          />
          <CardReport
              :data="dataCard.find(item => item.title === 'Fallidos')|| {}"
          />
          <CardReport
              :data="dataCard.find(item => item.title === 'Por Entregar')|| {}"
          />
        </div>
        <div v-if="dataCard" class="col-3">
          <CardReport
              :data="dataCard.find(item => item.title === 'Clics de Usuarios') || {}"
          />
          <CardReport
              :data="dataCard.find(item => item.title === 'Clics Únicos')|| {}"
          />
        </div>
      </div>
      <div class="row align-items-stretch">
        <div class="col-8">
          <gradient-line-chart
              v-if="dataLine"
              id="chart-line"
              :key="dataLine"
              class="borde-bar"
              :chart="dataLine"
          />
        </div>
<!--        <div v-if="dataCard" class="col-4">
          <CardReport
              :data="dataCard.find(item => item.title === 'Detallado uno') || {}"
          />
          <CardReport
              :data="dataCard.find(item => item.title === 'Detallado dos')|| {}"
          />
        </div>-->
      </div>
      <div class="row">
        <div v-for="(row, index) in dataDoughnut" :key="index" class="col-4">
          <GradientDoughnutChart
              class="borde-bar"
              :id="'chart-doughnut-' + index"
              :chart="row"
          />
        </div>
      </div>
    </div>
    <Loader ref="loader"/>
  </div>
</template>

<script>
import {
  report_ejecutivo_grafica_doughnut_detallado,
  report_ejecutivo_grafica_doughnut_cemi,
  report_ejecutivo_cards_detallado, report_ejecutivo_grafica_line_detallada
} from "@/config/servicios/campana/envios/Reportes";
import {
  encryptAES,
  getUser,
  getUserSub, horaAhora,
} from "@/config/servicios/campana/util";
import Swal from "sweetalert2";
import Loader from "@/components/Modal/Loader.vue";
import GradientDoughnutChart from "@/examples/Charts/GradientDoughnutChart.vue";
import CardReport from "@/views/envios/Reportes/components/CardReport.vue";
import GradientLineChart from "@/examples/Charts/GradientLineChart.vue";
export default {
  name: "ReportesDetalladoTable",
  components: {
    GradientDoughnutChart,
    Loader,
    CardReport,
    GradientLineChart,
  },
  props: {

  },
  data() {
    return {
      dataCard: null,
      dataLine: null,
      dataDoughnut: null,
      dataDoughnutCemi: null,
    };
  },
  mounted() {
    this.cargarDatos();
  },
  methods: {
    goBack() {
      this.$router.go(-1); // Regresa a la página anterior
      // O puedes usar:
      // this.$router.back();
    },
    async cargarDatos(){
      await this.obtenerGraficosLine();
      await this.obtenerGraficosDoughnut();
      await this.obtenerGraficosDoughnutCemi();
      await this.obtenerCards();
    },
    async obtenerGraficosLine() {

      this.$refs.loader.showLoader();

      let self = this;
      let user = (getUserSub()!=null) ? getUserSub() :getUser();

      let js = {
        "sending_sms": encodeURIComponent(JSON.stringify(this.$store.state.reporteDetallado.row)),
        "customer": encodeURIComponent(JSON.stringify(user.customer)),
        "time_expired":horaAhora(),
      }

      var body = new URLSearchParams(); body.append('TOKEN', (encryptAES(js)));

      await report_ejecutivo_grafica_line_detallada(body)
          .then(function (response) {

            if (response.data.response === "200") {
              self.dataLine = response.data.data[0];
            } else {
              console.log("No se encontraron registros1");
              Swal.fire({
                title: 'No se encontraron registros',
                buttonsStyling: false,
                customClass: {
                  confirmButton: 'btn btn-celcom-orange',
                },
                type: 'warning',
                icon: 'warning'
              })
            }

            self.$refs.loader.hideLoader();

          })
          .catch(function (error) {
            console.log("No se encontraron registros2",error);
            Swal.fire({
              title: 'No se encontraron registros',
              buttonsStyling: false,
              customClass: {
                confirmButton: 'btn btn-celcom-orange',
              },
              type: 'warning',
              icon: 'warning'
            })
            self.$refs.loader.hideLoader();
          });

    },
    async obtenerGraficosDoughnut() {

      this.$refs.loader.showLoader();

      let self = this;
      let user = (getUserSub()!=null) ? getUserSub() :getUser();

      let js = {
        "sending_sms": encodeURIComponent(JSON.stringify(this.$store.state.reporteDetallado.row)),
        "customer": encodeURIComponent(JSON.stringify(user.customer)),
        "time_expired":horaAhora(),
      }

      var body = new URLSearchParams(); body.append('TOKEN', (encryptAES(js)));

      await report_ejecutivo_grafica_doughnut_detallado(body)
          .then(function (response) {

            if (response.data.response === "200") {
              self.dataDoughnut = response.data.data;
            } else {
              console.log("No se encontraron registros1");
              Swal.fire({
                title: 'No se encontraron registros',
                buttonsStyling: false,
                customClass: {
                  confirmButton: 'btn btn-celcom-orange',
                },
                type: 'warning',
                icon: 'warning'
              })
            }

            self.$refs.loader.hideLoader();

          })
          .catch(function (error) {
            console.log("No se encontraron registros2",error);
            Swal.fire({
              title: 'No se encontraron registros',
              buttonsStyling: false,
              customClass: {
                confirmButton: 'btn btn-celcom-orange',
              },
              type: 'warning',
              icon: 'warning'
            })
            self.$refs.loader.hideLoader();
          });

    },
    async obtenerGraficosDoughnutCemi() {

      this.$refs.loader.showLoader();

      let self = this;
      let user = (getUserSub()!=null) ? getUserSub() :getUser();

      let js = {
        "sending_sms": encodeURIComponent(JSON.stringify(this.$store.state.reporteDetallado.row)),
        "customer": encodeURIComponent(JSON.stringify(user.customer)),
        "time_expired":horaAhora(),
      }

      var body = new URLSearchParams(); body.append('TOKEN', (encryptAES(js)));

      await report_ejecutivo_grafica_doughnut_cemi(body)
          .then(function (response) {
            if (response.data.response === "200") {
              self.dataDoughnutCemi = response.data.data;
            } else {
              console.log("No se encontraron registros1");
              Swal.fire({
                title: 'No se encontraron registros',
                buttonsStyling: false,
                customClass: {
                  confirmButton: 'btn btn-celcom-orange',
                },
                type: 'warning',
                icon: 'warning'
              })
            }

            self.$refs.loader.hideLoader();

          })
          .catch(function (error) {
            console.log("No se encontraron registros2",error);
            Swal.fire({
              title: 'No se encontraron registros',
              buttonsStyling: false,
              customClass: {
                confirmButton: 'btn btn-celcom-orange',
              },
              type: 'warning',
              icon: 'warning'
            })
            self.$refs.loader.hideLoader();
          });

    },
    async obtenerCards() {

      this.$refs.loader.showLoader();

      let self = this;
      let user = (getUserSub()!=null) ? getUserSub() :getUser();

      let js = {
        "sending_sms": encodeURIComponent(JSON.stringify(this.$store.state.reporteDetallado.row)),
        "customer": encodeURIComponent(JSON.stringify(user.customer)),
        "time_expired":horaAhora(),
      }

      var body = new URLSearchParams(); body.append('TOKEN', (encryptAES(js)));

      await report_ejecutivo_cards_detallado(body)
          .then(function (response) {
            if (response.data.response === "200") {
              self.dataCard = response.data.data.map(item => ({
                title: item.title,
                value: item.value.toLocaleString(), // Formatea el número con comas
                porcentaje: item.porcentaje,
              }));
            }else {
              console.log("No se encontraron registros1");
              Swal.fire({
                title: 'No se encontraron registros',
                buttonsStyling: false,
                customClass: {
                  confirmButton: 'btn btn-celcom-orange',
                },
                type: 'warning',
                icon: 'warning'
              })
            }

            self.$refs.loader.hideLoader();

          })
          .catch(function (error) {
            console.log("No se encontraron registros2",error);
            Swal.fire({
              title: 'No se encontraron registros',
              buttonsStyling: false,
              customClass: {
                confirmButton: 'btn btn-celcom-orange',
              },
              type: 'warning',
              icon: 'warning'
            })
            self.$refs.loader.hideLoader();
          });

    },
  },
};
</script>

<style scoped>
.label-map {
  font-size: 1.0rem;
  color: #333;
}
.map-estilo {
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}
.borde-bar {
  margin: 8px;
}
.borde-bar-1 {
  margin: 5px;
}
.btn-plantilla{
  color: #fff;
  background-color: #e64477;
  border-color: #e64477;
}
.buttons {
  margin: 8px;
}
.tooltip-container {
  position: relative;
  display: inline-block;
}

.tooltip {
  position: absolute;
  bottom: 100%; /* Coloca el tooltip arriba del contenido */
  left: 50%;
  transform: translateX(-50%);
  padding: 5px;
  background-color: #333;
  color: #fff;
  border-radius: 3px;
  font-size: 12px;
  opacity: 0;
  transition: opacity 0.3s ease;
  pointer-events: none; /* Evita que el tooltip sea clickeable */
}

.tooltip-container:hover .tooltip {
  opacity: 1;
}
</style>