<template>
  <div class="card z-index-2">
    <div class="pb-0 card-header mb-0">
      <h6>{{ title }}</h6>
      <!--  eslint-disable-next-line vue/no-v-html -->
      <p v-if="description" class="text-sm" v-html="description" />
    </div>
    <div class="p-3 card-body">
      <div class="chart">
        <canvas :id="id" class="chart-canvas" :height="height"></canvas>
      </div>
    </div>
  </div>
</template>

<script>
import Chart from "chart.js/auto";

export default {
  name: "GradientLineChart",
  props: {
    id: {
      type: String,
      required: true,
    },
    height: {
      type: String,
      default: "300",
    },
    title: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
    chart: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    const gradientLineChart = document.getElementById(this.id).getContext("2d");

    const gradientStrokes = [];
    const datasets = [];
    // Definir gradientes de color para cada conjunto de datos
    for (let i = 0; i < this.chart.datasets.length; i++) {
      const borderColor = this.getBorderColor(i); // Obtiene el color del borde en función del índice
      const gradientStroke = gradientLineChart.createLinearGradient(0, 230, 0, 50);
      gradientStroke.addColorStop(1, `rgba(68,128,255,0.2)`);
      gradientStroke.addColorStop(0.2, `rgba(72,72,176,0.0)`);
      gradientStroke.addColorStop(0, `rgba(203,12,159,0)`);
      gradientStrokes.push(gradientStroke);

      datasets.push({
        label: this.chart.datasets[i].label,
        tension: 0.4,
        pointRadius: 0,
        borderColor: borderColor, // Color de borde diferente para cada conjunto de datos
        backgroundColor: gradientStroke,
        borderJoinStyle: "round",
        borderWidth: 3,
        fill: true,
        data: this.chart.datasets[i].data,
        maxBarThickness: 6,
      });
    }

    let chartStatus = Chart.getChart(this.id);
    if (chartStatus != undefined) {
      chartStatus.destroy();
    }

    // Crear el gráfico con los conjuntos de datos dinámicamente construidos
    new Chart(gradientLineChart, {
      type: "line",
      data: {
        labels: this.chart.labels,
        datasets: datasets,
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: true,
            position: "bottom",
            align: "center",
            labels: {
              boxHeight: 8,
              usePointStyle: true,
              pointStyle: "circle",
              padding: 15
            }
          },
        },
        interaction: {
          intersect: false,
          mode: "index",
        },
        scales: {
          y: {
            grid: {
              drawBorder: false,
              display: true,
              drawOnChartArea: true,
              drawTicks: false,
              borderDash: [5, 5],
            },
            ticks: {
              display: true,
              padding: 10,
              color: "#fbfbfb",
              font: {
                size: 11,
                family: "Open Sans",
                style: "normal",
                lineHeight: 2,
              },
            },
          },
          x: {
            grid: {
              drawBorder: false,
              display: false,
              drawOnChartArea: false,
              drawTicks: false,
              borderDash: [5, 5],
            },
            ticks: {
              display: true,
              color: "#ccc",
              padding: 20,
              font: {
                size: 11,
                family: "Open Sans",
                style: "normal",
                lineHeight: 2,
              },
            },
          },
        },
      },
    });
  },
  methods: {
    getBorderColor(index) {
      switch (index) {
        case 0:
          return "#0033A0"; // Primer color
        case 1:
          return "#207c20"; // Segundo color
        case 2:
          return "#ff33e4"; // Tercer color
        default:
          return "#000000"; // Color por defecto
      }
    },
  },
};

</script>
