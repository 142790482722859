<template>
  <div class="card">
      <div class="pb-0 card-header mb-0 d-flex justify-content-between align-items-center">
        <h6 class="mb-0">Lista de Canales</h6>
        <div class="d-flex">
          <button
              :disabled="true"
              v-if="tipoUsuario"
              class="btn font-weight-bold"
              data-toggle="tooltip"
              data-original-title="Edit user"
              @click.prevent="agregar"
          >
            <i class="fas fa-plus"></i> Agregar
          </button>
        </div>
      </div>
      <!-- Agrega un campo de entrada para el buscador -->
    <div class="pb-0 card-header mb-0">
      <input v-model="search" type="text" class="form-control mt-2" placeholder="Buscar...">
    </div>
    <div class="table-responsive">
      <table class="table align-items-center mb-0">
        <thead>
          <tr>
            <th
              class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
            >
              Nombre del Canal
            </th>
            <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
            >
              Estado
            </th>
            <th class="text-secondary opacity-7"></th>
          </tr>
        </thead>
        <tbody>
                <!-- Utiliza v-for para recorrer el arreglo y generar filas -->
                <tr v-for="(canal, index) in filteredData" :key="index">
                  <td>
                    <div class="d-flex px-2 py-1">
                      <div>
                        <img
                            src="@/assets/img/icons/canal/canal.png"
                            class="avatar avatar-sm me-3"
                        />
                      </div>
                      <div class="d-flex flex-column justify-content-center">
                        <h6 class="mb-0 text-xs">{{ canal.channelName }}</h6>
                        <p class="text-xs text-secondary mb-0">{{ canal.active ? "Activo" : "No activo" }}</p>
                      </div>
                    </div>
                  </td>
                  <td
                      @click.prevent="$parent.openModalEditarCanalUser(canal.channelId, isCanalActive(canal.channelId))"
                      :style="{ cursor: isCanalInUserList(canal.channelId) ? 'pointer' : 'default' }"
                  >
              <span class="badge badge-dot" v-if="isCanalInUserList(canal.channelId)">
                <i :class="{ 'bg-info': isCanalActive(canal.channelId), 'bg-dark': !isCanalActive(canal.channelId) }"></i>
                <span class="text-dark text-xs">{{isCanalActive(canal.channelId) ? "Activo" : "No activo"}}</span>
              </span>
                  </td>
                  <td v-if="tipoUsuario" class="align-middle text-center text-sm">
                      <button
                          class="btn font-weight-bold"
                          data-toggle="tooltip"
                          data-original-title="Edit user"
                          @click.prevent="$parent.openModalEditarCanal(canal)"
                      >
                        <i class="fas fa-pencil-alt me-1"></i> <!-- Ícono de lápiz -->
                      </button>
                    <button
                        v-if="tipoUser !== 'admin'"
                        class="btn font-weight-bold"
                        data-toggle="tooltip"
                        data-original-title="Eliminar user"
                        @click.prevent="$parent.openModal(canal.channelId)"
                    >
                      <i class="fas fa-minus-circle icono-rojo"></i> <!-- Ícono de menos para eliminar -->
                    </button>
                  </td>
                  <td v-if="!tipoUsuario" class="align-middle text-center text-sm">
                    <button
                        :disabled="isCanalInUserList(canal.channelId)"
                        class="btn font-weight-bold"
                        data-toggle="tooltip"
                        data-original-title="Edit user"
                        @click.prevent="$parent.asignarCanal(canal.channelId)"
                    >
                      <i class="fas fa-check me-1"></i>
                      <!-- Ícono de la palomita (check) -->
                    </button>
                    <button
                        :disabled="!isCanalInUserList(canal.channelId)"
                        class="btn font-weight-bold"
                        data-toggle="tooltip"
                        data-original-title="Eliminar user"
                        @click.prevent="$parent.quitarCanal(canal.channelId)"
                    >
                      <i class="fas fa-minus-circle icono-rojo"></i> <!-- Ícono de menos para eliminar -->
                    </button>
                  </td>
                </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { tipoUser } from "@/config/config";

export default {
  name: "CanalTable",
  props: {
    tipoUser: {
      type: String,
      required: true,
    },
    canalData: {
      type: Array,
      required: true,
    },
    canalDataUser: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      tipoUsuario: null,
      search: "", // Variable para almacenar la entrada del buscador
    };
  },
  computed: {
    // Utiliza una propiedad computada para filtrar los datos según la búsqueda
    filteredData() {
      if (!Array.isArray(this.canalData)) {
        return [];
      }
      return this.canalData.filter(canal => {
        const channelName = canal.channelName ?? '';

        return (
            channelName.toLowerCase().includes(this.search.toLowerCase())
        );
      });
    },
  },
  methods: {
    isCanalInUserList(channelId) {
      return Array.isArray(this.canalDataUser) && this.canalDataUser.some(canal => canal.channel && canal.channel.channelId === channelId);
    },
    isCanalActive(channelId) {
      return Array.isArray(this.canalDataUser) && this.canalDataUser.some(canal => {
        return canal.active && canal.channel && canal.channel.channelId === channelId;
      });
    },
    async tipoUsers() {
      try {
        const response = await tipoUser();
        // Almacena la respuesta en la variable de datos
        this.tipoUsuario = response;
      } catch (error) {
        console.error("Error al obtener datos", error);
      }
    },
    agregar(){
      this.$router.push({ name: "Nuevo Canal" });
      console.log("Agregar");
    },
  },
  mounted() {
    this.tipoUsers();
  }
};
</script>
<style>
.icono-rojo {
  color: red;
}
</style>
