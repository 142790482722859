<template>
  <div class="container-fluid py-4">
    <div class="row mt-4">
      <div class="col-12 col-lg-10 mx-auto mb-4">
        <sender-sms-campanna-table :senderSmsCustomerData="responseSenderSmsCustomer"/>
      </div>
    </div>
    <modal :is-visible="modalAddSender">
      <form>
        <h6 class="heading-small text-muted mb-4">Información de Remitente</h6>
        <label class="form-label">Origen</label>
          <select
              id="opcionesListCont"
              v-model="selectedSender"
              class="multisteps-form__select form-control form-select"
              name="opciones-ListCont"
          >
            <option value="" disabled selected>Elegir número</option>
            <option
                v-for="(sender, index) in responseSenderSms"
                :key="index"
                :value="sender"
            >
              {{ sender.msisdn }}
            </option>
          </select>
          <div class="modal-footer px-0 mt-lg-4 d-flex justify-content-between">
            <button class="btn bg-gradient-celcom-orange mt-2 mb-0" @click.prevent="addSenderSmsCustomer">Agregar</button>
            <button class="btn bg-gradient-dark mt-2 mb-0" @click.prevent="closeModal">Cancelar</button>
          </div>
      </form>
    </modal>
    <modal-delete :is-visible="modalEliminarSender">
      <p class="text-center"><b>¿Estás seguro de que deseas eliminar?</b></p>
      <div class="mt-lg-4 d-flex justify-content-between">
        <button class="btn bg-gradient-danger my-sm-auto mt-2 mb-0" @click="borrarSender">Eliminar</button>
        <button class="btn bg-gradient-dark my-sm-auto mt-2 mb-0" @click="closeModal">Cancelar</button>
      </div>
    </modal-delete>
  </div>
</template>

<script>
import SenderSmsCampannaTable from "./components/SenderSmsCampannaTable.vue";
import Modal from "@/components/Modal/Modal.vue";
import {encryptAES, getUser, getUserSub, horaAhora} from "@/config/servicios/campana/util";
import swal from "sweetalert2";
import ModalDelete from "@/components/Modal/ModalDelete.vue";
import {sender_enable, sender_disable} from "@/config/servicios/campana/sender/servicesSender";

export default {
  name: "SenderSmsCampanna",
  components: {
    ModalDelete,
    Modal,
    SenderSmsCampannaTable,
  },
  data() {
    return {
      responseSenderSms: [],
      responseSenderSmsCustomer: [],
      dataSender: {},
      selectedSender: '',
      modalAddSender: false,
      modalEditSender: false,
      modalEliminarSender: false,
      type: '',
      msisdn: '',
      active: false,
    };
  },
  mounted() {
    this.obtenerSmsSender();
  },
  methods: {
    async obtenerSmsSender(){
      await this.$store.getters.obtenerSenderSms();
      this.responseSenderSms = this.$store.state.responseSenderSms;
      this.responseSenderSmsCustomer = this.$store.state.responseSenderSmsCustomer;
    },
    closeModal() {
      this.modalAddSender = false;
      this.modalEditSender = false;
      this.modalEliminarSender = false;
      this.fromName = '';
      this.emailFrom = '';
    },

    //Inicio de Eliminar un SenderSms
    openModalEliminarSenderCustomer(row) {
      this.dataSender = JSON.parse(JSON.stringify(row));
      this.modalEliminarSender = true;
    },
    async borrarSender()  {
      let self = this;
      let user = getUser();

      let js = {
        "time_expired": horaAhora(),
        "sender": encodeURIComponent(JSON.stringify(self.dataSender)),
        "customer": encodeURIComponent(JSON.stringify(user.customer)),
      }

      var body = new URLSearchParams();
      body.append('TOKEN', (encryptAES(js)));

      await sender_disable(body)
          .then(function (response) {
            if (response.data.response === "200") {
              self.modalEliminarSender = false;
              self.obtenerSmsSender();
            } else {
              console.log("No se pudo borrar remitente");
              swal.fire({
                title: 'No se pudo borrar el remitente',
                buttonsStyling: false,
                customClass: {
                  confirmButton: 'btn btn-celcom-orange',
                },
                type: 'error',
                icon: 'error',
              })
            }

          })
          .catch(function (error) {
            console.log("No se pudo borrar el remitente", error);
            swal.fire({
              title: 'No se pudo borrar el remitente',
              buttonsStyling: false,
              customClass: {
                confirmButton: 'btn btn-celcom-orange',
              },
              type: 'error',
              icon: 'error',
            })
          });
    },
    //Fin de Eliminar un SenderSms

    //Inicio de agregar un SenderSms
    openModalAddSenderCustomer() {
      this.modalAddSender = true;
    },
    async addSenderSmsCustomer() {
      let self = this;
      let user = (getUserSub() != null) ? getUserSub() : getUser();

      if (this.selectedSender === "") {
        await swal.fire({
          title: "Error Debe elegir un remitente",
          buttonsStyling: false,
          customClass: {
            confirmButton: 'btn btn-celcom-orange',
          },
          icon: 'error',
        })
        return false;
      }

      let js = {
        "time_expired": horaAhora(),
        "sender": encodeURIComponent(JSON.stringify(this.selectedSender)),
        "customer": encodeURIComponent(JSON.stringify(user.customer)),
      }
      var body = new URLSearchParams();
      body.append('TOKEN', (encryptAES(js)));

      await sender_enable(body)
          .then(function (response) {
            if (response.data.response === "200") {
              self.modalAddSender = false;
              self.obtenerSmsSender();
              self.selectedSender = '';
              swal.fire({
                title: '¡Remitente agregado con éxito!',
                buttonsStyling: false,
                customClass: {
                  confirmButton: 'btn btn-celcom-blue',
                },
                type: 'success',
                icon: 'success',
              })
            } else {
              console.log("No se pudo agregar el remitente");
              swal.fire({
                title: 'No se pudo agregar el remitente',
                buttonsStyling: false,
                customClass: {
                  confirmButton: 'btn btn-celcom-orange',
                },
                type: 'error',
                icon: 'error',
              })
            }
          })
          .catch(function (error) {
            console.log("No se pudo agregar el remitente", error);
            swal.fire({
              title: 'No se pudo agregar el remitente',
              buttonsStyling: false,
              customClass: {
                confirmButton: 'btn btn-celcom-orange',
              },
              type: 'error',
              icon: 'error',
            })
          });
    },
    //Fin de agregar un SenderSms
  },
};
</script>
<style scoped>
/* Agrega estilos según tus necesidades */
.modal {
  display: none;
}

.modal.is-active {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
}

.modal-close {
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
  background: none;
  border: none;
}

.buttons {
  margin: 8px;
}
</style>