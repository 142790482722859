import axios from "axios";
import Config from "@/config/servicios/campana/config";

export const obtenerDomainEmail = (body) => {
    return axios.post(Config.url_service + Config.obtenerDomainEmail,body,{
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        }});
};
export const addDomainEmail = (body) => {
    return axios.post(Config.url_service + Config.addDomainEmail,body,{
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        }});
};
export const statusDomainEmail = (body) => {
    return axios.post(Config.url_service + Config.statusDomainEmail,body,{
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        }});
};
export const deleteDomainEmail = (body) => {
    return axios.post(Config.url_service + Config.deleteDomainEmail,body,{
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        }});
};