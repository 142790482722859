import { agreCustomer } from "@/config/servicios/customer/servicesCustomer";
import { llenarDatosCustomer} from "@/config/servicios/customer/plantillaCustomer";

export const addCustomer = (name, adminCustomer, active, country) => {
    const jsonDataCustomer = llenarDatosCustomer(null, name, adminCustomer, active, country);

    return agreCustomer(jsonDataCustomer)
        .then((response) => {
        console.log(response.data);
    })
        .catch((error) => {
        console.error("Error al enviar los datos:", error);
    });
};