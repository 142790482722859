// api.js
import axios from 'axios';
import Config from "@/config/servicios/campana/config";
export const obtenerSenderEmail = (body) => {
    return axios.post(Config.url_service + Config.sender_available_email,body,{
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        }});
};
export const sender_delete_email = (body) => {
    return axios.post(Config.url_service + Config.sender_delete_email,body,{
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        }});
};
export const sender_add_email = (body) => {
    return axios.post(Config.url_service + Config.sender_add_email,body,{
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        }});
};
export const sender_edit_email = (body) => {
    return axios.post(Config.url_service + Config.sender_edit_email,body,{
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        }});
};
// Agrega más funciones según tus necesidades