<template>
  <div id="profile" class="card card-body mt-4">
    <div class="row justify-content-center align-items-center">
      <div class="col-sm-auto col-4">
        <argon-avatar
          :img="img"
          alt="team-3"
          size="xl"
          shadow="sm"
          border-radius="lg"
        />
      </div>
      <div class="col-sm-auto col-8 my-auto">
        <div class="h-100">
          <h5 class="mb-1 font-weight-bolder">
            {{ dataUserActual.shortName }}
          </h5>
          <p class="mb-0 font-weight-bold text-sm">
            ID: {{ dataUserActual.customerUserId }}
          </p>
<!--          <p class="mb-0 font-weight-bold text-sm">
            Créditos propios:
            {{ numberWithCommas(dataUserActual.remainingSms) }}
          </p>-->
        </div>
      </div>
      <div class="col-sm-auto ms-sm-auto mt-sm-0 mt-3 d-flex">
        <label class="form-check-label mb-0 me-2">
          <small id="profileVisibility"
            ><b>{{
              dataUserActual.adminUser === true ? "Administrador" : "Usuario"
            }}</b></small
          >
        </label>
        <argon-switch
          id="switch-invisible"
          name="Switch to Invisible"
          style="pointer-events: none"
          checked
        />
      </div>
    </div>
  </div>
  <div id="account-details" class="card mt-4">
    <div class="card-header">
      <h5 class="mb-0">Detalles de la Cuenta</h5>
      <!--      <p class="text-sm">
        Choose how you receive notifications. These notification settings apply
        to the things you’re watching.
      </p>-->
    </div>
    <div class="card-body pt-0">
      <div class="table-responsive">
        <table class="table mb-0">
          <thead>
            <tr>
              <th class="ps-1" colspan="4">
                <!--              <p class="mb-0">Activity</p>-->
              </th>
              <th class="text-center">
                <!--              <p class="mb-0">Email</p>-->
              </th>
              <!--            <th class="text-center">
              <p class="mb-0">Push</p>
            </th>
            <th class="text-center">
              <p class="mb-0">SMS</p>
            </th>-->
            </tr>
          </thead>
          <tbody>
<!--            <tr>
              <td valign="middle" class="text-center">
                <i class="fas fa-money-check-alt"></i>
              </td>
              <td class="ps-1" colspan="4">
                <div class="my-auto">
                  <span class="text-dark d-block text-sm">Tipo de cuenta</span>
                  <span class="text-xs font-weight-normal">{{
                    tipoCuenta()
                  }}</span>
                </div>
              </td>
            </tr>
            <tr>
              <td valign="middle" class="text-center">
                <i class="fas fa-calendar-day"></i>
              </td>
              <td class="ps-1" colspan="4">
                <div class="my-auto">
                  <span class="text-dark d-block text-sm"
                    >Fecha de creación</span
                  >
                  <span class="text-xs font-weight-normal">{{
                    fechaFormato(dataUserActual.customer.created)
                  }}</span>
                </div>
              </td>
            </tr>-->
            <tr>
              <td valign="middle" class="text-center">
                <i class="fas fa-globe-americas"></i>
              </td>
              <td class="ps-1" colspan="4">
                <div class="my-auto">
                  <span class="text-dark d-block text-sm">País</span>
                  <span class="text-xs font-weight-normal">{{
                    dataUserActual.customer.country.name
                  }}</span>
                </div>
              </td>
            </tr>
            <tr>
              <td valign="middle" class="text-center">
                <i class="fas fa-language"></i>
              </td>
              <td class="ps-1" colspan="4">
                <div class="my-auto">
                  <span class="text-dark d-block text-sm">Idioma</span>
                  <span class="text-xs font-weight-normal">{{
                    dataUserActual.customer.language.name
                  }}</span>
                </div>
              </td>
            </tr>
            <tr>
              <td valign="middle" class="text-center">
                <i class="fas fa-clock"></i>
              </td>
              <td class="ps-1" colspan="4">
                <div class="my-auto">
                  <span class="text-dark d-block text-sm">Zona Horaria</span>
                  <span class="text-xs font-weight-normal">{{
                    dataUserActual.customer.gmtTimezoneInterval
                  }}</span>
                </div>
              </td>
            </tr>
            <!--          <tr>
            <td class="ps-1" colspan="4">
              <div class="my-auto">
                <span class="text-dark d-block text-sm">Follows</span>
                <span class="text-xs font-weight-normal"
                >Notify when another user follows you.</span
                >
              </div>
            </td>
            <td>
              <argon-switch
                  id="notify-follow-email"
                  name="Email"
                  class="mb-0 d-flex align-items-center justify-content-center"
                  checked
              />
            </td>
            <td>
              <argon-switch
                  id="notify-follow-push"
                  name="Push"
                  class="mb-0 d-flex align-items-center justify-content-center"
                  checked
              />
            </td>
            <td>
              <argon-switch
                  id="notify-follow-sms"
                  name="SMS"
                  class="mb-0 d-flex align-items-center justify-content-center"
                  checked
              />
            </td>
          </tr>
          <tr>
            <td class="ps-1" colspan="4">
              <div class="my-auto">
                <p class="text-sm mb-0">Log in from a new device</p>
              </div>
            </td>
            <td>
              <argon-switch
                  id="notify-login-email"
                  name="Email"
                  class="mb-0 d-flex align-items-center justify-content-center"
                  checked
              />
            </td>
            <td>
              <argon-switch
                  id="notify-login-push"
                  name="Push"
                  class="mb-0 d-flex align-items-center justify-content-center"
                  checked
              />
            </td>
            <td>
              <argon-switch
                  id="notify-login-sms"
                  name="SMS"
                  class="mb-0 d-flex align-items-center justify-content-center"
                  checked
              />
            </td>
          </tr>-->
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div v-if="dataUserActual.adminUser === true">
    <div id="basic-info" class="card mt-4">
      <div class="card-header">
        <h5>Información Básica</h5>
      </div>
      <div class="card-body pt-0">
        <div class="row">
          <div class="col-md-6">
            <label class="form-label">Nombre Corto</label>
            <argon-input
              id="firstname"
              v-model="dataUserActual.shortName"
              type="text"
              placeholder="Alec"
            />
          </div>
          <div class="col-md-6">
            <label class="form-label">Correo / Login</label>
            <argon-input
              id="correo"
              v-model="dataUserActual.email"
              type="text"
              placeholder="Thompson"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <label class="form-label">Nombre</label>
            <argon-input
              id="fullname"
              v-model="dataUserActual.name"
              type="text"
              placeholder="Alec"
            />
          </div>
          <div class="col-md-6">
            <label class="form-label">Apellidos</label>
            <argon-input
              id="lastname"
              v-model="dataUserActual.lastName"
              type="text"
              placeholder="Thompson"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <label class="form-label">RUT</label>
            <argon-input
              id="rut"
              v-model="dataUserActual.customer.rut"
              type="text"
              placeholder="Alec"
            />
          </div>
          <div class="col-md-6">
            <label class="form-label">Direccion</label>
            <argon-input
              id="address"
              v-model="dataUserActual.customer.adress"
              type="text"
              placeholder="Thompson"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <label class="form-label">Contraseña</label>
            <argon-input
              id="new-password"
              v-model="dataUserActual.password"
              type="password"
              placeholder="Password"
            />
          </div>
          <div class="col-md-6">
            <label class="form-label">Verificar Contraseña</label>
            <argon-input
              id="confirm-password"
              v-model="dataUserActual.password"
              type="password"
              placeholder="Confirm password"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-sm-4 col-6">
            <label class="form-label mt-2">País</label>
            <select
              v-model="selects.simple"
              class="form-control form-select"
              placeholder="Elegir País"
            >
              <option selected disabled value="">Países</option>
              <option
                v-for="option in selects.paises"
                :key="option.name"
                class="select-danger"
                :value="option.name"
                :label="option.name"
              ></option>
            </select>
          </div>
          <div class="col-sm-4 col-6">
            <label class="form-label mt-2">Idioma</label>
            <select v-model="selects.simpleIdioma" class="form-control form-select">
              <option selected disabled value="">Idiomas</option>
              <option
                v-for="option in selects.idioma"
                :key="option.name"
                class="select-danger"
                :value="option.name"
                :label="option.name"
              ></option>
            </select>
          </div>
          <div class="col-sm-4">
            <label class="form-label mt-2">Zona Horaria</label>
            <select v-model="selects.simpleZona" class="form-control form-select">
              <option selected disabled value="">Zonas Horarias</option>
              <option
                v-for="option in selects.zonaHoraria"
                :key="option.name"
                class="select-danger"
                :value="option.name"
                :label="option.name"
              ></option>
            </select>
          </div>
        </div>
        <!--        <div class="row">
          <div class="col-md-6">
            <label class="form-label mt-2">Email</label>
            <argon-input
              id="email"
              type="email"
              placeholder="example@email.com"
            />
          </div>
          <div class="col-md-6">
            <label class="form-label mt-2">Confirmation Email</label>
            <argon-input
              id="confirm-email"
              type="email"
              placeholder="example@email.com"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <label class="form-label mt-2">Your location</label>
            <argon-input id="location" type="text" placeholder="Sydney, A" />
          </div>
          <div class="col-md-6">
            <label class="form-label mt-2">Phone Number</label>
            <argon-input id="phone" type="text" placeholder="+40 745 765 439" />
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 align-self-center">
            <label class="form-label mt-2">Language</label>
            <select
              id="choices-language"
              class="form-control"
              name="choices-language"
            >
              <option value="English">English</option>
              <option value="French">French</option>
              <option value="Spanish">Spanish</option>
            </select>
          </div>
          <div class="col-md-6">
            <label class="form-label mt-2">Skills</label>
            <input
              id="choices-skills"
              class="form-control"
              type="text"
              value="vuejs, angular, react"
              placeholder="Enter something"
              onfocus="focused(this)"
              onfocusout="defocused(this)"
            />
          </div>
        </div>-->
        <div class="row mt-3">
          <div class="col-12">
            <argon-alert class="alert alert-danger" role="alert"
            >El saldo total disponibles:
              <strong>{{
                  "$" + redondear(dataUserActual.customer.flexCredits)
              }}</strong></argon-alert
            >
          </div>
<!--          <div class="col-md-6">
            <input
              v-model="radio.radio1"
              type="radio"
              name="limite"
              value="checked"
            /><label>Límite propio</label
            ><i
              class="btn-tooltip fas fa-question-circle"
              title="Consumir créditos de tu límite propio"
            ></i>
            <br />
            <input
              v-model="radio.radio1"
              type="radio"
              name="limite"
              value="unchecked"
            /><label>Límite cuenta master</label>
          </div>
          <div class="col-md-6">
            <argon-input
              v-model="dataUserActual.remainingSms"
              type="text"
              :style="
                radio.radio1 === 'unchecked' ? 'pointer-events: none' : ''
              "
            />
            <small>Restantes</small>
          </div>-->
        </div>
      </div>
      <div class="card-body d-sm-flex pt-0">
        <argon-button
          color="celcom"
          variant="gradient"
          class="mb-0 ms-2 text-white"
          type="button"
          name="button"
          @click="guardar"
          >Guardar</argon-button
        >
      </div>
    </div>
    <!--    <div id="password" class="card mt-4">
      <div class="card-header">
        <h5>Change Password</h5>
      </div>
      <div class="card-body pt-0">
        <label class="form-label">Current password</label>
        <argon-input
          id="password"
          type="password"
          placeholder="Current Password"
        />
        <label class="form-label">New password</label>
        <argon-input
          id="new-password"
          type="password"
          placeholder="New Password"
        />
        <label class="form-label">Confirm new password</label>
        <argon-input
          id="confirm-password"
          type="password"
          placeholder="Confirm password"
        />
        <h5 class="mt-5">Password requirements</h5>
        <p class="text-muted mb-2">
          Please follow this guide for a strong password:
        </p>
        <ul class="text-muted ps-4 mb-0 float-start">
          <li>
            <span class="text-sm">One special characters</span>
          </li>
          <li>
            <span class="text-sm">Min 6 characters</span>
          </li>
          <li>
            <span class="text-sm">One number (2 are recommended)</span>
          </li>
          <li>
            <span class="text-sm">Change it often</span>
          </li>
        </ul>
        <argon-button
          class="float-end mt-6 mb-0"
          color="dark"
          variant="gradient"
          size="sm"
          >Update password</argon-button
        >
      </div>
    </div>
    <div id="2fa" class="card mt-4">
      <div class="card-header d-flex">
        <h5 class="mb-0">Two-factor authentication</h5>
        <argon-badge color="success" class="ms-auto">Enabled</argon-badge>
      </div>
      <div class="card-body">
        <div class="d-flex">
          <p class="my-auto">Security keys</p>
          <p class="text-secondary text-sm ms-auto my-auto me-3">
            No Security Keys
          </p>
          <argon-button
            color="dark"
            variant="outline"
            size="sm"
            class="mb-0"
            type="button"
            >Add</argon-button
          >
        </div>
        <hr class="horizontal dark" />
        <div class="d-flex">
          <p class="my-auto">SMS number</p>
          <p class="text-secondary text-sm ms-auto my-auto me-3">+4012374423</p>
          <argon-button
            color="dark"
            variant="outline"
            size="sm"
            class="mb-0"
            type="button"
            >Edit</argon-button
          >
        </div>
        <hr class="horizontal dark" />
        <div class="d-flex">
          <p class="my-auto">Authenticator app</p>
          <p class="text-secondary text-sm ms-auto my-auto me-3">
            Not Configured
          </p>
          <argon-button
            color="dark"
            variant="outline"
            size="sm"
            class="mb-0"
            type="button"
            >Set up</argon-button
          >
        </div>
      </div>
    </div>
    <div id="accounts" class="card mt-4">
      <div class="card-header">
        <h5>Accounts</h5>
        <p class="text-sm">
          Here you can setup and manage your integration settings.
        </p>
      </div>
      <div class="card-body pt-0">
        <div class="d-flex">
          <argon-avatar class="width-48-px" :img="img1" alt="logo_slack" />
          <div class="my-auto ms-3">
            <div class="h-100">
              <h5 class="mb-0">Slack</h5>
              <a class="text-sm text-body" href="javascript:;">
                Show less
                <i class="fas fa-chevron-up text-xs ms-1" aria-hidden="true"></i>
              </a>
            </div>
          </div>
          <p class="text-sm text-secondary ms-auto me-3 my-auto">Enabled</p>
          <div class="my-auto">
            <argon-switch id="enable-slack" name="Enabled" checked />
          </div>
        </div>
        <div class="ps-5 pt-3 ms-3">
          <p class="mb-0 text-sm">
            You haven't added your Slack yet or you aren't authorized. Please add
            our Slack Bot to your account by clicking on
            <a href="javascript">here</a>. When you've added the bot, send your
            verification code that you have received.
          </p>
          <div
            class="d-sm-flex align-items-center bg-gray-100 border-radius-lg p-2 my-4"
          >
            <p class="text-sm font-weight-bold my-auto ps-sm-2">
              Verification Code
            </p>
            <argon-input
              id="verification-code"
              type="text"
              value="1172913"
              class="my-auto w-20 ms-sm-auto"
            />
          </div>
          <div class="d-sm-flex bg-gray-100 border-radius-lg p-2 my-4">
            <p class="text-sm font-weight-bold my-auto ps-sm-2">
              Connected account
            </p>
            <h6 class="text-sm ms-auto me-3 my-auto">hello@creative-tim.com</h6>
            <argon-button
              color="danger"
              variant="gradient"
              size="sm"
              class="my-sm-auto mt-2 mb-0"
              type="button"
              name="button"
              >Delete</argon-button
            >
          </div>
        </div>
        <hr class="horizontal dark" />
        <div class="d-flex">
          <argon-avatar class="width-48-px" :img="img2" alt="logo_spotify" />
          <div class="my-auto ms-3">
            <div class="h-100">
              <h5 class="mb-0">Spotify</h5>
              <p class="mb-0 text-sm">Music</p>
            </div>
          </div>
          <p class="text-sm text-secondary ms-auto me-3 my-auto">Enabled</p>
          <div class="my-auto">
            <argon-switch id="enable-spotify" name="Spotify" checked />
          </div>
        </div>
        <hr class="horizontal dark" />
        <div class="d-flex">
          <argon-avatar class="width-48-px" :img="img3" alt="logo_atlassian" />
          <div class="my-auto ms-3">
            <div class="h-100">
              <h5 class="mb-0">Atlassian</h5>
              <p class="mb-0 text-sm">Payment vendor</p>
            </div>
          </div>
          <p class="text-sm text-secondary ms-auto me-3 my-auto">Enabled</p>
          <div class="my-auto">
            <argon-switch id="enable-atlassian" name="Atlassian" checked />
          </div>
        </div>
        <hr class="horizontal dark" />
        <div class="d-flex">
          <argon-avatar class="width-48-px" :img="img4" alt="logo_asana" />
          <div class="my-auto ms-3">
            <div class="h-100">
              <h5 class="mb-0">Asana</h5>
              <p class="mb-0 text-sm">Organize your team</p>
            </div>
          </div>
          <div class="my-auto ms-auto">
            <argon-switch id="enable-asana" name="Asana" />
          </div>
        </div>
      </div>
    </div>
    <div id="sessions" class="card mt-4">
      <div class="card-header pb-3">
        <h5>Sessions</h5>
        <p class="text-sm">
          This is a list of devices that have logged into your account. Remove
          those that you do not recognize.
        </p>
      </div>
      <div class="card-body pt-0">
        <div class="d-flex align-items-center">
          <div class="text-center w-5">
            <i class="fas fa-desktop text-lg opacity-6"></i>
          </div>
          <div class="my-auto ms-3">
            <div class="h-100">
              <p class="text-sm mb-1">Bucharest 68.133.163.201</p>
              <p class="mb-0 text-xs">Your current session</p>
            </div>
          </div>
          <argon-badge color="success" size="sm" class="my-auto ms-auto me-3"
            >Active</argon-badge
          >
          <p class="text-secondary text-sm my-auto me-3">EU</p>
          <a
            href="javascript:;"
            class="text-primary text-sm icon-move-right my-auto"
          >
            See more
            <i class="fas fa-arrow-right text-xs ms-1" aria-hidden="true"></i>
          </a>
        </div>
        <hr class="horizontal dark" />
        <div class="d-flex align-items-center">
          <div class="text-center w-5">
            <i class="fas fa-desktop text-lg opacity-6"></i>
          </div>
          <p class="my-auto ms-3">Chrome on macOS</p>
          <p class="text-secondary text-sm ms-auto my-auto me-3">US</p>
          <a
            href="javascript:;"
            class="text-primary text-sm icon-move-right my-auto"
          >
            See more
            <i class="fas fa-arrow-right text-xs ms-1" aria-hidden="true"></i>
          </a>
        </div>
        <hr class="horizontal dark" />
        <div class="d-flex align-items-center">
          <div class="text-center w-5">
            <i class="fas fa-mobile text-lg opacity-6"></i>
          </div>
          <p class="my-auto ms-3">Safari on iPhone</p>
          <p class="text-secondary text-sm ms-auto my-auto me-3">US</p>
          <a
            href="javascript:;"
            class="text-primary text-sm icon-move-right my-auto"
          >
            See more
            <i class="fas fa-arrow-right text-xs ms-1" aria-hidden="true"></i>
          </a>
        </div>
      </div>
    </div>
    <div id="delete" class="card mt-4">
      <div class="card-header">
        <h5>Delete Account</h5>
        <p class="text-sm mb-0">
          Once you delete your account, there is no going back. Please be certain.
        </p>
      </div>
      <div class="card-body d-sm-flex pt-0">
        <div class="d-flex align-items-center mb-sm-0 mb-4">
          <div>
            <argon-switch id="delete-account" name="Delete Account" />
          </div>
          <div class="ms-2">
            <span class="text-dark font-weight-bold d-block text-sm"
              >Confirm</span
            >
            <span class="text-xs d-block">I want to delete my account.</span>
          </div>
        </div>
        <argon-button
          color="secondary"
          variant="outline"
          class="mb-0 ms-auto"
          type="button"
          name="button"
          >Deactivate</argon-button
        >
        <argon-button
          color="danger"
          variant="gradient"
          class="mb-0 ms-2"
          type="button"
          name="button"
          >Delete Account</argon-button
        >
      </div>
    </div>-->
  </div>
</template>

<script>
import * as Choices from "choices.js";
import ArgonButton from "@/components/ArgonButton.vue";
import ArgonSwitch from "@/components/ArgonSwitch.vue";
import {setUser} from "@/config/servicios/campana/util";
//import ArgonBadge from "@/components/ArgonBadge.vue";
import ArgonAvatar from "@/components/ArgonAvatar.vue";
import ArgonInput from "@/components/ArgonInput.vue";
import img from "../../../../assets/img/user/usuario_default.png";
import { getUser } from "@/config/servicios/campana/util";
import moment from "moment";
import swal from "sweetalert2";
import { horaAhora, encryptAES } from "@/config/servicios/campana/util";
import axios from "axios";
import Config from "@/config/servicios/campana/config";
import ArgonAlert from "@/components/ArgonAlert.vue";
//import TodoList from "@/views/pages/projects/components/TodoList.vue";
export default {
  name: "SideNavItem",
  components: {
    ArgonAlert,
    ArgonButton,
    ArgonSwitch,
    //ArgonBadge,
    ArgonAvatar,
    ArgonInput,
  },
  data() {
    return {
      img,
      dataUser: {},
      dataUserActual: getUser(),
      radio: {
        radio1: "checked",
      },
      selects: {
        simple: "",
        paises: [],
        simpleIdioma: "",
        idioma: [],
        simpleZona: "",
        zonaHoraria: [],
      },
    };
  },
  created() {
    this.obtenerInfoCustomer();
    this.obtenerIdiomas();
  },
  mounted() {
    document.getElementById("switch-invisible").checked =
      this.dataUserActual.adminUser === true;

    if (document.getElementById("choices-gender")) {
      var gender = document.getElementById("choices-gender");
      new Choices(gender, { allowHTML: true });
    }

    if (document.getElementById("choices-language")) {
      var language = document.getElementById("choices-language");
      new Choices(language, { allowHTML: true });
    }

    if (document.getElementById("choices-month")) {
      var month = document.getElementById("choices-month");
      setTimeout(function () {
        new Choices(month, { allowHTML: true });
      }, 1);

      // eslint-disable-next-line no-unused-vars
      var d = new Date();
      var monthArray = new Array();
      monthArray[0] = "January";
      monthArray[1] = "February";
      monthArray[2] = "March";
      monthArray[3] = "April";
      monthArray[4] = "May";
      monthArray[5] = "June";
      monthArray[6] = "July";
      monthArray[7] = "August";
      monthArray[8] = "September";
      monthArray[9] = "October";
      monthArray[10] = "November";
      monthArray[11] = "December";
      for (let m = 0; m <= 11; m++) {
        var optn = document.createElement("OPTION");
        optn.text = monthArray[m];
        // server side month start from one\
        optn.value = m + 1;
        // if june selected
        if (m == 1) {
          optn.selected = true;
        }
        month.options.add(optn);
      }
    }

    if (document.getElementById("choices-day")) {
      var day = document.getElementById("choices-day");
      setTimeout(function () {
        new Choices(day, { allowHTML: true });
      }, 1);

      for (let y = 1; y <= 31; y++) {
        var optn1 = document.createElement("OPTION");
        optn1.text = y;
        optn1.value = y;

        if (y == 1) {
          optn1.selected = true;
        }

        day.options.add(optn1);
      }
    }

    if (document.getElementById("choices-year")) {
      var year = document.getElementById("choices-year");
      setTimeout(function () {
        new Choices(year, { allowHTML: true });
      }, 1);

      for (let y = 1900; y <= 2020; y++) {
        var optn2 = document.createElement("OPTION");
        optn2.text = y;
        optn2.value = y;

        if (y == 2020) {
          optn.selected = true;
        }

        year.options.add(optn2);
      }
    }

    if (document.getElementById("choices-skills")) {
      var skills = document.getElementById("choices-skills");
      new Choices(skills, {
        delimiter: ",",
        editItems: true,
        maxItemCount: 5,
        removeItemButton: true,
        addItems: true,
        allowHTML: true,
      });
    }
  },
  methods: {
    async guardar(){

      let self = this;
      let user = getUser();
      let js = {
        "time_expired":horaAhora(),
        "customer":encodeURIComponent(JSON.stringify(this.dataUserActual.customer)),
        "customer_user":encodeURIComponent(JSON.stringify(this.dataUserActual))
      }

      var body = new URLSearchParams(); body.append('TOKEN', (encryptAES(js)));

      await axios
          .post(Config.url_service+Config.costumer_user_edit, body, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      })
          .then(function (response) {
            console.log(response)
            if (response.data.response === "200") {

              self.dataUser = response.data.data;

              self.dataUser.forEach(function(element) {
                if(element.customerUserId === user.customerUserId) {

                  self.dataUserActual = element;
                  setUser(element);
                }
              })

              self.element = response.data.data;
              swal.fire({
                title: '¡Datos editados con éxito!',
                buttonsStyling: false,
                customClass: {
                  confirmButton: 'btn btn-celcom-blue',
                },
                type: 'success',
                icon: 'success'
              })

            } else {
              console.log("No se editó usuario");
              swal.fire({
                title: 'No se editó usuario',
                buttonsStyling: false,
                customClass: {
                  confirmButton: 'btn btn-danger',
                },
                type: 'warning',
                icon: 'warning'
              })
            }

            //self.$loading.hide()

          })
          .catch(function (error) {
            console.log("No se editó usuario",error);
            swal.fire({
              title: 'No se editó usuario',
              buttonsStyling: false,
              customClass: {
                confirmButton: 'btn btn-danger',
              },
              type: 'warning',
              icon: 'warning'
            })

            //self.$loading.hide()
          });
    },
    async obtenerInfoCustomer() {
      let self = this;
      let user = getUser();

      let js = {
        time_expired: horaAhora(),
        customer: encodeURIComponent(JSON.stringify(user.customer)),
      };

      var body = new URLSearchParams();
      body.append("TOKEN", encryptAES(js));

      await axios
        .post(Config.url_service + Config.costumer_user_available, body, {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        })
        .then(function (response) {
          if (response.data.response === "200") {
            self.dataUser = response.data.data;

            self.dataUser.forEach(function (element) {
              if (element.customerUserId === user.customerUserId) {
                self.selects.paises.push({
                  name: element.customer.country.name,
                });
                self.selects.zonaHoraria.push({
                  name:
                    element.customer.country.gmt_timezone_name +
                    "|" +
                    element.customer.country.gmt_timezone_interval,
                });
                self.selects.simple = element.customer.country.name;
                self.selects.simpleZona =
                  element.customer.country.gmt_timezone_name +
                  "|" +
                  element.customer.country.gmt_timezone_interval;
                self.selects.simpleIdioma = element.customer.language.name;

                //self.radio.radio1 = (element.smsAdminUser) ? "unchecked" : "checked";

                self.dataUserActual = element;
              }
            });
          } else {
            console.log("No se encontró usuario");

            swal.fire({
              title: "No se encontró usuario",
              buttonsStyling: false,
              customClass: {
                confirmButton: 'btn btn-danger',
              },
              type: "warning",
              icon: "warning"
            });
          }

          //self.$loading.hide()
        })
        .catch(function (error) {
          console.log("No se encontró usuario", error);

          swal.fire({
            title: "No se encontró usuario",
            buttonsStyling: false,
            customClass: {
              confirmButton: 'btn btn-danger',
            },
            type: "warning",
            icon: "warning"
          });

          //self.$loading.hide()
        });
    },
    obtenerIdiomas() {
      let self = this;

      let js = {
        time_expired: horaAhora(),
      };

      var body = new URLSearchParams();
      body.append("TOKEN", encryptAES(js));

      axios
        .post(Config.url_service + Config.language_available, body, {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        })
        .then(function (response) {
          if (response.data.response === "200") {
            self.selects.idioma = response.data.data;
            console.log(response.data.data);
          } else {
            console.log("No se encontraron paises");
          }

          //self.$loading.hide()
        })
        .catch(function (error) {
          console.log("No se encontraron paises", error);
          //self.$loading.hide()
        });
    },
    fechaFormato(unixTimestamp) {
      return moment(unixTimestamp).format("YYYY-MM-DD");
    },
    numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    redondear(numero) {
      return Number(numero.toFixed(1));
    },
    tipoCuenta() {
      var resp = "NA";

      if (
        this.dataUserActual.customer.postpaid === true &&
        this.dataUserActual.customer.monthlyLimit === true
      ) {
        resp = "PostPago";
      }

      if (
        this.dataUserActual.customer.postpaid === false &&
        this.dataUserActual.customer.monthlyLimit === true
      ) {
        resp = "PrePago";
      }

      if (
        this.dataUserActual.customer.postpaid === true &&
        this.dataUserActual.customer.monthlyLimit === false
      ) {
        resp = "PostPago";
      }

      return resp;
    },
  },
};
</script>
