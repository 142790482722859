<template>
  <div v-if="loading" class="loader-overlay">
    <div class="loading-text">
      Cargando {{ Math.floor(progress) }}%<span class="dots">...</span>
    </div>
    <div class="progress-bar">
      <div class="progress" :style="{ width: progress + '%' }"></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      progress: 0, // Estado para el progreso de la barra
    };
  },
  methods: {
    // Método para mostrar el loader
    showLoader() {
      this.loading = true;
    },
    // Método para ocultar el loader
    hideLoader() {
      this.loading = false;
      this.progress = 0; // Reiniciar el progreso
    },
  },
};
</script>

<style scoped>
.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.loading-text {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 15px;
}

.dots {
  animation: blink 1.5s infinite steps(5, start);
}

@keyframes blink {
  0%, 20% {
    color: rgba(0, 0, 0, 0);
  }
  100% {
    color: rgba(0, 0, 0, 1);
  }
}

.progress-bar {
  width: 80%; /* Ancho de la barra de progreso */
  background-color: #f3f3f3;
  border-radius: 25px;
  overflow: hidden;
  height: 20px;
}

.progress {
  background-color: #3498db;
  height: 100%;
  width: 0;
  transition: width 0.1s ease-in-out;
}
</style>
