// api.js
import axios from 'axios';
import Config from "@/config/servicios/campana/config";
export const listaContactosCamp = (body) => {
    return axios.post(Config.url_service + Config.contact_groups,body,{
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        }});
};
export const contact_add_contact_group = (body) => {
    return axios.post(Config.url_service + Config.contact_add_contact_group,body,{
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        }});
};
export const contact_add_contacts = (body) => {
    return axios.post(Config.url_service + Config.contact_add_contacts,body,{
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        }});
};
export const contact_add_contact = (body) => {
    return axios.post(Config.url_service + Config.contact_add_contact,body,{
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        }});
};
export const contact_delete_contact_group = (body) => {
    return axios.post(Config.url_service+Config.contact_delete_contact_group,body,{
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        }});
};
export const contact_delete_contact = (body) => {
    return axios.post(Config.url_service+Config.contact_delete_contact,body,{
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        }});
};
export const contact_available_contact = (body) => {
    return axios.post(Config.url_service+Config.contact_available_contact,body,{
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        }});
};
export const contact_edit_contact = (body) => {
    return axios.post(Config.url_service+Config.contact_edit_contact,body,{
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        }});
};
export const contact_edit_contact_group = (body) => {
    return axios.post(Config.url_service+Config.contact_edit_contact_group,body,{
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        }});
};
export const blacklist_available = (body) => {
    return axios.post(Config.url_service+Config.blacklist_available,body,{
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        }});
};
export const blacklist_delete_blacklist = (body) => {
    return axios.post(Config.url_service+Config.blacklist_delete_blacklist,body,{
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        }});
};
export const blacklist_add_blacklists = (body) => {
    return axios.post(Config.url_service+Config.blacklist_add_blacklists,body,{
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        }});
};
// Agrega más funciones según tus necesidades