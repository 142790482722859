  import AES256 from 'aes-everywhere';
  import moment from 'moment';
  import axios from 'axios';
  import Config from "@/config/servicios/campana/config";
  //import swal from 'sweetalert2';
  export function getUser(){
    let encryptedToken = localStorage.getItem('user');
    return decryptAES(encryptedToken);
  }



  export function getUserSub(){

    return JSON.parse(localStorage.getItem('user_sub'));
  }

  // utils.js
  export function traducir_header(headerName) {
    // Diccionario de mapeo de headers en inglés a español
    const headerMap = {
      "first_name": "nombre",
      "last_name": "apellido",
      "address": "direccion",
      "msisdn": "telefono",
      "email": "correo",
      "tax": "rut",
      "country": "pais",
      "company": "empresa",
      "gender": "sexo",
      "birth_date": "fecha de nacimiento"
    };

    // Retornar el valor correspondiente en español, o el mismo header si no se encuentra en el diccionario
    return headerMap[headerName] || headerName;
  }


  /*export function search_data(nameKey, myArray){

    var array_result = [];

      for (var i=0; i < myArray.length; i++) {
        if (myArray[i].name != null && myArray[i].name.toUpperCase() .toString().includes(nameKey.toUpperCase() )) {
            array_result.push(myArray[i]);
        }
        if (myArray[i].phone != null && myArray[i].phone.toString().includes(nameKey)) {
            array_result.push(myArray[i]);
        }
        if (myArray[i].shortName != null && myArray[i].shortName.toUpperCase() .toString().includes(nameKey.toUpperCase() )) {
            array_result.push(myArray[i]);
        }
        if (myArray[i].sendingName != null && myArray[i].sendingName.toUpperCase() .toString().includes(nameKey.toUpperCase() )) {
            array_result.push(myArray[i]);
        }
        if (myArray[i].blacklistPK!= null && myArray[i].blacklistPK.phone != null && myArray[i].blacklistPK.phone.toString().includes(nameKey)) {
            array_result.push(myArray[i]);
        }
        if (myArray[i].payProductId!= null && myArray[i].payProductId.productName != null && myArray[i].payProductId.productName.toUpperCase() .toString().includes(nameKey.toUpperCase() )) {
            array_result.push(myArray[i]);
        }
        if (myArray[i].paymentPlatformId!= null && myArray[i].paymentPlatformId.paymentPlatformName != null && myArray[i].paymentPlatformId.paymentPlatformName.toUpperCase() .toString().includes(nameKey.toUpperCase() )) {
            array_result.push(myArray[i]);
        }
      }

      return array_result;
  }*/



  export function getUserAdmin(){
    let encryptedToken = localStorage.getItem('user_admin');
    return decryptAES(encryptedToken);
  }




  /*export async function obtenerReporteEnvio() {

    let self = this;
    let user = getUser();

    var arr=[];

    let js = {
      "start_date":moment( moment(Date.now() - 7 * 24 * 3600 * 1000).format('YYYY-MM-DD h:mm:ss')).valueOf().toString(),
      "end_date": moment(moment().format('YYYY-MM-DD h:mm:ss')).valueOf().toString() ,
      /!*"start_date":moment("2019-01-01").valueOf().toString(),
      "end_date": moment("2019-01-07").valueOf().toString() ,*!/
      "global_report":false,
      "time_expired":horaAhora(),
      "customer_user":encodeURIComponent(JSON.stringify(user)),
    }

    console.log(js);

    var body = new URLSearchParams(); body.append('TOKEN', (encryptAES(js)));

    await Vue.axios.post(Config.url_service+Config.report_available, body, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    })
      .then(function (response) {
          console.log(response)

          if (response.data.response === "200") {
            console.log(response.data.data);

            var info = response.data.data.sort((a, b) => (a.idSending < b.idSending) ? 1 : -1)

            localStorage.setItem('enviosreport',JSON.stringify(info))


          } else {
            console.log("No se encontraron registros envios para reporte home");

          }

      })
      .catch(function (error) {
        console.log("No se encontraron registros envios para reporte home",error);

      });

    }*/



/*  export function removeSpecialChars(str) {
    return str.replace(/(?!\w|\s)./g, '')
      .replace(/\s+/g, ' ')
      .replace(/^(\s*)([\W\w]*)(\b\s*$)/g, '$2');
  }*/



  export function diaSemana (d){
    var fecha = new Date();
    var dias = ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sabado"];

    var mes = fecha.getMonth()+1; //obteniendo mes
    var dia = d;
    var ano = fecha.getFullYear(); //obteniendo año
    if(dia<10)
        dia='0'+dia; //agrega cero si el menor de 10
    if(mes<10)
        mes='0'+mes //agrega cero si el menor de 10
    var fec = mes+"/"+dia+"/"+ano;
    var day = new Date(fec).getDay();

    return dias[day];
  }

  export function dataReportEnvioSms(){

    /* let days = ['Lunes','Martes','Miércoles', 'Jueves', 'Viernes', 'Sabado', 'Domingo'];
     let d = new Date('02/15/1989'); //Miércoles
     let dia = days[d.getUTCDay()-1];*/

    var  arr=[0,0,0,0,0,0,0];
    var  arr_days=[
      {
        'dia': "Lunes",
        'value': "0",
      },
      {
        'dia': "Martes",
        'value': "1",

      },
      {
        'dia': "Miércoles",
        'value': "2",

      },
      {
        'dia': "Jueves",
        'value': "3",

      },
      {
        'dia': "Viernes",
        'value': "4",

      },
      {
        'dia': "Sabado",
        'value': "5",

      },
      {
        'dia': "Domingo",
        'value': "6",

      },
    ];



    var suma_report=0;
    var dia_actual = 0;
    let encryptedToken = localStorage.getItem('enviosreport');
    let decryptedJson = decryptAES(encryptedToken);

    decryptedJson.forEach(function(key) {
      if (key.channelId === 1) {
        var index = arr_days.find(x => x.dia === diaSemana(moment(key.sendingDate).format('DD').toString())).value;

        if (parseInt(dia_actual) != parseInt(index)) {

          dia_actual = index;
          suma_report = 0;
        }

        if (/^([0-9])*$/.test(key.sendingOk)) {

          suma_report += parseInt(key.sendingOk);

        }


        arr[index] = suma_report
      }
      //alert(dia_actual+" "+diaSemana(moment(key.sendingDate).format('DD').toString()) +" = "+key.sendingOk+" = "+index+" = "+suma_report)

    })

    return arr;

  }
  export function dataReportWhat(){

    /* let days = ['Lunes','Martes','Miércoles', 'Jueves', 'Viernes', 'Sabado', 'Domingo'];
     let d = new Date('02/15/1989'); //Miércoles
     let dia = days[d.getUTCDay()-1];*/

    var  arr=[0,0,0,0,0,0,0];
    var  arr_days=[
      {
        'dia': "Lunes",
        'value': "0",
      },
      {
        'dia': "Martes",
        'value': "1",

      },
      {
        'dia': "Miércoles",
        'value': "2",

      },
      {
        'dia': "Jueves",
        'value': "3",

      },
      {
        'dia': "Viernes",
        'value': "4",

      },
      {
        'dia': "Sabado",
        'value': "5",

      },
      {
        'dia': "Domingo",
        'value': "6",

      },
    ];



    var suma_report=0;
    var dia_actual = 0;

    let encryptedToken = localStorage.getItem('enviosreport');
    let decryptedJson = decryptAES(encryptedToken);
    decryptedJson.forEach(function(key) {
      if (key.channelId === 2) {
        var index = arr_days.find(x => x.dia === diaSemana(moment(key.sendingDate).format('DD').toString())).value;

        if (parseInt(dia_actual) != parseInt(index)) {

          dia_actual = index;
          suma_report = 0;
        }

        if (/^([0-9])*$/.test(key.sendingOk)) {

          suma_report += parseInt(key.sendingOk);

        }


        arr[index] = suma_report
      }
      //alert(dia_actual+" "+diaSemana(moment(key.sendingDate).format('DD').toString()) +" = "+key.sendingOk+" = "+index+" = "+suma_report)

    })

    return arr;

  }
  export function dataReportEmail(){

    /* let days = ['Lunes','Martes','Miércoles', 'Jueves', 'Viernes', 'Sabado', 'Domingo'];
     let d = new Date('02/15/1989'); //Miércoles
     let dia = days[d.getUTCDay()-1];*/

    var  arr=[0,0,0,0,0,0,0];
    var  arr_days=[
      {
        'dia': "Lunes",
        'value': "0",
      },
      {
        'dia': "Martes",
        'value': "1",

      },
      {
        'dia': "Miércoles",
        'value': "2",

      },
      {
        'dia': "Jueves",
        'value': "3",

      },
      {
        'dia': "Viernes",
        'value': "4",

      },
      {
        'dia': "Sabado",
        'value': "5",

      },
      {
        'dia': "Domingo",
        'value': "6",

      },
    ];



    var suma_report=0;
    var dia_actual = 0;

    let encryptedToken = localStorage.getItem('enviosreport');
    let decryptedJson = decryptAES(encryptedToken);
    decryptedJson.forEach(function(key) {
      if (key.channelId === 3) {
        var index = arr_days.find(x => x.dia === diaSemana(moment(key.sendingDate).format('DD').toString())).value;

        if (parseInt(dia_actual) != parseInt(index)) {

          dia_actual = index;
          suma_report = 0;
        }

        if (/^([0-9])*$/.test(key.sendingOk)) {

          suma_report += parseInt(key.sendingOk);

        }


        arr[index] = suma_report
      }
      //alert(dia_actual+" "+diaSemana(moment(key.sendingDate).format('DD').toString()) +" = "+key.sendingOk+" = "+index+" = "+suma_report)

    })
    return arr;

  }

 /* export function dataReportEnvio(){

   /!* let days = ['Lunes','Martes','Miércoles', 'Jueves', 'Viernes', 'Sabado', 'Domingo'];
    let d = new Date('02/15/1989'); //Miércoles
    let dia = days[d.getUTCDay()-1];*!/

    var  arr=[0,0,0,0,0,0,0];
    var  arr_days=[
      {
        'dia': "Lunes",
        'value': "0",
      },
      {
        'dia': "Martes",
        'value': "1",

      },
      {
        'dia': "Miércoles",
        'value': "2",

      },
      {
        'dia': "Jueves",
        'value': "3",

      },
      {
        'dia': "Viernes",
        'value': "4",

      },
      {
        'dia': "Sabado",
        'value': "5",

      },
      {
        'dia': "Domingo",
        'value': "6",

      },
    ];



    var suma_report=0;
    var dia_actual = 0;

    JSON.parse(localStorage.getItem('enviosreport')).forEach(function(key) {

      var index = arr_days.find(x => x.dia === diaSemana(moment(key.sendingDate).format('DD').toString())).value;

      if(parseInt(dia_actual)!=parseInt(index)){

        dia_actual=index;
        suma_report=0;
      }

      if (/^([0-9])*$/.test(key.sendingOk)){

        suma_report+=parseInt(key.sendingOk);

      }


      arr[index] = suma_report

      //alert(dia_actual+" "+diaSemana(moment(key.sendingDate).format('DD').toString()) +" = "+key.sendingOk+" = "+index+" = "+suma_report)

    })

    return arr;

  }
  export function dataReportNoEnvio(){


    var  arr=[];
    JSON.parse(localStorage.getItem('enviosreport')).forEach(function(key) {

      arr.push(key.sendingNoOk)

    })

    return arr;

  }*/

  export async function  getTimeServe(){

    var resp="";

    var js = {
      "customerId": 1,
      "time_expired": horaAhora(),
    }


    var body = new URLSearchParams(); body.append('TOKEN', (encryptAES(js)));

    await axios.post(Config.url_service+Config.time_serve, body, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    }).then(function (response) {

        if (response.data.response === "200") {

          resp =  response.data.data;

        }

    })
    .catch(function (error) {
        console.log("Error de servidor get time ",error);
    });

    return resp;

  }

  export async function  getUserRealTime(){

    let encryptedToken = localStorage.getItem('user');
    let user = decryptAES(encryptedToken);
    let responseUser = "";


    if(getUserSub()!=null){

      var json_login = {
        "email": getUserSub().email,
        "password": getUserSub().password,
        "userPhone": "false",
        "timeExpired": horaAhora(),
      }

    }else{

       json_login = {
        "email": user.email,
        "password": user.password,
        "userPhone": "false",
        "timeExpired": horaAhora(),
      }

    }



    // encryption
    await axios.get(Config.url_service+Config.login+encodeURIComponent(encryptAES(json_login)))
    .then(function (response) {
        console.log("UPDATE USER REALTIME GET ",response)

        if (response.data.response === "200") {

          responseUser =  response.data.data.user;

          if(getUserSub()==null){
              setUser(response.data.data.user)
          }else{
            setUserSub(response.data.data.user)
          }

        }else{

          /*swal({
            title: "Error obteniendo datos de usuario",
            buttonsStyling: false,
            confirmButtonClass: 'btn btn-danger btn-fill'
            })*/
          console.log("Error obteniendo datos de usuario");

          window.location.href="/login";

        }

    })
    .catch(function (error) {
        console.log("Error de servidor get user ",error);
       /* swal({
          title: "Error obteniendo datos de usuario",
          buttonsStyling: false,
          confirmButtonClass: 'btn btn-danger btn-fill'
        })*/
        window.location.href="/login";

    });

    return responseUser;

  }


export function removeElement(array, item){
  for(var i in array){
      if(array[i]==item){
          array.splice(i,1);
          break;
      }
  }
}

export function setUser(obj){
  localStorage.setItem('user', encryptAES(obj));

}


export function setUserSub(obj){
  localStorage.setItem('user_sub', JSON.stringify(obj));

}


/*export function setUserAdmin(obj){
  localStorage.setItem('user_admin', JSON.stringify(obj));

}*/

export function encryptAES(json_gen) {
  let token = AES256.encrypt(JSON.stringify(json_gen), 'vFeLfR2MnXgXQStZWglFO6AffhROonTq');
  return token;
}
export function decryptAES(encryptedToken) {
    try {
      let decryptedJson = AES256.decrypt(encryptedToken, 'vFeLfR2MnXgXQStZWglFO6AffhROonTq');
      return JSON.parse(decryptedJson);
    } catch (error) {
      console.error("Error al descifrar el token:", error);
      return null;
    }
  }
export function horaAhora() {
  return moment().format('YYYY-MM-DD h:mm:ss');
}
export function horaAhora2() {
  return moment().format('YYYY-MM-DDTHH:mm:ss');
}
export function fechaFormato(unixTimestamp) {
  return moment(unixTimestamp).format('YYYY-MM-DD HH:mm:ss');
}
  export function fechaFormatoVariante2(unixTimestamp) {
    return moment(unixTimestamp).format('YYYY-MM-DDTHH:mm:ss');
  }
/*export function customer_user() {
    let user = JSON.parse(localStorage.getItem('user'));

    let responseUser = "";

    var js = {
      "customer": encodeURIComponent(JSON.stringify(user.customer)),
      "time_expired": horaAhora(),
    }

    // encryption
    var body = new URLSearchParams(); body.append('TOKEN', (encryptAES(js)));

    Vue.axios.post(Config.url_service+Config.costumer_user_available, body, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    })
    .then(function (response) {
        console.log(response);

        if (response.data.response === "200") {

          responseUser = response.data.data;

        }

    })
    .catch(function (error) {
        console.log("Error al obtener usuarios",error);
    });

    return responseUser;
}





/!*export function validarRut(rut){

  var rut = document.getElementById('rut').value;
  document.getElementById('rut').value = rut.replace(/\s/g, '');

  if(rut){
    //MATCH DE SOLO CARACTERES IMPORTANTES
    rut = rut.match(/[0-9Kk]+/g).join('');
    return true
  }
  else{
    return false;
  }
};*!/

export function verificarDigito(rut){

  var factor = 2,
      auxSum = 0,
      digitoVerificador = rut[rut.length - 1],
      l = rut.length - 1,
      digitoCalculado;

  //-- a la derecha
  while(l--){
    //+ operador 'unary' para hacer cast
    //  a number
    auxSum += +(rut[l]) * factor;                              //operador binario
    factor = factor === 7 ? 2
                          : ++factor; //++ a la izquierda
  }

  digitoCalculado = 11 - (auxSum % 11);
  digitoCalculado =  digitoCalculado === 11 ? 0
                                            : (digitoCalculado === 10? 'K' : digitoCalculado);

  return String(digitoCalculado).toLowerCase() === String(digitoVerificador).toLowerCase();
}*/


export function onPermissionsUser(vista, permisoSolicitado) {

  //let self = this;
  //let user = getUser();
  let encryptedToken = localStorage.getItem('user_permisos');
  var permisosUser = decryptAES(encryptedToken);

    var result = false;

    if(typeof permisosUser !=="undefined" && permisosUser!=null){

    if(permisoSolicitado == "view") {

      permisosUser.forEach(function(permisoVer) {
        if(permisoVer.applicationMenu.view == vista && permisoVer.retrieve == true) {

          result = true;

        }
      })
    } else

    if(permisoSolicitado == "destroy") {
      permisosUser.forEach(function(permisoBorrar) {
        if(permisoBorrar.applicationMenu.view == vista && permisoBorrar.destroy == true) {

          result = true;

        }
      })
    } else

    if(permisoSolicitado == "modify") {
      permisosUser.forEach(function(permisoModificar) {
        if(permisoModificar.applicationMenu.view == vista && permisoModificar.modify == true) {

          result = true;

        }
      })
    } else

    if(permisoSolicitado == "create") {
      permisosUser.forEach(function(permisoCrear) {
        if(permisoCrear.applicationMenu.view == vista && permisoCrear.creates == true) {

          result = true;
        }

      })
    } else {
      result = true;
    }

  }


    return result;



}

/*export async function initPermisos() {

  let self = this;
  let user = getUser();

  let permisosUser = {};

  let js = {
    "time_expired":horaAhora(),
    "customer_user":encodeURIComponent(JSON.stringify(user))
  }

  var body = new URLSearchParams(); body.append('TOKEN', (encryptAES(js)));

  await Vue.axios.post(Config.url_service+Config.customer_user_access, body, {
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
  },
  })
    .then(function (response) {
        if (response.data.response == "200") {
          console.log(response.data.data);

          permisosUser = response.data.data;

          localStorage.setItem('user_permisos', JSON.stringify(permisosUser));


        } else {
          console.log("No se pudo obtener permisos");
          swal({
            title: 'No se pudo obtener permisos',
            buttonsStyling: false,
            confirmButtonClass: 'btn btn-danger',
            type: 'error',
          })

        }


    })
    .catch(function (error) {
        console.log("No se pudo obtener permisos",error);
        swal({
          title: 'No se pudo obtener permisos',
          buttonsStyling: false,
          confirmButtonClass: 'btn btn-danger',
          type: 'error',
        })

    });

}*/
