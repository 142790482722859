<template>
  <div class="container-fluid py-5">
    <div class="row">
      <div class="col-12">
        <div class="multisteps-form">
          <!--form panels-->
          <div class="row">
            <div class="col-12 col-lg-10 m-auto">
              <form class="multisteps-form__form">
                <sms-link-table :plantillaData="response"></sms-link-table>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {encryptAES, horaAhora, getUser, getUserSub} from "@/config/servicios/campana/util";
import {obtenerLista} from "@/config/servicios/campana/envios/servicesSMS";
import smsLinkTable from "@/views/envios/components/Sms-linkTable.vue";
export default {
  name: "SmsLink",
  components: {
    smsLinkTable,
  },
  data() {
    return {
      response: {},
    };
  },
  methods: {
    async obtenerLista(){
      try {
        let user = (getUserSub()!=null) ? getUserSub() :getUser();
        let js = {
          time_expired: horaAhora(),
          "customer_user":encodeURIComponent(JSON.stringify(user)),
        };

        var body = new URLSearchParams();
        body.append("TOKEN", encryptAES(js));


        const response = await obtenerLista(body);
        // Almacena la respuesta en la variable de datos
        if (response.data.response === "200") {
          console.log(response.data.data);

          this.response = response.data.data;
        } else {
          console.log("No se encontró lista");

        }
      } catch (error) {
        console.error("Error al obtener datos", error);
      }
    }
  },
  mounted() {
    this.obtenerLista();
  },
  created() {

  }
};
</script>
<style scoped>
</style>