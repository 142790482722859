<template>
  <div>
    <input
        ref="flatpickr"
        v-model="selectedDate"
        class="flatpickr form-control mb-2 mb-md-0"
        :placeholder="value"
    />
  </div>
</template>

<script>
import Flatpickr from 'flatpickr';
import 'flatpickr/dist/flatpickr.min.css';

export default {
  name: 'DatePicker',
  props: {
    value: {
      type: String,
      default: null
    }
  },
  emits: ['fechaSeleccionada'],
  data() {
    return {
      selectedDate: null
    };
  },
  watch: {
    selectedDate(newVal) {
      this.$emit('fechaSeleccionada', newVal);
    }
  },
  mounted() {
    Flatpickr(this.$refs.flatpickr, {
      enableTime: false,
      dateFormat: 'Y-m-d',
      time_24hr: true,
      locale: {
        firstDayOfWeek: 1,
        weekdays: {
          shorthand: ['Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab'],
          longhand: [
            'Domingo',
            'Lunes',
            'Martes',
            'Miércoles',
            'Jueves',
            'Viernes',
            'Sábado'
          ]
        },
        months: {
          shorthand: [
            'Ene',
            'Feb',
            'Mar',
            'Abr',
            'May',
            'Jun',
            'Jul',
            'Ago',
            'Sep',
            'Oct',
            'Nov',
            'Dic'
          ],
          longhand: [
            'Enero',
            'Febrero',
            'Marzo',
            'Abril',
            'Mayo',
            'Junio',
            'Julio',
            'Agosto',
            'Septiembre',
            'Octubre',
            'Noviembre',
            'Diciembre'
          ]
        }
      }
      // Aquí puedes añadir más opciones de configuración según tus necesidades
    });
  }
};
</script>

<style>
.flatpickr-calendar .flatpickr-days .flatpickr-day.today, 
.flatpickr-calendar .flatpickr-days .flatpickr-day.selected, 
.flatpickr-calendar .flatpickr-days .flatpickr-day.startRange, 
.flatpickr-calendar .flatpickr-days .flatpickr-day.endRange {
  background: #0033A0 !important;
}

</style>