// api.js
import axios from 'axios';
import Config from "@/config/servicios/campana/config";

export const mo_response = (body) => {
    return axios.post(Config.url_service + Config.mo_response,body,{
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        }});
};
//Configuracion MO (SMS)
export const mo_add_sms = (body) => {
    return axios.post(Config.url_service + Config.mo_add,body,{
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        }});
};
export const mo_edit_sms = (body) => {
    return axios.post(Config.url_service + Config.mo_edit,body,{
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        }});
};
export const mo_delete_sms = (body) => {
    return axios.post(Config.url_service + Config.mo_delete,body,{
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        }});
};
//Configuracion MO (WHATSAPP)
export const mo_add_whatsapp = (body) => {
    return axios.post(Config.url_service + Config.mo_add_whatsapp,body,{
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        }});
};
export const mo_edit_whatsapp = (body) => {
    return axios.post(Config.url_service + Config.mo_edit_whatsapp,body,{
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        }});
};
export const mo_delete_whatsapp = (body) => {
    return axios.post(Config.url_service + Config.mo_delete_whatsapp,body,{
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        }});
};