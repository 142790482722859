<template>
  <div class="card">
    <div class="card-header border-1 d-flex justify-content-between align-items-center flex-column flex-md-row">
      <h6 class="mb-md-0">Envios Programados</h6>
      
      <div class="d-flex align-items-center">
        <div class="tooltip-container badge badge-lg badge-celcom-deep-blue text-white my-2 my-md-0 me-2" @mouseover="showTooltip" @mouseout="hideTooltip">
          <i class="fas fa-envelope-open-text mr-2"></i>
          <span v-if="showingTooltip" class="tooltip">Créditos Disponibles</span>
          {{ numberWithCommas((getUserSub != null) ? getUserSub.remainingSms : dataUserActual.remainingSms) }}
        </div>
        <button
            class="btn btn-celcom-orange m-0"
            data-toggle="tooltip"
            data-original-title="Agregar Contacto"
            @click.prevent="agregar"
        >
          <i class="fas fa-plus"></i> Nuevo Envío
        </button>
      </div>
    </div>

    <!-- Agrega un campo de entrada para el buscador -->
    <div class="card-body pb-0">
      <input v-model="search" type="text" class="form-control mt-2" placeholder="Buscar...">
    </div>
    <div class="card-body table-responsive">
      <table class="table align-items-center mb-0">
        <thead>
        <tr>
          <th
              class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
          >
            Nombre
          </th>
          <th
              class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
          >
            Fecha de envío
          </th>
          <th
              class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
          >
            Tipo
          </th>
          <th
              class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
          >
            Contactos
          </th>
          <th
              class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
          >
            Estado
          </th>
          <th class="text-secondary opacity-7"></th>
        </tr>
        </thead>
        <tbody>
        <!-- Utiliza v-for para recorrer el arreglo y generar filas -->
        <tr v-for="(programado, index) in filteredData" :key="index">
          <td class="align-middle text-center text-sm">
            <div class="d-flex flex-column justify-content-center">
              <h6 class="mb-0 text-xs">{{ (programado && programado.name) ? programado.name : 'Sin nombre' }}</h6>
            </div>
          </td>
          <td class="align-middle text-center text-sm">
            <div class="d-flex flex-column justify-content-center">
              <h6 class="mb-0 text-xs">{{ formatoFecha(programado.calendar) }}</h6>
            </div>
          </td>
          <td class="align-middle text-center text-sm">
            <div class="d-flex flex-column justify-content-center">
              <h6 class="mb-0 text-xs">{{  programado.receiverType === 2 ? obtenerTipo(programado) : "Individual" }}</h6>
            </div>
          </td>
          <td class="align-middle text-center text-sm">
            <div class="d-flex flex-column justify-content-center">
              <h6 class="mb-0 text-xs">{{  programado.receiverType === 2 ? $parent.findCustomerByContactGroupId(programado.receiverId).validos : 1 }}</h6>
            </div>
          </td>
          <td class="align-middle text-center text-sm">
            <span class="badge badge-dot">
                <i class="bg-info"></i>
                <span class="text-dark text-xs">{{"Activo"}}</span>
              </span>
          </td>
          <td class="align-middle text-center text-sm">
            <button
                class="btn btn-info me-2"
                data-toggle="tooltip"
                data-original-title="Edit user"
                @click.prevent="$parent.editarProgramda(programado)"
            >
              <img src="../../../assets/iconos/pen-to-square-solid.svg" alt="Cesto de basura" class="icono-borrar">
            </button>
            <button
                class="btn btn-danger"
                data-toggle="tooltip"
                data-original-title="Eliminar user"
                @click.prevent="$parent.openModal(programado)"
            >
              <i class="fas fa-duotone fa-trash"></i>
            </button>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import {formatoFecha} from "@/config/config";
import {getUser, getUserSub} from "@/config/servicios/campana/util";
export default {
  name: "ProgramadosTable",
  components: {

  },
  props: {
    programadoData: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      showingTooltip: false,
      dataUserActual: getUser(),
      getUserSub : getUserSub(),
      modalVisible: false,
      search: "", // Variable para almacenar la entrada del buscador
    };
  },
  computed: {
    // Utiliza una propiedad computada para filtrar los datos según la búsqueda
    filteredData() {
      if (!Array.isArray(this.programadoData)) {
        return [];
      }
      return this.programadoData.filter(programado => {
        const name = programado.name ?? '';
        const phoneCode = programado.phoneCode;
        const phone = programado.phone;

        return (
            name.toLowerCase().includes(this.search.toLowerCase()) ||
            (phoneCode !== undefined && phoneCode.toString().includes(this.search.toLowerCase())) ||
            (phone !== undefined && phone.toString().includes(this.search.toLowerCase()))
        );
      });
    },
  },
  methods: {
    obtenerTipo(programada){
      if(programada){
        let obj = JSON.parse(decodeURIComponent(programada.filterstr));
        if (Array.isArray(obj.segment) && obj.segment.length > 0) {
          return "Segmento";
        } else if (Array.isArray(obj.tags) && obj.tags.length > 0) {
          return "Tags";
        } else {
          return "Lista";
        }
      }
    },
    showTooltip() {
      this.showingTooltip = true;
    },
    hideTooltip() {
      this.showingTooltip = false;
    },
    numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    formatoFecha(fecha) {
      return formatoFecha(fecha);
    },
    openModal() {
      this.modalVisible = true;
    },
    closeModal() {
      this.modalVisible = false;
    },
    agregar(){
      this.$router.push({ name: "Programar Envio" });
      console.log("Agregar");
    },
  },
};
</script>

<style scoped>
.btn-plantilla{
  color: #fff;
  background-color: #e64477;
  border-color: #e64477;
}
/* Agrega estilos según tus necesidades */
.icono-rojo {
  color: red;
}
.modal {
  display: none;
}

.modal.is-active {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
}

.modal-close {
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
  background: none;
  border: none;
}
.buttons {
  margin: 8px;
}
.tooltip-container {
  position: relative;
  display: inline-block;
}

.tooltip {
  position: absolute;
  bottom: 100%; /* Coloca el tooltip arriba del contenido */
  left: 50%;
  transform: translateX(-50%);
  padding: 5px;
  background-color: #333;
  color: #fff;
  border-radius: 3px;
  font-size: 12px;
  opacity: 0;
  transition: opacity 0.3s ease;
  pointer-events: none; /* Evita que el tooltip sea clickeable */
}

.tooltip-container:hover .tooltip {
  opacity: 1;
}
</style>