<template>
  <div class="container-fluid py-5">
    <div class="row">
      <div class="col-12">
        <div class="multisteps-form">
          <!--form panels-->
          <div class="row">
            <div class="col-12 col-lg-10 m-auto">
              <form class="multisteps-form__form">
                <ConfiguracionMoTable :response-mo="responseMo"/>
              </form>
            </div>
          </div>
        </div>
      </div>
      <modal :is-visible="modalAdd" close-modal="">
        <div class="row">
          <div class="col-12 col-sm-6">
            <p><b>Datos de la Configuración</b></p>
            <div class="form-check">
              <input
                  v-model="addMO.active"
                  class="form-check-input"
                  type="checkbox"
                  id="estadoCheckboxAdd"
              />
              <label class="form-check-label" for="estadoCheckboxAdd">
                {{ addMO.active === true ? "Activo" : "Inactivo" }}
              </label>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-sm-5">
            <label>Tipo</label>
            <select
                v-model="addMO.type"
                class="multisteps-form__select form-control form-select"
                @change="limpiarDatos"
            >
              <option value="" disabled selected>Seleccione Tipo</option>
              <option
                  v-for="(type, typeIndex) in responseType"
                  :key="typeIndex"
                  :value="type"
              >
                {{ type }}
              </option>
            </select>
          </div>
          <div v-if="addMO.type === 'DEFAULT' && addMO.type" class="col-12 col-sm-7">
            <label>Perfil</label>
            <select
                v-model="addMO.customerWhatsappId"
                class="multisteps-form__select form-control form-select"
            >
              <option value="" disabled selected>Seleccione Perfil</option>
              <option
                  v-for="(perfil, perfilIndex) in responsePerfil"
                  :key="perfilIndex"
                  :value="perfil.customerWhatsappId"
              >
                {{ perfil.whatsappName }}
              </option>
            </select>
          </div>
          <div v-if="addMO.type === 'TEMPLATE' && addMO.type" class="col-12 col-sm-7">
            <label>Plantillas</label>
            <select
                v-model="plantillaBotones"
                class="multisteps-form__select form-control form-select"
            >
              <option value="" disabled selected>Seleccione Plantilla</option>
              <option
                  v-for="(plantilla, plantillaIndex) in responseTemplate"
                  :key="plantillaIndex"
                  :value="plantilla"
              >
                {{ plantilla.templateName }}
              </option>
            </select>
          </div>
          <div v-if="addMO.type === 'BUTTON'" class="col-12 col-sm-7">
            <label>Plantillas</label>
            <select
                v-model="plantillaBotones"
                class="multisteps-form__select form-control form-select"
            >
              <option value="" disabled selected>Seleccione Plantilla</option>
              <option
                  v-for="(plantilla, plantillaIndex) in responseTemplateBotones"
                  :key="plantillaIndex"
                  :value="plantilla"
              >
                {{ plantilla.templateName }}
              </option>
            </select>
          </div>
          <div v-if="plantillaBotones !== '' && addMO.type === 'BUTTON'" class="col-12 col-sm-6">
            <label>Botones</label>
            <select
                v-model="addMO.buttonId"
                class="multisteps-form__select form-control form-select"
                @change="setSelectedPlantilla"
            >
              <option value="" disabled selected>Seleccione Boton</option>
              <option
                  v-for="(boton, botonIndex) in plantillaBotones.whatsappButtonList"
                  :key="botonIndex"
                  :value="boton.whatsappButtonId"
              >
                {{ boton.buttonName }}
              </option>
            </select>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <label>Texto a responder</label>
            <textarea
                id="mensajeEdit"
                v-model="addMO.message"
                class="form-control espacio"
                placeholder="Mensaje"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-sm-6">

          </div>
        </div>
        <div class="modal-footer px-0 mt-lg-4 d-flex justify-content-between">
          <button
              class="btn bg-gradient-celcom-orange mb-0"
              :disabled="validarForm"
              @click="addConfiguracionMo">Agregar</button>
          <button class="btn bg-gradient-dark mb-0" @click="closeModal">Cancelar</button>
        </div>
      </modal>
      <modal :is-visible="modalEditar" close-modal="">
        <div class="row">
          <div class="col-12 col-sm-6">
            <p><b>Edite la Configuración</b></p>
            <div class="form-check">
              <input
                  v-model="dataMo.active"
                  class="form-check-input"
                  type="checkbox"
                  id="estadoCheckbox"
              />
              <label class="form-check-label" for="estadoCheckbox">
                {{ dataMo.active === true ? "Activo" : "Inactivo" }}
              </label>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-sm-5">
            <label>Tipo</label>
            <select
                v-model="dataMo.type"
                class="multisteps-form__select form-control form-select"
                @change="limpiarDatosEdit"
            >
              <option value="" disabled selected>Seleccione Tipo</option>
              <option
                  v-for="(type, typeIndex) in responseType"
                  :key="typeIndex"
                  :value="type"
              >
                {{ type }}
              </option>
            </select>
          </div>
          <div v-if="dataMo.type === 'DEFAULT'" class="col-12 col-sm-7">
            <label>Perfil</label>
            <select
                v-model="dataMo.customerWhatsappId"
                class="multisteps-form__select form-control form-select"
            >
              <option value="" disabled selected>Seleccione Perfil</option>
              <option
                  v-for="(perfil, perfilIndex) in responsePerfil"
                  :key="perfilIndex"
                  :value="perfil.customerWhatsappId"
              >
                {{ perfil.whatsappName }}
              </option>
            </select>
          </div>
          <div v-if="dataMo.type === 'TEMPLATE'" class="col-12 col-sm-7">
            <label>Plantillas</label>
            <select
                v-model="plantillaBotones"
                class="multisteps-form__select form-control form-select"
            >
              <option value="" disabled selected>Seleccione Plantilla</option>
              <option
                  v-for="(plantilla, plantillaIndex) in responseTemplate"
                  :key="plantillaIndex"
                  :value="plantilla"
              >
                {{ plantilla.templateName }}
              </option>
            </select>
          </div>
          <div v-if="dataMo.type === 'BUTTON'" class="col-12 col-sm-7">
            <label>Plantillas</label>
            <select
                v-model="plantillaBotones"
                class="multisteps-form__select form-control form-select"
            >
              <option value="" disabled selected>Seleccione Plantilla</option>
              <option
                  v-for="(plantilla, plantillaIndex) in responseTemplateBotones"
                  :key="plantillaIndex"
                  :value="plantilla"
              >
                {{ plantilla.templateName }}
              </option>
            </select>
          </div>
          <div v-if="plantillaBotones !== '' && dataMo.type === 'BUTTON'" class="col-12 col-sm-6">
            <label>Botones</label>
            <select
                v-model="dataMo.buttonId"
                class="multisteps-form__select form-control form-select"
            >
              <option value="" disabled selected>Seleccione Boton</option>
              <option
                  v-for="(boton, botonIndex) in plantillaBotones.whatsappButtonList"
                  :key="botonIndex"
                  :value="boton.whatsappButtonId"
              >
                {{ boton.buttonName }}
              </option>
            </select>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <label>Texto a responder</label>
            <textarea
                id="mensajeEdit"
                v-model="dataMo.message"
                class="form-control espacio"
                placeholder="Mensaje"
            />
          </div>
        </div>
        <div class="modal-footer px-0 mt-lg-4 d-flex justify-content-between">
          <button
              class="btn bg-gradient-celcom-orange mb-0"
              :disabled="validarFormEdit"
              @click="editarConfiguracionMo">Editar</button>
          <button class="btn bg-gradient-dark mb-0" @click="closeModal">Cancelar</button>
        </div>
      </modal>
      <modal-delete :is-visible="modalDelete" close-modal="">
        <p class="text-center"><b>¿Estás seguro de que deseas eliminar?</b></p>
        <div class="mt-lg-4 d-flex justify-content-between">
          <button class="btn bg-gradient-danger mb-0" @click="deleteConfiguracionMo">Eliminar</button>
          <button class="btn bg-gradient-dark mb-0" @click="closeModal">Cancelar</button>
        </div>
      </modal-delete>
    </div>
  </div>
</template>

<script>

import {encryptAES, horaAhora, getUser, getUserSub} from "@/config/servicios/campana/util";
import {mo_response, mo_add_whatsapp, mo_edit_whatsapp, mo_delete_whatsapp} from "@/config/servicios/campana/configuracionMo/servicesConfiguracionMo";
import ConfiguracionMoTable from "@/views/channels/components/ConfiguracionMoWhatsappTable.vue";
import Modal from "@/components/Modal/Modal.vue";
import ModalDelete from "@/components/Modal/ModalDelete.vue";
import swal from "sweetalert2";
import {LIST_WSP_ACCOUNT_BY_CUSTOMER} from "@/config/servicios/campana/whatsapp/servicesWhatsapp";

export default {
  name: "ConfiguracionMoWhatsapp",
  components: {
    ModalDelete,
    Modal,
    ConfiguracionMoTable,
  },
  data() {
    return {
      plantillaBotones: "",
      responseType: [
        "DEFAULT",
        "BUTTON",
        "TEMPLATE"
      ],
      responsePerfil: [],
      responseMo: [],
      dataMo: {},
      addMO: {
        customerMoResponseId: null,
        customerId: null,
        whatsappSenderId: null,
        type: "",
        buttonId: null,
        message: "",
        hdmid: "",
        active: false,
        customerWhatsappId: "",
        source: "",
        channelId: 2,
      },
      modalDelete: false,
      modalEditar: false,
      modalAdd: false,
    };
  },
  computed: {
    validarForm() {
      if (this.addMO.type) {
        if (this.addMO.type === 'DEFAULT') {
          return this.addMO.message === "" || this.addMO.customerWhatsappId === "";
        } else if (this.addMO.type === 'TEMPLATE') {
          return this.addMO.message === "" || this.addMO.hdmid === "";
        } else if (this.addMO.type === 'BUTTON') {
          return this.addMO.message === "" || this.addMO.hdmid === "" || this.addMO.buttonId === "";
        }
        return false;
      }else {
        return true;
      }
    },
    validarFormEdit() {
      if (this.dataMo.type) {
        if (this.dataMo.type === 'DEFAULT') {
          return this.dataMo.message === "" || this.dataMo.customerWhatsappId === "";
        } else if (this.dataMo.type === 'TEMPLATE') {
          return this.dataMo.message === "" || this.dataMo.hdmid === "";
        } else if (this.dataMo.type === 'BUTTON') {
          return this.dataMo.message === "" || this.dataMo.hdmid === "" || this.dataMo.buttonId === "";
        }
        return false;
      }else {
        return true;
      }
    },
    responseTemplate() {
      const templates = this.$store.state.LIST_TEMPLATE_WHATSPP_BY_CUSTOMER;
      return Array.isArray(templates) ? templates : [];
    },
    responseTemplateBotones() {
      return this.responseTemplate.filter(item => item.whatsappButtonList.length > 0 && item.statusWhatsapp === 'APPROVED');
    },
    responseTemplateNoBotones() {
      return this.responseTemplate.filter(item => item.whatsappButtonList.length === 0 && item.statusWhatsapp === 'APPROVED');
    },
  },
  watch: {
    message(newValue) {
      console.log('Valor de message:', newValue);
    },
  },
  created() {
    this.$store.commit('RESET_Data_Paginada');
  },
  async mounted() {
    this.obtenerConfiguracionMo();
    this.obtenerLista();
    await this.$store.getters.list_template_whatsapp();
  },
  methods: {
    setSelectedPlantilla() {
      if (this.dataMo.type === 'BUTTON') {
        this.plantillaBotones = this.responseTemplateBotones.find(item => item.whatsappSenderId === this.dataMo.whatsappSenderId);
      }
    },
    async obtenerLista(){
      try {
        let user = (getUserSub()!=null) ? getUserSub() :getUser();

        let js = {
          time_expired: horaAhora(),
          "customer":encodeURIComponent(JSON.stringify(user.customer)),
        };

        var body = new URLSearchParams();
        body.append("TOKEN", encryptAES(js));


        const response = await LIST_WSP_ACCOUNT_BY_CUSTOMER(body);
        // Almacena la respuesta en la variable de datos
        if (response.data.response === "200") {
          console.log(response.data.data);

          this.responsePerfil = response.data.data;
        } else {
          console.log("No se encontró lista");

        }
      } catch (error) {
        console.error("Error al obtener datos", error);
      }
    },
    openModal(dataMo) {
      this.dataMo = dataMo;
      this.modalDelete = true;
    },
    openModalAdd() {
      this.modalAdd = true;
    },
    openModalEditar(dataMo) {
      this.dataMo = dataMo;
      this.modalEditar = true;
      if (this.dataMo.type === "BUTTON") {
        this.setSelectedPlantilla();
      }
    },
    limpiarDatosEdit() {
      if (this.dataMo.type) {
        this.plantillaBotones = "";
        this.dataMo = {
          ...this.dataMo,
          customerMoResponseId: null,
          customerId: null,
          buttonId: null,
          message: "",
          hdmid: null,
          customerWhatsappId: null,
          source: "",
          channelId: 2,
        };
      }
    },
    limpiarDatos() {
      if (this.addMO.type) {
        this.plantillaBotones = "";
        this.addMO = {
          ...this.addMO,
          customerMoResponseId: null,
          customerId: null,
          buttonId: null,
          message: "",
          hdmid: null,
          customerWhatsappId: null,
          source: "",
          channelId: 2,
        };
      }
    },
    async closeModal() {
      await this.obtenerConfiguracionMo();
      this.plantillaBotones = "";
      this.modalDelete = false;
      this.modalEditar = false;
      this.modalAdd = false;
      this.dataMo = {};
      this.addMO = {
        customerMoResponseId: null,
        customerId: null,
        type: "",
        buttonId: null,
        message: "",
        hdmid: null,
        active: false,
        customerWhatsappId: null,
        source: "",
        channelId: 2,
      };
    },
    async obtenerConfiguracionMo(){
      try {
        let user = (getUserSub()!=null) ? getUserSub() :getUser();
        let js = {
          time_expired: horaAhora(),
          customer: encodeURIComponent(JSON.stringify(user.customer))
        };

        var body = new URLSearchParams();
        body.append("TOKEN", encryptAES(js));


        const response = await mo_response(body);
        // Almacena la respuesta en la variable de datos
        if (response.data.response === "200") {
          this.responseMo = response.data.data.filter(item => item.channelId === 2);
        } else {
          console.log("No se encontró lista");

        }
      } catch (error) {
        console.error("Error al obtener datos", error);
      }
    },
    async addConfiguracionMo() {
      try {
        let user = (getUserSub()!=null) ? getUserSub() :getUser();
        this.addMO.customerId = user.customer.id;
        this.addMO.hdmid = this.plantillaBotones.templateId;
        this.addMO.whatsappSenderId = this.plantillaBotones.whatsappSenderId;
        if (this.plantillaBotones) {
          this.addMO.customerWhatsappId = this.plantillaBotones.customerWhatsapp.customerWhatsappId;
        }
        let js = {
          time_expired: horaAhora(),
          customer: encodeURIComponent(JSON.stringify(user.customer)),
          customerMoResponse: encodeURIComponent(JSON.stringify(this.addMO))
        };
        var body = new URLSearchParams();
        body.append("TOKEN", encryptAES(js));


        const response = await mo_add_whatsapp(body);
        // Almacena la respuesta en la variable de datos
        if (response.data.response === "200") {
          this.mo_response = response.data.data;
          swal.fire({
            text: '¡Configuración creada exitosamente!',
            buttonsStyling: false,
            customClass: {
              confirmButton: 'btn btn-celcom-blue',
            },
            icon: 'success',
          })
          this.closeModal();
          await this.obtenerConfiguracionMo();
        } else if (response.data.response === "400" && response.data.message === "Registro ya Existe"){
          swal.fire({
            text: '¡Ya tiene un registro con esta configuración!',
            buttonsStyling: false,
            customClass: {
              confirmButton: 'btn btn-danger',
            },
            icon: 'error',
          })
          this.closeModal();
           await this.obtenerConfiguracionMo();
        }else {
          swal.fire({
            text: '¡Error al Crear la Configuración!',
            buttonsStyling: false,
            customClass: {
              confirmButton: 'btn btn-danger',
            },
            icon: 'error',
          })
          this.closeModal();
           await this.obtenerConfiguracionMo();
        }
      } catch (error) {
        swal.fire({
          text: '¡Error al Crear la Configuración!',
          buttonsStyling: false,
          customClass: {
            confirmButton: 'btn btn-danger',
          },
          icon: 'error',
        })
        this.closeModal();
         await this.obtenerConfiguracionMo();
      }
    },
    async editarConfiguracionMo() {
      try {
        let user = (getUserSub()!=null) ? getUserSub() :getUser();
        this.dataMo.hdmid = this.plantillaBotones.templateId;
        this.dataMo.whatsappSenderId = this.plantillaBotones.whatsappSenderId;
        if (this.plantillaBotones) {
          this.dataMo.customerWhatsappId = this.plantillaBotones.customerWhatsapp.customerWhatsappId;
        }
        let js = {
          time_expired: horaAhora(),
          customer: encodeURIComponent(JSON.stringify(user.customer)),
          customerMoResponse: encodeURIComponent(JSON.stringify(this.dataMo))
        };

        var body = new URLSearchParams();
        body.append("TOKEN", encryptAES(js));


        const response = await mo_edit_whatsapp(body);
        // Almacena la respuesta en la variable de datos
        if (response.data.response === "200") {
          this.mo_response = response.data.data;
          swal.fire({
            text: '¡Configuración editada exitosamente!',
            buttonsStyling: false,
            customClass: {
              confirmButton: 'btn btn-celcom-blue',
            },
            icon: 'success',
          })
          this.closeModal();
           await this.obtenerConfiguracionMo();
        }  else if (response.data.response === "400" && response.data.message === "Registro ya Existe"){
          swal.fire({
            text: '¡Ya tiene un registro con esta configuración!',
            buttonsStyling: false,
            customClass: {
              confirmButton: 'btn btn-danger',
            },
            icon: 'error',
          })
          this.closeModal();
           await this.obtenerConfiguracionMo();
        }else {
          swal.fire({
            text: '¡Error al Editar la Configuración!',
            buttonsStyling: false,
            customClass: {
              confirmButton: 'btn btn-danger',
            },
            icon: 'error',
          })
          this.closeModal();
           await this.obtenerConfiguracionMo();
        }
      } catch (error) {
        swal.fire({
          text: '¡Error al Editar la Configuración!',
          buttonsStyling: false,
          customClass: {
            confirmButton: 'btn btn-danger',
          },
          icon: 'error',
        })
        this.closeModal();
      }
    },
    async deleteConfiguracionMo() {
      if (this.dataMo) {
        try {
          let user = (getUserSub()!=null) ? getUserSub() :getUser();
          let js = {
            time_expired: horaAhora(),
            customer: encodeURIComponent(JSON.stringify(user.customer)),
            customerMoResponse: encodeURIComponent(JSON.stringify(this.dataMo))
          };
          var body = new URLSearchParams();
          body.append("TOKEN", encryptAES(js));

          const response = await mo_delete_whatsapp(body);

          if (response.data.response === "200") {
            console.log("Configuración Eliminada ", response.data.data);
            this.closeModal();
          } else {
            console.log("No se pudo eliminar la Configuración");
          }
          console.log("Configuración eliminada" );
        } catch (error) {
          console.error("Error al eliminar la Configuración", error);
        } finally {
          this.modalDelete = false;
          await this.obtenerConfiguracionMo();
        }
      }
    },
  },
};
</script>
<style scoped>
/* Agrega estilos según tus necesidades */
.modal {
  display: none;
}

.modal.is-active {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
}

.modal-close {
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
  background: none;
  border: none;
}
.buttons {
  margin: 8px;
}
.espacio{
  margin-bottom: 0;;
}
.espacio1{
  margin-top: 0;
  margin-left: 1px;
  margin-right: 1px;
}
label {
  margin-left: 0;
}
</style>