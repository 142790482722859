// api.js
import axios from 'axios';
import {decryptAES} from "@/config/servicios/campana/util";

const API_BASE_URL = 'https://automata2.celcom.cl/flex/api/';

const urlCustomerId = () => {
// Obtener el valor del local storage
    let encryptedToken = localStorage.getItem('user');
    const userDataString = decryptAES(encryptedToken);

// Convertir la cadena JSON a un objeto JavaScript
    const userData = JSON.parse(userDataString);

// Obtener el valor de customerId
    return userData.customer.customerId;
};
export const testFtp = (host, user, password, port, path) => {
    console.log("testFtp")
    const API_LIST_CONT = 'contact/ftp/'+ urlCustomerId() + '/test';
    return axios.post(`${API_BASE_URL}`+ API_LIST_CONT,data(host, user, password, port, path));
};

export const addFtp = (host, user, password, port, path) => {
    console.log("addFtp")
    const API_LIST_CONT = 'contact/ftp/'+ urlCustomerId() + '/add';
    return axios.post(`${API_BASE_URL}`+ API_LIST_CONT,data(host, user, password, port, path));
};
export const getFilesFtp = (id) => {
    console.log("listSourceConfiguration")
    const API_LIST_CONT = 'contact/ftp/'+ urlCustomerId() + '/' + id +'/files';
    return axios.post(`${API_BASE_URL}`+ API_LIST_CONT,1);
};
function data(host, user, password, port, path){
    const dataFtp = {
        "host": host,
        "user": user,
        "password": password,
        "port": port,
        "path": path === null ? "" : path
    };
    return dataFtp;
};
// Agrega más funciones según tus necesidades