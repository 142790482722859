<template>
  <div class="multisteps-form__content">
    <ul class="list-style-type: circle">
      <!-- Utiliza la propiedad computada filteredData directamente en v-for -->
      <li v-for="(arch, index) in selectArchivos" :key="index">
        {{ arch }}
      </li>
    </ul>

    <div class="row">
      <div class="col-12 col-sm-6">
        <div class="mt-3">
          <label>Seleccione las Etiquetas</label>
          <select
              v-model="selectedTag"
              class="multisteps-form__select form-control form-select"
              name="opciones-Tags"
              @change="updateTag"
          >
            <option disabled selected value="">Etiquetas</option>
            <option v-for="(tag, index) in responseTag.data" :key="index" :value="tag.customerTagSegmentName">
              {{ tag.customerTagSegmentName }}
            </option>
          </select>
        </div>
      </div>

      <div class="col-12 col-sm-6">
        <div class="mt-3">
          <label>Seleccione el Segmento</label>
          <select
              v-model="selectedSegment"
              class="multisteps-form__select form-control form-select"
              name="opciones-segment"
              @change="updateSegment"
          >
            <option selected disabled value="">Segmento</option>
            <option v-for="(segm, index) in responseSegment.data" :key="index" :value="segm.customerTagSegmentId">
              {{ segm.customerTagSegmentName }}
            </option>
          </select>
        </div>
      </div>
    </div>

    <div class="col-12 col-sm-6 mt-3">
      <label class="form-label mt-2">Etiquetas Elegidas</label>
      <input
          id="selTags"
          v-model="tag"
          class="form-control"
          type="text"
          placeholder="Enter something"
      />
      <div v-if="!validarVacios" :class="{ 'is-invalid-d': !validarVacios }">
        <p class="mb-0 text-sm">Debe elegir una Etiqueta al menos</p>
      </div>
    </div>
  </div>

</template>
<script>
import { validarVacio} from "@/config/validaciones";
import {getSegment} from "@/config/servicios/segment/servicesSegment";
import {getTag} from "@/config/servicios/tag/servicesTag";

export default {
  name: "Load",
  props: {
    selectArchivos: {
      type: Array,
      required: true,
    },
  },
  computed: {
    validarVacios() {
      return validarVacio(this.tag);
    },
  },
  data() {
    return {
      tag: "",
      selectedSegment: "",
      selectedTag: "",
      responseTag: {},
      responseSegment: {},
    };
  },
  methods:{
    async listTag() {
      try {
        const response = await getTag();
        // Almacena la respuesta en la variable de datos
        this.responseTag = response.data;
      } catch (error) {
        console.error("Error al obtener datos", error);
      }
    },
    async listSegment() {
      try {
        const response = await getSegment();
        // Almacena la respuesta en la variable de datos
        this.responseSegment = response.data;
      } catch (error) {
        console.error("Error al obtener datos", error);
      }
    },
    updateTag() {
      // Agregar el tag seleccionado al string de tags
      if (this.selectedTag && this.selectedTag !== "..." && !this.tag.includes(this.selectedTag)) {
        if (this.tag.length > 0) {
          this.tag += `, ${this.selectedTag}`;
        } else {
          this.tag = this.selectedTag;
        }
        this.$emit('update-tag', this.tag);
      }
    },
    updateSegment() {
      if (this.selectedSegment && this.selectedSegment !== "...") {
        this.$emit('update-segment', this.selectedSegment);
      }
    },
  },
  mounted() {
    this.listTag();
    this.listSegment();
  }
}

</script>