<template>
  <div class="container-fluid py-4">
    <div class="row">
    <div class="row mt-4">
      <div class="col-12">
        <reports-table :userData="response.data"/>
      </div>
    </div>
      <modal :is-visible="modalEditarCustomerUser">
          <p class="mb-0 text-sm">Información obligatoria</p>
          <div class="multisteps-form__content">
            <div class="col-12 col-sm-6">
              <label>Correo</label>
              <argon-input
                  id="customerUserEmailEdit"
                  v-model="email"
                  class="multisteps-form__input"
                  type="email"
                  placeholder="celcom@gmail.com"
                  required
                  pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
              />
              <div v-if="!EmailValid" :class="{ 'is-invalid-d': !EmailValid }">
                <p class="mb-0 text-sm">Ingresa un correo electrónico válido</p>
              </div>

            </div>
            <div class="row mt-3">
              <div class="col-12 col-sm-6">
                <label class="form-label">Nueva contraseña</label>
                <argon-input
                    id="new-password-edit"
                    v-model="newPassword"
                    type="password"
                    placeholder="New Password"
                />
                <div v-if="!ValidatePassword" :class="{ 'is-invalid-d': !ValidatePassword }">
                  <p class="mb-0 text-sm">La contraseña no cumple los requisitos</p>
                </div>
              </div>
              <div class="col-12 col-sm-6">
                <label class="form-label">Confirme su contraseña</label>
                <argon-input
                    id="confirm-password-edit"
                    v-model="confirmPassword"
                    type="password"
                    placeholder="Confirm password"
                />
                <div v-if="!ValidateConfirmPassword" :class="{ 'is-invalid-d': !ValidateConfirmPassword }">
                  <p class="mb-0 text-sm">Las contraseñas no coinciden</p>
                </div>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-12 col-sm-6">
                <label>Estado</label>
                <div class="form-check">
                  <input
                      id="estadoCheckboxEdit"
                      v-model="active"
                      class="form-check-input"
                      type="checkbox"
                  />
                  <label class="form-check-label" for="estadoCheckbox">
                    {{ active ? 'Activo' : 'No Activo' }}
                  </label>
                </div>
              </div>
              <div class="col-12 col-sm-6">
                <label>Super Administrador</label>
                <div class="form-check">
                  <input
                      id="adminEstadoCheckboxEdit"
                      v-model="adminUser"
                      class="form-check-input"
                      type="checkbox"
                  />
                  <label class="form-check-label" for="estadoCheckbox">
                    {{ adminUser ? 'Activo' : 'No Activo' }}
                  </label>
                </div>
              </div>
            </div>

            <div class="button-row d-flex mt-2">
              <div class="text-white ms-auto mb-0 js-btn-next">
                <button class="btn buttons mb-0 bg-gradient-success btn-sm null null mt-2 mb-0" @click="editCustomerUser">Editar</button>
                <button class="btn buttons mb-0 bg-gradient-dark btn-sm null null mt-2 mb-0" @click="closeModal">Cancelar</button>
              </div>
            </div>
          </div>
      </modal>
  </div>
  </div>
</template>

<script>
import ReportsTable from "./components/ReportsTable.vue";
import {
  listCustomerUserServ,
  listCustomerUserAllServ,
} from "@/config/servicios/customerUser/servicesCustomerUser";
import { tipoUser } from "@/config/config";
import {editCustomerU} from "@/config/servicios/customerUser/editCustomerUser";
import Modal from "@/components/Modal/Modal.vue";
import ArgonInput from "@/components/ArgonInput.vue";
import {isEmailValid, validateConfirmPassword, validatePassword} from "@/config/validaciones";
export default {
  name: "Reports",
  components: {
    ArgonInput, Modal,
    ReportsTable,
  },
  computed: {
    EmailValid() {
      return isEmailValid(this.email);
    },
    ValidatePassword() {
      return validatePassword(this.newPassword);
    },
    ValidateConfirmPassword() {
      return validateConfirmPassword(this.newPassword, this.confirmPassword);
    },
  },
  data() {
    return {
      id: null,
      newPassword: null,
      confirmPassword: null,
      email: null,
      active: null,
      adminUser: null,
      modalEditarCustomerUser: false,
      tipoUsuario: false,
      response: {}, // Aquí almacenarás la respuesta del servicio
    };
  },
  methods: {
    async tipoUsers() {
      try {
        const response = await tipoUser();
        // Almacena la respuesta en la variable de datos
        this.tipoUsuario = response;
        if (this.tipoUsuario === false) {
          console.log("1")
          this.listCustomerUser();
        } else {
          console.log("2")
          this.listCustomerUserAll();
        }
      } catch (error) {
        console.error("Error al obtener datos", error);
      }
    },
    async listCustomerUser() {
      try {
        const response = await listCustomerUserServ();
        // Almacena la respuesta en la variable de datos
        this.response = response.data;
      } catch (error) {
        console.error("Error al obtener datos", error);
      }
    },
    async listCustomerUserAll() {
      try {
        const response = await listCustomerUserAllServ();
        // Almacena la respuesta en la variable de datos
        this.response = response.data;
      } catch (error) {
        console.error("Error al obtener datos", error);
      }
    },
    async editCustomerUser() {
      alert(this.id + " " + this.email + " " + this.newPassword + " " + this.adminUser + " " + this.active);
      try {
        const response = await editCustomerU(
            this.id,
            this.email,
            this.newPassword,
            this.adminUser,
            this.active);
        // Almacena la respuesta en la variable de datos
        console.log("Customer User Editado: " + response);
      } catch (error) {
        console.error("Error al obtener datos", error);
      } finally {
        this.modalEditarCustomerUser = false;
        this.id = null;
        this.email = null;
        this.newPassword = null;
        this.adminUser = null;
        this.active = null;
        await this.tipoUsers();
      }
    },
    openModalEditarCustomerUser(customerUser) {
      const customerUserData = JSON.parse(JSON.stringify(customerUser));

      this.id = customerUserData.customerUserId;
      this.email = customerUserData.email;
      this.adminUser = customerUserData.adminUser;
      this.active = customerUserData.active;

      this.modalEditarCustomerUser = true;
    },
    closeModal() {
      this.modalEditarCustomerUser = false;
    },
  },
  mounted() {
    this.tipoUsers();
  },
};
</script>
<style scoped>
/* Agrega estilos según tus necesidades */
.modal {
  display: none;
}

.modal.is-active {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
}

.modal-close {
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
  background: none;
  border: none;
}
.buttons {
  margin: 8px;
}
</style>