<template>
  <svg
    :width="size"
    viewBox="0 0 41 31"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <title>wifi</title>
    <g
      id="Page-1"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g id="wifi" transform="translate(3.000000, 3.000000)">
        <path
          d="M7.37102658,14.6156105 C12.9664408,9.02476091 22.0335592,9.02476091 27.6289734,14.6156105"
          stroke="#FFFFFF"
          stroke-width="5"
          stroke-linecap="round"
          stroke-linejoin="round"
        ></path>
        <circle
          id="Oval"
          fill="#FFFFFF"
          cx="17.5039082"
          cy="22.7484921"
          r="4.9082855"
        ></circle>
        <path
          d="M0,7.24718945 C9.66583791,-2.41572982 25.3341621,-2.41572982 35,7.24718945"
          stroke="#FFFFFF"
          stroke-width="5"
          opacity="0.398982558"
          stroke-linecap="round"
          stroke-linejoin="round"
        ></path>
      </g>
    </g>
  </svg>
</template>
<script>
export default {
  name: "Wifi",
  props: {
    size: {
      type: String,
      default: "45px",
    },
  },
};
</script>
