<template>
  <div class="card">
    <div class="card-header border-1 d-flex justify-content-between align-items-center flex-column flex-md-row">
      <h6 class="mb-md-0">Configuración MO - SMS</h6>
      <div class="d-flex">
        <button
            class="btn btn-celcom-orange m-0"
            data-toggle="tooltip"
            data-original-title="Agregar Contacto"
            @click.prevent="$parent.openModalAdd()"
        >
          <i class="fas fa-plus"></i> Nueva Configuración
        </button>
      </div>
    </div>

    <!-- Agrega un campo de entrada para el buscador -->
    <div class="pb-0 card-body">
      <input v-model="search" type="text" class="form-control mt-2" placeholder="Buscar...">
    </div>
    <div class="card-body table-responsive">
      <table class="table align-items-center mb-0">
        <thead>
        <tr>
          <th
              class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
          >
            Tipo
          </th>
          <th
              class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
          >
            Source
          </th>
          <th
              class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
          >
            Mensaje
          </th>
          <th
              class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
          >
            Activo
          </th>
          <th class="text-secondary opacity-7"></th>
        </tr>
        </thead>
        <tbody>
        <!-- Utiliza v-for para recorrer el arreglo y generar filas -->
        <tr v-for="(row, index) in $store.state.datosPaginados" :key="index">
          <td class="align-middle text-center text-sm">
            <div class="d-flex flex-column justify-content-center">
              <h6 class="mb-0 text-xs">{{ row.type }}</h6>
            </div>
          </td>
          <td class="align-middle text-center text-sm">
            <div class="d-flex flex-column justify-content-center">
              <h6 class="mb-0 text-xs">{{  row.source }}</h6>
            </div>
          </td>
          <td class="align-middle text-center text-sm">
            <div class="d-flex flex-column justify-content-center">
              <h6 class="mb-0 text-xs">{{  row.message }}</h6>
            </div>
          </td>
          <td class="align-middle text-center text-sm">
              <span class="badge badge-dot">
                <i
                    :class="{ 'bg-info': row.active, 'bg-dark': !row.active }"
                ></i>
                <span class="text-dark text-xs">{{
                    row.active === true ? "Activo" : "Inactivo"
                  }}</span>
              </span>
          </td>
          <td class="align-middle text-center text-sm">
            <button
                class="btn btn-info me-2 mb-0"
                data-toggle="tooltip"
                data-original-title="Edit user"
                @click.prevent="$parent.openModalEditar(row)"
            >
              <img src="../../../assets/iconos/pen-to-square-solid.svg" alt="Cesto de basura" class="icono-borrar">
            </button>
            <button
                class="btn btn-danger mb-0"
                data-toggle="tooltip"
                data-original-title="Eliminar user"
                @click.prevent="$parent.openModal(row)"
            >
              <i class="fas fa-duotone fa-trash"></i>
            </button>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <BasePagination :filtered-data="filteredData" />
  </div>
</template>

<script>
import BasePagination from "@/components/BasePagination.vue";

export default {
  name: "ConfiguracionMoSmsTable",
  components: {
    BasePagination,
  },
  props: {
    responseMo: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      modalVisible: false,
      search: "", // Variable para almacenar la entrada del buscador
    };
  },
  computed: {
    // Utiliza una propiedad computada para filtrar los datos según la búsqueda
    filteredData() {
      if (!Array.isArray(this.responseMo)) {
        return [];
      }
      return this.responseMo.filter(row => {
        const source = row.source ?? '';
        const message = row.message ?? '';
        const type = row.type ?? '';
        return (
            source.toLowerCase().includes(this.search.toLowerCase()) ||
            message.toLowerCase().includes(this.search.toLowerCase()) ||
            type.toLowerCase().includes(this.search.toLowerCase())
        );
      });
    },
  },
  watch: {
    filteredData() {
      this.$store.getters.getDataPaginated(this.$store.state.paginaActual, this.filteredData);
    },
  },
  methods: {
    agregar(){
      this.$router.push({ name: "Nuevo Contacto" });
    },
  },
};
</script>

<style scoped>
.btn-plantilla{
  color: #fff;
  background-color: #e64477;
  border-color: #e64477;
}
/* Agrega estilos según tus necesidades */
.icono-rojo {
  color: red;
}
.modal {
  display: none;
}

.modal.is-active {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
}

.modal-close {
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
  background: none;
  border: none;
}
.buttons {
  margin: 8px;
}
</style>