<template>
  <div class="card">
    <div class="card-header border-1 d-flex justify-content-between align-items-center flex-column flex-md-row">
      <h6 class="mb-md-0">Lista de Clientes</h6>
      <div class="d-flex">
          <dropdown-menu direction="right">
            <template #trigger>
              <button
                  v-show="permisos.create"
                  class="btn btn-celcom-orange mb-0"
                  data-toggle="tooltip"
                  data-original-title="Agregar Contacto"
              >
                <i class="fas fa-plus"></i> Agregar
              </button>
            </template>

            <template #body>
              <div class="row p-3">
                <div class="col-12 col-md-6">
                  <button
                      class="btn btn-danger mb-0"
                      data-toggle="tooltip"
                      data-original-title="Agregar Contacto"
                      @click.prevent="agregar"
                  >Cliente
                  </button>
                </div>
                <div class="col-12 col-md-6">
                  <button
                      class="btn btn-celcom-blue mb-0"
                      data-toggle="tooltip"
                      data-original-title="Agregar Contacto"
                      @click.prevent="agregarUsuario"
                  >Usuario
                  </button>
                </div>
              </div>
            </template>
          </dropdown-menu>
        </div>
    </div>
    <!-- Agrega un campo de entrada para el buscador -->
    <div class="pb-0 card-header mb-0">
      <input v-model="search" type="text" class="form-control mt-2" placeholder="Buscar...">
    </div>
    <div v-if="$store.state.datosPaginados && $store.state.datosPaginados.length > 0" class="card-body table-responsive">
      <table class="table align-items-center mb-0">
        <thead>
          <tr>
            <th
              class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
            >
              Nombre
            </th>
            <th
              class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
            >
              País
            </th>
            <th
              class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
            >
              Activo
            </th>
            <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
            >
              Rut
            </th>
            <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
            >
              Ruta
            </th>
            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
              Acción
            </th>
          </tr>
        </thead>
        <tbody>
        <tr v-for="(row, index) in $store.state.datosPaginados" :key="index">
            <td>
              <div class="d-flex px-2 py-1">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm">{{row.name}}</h6>
                </div>
              </div>
            </td>
            <td>
              <p class="text-sm text-secondary mb-0">{{ row.country?.name ?? "" }}</p>
            </td>
            <td>
              <span class="badge badge-dot">
                <i :class="{ 'bg-info': row.active, 'bg-dark': !row.active }"></i>
                <span class="text-dark text-xs">{{row.active ? "Activo" : "No activo"}}</span>
              </span>
            </td>
          <td>
            <p class="text-sm text-secondary mb-0">{{ row.rut }}</p>
          </td>
          <td>
            <p class="text-sm text-secondary mb-0">{{ row.operatorGroupId?.operatorGroupName ?? ""}}</p>
          </td>
          <td class="align-middle text-center text-sm">
            <a class="btn btn-info me-2 mb-0" v-show="permisos.modify" href="#!"
               data-toggle="tooltip" data-placement="top" title="Editar Cliente"
               @click.prevent="$parent.openModalEditarCustomer(row)">
              <i class="fas fa-edit"></i>
            </a>

            <a class="btn btn-warning me-2 mb-0" v-show="permisos.modify" href="#!"
               data-toggle="tooltip" data-placement="top" title="Ver Usuarios"
               @click.prevent="modificarUsuarios(row)">
              <i class="fas fa-user-check"></i>
            </a>
          </td>
          </tr>
        </tbody>
      </table>
    </div>
    <BasePagination :filtered-data="filteredData" />
  </div>
</template>

<script>
import DropdownMenu from 'v-dropdown-menu'
import customer from "../UserAdmin.vue";
import {onPermissionsUser} from "@/config/servicios/campana/util";
import BasePagination from "@/components/BasePagination.vue";
export default {
  name: "ClientAdminTable",
  components: {
    BasePagination,
    DropdownMenu,
  },
  props: {
    userData: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      permisos: {
        create: onPermissionsUser('users.xhtml', 'create'),
        modify: onPermissionsUser('users.xhtml', 'modify'),
        destroy: onPermissionsUser('users.xhtml', 'destroy'),
      },
      search: "", // Variable para almacenar la entrada del buscador
    };
  },
  computed: {
    customer() {
      return customer
    },
    // Utiliza una propiedad computada para filtrar los datos según la búsqueda
    filteredData() {
      if (!Array.isArray(this.userData) || this.userData.length === 0) {
        return [];
      }
      return this.userData.filter(customer => {
        const name = customer.name ?? '';
        return name.toLowerCase().includes(this.search.toLowerCase());
      });
    },
  },
  watch: {
    search() {
      this.$store.getters.getDataPaginated(this.$store.state.paginaActual, this.filteredData);
    }
  },
  mounted() {
    this.$store.getters.getDataPaginated(this.$store.state.paginaActual, this.filteredData);
  },
  methods: {
    agregar() {
      this.$parent.openModalAddCustomer();
      this.showDropdown = false; // Opcional: cierra el menú después de la acción
    },
    agregarUsuario() {
      this.$router.push({ name: "Nuevo User SMS" });
      this.showDropdown = false; // Opcional: cierra el menú después de la acción
    },
    modificarUsuarios(cliente) {
      this.$store.commit('setClienteActual', cliente); // Guarda el cliente en Vuex
      this.$router.push({ name: "Admin User Edit" });
      this.showDropdown = false; // Opcional: cierra el menú después de la acción
    },
  },
};
</script>
<style scoped>
@import 'v-dropdown-menu/css';
</style>