<template>
  <div>
    <div class="row">
     <argon-input
          v-model="botonLocal.valor"
          class="multisteps-form__input col-12 col-sm-6"
          type="text"
          :placeholder="'Escriba el valor de '+ boton.cabecera"
          @input="actualizarTexto"
      />
      <span
          type="button"
          class="btn btn-danger col-12 col-sm-1"
          title="Haz click para eliminar el boton"
          @click="eliminarBoton">X</span>
    </div>
  </div>
</template>

<script>
import ArgonInput from "@/components/ArgonInput.vue";

export default {
  components: {ArgonInput},
  props: {
    boton:{
      type: Object,
      required: true,
    },
  },
  emits: ['disminuirIdBotones'],
  data() {
    return {

    };
  },
  computed: {
    // Crear una copia local del botón
    botonLocal() {
      return { ...this.boton };
    },
  },
  methods: {
    actualizarTexto() {
      // Emitir un evento al componente padre (BotonRR) con el nuevo texto
      this.$store.dispatch('actualizarTextoBotonCab', { id: this.botonLocal.id, nuevoTexto: this.botonLocal.valor });
    },
    eliminarBoton() {
      // Emitir un evento al componente padre (BotonRR) con el id a eliminar
      this.$emit('disminuirIdBotones');
      this.$store.dispatch('eliminarBotonCab', { id: this.botonLocal.id });

    },
  },
};
</script>

<style scoped>

</style>
