<template>
  <div
      class="card multisteps-form__panel border-radius-xl bg-white"
      data-animation="FadeIn"
  >
    <div class="card-header border-1">
      <h5 class="font-weight-bolder mb-0">Envío de {{ $store.state.seleccion === 'SMS' ? 'SMS' : 'Whatsapp'}} </h5>
      <p class="mb-0 text-sm">Mensaje</p>
    </div>
    <div class="card-body multisteps-form__content">
      <div v-if="$store.state.seleccion === 'SMS'" class="row">
        <div class="row">
          <label class="form-label mt-2">Tipo de Mensaje</label>
        </div>
        <div class="row ms-1">
          <div class="col-12 col-sm-6">
            <div class="row">
              <div class="form-check col-md-4">
                <input
                    id="redactar"
                    v-model="switchValueMen"
                    type="radio"
                    name="redactar"
                    value="redactar"
                    class="form-check-input"
                    @change="limpiar"
                />
                <label>Redactar</label>
              </div>
              <div class="form-check col-md-4">
                <input
                    id="plantilla"
                    v-model="switchValueMen"
                    type="radio"
                    name="plantilla"
                    value="plantilla"
                    class="form-check-input"
                    @change="limpiar"
                />
                <label>Plantillas</label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-1">
        <div v-if="$store.state.seleccion === 'SMS' && switchValueMen === 'redactar'" class="col-12 col-md-6">
          <label>Mensaje</label>
          <textarea
              id="redactarEdit"
              v-model="message"
              class="form-control espacio"
              rows="3"
              maxlength="160"
              placeholder="Mensaje"
          />
          <p class="text-xs text-end mt-1">Caracteres : {{message.length}}</p>
          <div>
            <label>Elija Variable</label>
            <select
                id="selectCab"
                v-model="selectedCab"
                class="multisteps-form__select form-control form-select"
                @change="putFieldMessage"
            >
              <option value="" disabled selected>Seleccione Variable</option>
              <option v-for="(cab, cabIndex) in $store.state.responseCustomer"
                      :key="cabIndex"
                      :value="cab"
              >{{ traducirHeader(cab.name) }}</option>
            </select>
          </div>
            <div class="row">
              <div class="col-12 ms-3 mt-3 form-check">
                <input
                    id="activeGuardar"
                    v-model="activeGuardar"
                    class="form-check-input"
                    type="checkbox"
                    :disabled="deshabilitarGuardar"
                />
                <label class="form-check-label" for="activeGuardar">
                  ¿Guardar como plantilla nueva?
                </label>
              </div>
              <div
                  v-if="activeGuardar"
                  class="col">
                <label>Nombre de la Plantilla</label>
                <div class="d-flex">
                  <argon-input
                      id="programadaName"
                      v-model="namePlantilla"
                      class="multisteps-form__input mb-0"
                      type="text"
                      placeholder="Nombre de la campaña"
                  />
                  <button
                      class="btn btn-celcom-orange ms-3 mb-0"
                      :disabled="namePlantilla === ''"
                      @click.prevent="addPlantillaSms">Guardar</button>
                </div>
              </div>
            </div>
            <br>
        </div>
        <div v-if="$store.state.seleccion === 'SMS' && switchValueMen === 'plantilla'" class="row mt-3">
          <div class="col-md-6">
            <label>Seleccionar Plantilla</label>
            <div class="">
              <select
                  id="opcionesSelectDraft"
                  v-model="selectedselectDraftValue"
                  class="multisteps-form__select form-control form-select"
                  name="opciones-ListCont"
                  :disabled="selectedselectDraft.length > 0"
                  @change="addToselectedselectDraft"
              >
                <option value="" disabled selected>Plantillas</option>
                <option v-for="option in $store.state.responsePlantillasSms" :key="option.draftId" :value="option">{{option.name}}</option>
              </select>
            </div>
          </div>
          <div class="col-12 col-md-6 mt-3">
            <card type="frame" v-for="option in selectedselectDraft"
                  :key="option.draftId">
              <div class="btn-close-position">
                <button type="button" class="btn-close" aria-label="Close" @click="quitarToSelectedDraft"></button>
              </div>
              <h5>Plantilla : {{option.name}}</h5>
              <p class="text-sm mb-0">{{option.message}}</p>
            </card>
          </div>
        </div>

        <div v-if="$store.state.seleccion === 'Whatsapp'" class="row">
        <label>Seleccione Template</label>
        <div class="col-12 col-sm-6">
          <select
              id="opcionesSelectDraft"
              v-model="selectedTemplateValue"
              class="multisteps-form__select form-control form-select"
              name="opciones-ListCont"
              :disabled="selectedselectTemplate.length > 0"
              @change="addToselectedTemplate"
          >
            <option value="" disabled selected>Templates</option>
            <option
                v-for="option in $store.state.LIST_TEMPLATE_WHATSPP_BY_CUSTOMER.filter(template => template.statusWhatsapp === 'APPROVED')"
                :key="option.whatsappSenderId"
                :value="option"
            >
              {{option.templateName}}
            </option>
          </select>
        </div>
        <div class="col-12 col-sm-6 mt-3 mt-sm-0">
          <card type="frame" v-for="option in selectedselectTemplate"
                :key="option.whatsappSenderId">
            <div class="btn-close-position">
              <button type="button" class="btn-close" aria-label="Close" @click="quitarToSelectedTemplate"></button>
            </div>
            <h5>Template : {{option.templateName}}</h5>
            <p class="text-sm mb-0">{{option.body}}</p>
          </card>
        </div>
      </div>

      <div v-if="$store.state.seleccion === 'SMS' && switchValueMen === 'redactar'" class="col-12 col-sm-6 mt-2">
        <vista-previa-sms
            :body="message"
            :header="sender"/>
      </div>
      </div>
      <br>
      <div class="form-check">
        <input
            v-model="configAvanzada"
            class="form-check-input"
            type="checkbox"
            id="configAvanzada"
            @change="limpiarAvanzada"
        />
        <label class="form-check-label" for="configAvanzada">
          Configuración Avanzada
        </label>
        <div v-if="configAvanzada" class="row config-avanzado">
          <!-- Usar Id de Seguimiento -->
          <div class="col-md-12">
            <div class="accordion-item">
              <h2 class="accordion-header" @click="toggleSection('seguimiento')">
                <button
                    :class="['accordion-button', { 'expanded': activeSection === 'seguimiento' }]"
                    type="button">
                  Usar Identificador de Referencia
                </button>
              </h2>
              <transition name="slide-fade">
              <div v-if="activeSection === 'seguimiento'" class="accordion-collapse collapse show">
                <div class="accordion-body">
                  <!-- Contenido expandido de Usar Id de Seguimiento -->
                  <label>Identificador de Referencia</label>
                  <div class="row">
                  <div class="col-sm-6">
                    <select
                        id="selectIdSeguimiento"
                        v-model="selectedIdSeguimiento"
                        class="multisteps-form__select form-control form-select"
                        :disabled="idSeguimientos.length > 1"
                        @change="agregarIdSeguimiento"
                    >
                      <option value="" disabled selected>Ids</option>
                      <option v-for="(cab, cabIndex) in $store.state.responseCustomer"
                              :key="cabIndex"
                              :value="cab"
                      >{{ traducirHeader(cab.name) }}</option>
                    </select>
                  </div>
                  <div class="col-sm-6">
                <span
                    v-for="id in idSeguimientos"
                    :key="id.name" class="badge badge-celcom-orange clickable-badge mt-3 mt-md-0"
                    @click="quitarIdSeguimiento(id)"
                > {{ traducirHeader(id.name) }} </span>
                  </div>
                  </div>
                </div>
              </div>
              </transition>
            </div>
          </div>

          <div v-if="$store.state.seleccion === 'SMS' && switchValueMen !== 'plantilla'" class="col-md-12">
            <div class="accordion-item">
              <h2 class="accordion-header" @click="toggleSection('corta')">
                <button
                    :class="['accordion-button', { 'expanded': activeSection === 'corta' }]"
                    type="button">
                  Rastreo e Informes
                </button>

              </h2>
              <transition name="slide-fade">
              <div v-if="activeSection === 'corta'" class="accordion-collapse collapse show">
                <div class="accordion-body">
                  <div class="row">
                    <div class="row ms-1">
                      <div class="col-12 col-sm-6">

                        <div class="row">

                          <div class="form-check col-md-6">
                            <input
                                id="general"
                                v-model="valueUrl"
                                type="radio"
                                name="general"
                                value="general"
                                class="form-check-input"
                                @change="limpiarUrl"
                            />
                            <label for="general">General</label>
                          </div>
                          <div class="form-check col-md-6">
                            <input
                                id="dinamica"
                                v-model="valueUrl"
                                type="radio"
                                name="dinamica"
                                value="dinamica"
                                class="form-check-input"
                                @change="limpiarUrl"
                            />
                            <label for="dinamica">Dinámica</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr class="my-2">
                  <div v-if="valueUrl === 'dinamica'" class="row">
                    <!-- Contenido expandido de identifique la URl -->
                    <label>Selecione campo que identifique la URL</label>
                    <div class="col-sm-6">
                      <select
                          id="selectIdSeguimiento"
                          v-model="selectedUrlIdentificador"
                          class="multisteps-form__select form-control form-select"
                          :disabled="selectedUrlIdentificador !== ''"
                          @change="getDinamica_url"
                      >
                        <option value="" disabled selected>Identificador</option>
                        <option v-for="(cab, cabIndex) in $store.state.responseCustomer"
                                :key="cabIndex"
                                :value="cab"
                        >{{ traducirHeader(cab.name) }}</option>
                      </select>
                    </div>
                    <div class="col-sm-6">
                <span
                    class="badge badge-celcom-orange clickable-badge mt-3 mt-md-0"
                    @click="quitarUrlIdentificada()"
                > {{ traducirHeader(selectedUrlIdentificador.name) }} </span>
                    </div>
                  </div>
                  <div v-else>
                    <div class="row">
                      <div class="col-12 col-sm-6">
                        <div class="d-flex align-items-center mb-3">
                          <argon-switch
                              id="switch-envio"
                              class="switch-url"
                              :checked="tipoUrl"
                              name="switch-envio"
                              @change="onSwitchChange"
                          />
                          <label for="switch-envio" :class="['h6', 'fw-bolder', 'm-0']">{{ tipoUrl ? "URL nueva" : "URL existente" }}</label>
                        </div>

                      </div>
                    </div>
                    <div v-if="tipoUrl" class="col-12 col-sm-12">
                      <label>Escriba la URL</label>
                      <argon-input
                          v-model="urlValue"
                          class="multisteps-form__input"
                          type="text"
                          placeholder="https://www.google.com"
                      />
                      <argon-button
                          type="button"
                          color="celcom"
                          variant="gradient"
                          class="text-white ms-auto mb-0 js-btn-next"
                          @click="getShort_url"
                      ><i class="fa fa-scissors ms-2"></i>  Acortar
                      </argon-button>
                      <label v-if="short_url" class="form-check-label"> URL corta: {{short_url.shortUrl}}</label>
                    </div>
                    <div v-if="!tipoUrl" class="col-12 col-sm-12">
                      <label>Seleccione la URL</label>
                      <select
                          id="selectCab"
                          v-model="selectedUrl"
                          class="multisteps-form__select form-control form-select"
                          @change="getUrlExistente"
                      >
                        <option value="" disabled selected>URL</option>
                        <option v-for="(url, urlIndex) in $store.state.list_available_short_url"
                                :key="urlIndex"
                                :value="url"
                        >{{ url.longUrl }}</option>
                      </select>
                      <label v-if="short_url" class="form-check-label"> URL corta: {{short_url.shortUrl}}</label>
                    </div>
                  </div>
                </div>
              </div>
              </transition>
            </div>
          </div>

          <!-- Cantidad de SMS por tiempo -->
          <div v-if="$store.state.seleccion === 'SMS'" class="col-md-12">
            <div class="accordion-item">
              <h2 class="accordion-header" @click="toggleSection('cantidad')">
                <button
                    :class="['accordion-button', { 'expanded': activeSection === 'cantidad' }]"
                    type="button">
                  Velocidad de envío de mensajes
                </button>
              </h2>
              <transition name="slide-fade">
              <div v-if="activeSection === 'cantidad'" class="accordion-collapse collapse show">
                <div class="accordion-body">
                  <div class="row">
                  <div class="col-sm-6">
                    <label>Unidad</label>
                    <argon-input
                        id="tiempoEnvio"
                        v-model="selectedTiempo.smsNumber"
                        class="multisteps-form__input"
                        type="number"
                        placeholder="Tiempo de envío"
                    />
                    <div v-if="!validarTiempo" :class="{ 'is-invalid-d': !validarTiempo }">
                      <p class="mb-0 text-sm">{{selectedTiempo.timeUnit === 'MINUTE' ? 'Solo se puede realizar envios de 1 a 300 SMS por minutos' : 'Solo se puede realizar envios de 1 a 3600 SMS por horas'}}</p>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <label>Período</label>
                    <select
                        id="unidadEnvio"
                        v-model="selectedTiempo.timeUnit"
                        class="multisteps-form__select form-control form-select"
                    >
                      <option value="" disabled selected>Tipo</option>
                      <option value="MINUTE">Minutos</option>
                      <option value="HOUR">Hora</option>
                    </select>
                  </div>
                  </div>
                </div>
              </div>
              </transition>
            </div>
          </div>
        </div>
      </div>
<!--      <div class="row">
        <div v-if="configAvanzada">

          <div v-if="opcionesAvanzadas === 'corta' && $store.state.seleccion === 'SMS'" class="row">
            <div class="row">
              <div class="col-12 col-sm-6">
                <div class="form-check">
                  <input
                      v-model="tipoUrl"
                      class="form-check-input"
                      type="checkbox"
                      id="multiNumeroCheckbox"
                      @change="limpiarUrl"
                  />
                  <label class="form-check-label" for="multiNumeroCheckbox">
                    Tipo de URl
                  </label>
                </div>
              </div>
              <div class="col-md-6">
                <span
                    :class="['badge', tipoUrl ? 'badge-success' : 'badge-info', 'me-1']"
                > {{ tipoUrl ? "URL nueva" : "URL existente" }} </span>
              </div>
            </div>
            <div v-if="tipoUrl" class="col-12 col-sm-12">
              <label>Escriba la URL</label>
              <argon-input
                  v-model="urlValue"
                  class="multisteps-form__input"
                  type="text"
                  placeholder="https://www.google.com"
              />
              <argon-button
                  type="button"
                  color="celcom"
                  variant="gradient"
                  class="text-white ms-auto mb-0 js-btn-next"
                  @click="getShort_url"
              ><i class="fa fa-scissors ms-2"></i>  Acortar
              </argon-button>
              <label v-if="short_url" class="form-check-label"> URL corta: {{short_url.shortUrl}}</label>
            </div>
            <div v-if="!tipoUrl" class="col-12 col-sm-12">
              <label>Seleccione la URL</label>
              <select
                  id="selectCab"
                  v-model="selectedUrl"
                  class="multisteps-form__select form-control form-select"
                  @change="getUrlExistente"
              >
                <option value="" disabled selected>URL</option>
                <option v-for="(url, urlIndex) in $store.state.list_available_short_url"
                        :key="urlIndex"
                        :value="url"
                >{{ url.longUrl }}</option>
              </select>
              <label v-if="short_url" class="form-check-label"> URL corta: {{short_url.shortUrl}}</label>
            </div>
          </div>

          <div v-if="opcionesAvanzadas === 'seguimiento'" class="row">
            <label>Id de Seguimiento</label>
            <div class="col-sm-6">
              <select
                  id="selectIdSeguimiento"
                  v-model="selectedIdSeguimiento"
                  class="multisteps-form__select form-control form-select"
                  :disabled="idSeguimientos.length > 1"
                  @change="agregarIdSeguimiento"
              >
                <option value="" disabled selected>Ids</option>
                <option v-for="(cab, cabIndex) in $store.state.responseCustomer"
                        :key="cabIndex"
                        :value="cab"
                >{{ traducirHeader(cab.name) }}</option>
              </select>
            </div>
            <div class="col-sm-6">
                <span
                    v-for="id in idSeguimientos"
                    :key="id.name" class="badge badge-celcom-orange clickable-badge mt-3 mt-md-0"
                    @click="quitarIdSeguimiento(id)"
                > {{ traducirHeader(id.name) }} </span>
            </div>
          </div>

          <div v-if="opcionesAvanzadas === 'cantidad'" class="row">
            <div class="col-sm-6">
              <label>Cada cuanto</label>
              <select
                  id="selectIdSeguimiento"
                  v-model="selectedTiempo"
                  class="multisteps-form__select form-control form-select"
              >
                <option value="" disabled selected>Valor</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="manual">Manual</option>
              </select>
            </div>
            <div class="col-sm-6">
              <label>Tiempo</label>
              <select
                  id="selectIdSeguimiento"
                  v-model="selectedTiempo"
                  class="multisteps-form__select form-control form-select"
              >
                <option value="" disabled selected>Tipo</option>
                <option value="min">Minutos</option>
                <option value="hora">Hora</option>
              </select>
            </div>
          </div>
        </div>
      </div>-->
    </div>
    <div class="card-footer border-1 mt-4 d-flex">
      <argon-button
          type="button"
          color="light"
          variant="gradient"
          class="js-btn-prev"
          @click="$parent.prevStep"
      ><i class="fas fa-chevron-left me-2"></i> Anterior
      </argon-button>
      <argon-button
          type="button"
          color="celcom"
          variant="gradient"
          class="text-white ms-auto mb-0 js-btn-next"
          @click="nextStepValor"
        >Siguiente <i class="fas fa-chevron-right ms-2"></i>
      </argon-button>
    </div>
  </div>
</template>

<script>
import ArgonButton from "@/components/ArgonButton.vue";
import Choices from "choices.js";
import {validarRut, validarVacio} from "@/config/validaciones";
import card from "@/views/envios/components/Card.vue"
import VistaPreviaSms from "@/views/envios/components/VistaPreviaSms.vue";
import ArgonInput from "@/components/ArgonInput.vue";
import {encryptAES, getUser, getUserSub, horaAhora, traducir_header} from "@/config/servicios/campana/util";
import {addPlantilla} from "@/config/servicios/campana/envios/servicesEnvios";
import swal from "sweetalert2";
import ArgonSwitch from "@/components/ArgonSwitch.vue";

export default {
  name: "ProgramadaEditAdd2",
  components: {
    ArgonSwitch,
    ArgonInput,
    card,
    ArgonButton,
    VistaPreviaSms,
  },
  props: {
    sender: {
      type: Object,
      default: () => {
        return {};
      },
    },
    seleccionTipoEnvio: {
      type: String,
      default: "",
    },
    sendingEditar: {
      type: Object,
      default: () => ({}),
    },
    editando: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['updateValues2'],
  data() {
    return {
      argonSwitch: true,
      selectedUrlIdentificador: "",
      valueUrl: "general",
      activeSection: null, // Controla qué sección está expandida
      opcionesAvanzadas: null,
      configAvanzada: false, // Simula la condición para mostrar el contenido avanzado
      switchValueMen: "redactar", // Simula el valor que tengas en tu $store o lógica
      selectedTiempo: {
        smsNumber: 0,
        timeUnit: "MINUTE",
      },

      tipoUrl: true,
      //configAvanzada: false,
      //opcionesAvanzadas: "seguimiento",
      activeGuardar: false,
      idSeguimientos: [],
      selectedUrl: '',
      url_existente: false,
      short_url: "",
      urlValue: "",
      activeUrl: false,
      activeIdSeguimiento: false,
      activeShort_url: false,
      selectedCab: '',
      selectedIdSeguimiento: '',
      selectedselectDraftValue: null,
      selectedTemplateValue: null,
      selectedPlanCont: "",
      message: '',
      //switchValueMen:  "redactar",
      switchValueURL:  "new",
      rut: "",
      selectedselectDraft: [],
      selectedselectTemplate: [],
      selectedTag: "", // Nuevo dato para almacenar la opción seleccionada
      tags: "",       // Nuevo dato para almacenar los tags seleccionados

      deshabilitarGuardar: false,
      namePlantilla: "",
      draftSingle: {
        draftId: null,
        name:"",
        channelId: 1,
        message: "",
        whatsappSenderId: "",
        created: null,
        master: false,
      },
    };
  },
  computed: {
    validarTiempo() {
      if (this.selectedTiempo.timeUnit === 'MINUTE' && (this.selectedTiempo.smsNumber < 1 || this.selectedTiempo.smsNumber > 300)) {
        return false // Devuelve true si está fuera del rango para minutos
      } else if (this.selectedTiempo.timeUnit === 'HOUR' && (this.selectedTiempo.smsNumber < 1 || this.selectedTiempo.smsNumber > 3600)) {
        return false; // Devuelve true si está fuera del rango para horas
      } else {
        return true; // Si está dentro del rango
      }
    },
    validarRut() {
      return validarRut(this.rut);
    },
    validarVacios() {
      return validarVacio(this.tags);
    },
  },
  watch: {
    sendingEditar: {
      handler(newValidForm) {
        if (newValidForm.messageType === 1) {
          this.message = newValidForm.message;
        }else if (newValidForm.messageType === 2) {
          if (newValidForm.channelId === 1 && this.$store.state.responsePlantillasSms.length > 0){
            const draft = this.$store.state.responsePlantillasSms.find((draft) => draft.draftId === newValidForm.draftFlexId);
            this.selectedselectDraft.push(draft);
            this.selectedselectDraftValue = draft;
            this.switchValueMen = "plantilla";
          }else if (newValidForm.channelId === 2 && this.$store.state.LIST_TEMPLATE_WHATSPP_BY_CUSTOMER.length > 0){
            const template = this.$store.state.LIST_TEMPLATE_WHATSPP_BY_CUSTOMER.find((template) => template.whatsappSenderId === newValidForm.draftFlexId);
            this.selectedselectTemplate.push(template);
            this.selectedTemplateValue = template;
            this.switchValueMen = "plantilla";
          }
        }
        if (newValidForm.trackId && newValidForm.trackId.length > 0) {
          this.activeIdSeguimiento = true;
          newValidForm.trackId.forEach((id) => {
            this.idSeguimientos.push(id);
          });
        }
        // Actualiza otras propiedades según sea necesario
      },
      immediate: true,
      deep: true,
    },
  },
  mounted() {
    this.$store.getters.arrayCabeceras();
    if (document.getElementById("opcionesTags")) {
      var element = document.getElementById("opcionesTags");
      new Choices(element, {
        searchEnabled: false,
        allowHTML: true,
      });
    }
  },
  methods: {
    onSwitchChange(event) {
      this.tipoUrl = event.target.checked;
      this.limpiarUrl();
    },
    limpiarAvanzada() {
      this.activeSection = null;
    },
    toggleSection(section) {
      if (section === 'corta'){
        this.activeUrl = !this.activeUrl;
      }
      this.putFieldUrl();
      this.activeSection = this.activeSection === section ? null : section;
    },
    limpiarUrl() {
      this.selectedUrlIdentificador = "";
      this.urlValue = "";
      this.short_url = "";
      this.selectedUrl = "";
    },
    async addPlantillaSms() {
    try {
      const tiempoActual = new Date().getTime();
      this.draftSingle.name = this.namePlantilla;
      this.draftSingle.message = this.message;
      this.draftSingle.created = tiempoActual;

      if(this.draftSingle.name==="" || this.draftSingle.message===""){
        console.log("Error debe completar los datos requeridos");
      }
      let user = (getUserSub()!=null) ? getUserSub() :getUser();
      let js = {
        time_expired: horaAhora(),
        customer: encodeURIComponent(JSON.stringify(user.customer)),
        draft: encodeURIComponent(JSON.stringify(this.draftSingle)),
        short_url: this.short_url ? encodeURIComponent(JSON.stringify(this.short_url)) : encodeURIComponent(JSON.stringify({})),
      };

      var body = new URLSearchParams();
      body.append("TOKEN", encryptAES(js));


      const response = await addPlantilla(body);
      console.log(JSON.stringify(response.data.response));
      // Almacena la respuesta en la variable de datos
      if (response.data.response === "200") {
        console.log("Plantilla Add");
        swal.fire({
          text: '¡Plantilla creada exitosamente!',
          buttonsStyling: false,
          customClass: {
            confirmButton: 'btn btn-celcom-blue',
          },
          icon: 'success',
        })
        this.cargarDatos();
      } else {
        swal.fire({
          text: '¡No se pudo crear la Plantilla!',
          buttonsStyling: false,
          customClass: {
            confirmButton: 'btn btn-danger',
          },
          icon: 'error',
        })
      }
    } catch (error) {
      swal.fire({
        text: '¡No se pudo crear la Plantilla!',
        buttonsStyling: false,
        customClass: {
          confirmButton: 'btn btn-danger',
        },
        icon: 'error',
      })
    }
  },
    async cargarDatos(){
      await this.$store.getters.plantillasSmsGet();
      this.namePlantilla = "";
      this.activeGuardar = false;
      this.deshabilitarGuardar = true;
    },
    traducirHeader(name){
      return traducir_header(name);
    },
    quitarIdSeguimiento(id) {
      this.idSeguimientos = this.idSeguimientos.filter((item) => item !== id);;
    },
    quitarUrlIdentificada() {
      this.limpiarUrl();
    },
    agregarIdSeguimiento() {
      if (this.selectedIdSeguimiento !== "" && !this.idSeguimientos.includes(this.selectedIdSeguimiento)){
        this.idSeguimientos.push(this.selectedIdSeguimiento);
      }
    },
    limpiar() {
      this.message = "";
      this.selectedselectDraft = [];
      this.selectedselectTemplate = [];
      this.short_url = "";
      this.urlValue = "";
      this.activeUrl = false;
      this.activeShort_url = false;
      this.selectedCab = "";
      this.selectedselectDraftValue = null;
      this.selectedTemplateValue = null;
      this.selectedTag = "";
      this.tags = "";
    },
    getUrlExistente() {
        this.short_url = this.selectedUrl;
    },
    async getShort_url() {
      this.short_url = await this.$store.getters.add_short_urls(this.urlValue);
    },
    async getDinamica_url() {
      let user = (getUserSub()!=null) ? getUserSub() :getUser();
      this.short_url = {
        "flexUrlId": null,
        "shortUrl": null,
        "longUrl": null,
        "customerId": user.customer.customerId,
        "createdAt": null,
        "read": 0,
        "type": this.valueUrl === 'general' ? 1 : 2, //1 url general y 2 url dinamica
        "contactField": this.valueUrl === 'general' ? '{}' : JSON.stringify(this.selectedUrlIdentificador),
      }
    },
    putFieldUrl() {
      if (this.selectedUrlIdentificador === "" && this.short_url === "" && !this.activeUrl) {
        this.message = this.message.replace("{URL}", "");
      }else if (this.activeUrl && this.message.indexOf("{URL}") === -1) {
        this.message += "{URL}";
      }else if (this.selectedUrlIdentificador === "" && this.short_url === "" && this.message.indexOf("{URL}") !== -1){
        this.message = this.message.replace("{URL}", "");
      }
    },
    putFieldMessage() {
      this.message += "{" + this.selectedCab.name + "}";
      this.selectedCab = '';
    },
    addToselectedselectDraft() {
      // Verificar si el valor ya está en el arreglo antes de agregarlo

      if (this.selectedselectDraft.indexOf(this.selectedselectDraftValue) === -1) {
        this.selectedselectDraft.push(this.selectedselectDraftValue);
      }
      console.log(JSON.stringify(this.selectedselectDraft));
    },
    addToselectedTemplate() {
      // Verificar si el valor ya está en el arreglo antes de agregarlo

      this.selectedselectTemplate = [];
      if (this.selectedselectTemplate.indexOf(this.selectedTemplateValue) === -1) {
        this.selectedselectTemplate.push(this.selectedTemplateValue);
      }
      console.log(JSON.stringify(this.selectedTemplateValue));
    },
    quitarToSelectedTemplate() {
      // Verificar si el valor ya está en el arreglo antes de agregarlo
      this.selectedselectTemplate = this.selectedselectTemplate.filter((item) => item !== this.selectedTemplateValue);
    },
    quitarToSelectedDraft() {
      // Verificar si el valor ya está en el arreglo antes de agregarlo
      this.selectedselectDraft = this.selectedselectDraft.filter((item) => item !== this.selectedselectDraftValue);
      this.selectedselectDraftValue = null;
    },
    nextStepValor() {
      // Puedes emitir un evento para notificar al componente padre sobre los cambios
      this.$emit('updateValues2', {
        message: this.message,
        selectDraft: this.selectedselectDraft,
        selectTemplate: this.selectedselectTemplate,
        short_url: this.short_url,
        idSeguimiento: this.idSeguimientos,
        cantidadSmsTiempo: this.selectedTiempo,
        selectedUrlIdentificador: this.selectedUrlIdentificador,
        valueUrl: this.valueUrl,
      });
      this.$parent.nextStep();
    },
    /*onSwitchChange(event) {
      this.switchValueMen = event.target.checked;
    },*/
    validarVacio,
    updateTags() {
      // Agregar el tag seleccionado al string de tags
      if (this.selectedTag && this.selectedTag !== "..." && !this.tags.includes(this.selectedTag)) {
        if (this.tags.length > 0) {
          this.tags += `, ${this.selectedTag}`;
        } else {
          this.tags = this.selectedTag;
        }
      }
    },
  },
};
</script>
<style>
.clickable-badge {
  cursor: pointer; /* Cambia el cursor a una mano para indicar interactividad */
  transition: background-color 0.3s; /* Suaviza la transición de colores */
}

.clickable-badge:hover {
  background-color: #f8d7da; /* Cambia el color de fondo al pasar el mouse */
}
.btn-close-position {
  position: absolute;
  right: 1rem;
  top: 1rem;
}

.config-avanzado .accordion-item {
  margin-bottom: 10px;
  border: 1px solid #ddd;
}

.config-avanzado .accordion-button:hover {
  background-color: #eaeaea;
}

.config-avanzado .accordion-button:after {
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  font-family: "Font Awesome 5 Free";
  font-weight: 700;
  content: "\f106";
  margin-left: auto;
  color: rgba(33, 37, 41, 0.5);
  transition: all 0.2s ease-in-out;
}
.config-avanzado .accordion-button.expanded:after {
  content: "\f107"; /* Ícono alternativo cuando se expande */
}
.config-avanzado .accordion-body {
  padding: 15px;
  background-color: #eaeaea;
  border: 0;
}

.slide-fade-enter-active, .slide-fade-leave-active {
  transition: all 0.5s ease;
}
.slide-fade-enter-from, .slide-fade-leave-to /* .slide-fade-leave-active en Vue 2 */ {
  transform: translateY(-10px);
  opacity: 0;
}
.switch-url.form-switch .form-check-input:after {
  width: 1rem !important;
  height: 1rem !important;
}
</style>
