import { editContacto } from "@/config/servicios/contactos/services";
import {llenarDatosContacto} from "@/config/servicios/contactos/plantilla";

export const editContact = (id, name, rut, email, empresa, msisdn, tag, birthdate) => {
    const jsonDataContacto = llenarDatosContacto(id, name, rut, email, empresa, msisdn, tag, birthdate);

    return editContacto(jsonDataContacto)
        .then((response) => {
            console.log(response.data);
        })
        .catch((error) => {
            console.error("Error al enviar los datos:", error);
        });
};