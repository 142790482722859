<!-- Modal.vue -->

<template>
  <div class="modal modal-dialog modal-dialog-scrollable" :class="{ 'is-active': isVisible }">
    <div class="modal-background" @click="closeModal"></div>
    <div class="modal-content align-items-center mb-0">
      <div class="modal-body w-100">
        <slot></slot>
      </div>
    </div>
    <button class="modal-close is-large" aria-label="close" @click="closeModal"></button>
  </div>
</template>

<script>
export default {
  props: {
    isVisible: {
      type: Boolean,
      required: true,
    },
    closeModal: {
      type: Function,
      required: true,
    },
  },
};
</script>

<style scoped>
/* Agrega estilos según tus necesidades */
.modal {
  display: none;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.modal.is-active {
  display: flex;
}

.modal-background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-close {
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
  background: none;
  border: none;
}
.box {
  width: 100%;
}
</style>
