// api.js
import axios from 'axios';
import Config from "@/config/servicios/campana/config";
export const sender_available_precios = (body) => {
    return axios.post(Config.url_service+Config.sender_available_precios,body,{
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        }});
};
export const sender_edit_precios = (body) => {
    return axios.post(Config.url_service+Config.sender_edit_precios,body,{
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        }});
};
export const sender_available_precios_sms = (body) => {
    return axios.post(Config.url_service+Config.sender_edit_available_sms,body,{
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        }});
};
export const sender_edit_precios_sms = (body) => {
    return axios.post(Config.url_service+Config.sender_edit_precios_sms,body,{
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        }});
};