import { deleteContacto } from "@/config/servicios/contactos/services";

export const deleteContact = (id) => {

    const jsonDataContactoId =
        {
            "contactId": id,
        };
    return deleteContacto(jsonDataContactoId)
        .then(() => {
            console.log("Contacto eliminado correctamente");
        })
        .catch((error) => {
            console.error("Error al enviar los datos:", error);
        });
};