<template>
  <div class="card">
    <div class="card-header border-1 d-flex justify-content-between align-items-center flex-column flex-md-row">
      <h6 class="mb-md-0">Remitentes SMS</h6>
      <div class="d-flex">
        <button
            class="btn btn-celcom-orange m-0"
            data-toggle="tooltip"
            data-original-title="Agregar Contacto"
            @click.prevent="agregar"
        >
          <i class="fas fa-plus"></i> Agregar
        </button>
      </div>
    </div>
    <!-- Agrega un campo de entrada para el buscador -->
    <div class="pb-0 card-body mb-0">
      <input v-model="search" type="text" class="form-control mt-2" placeholder="Buscar...">
    </div>
    <div class="card-body table-responsive">
      <table class="table align-items-center mb-0">
        <thead>
        <tr>
          <th
              class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
          >
            Tipo
          </th>
          <th
              class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
          >
            Número
          </th>
          <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
            Acción
          </th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(row, index) in filteredData" :key="index">
          <td>
            <div class="d-flex px-2 py-1">
              <div class="d-flex flex-column justify-content-center">
                <h6 class="mb-0 text-sm">{{row.type}}</h6>
              </div>
            </div>
          </td>
          <td>
            <p class="mb-0 text-sm">{{ row.msisdn }}</p>
          </td>
          <td class="align-middle text-center text-sm">
            <a href="#!" class="btn btn-danger m-0" data-toggle="tooltip" data-original-title="Eliminar sender"
               @click.prevent="$parent.openModalEliminarSenderCustomer(row)">
              <i class="fas fa-trash"></i>
            </a>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>

export default {
  name: "SenderSmsCampannaTable",
  components: {

  },
  props: {
    senderSmsCustomerData: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      search: "", // Variable para almacenar la entrada del buscador
    };
  },
  computed: {
    // Utiliza una propiedad computada para filtrar los datos según la búsqueda
    filteredData() {
      if (!Array.isArray(this.senderSmsCustomerData)) {
        return [];
      }
      return this.senderSmsCustomerData.filter(sender => {
        const type = sender.type ?? '';
        const msisdn = sender.msisdn ?? '';

        return (
            type.toLowerCase().includes(this.search.toLowerCase()) ||
            msisdn.toLowerCase().includes(this.search.toLowerCase())
        );
      });
    },
  },
  methods: {
    agregar(){
      this.$parent.openModalAddSenderCustomer();
    },
  },
};
</script>
