<template>
  
  <main class="main-content main-content-bg mt-0">
    <div
      class="page-header bg-celcom-blue bg-login min-vh-100"
    >
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-4 col-md-7 mt-2">
            <div class="text-white text-center">
              <h3 class="text-white">¡Bienvenido!</h3>
              <p>Ingresa tus datos para iniciar sesión o crea un nuevo usuario.</p>
            </div>
            <div class="card border-0 mb-0">
              <div class="card-header bg-transparent">
                <h5 class="text-dark text-center mt-2 mb-3">Ingresar a tu cuenta</h5>
                <div class="btn-wrapper text-center">
                  <argon-button
                    color="celcom"
                    variant="neutral"
                    class="my-2"
                    @click="googleLogin"
                    >
                    <img
                      class="w-30"
                      src="../../../assets/img/logos/google.svg"
                    />
                    Google
                  </argon-button
                  >
                </div>
              </div>
              <div class="card-body px-lg-5 pt-0">
                <div class="text-center text-muted mb-4">
                  <small>O ingresa con tus credenciales</small>
                </div>
                
                <form @submit.prevent="login()" role="form" class="text-start">
                  <div class="mb-3">
                    <argon-input
                      id="username"
                      type="text"
                      placeholder="Usuario"
                      aria-label="Usuario"
                      v-model="user.user"
                    />
                  </div>
                  <div class="mb-3">
                    <argon-input
                      id="password"
                      type="password"
                      placeholder="Password"
                      aria-label="Password"
                      v-model="user.password"
                    />
                  </div>
                  <argon-switch id="rememberMe" name="rememberMe">
                    Recuérdame
                  </argon-switch>

                  <div class="text-center">
                    <argon-button
                      color="celcom"
                      variant="gradient"
                      full-width
                      class="text-white my-4 mb-2"
                      >Ingresar</argon-button
                    >
                  </div>
                  <div class="mb-2 position-relative text-center">
                    <p
                      class="text-sm font-weight-bold mb-2 text-secondary text-border d-inline z-index-2 bg-white px-3"
                    >
                      o
                    </p>
                  </div>
                  <div class="text-center">
                    <argon-button
                      color="dark"
                      variant="gradient"
                      full-width
                      class="mt-2 mb-4"
                      >Crear nueva cuenta</argon-button
                    >
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import ArgonInput from "@/components/ArgonInput.vue";
import ArgonSwitch from "@/components/ArgonSwitch.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import { mapMutations } from "vuex";
import Swal from 'sweetalert2/dist/sweetalert2';

import Config from '@/config/config.js';
import { setUser } from '@/util/util.js';

import CryptoJS from 'crypto-js';
import axios from 'axios';

import { googleTokenLogin } from "vue3-google-login"

export default {
  name: "SigninBasic",
  components: {
    ArgonInput,
    ArgonSwitch,
    ArgonButton,
  },
  data() {
    return {
      user: {
        user: '',
        password: ''
      }
    };
  },
  computed: {
  },
  created() {
    this.$store.state.hideConfigButton = true;
    this.toggleDefaultLayout();
  },
  beforeUnmount() {
    this.$store.state.hideConfigButton = false;
    this.toggleDefaultLayout();
  },
  methods: {
    ...mapMutations(["toggleDefaultLayout"]),
    login() {
      let self = this;

      if(this.user.user != "" && this.user.password != "") {

        var json_login = {
          "user": this.user.user,
          "password": this.user.password,
        }

        var token = CryptoJS.AES.encrypt(JSON.stringify(json_login), 'vFeLfR2MnXgXQStZWglFO6AffhROonTq').toString();

        let data = {
          "token": token
        };
        
        let config = {
          method: 'post',
          url: Config.url_service+Config.login,
          data: data
        };

        axios.request(config)
        .then(response => {
            console.log(JSON.stringify(response.data))
            
            if (response.data.response === "200") {
              setUser(response.data.data);
              localStorage.removeItem('user_sub');
              self.$router.replace({ name: "Dashboard" });
            } else {
              console.log("Usuario incorrecto");

              Swal.fire({
                title: 'Usuario incorrecto',
                buttonsStyling: false,
                customClass: {
                  confirmButton: 'btn btn-celcom-orange',
                },
                type: 'error',
                icon: 'error'
              })
            }
        })
        .catch(error => {
            console.log("Error de servidor",error);

            Swal.fire({
              text: error,
              buttonsStyling: false,
              customClass: {
                confirmButton: 'btn btn-celcom-orange',
              },
              type: 'error',
              icon: 'error'
            })
        });

      } else {

        Swal.fire({
          title: 'Complete los campos requeridos',
          buttonsStyling: false,
          customClass: {
            confirmButton: 'btn btn-celcom-orange',
          },
          type: 'error',
          icon: 'error'
        })

      }
    },
    googleLogin() {
      googleTokenLogin()
      .then((response) => {
        console.log("Handle the response", response)
      })
      .catch((error) => {
        console.log("Handle the error", error)
      })
    }
  },
  mounted() {
  },
};
</script>

<style scoped>
.bg-login {
  background-image: url('@/assets/img/bg-login.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom;
}

</style>