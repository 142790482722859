<template>
  <div
      class="card multisteps-form__panel border-radius-xl bg-white"
      data-animation="FadeIn"
  >
    <div class="multisteps-form__content">
      <div class="card-header border-1 bg-transparent">
        <h5 class="font-weight-bolder mb-0">Envío de Correo</h5>
        <p class="mb-0 text-sm">Confirmación del envío</p>
      </div>
      <div class="card-body">

        <h6 class="font-weight-bolder">Vista previa del envío</h6>
        <p class="small">Se muestra un ejemplo de 10 números.</p>

        <div class="table-responsive">
          <table class="table mb-0">
            <thead>
            <tr class="thead-light">
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Correo</th>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Asunto</th>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Mensaje</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(row, index) in (sendingemail.sendingSmsDetailList ? sendingemail.sendingSmsDetailList.slice(0, 1) : [])" :key="index">
              <td class="align-md-middle text-sm">{{ row.destination }}</td>
              <td class="align-md-middle text-wrap text-sm">{{ row.subject }}</td>
              <td class="align-md-middle text-wrap text-sm box-mensaje w-50" v-html="row.message"></td>
            </tr>
            </tbody>
          </table>
        </div>
        <hr>
        <div class="col-md-12 config-avanzado">
          <div class="accordion-item">
            <h2 class="accordion-header" @click="toggleSection('prueba')">
              <button
                  :class="['accordion-button', { 'expanded': activeSection === 'prueba' }]"
                  type="button">
                Realizar envio de prueba
              </button>
            </h2>
            <transition name="slide-fade">
              <div v-if="activeSection === 'prueba'" class="accordion-collapse collapse show">
                <div class="accordion-body">
                  <!-- Contenido expandido de Usar Id de Seguimiento -->
                  <label>Ingrese correo de prueba</label>
                  <div class="row">
                    <div class="col-sm-6">
                      <argon-input
                          v-model="emailValue"
                          class="multisteps-form__input"
                          type="text"
                          :disabled="dataPruebas.length > 2"
                          placeholder="micorreo@gmail.com"
                      />
                      <argon-button
                          type="button"
                          color="celcom"
                          variant="gradient"
                          class="text-white ms-auto mb-0 js-btn-next me-5"
                          :disabled="dataPruebas.length > 2"
                          @click="guardarNumPrueba"
                      >  Agregar
                      </argon-button>
                      <argon-button
                          type="button"
                          color="celcom"
                          variant="gradient"
                          class="text-white ms-auto mb-0 js-btn-next"
                          :disabled="dataPruebas.length === 0"
                          @click="realizarTest"
                      >  Probar
                      </argon-button>
                    </div>
                    <div class="col-sm-6">
                        <span
                            v-for="id in dataPruebas"
                            :key="id" class="badge badge-celcom-orange clickable-badge mt-3 mt-md-0"
                            @click="quitarDataPrueba(id)"
                        >{{ id }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </transition>
          </div>
        </div>
      </div>
      <div class="card-footer border-1">
        <argon-button @click.prevent="nextStepValor" class="mt-0 d-block ml-auto">
          <i class="fas fa-paper-plane"></i> Enviar
        </argon-button>
      </div>
    </div>
  </div>
</template>

<script>
import ArgonButton from "@/components/ArgonButton.vue";
import ArgonInput from "@/components/ArgonInput.vue";

export default {
  name: "ProgramadaEditEmailAdd4",
  components: {
    ArgonInput,
    ArgonButton
  },
  props: {
    sendingemail: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      emailValue: "",
      dataPruebas: [],
      activeSection: null, // Controla qué sección está expandida
    };
  },
  methods: {
    toggleSection(section) {
      this.activeSection = this.activeSection === section ? null : section;
    },
    async realizarTest() {
      await this.$parent.realizarTestEmail(this.dataPruebas);
    },
    guardarNumPrueba() {
      this.dataPruebas.push(
          this.emailValue
      );
      this.emailValue = "";
    },
    quitarDataPrueba(name) {
      this.dataPruebas = this.dataPruebas.filter((data) => data !== name);
    },
    async nextStepValor() {
      if (this.$store.state.seleccion === 'Email') {
        await this.$parent.envioServicesEmail();
      }
    },
  },

};
</script>
<style>
  .box-mensaje img {
    max-width: 100%;
  }
  .box-mensaje p {
    font-size: .875rem;
  }
  .config-avanzado .accordion-item {
    margin-bottom: 10px;
    border: 1px solid #ddd;
  }

  .config-avanzado .accordion-button:hover {
    background-color: #eaeaea;
  }

  .config-avanzado .accordion-button:after {
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    font-family: "Font Awesome 5 Free";
    font-weight: 700;
    content: "\f106";
    margin-left: auto;
    color: rgba(33, 37, 41, 0.5);
    transition: all 0.2s ease-in-out;
  }
  .config-avanzado .accordion-button.expanded:after {
    content: "\f107"; /* Ícono alternativo cuando se expande */
  }
  .config-avanzado .accordion-body {
    padding: 15px;
    background-color: #eaeaea;
    border: 0;
  }

  .slide-fade-enter-active, .slide-fade-leave-active {
    transition: all 0.5s ease;
  }
  .slide-fade-enter-from, .slide-fade-leave-to /* .slide-fade-leave-active en Vue 2 */ {
    transform: translateY(-10px);
    opacity: 0;
  }
  .switch-url.form-switch .form-check-input:after {
    width: 1rem !important;
    height: 1rem !important;
  }
</style>