<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col mx-auto">
                <div class="card">
                    <div class="card-header border-1">
                        <h5 class="font-weight-bolder mb-0">Chatbot - Prueba Drag and Drop</h5>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-3">
                                <h3>Dialogs</h3>

                                <div id="accordionChat" class="accordion">
                                    <accordion-item
                                        accordion-id="headingOne"
                                        collapse-id="collapseDiaOne"
                                        active
                                    >
                                        <template #header>Item 1 </template>
                                        <template #body>
                                            <ul class="list-group list-group-flush">
                                                <a href="#" class="list-group-item list-group-item-action active" aria-current="true">Dialog 1</a>
                                                <a href="#" class="list-group-item list-group-item-action">Dialog 2</a>
                                                <a href="#" class="list-group-item list-group-item-action">Dialog 3</a>
                                                <a href="#" class="list-group-item list-group-item-action">Dialog 4</a>
                                                <a class="list-group-item list-group-item-action disabled">Dialog 5</a>
                                            </ul>
                                        </template>
                                    </accordion-item>
                                </div>
                            </div>
                            <div class="col-md-5 bg-light">
                                <div class="row justify-content-center">
                                    <div class="col">
                                        <div class="p-3 d-flex align-items-start justify-content-between">
                                            <draggable
                                                class="dragArea list-group"
                                                :list="list2"
                                                group="people"
                                                @change="log"
                                                item-key="name"
                                                @click="showDelete = !showDelete"
                                            >
                                                <template #item="{ element }">
                                                    <div class="card mb-3">
                                                        <div class="card-body">
                                                            <div class="d-flex align-items-center">
                                                                <i :class="element.icono" class="fa-lg p-2 border border-2 border-celcom-blue text-celcom-orange rounded-1"></i>
                                                                <h5 class="m-0 ms-2">{{ element.name }}</h5>
                                                            </div>
                                                            <p class="mt-2 mb-0 lh-sm text-sm">Aca se refleja lo que dice el textarea de mensaje.</p>
                                                        </div>
                                                    </div>
                                                </template>
                                            </draggable>
                                            <a class="btn btn-white btn-delete" :class="{ show: showDelete }">
                                                <i class="fas fa-trash-alt"></i>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <h3>Build</h3>
                                <draggable
                                    class="dragArea row"
                                    :list="list1"
                                    :group="{ name: 'people', pull: 'clone', put: false }"
                                    @change="log"
                                    item-key="name"
                                >
                                    <template #item="{ element }">
                                        <div class="col-md-4">
                                            <div class="card mb-3">
                                                <div class="p-3 text-center">
                                                    <i :class="element.icono" class="fa-lg p-2 mb-2 border border-2 border-celcom-blue text-celcom-orange rounded-1"></i>
                                                    <h5>{{ element.name }}</h5>
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </template>
                                </draggable>

                                <div class="chatbot-enviar-texto mb-3">
                                    <div class="card">
                                        <div class="card-body">
                                            <a href="#" class="link-celcom-blue text-uppercase text-xs fw-bolder"><i class="fas fa-caret-left fa-lg me-2"></i>Atrás</a>
                                            <div class="d-flex align-items-center my-3">
                                                <i class="fas fa-font fa-lg p-2 me-2 border border-2 border-celcom-blue text-celcom-orange rounded-1"></i>
                                                <h5 class="mb-0">Enviar Texto</h5>
                                            </div>
                                            <p class="text-sm">Lorem ipsum dolor sit, amet consectetur adipisicing elit.</p>
                                            
                                            <div class="border-1 border-secondary border-bottom d-flex justify-content-between align-items-center">
                                                <div class="link-variation-list">
                                                    <a class="link-variation-item active">1</a>
                                                </div>
                                                <a href="#" class="link-celcom-blue text-uppercase text-xs fw-bolder"><i class="fas fa-plus me-2"></i>Agregar variación</a>
                                            </div>

                                            <div class="mt-3">
                                                <div class="mensaje-header">
                                                    <h6 class="mb-0">Mensaje 1</h6>
                                                    <span class="text-secondary text-xs">0/0</span>
                                                </div>
                                                <div class="mensaje-box mt-2">
                                                    <textarea class="form-control w-100" name="" id="" rows="5" placeholder="Ingresar mensaje"></textarea>
                                                    <div class="mensaje-box-botones">
                                                        <div class="dropdown px-2">
                                                            <a 
                                                                href="#" 
                                                                class="link-celcom-blue" 
                                                                :class="{ show: showMenu }"
                                                                data-bs-toggle="dropdown"
                                                                aria-expanded="false"
                                                                @click="showMenu = !showMenu"><i class="fa-xs fas fa-terminal"></i>
                                                            </a>
                                                            <ul
                                                                class="px-2 py-3 dropdown-menu dropdown-menu-end me-sm-n4"
                                                                :class="{ show: showMenu }"
                                                                aria-labelledby="dropdownCam"
                                                            >
                                                                <li>
                                                                    <a class="dropdown-item border-radius-md" href="#">Opción 1</a>
                                                                </li>
                                                                <li>
                                                                    <a class="dropdown-item border-radius-md" href="#">Opción 2</a>
                                                                </li>
                                                                <li>
                                                                    <a class="dropdown-item border-radius-md" href="#">Opción 3</a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        <a href="" class="link-celcom-blue"><i class="far fa-smile px-2"></i></a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="chatbot-enviar-imagen mb-3">
                                    <div class="card">
                                        <div class="card-body">
                                            <a href="#" class="link-celcom-blue text-uppercase text-xs fw-bolder"><i class="fas fa-caret-left fa-lg me-2"></i>Atrás</a>
                                            <div class="d-flex align-items-center my-3">
                                                <i class="far fa-image fa-lg p-2 me-2 border border-2 border-celcom-blue text-celcom-orange rounded-1"></i>
                                                <h5 class="mb-0">Enviar Imagen</h5>
                                            </div>
                                            <p class="text-sm">Lorem ipsum dolor sit, amet consectetur adipisicing elit.</p>
                                            
                                            
                                            <div class="mt-3">
                                                <div class="imagen-url">
                                                    <h6 class="mb-0">Subir imagen</h6>
                                                    <div
                                                        id="dropzone"
                                                        class="form-control dropzone"
                                                    >
                                                        <div class="fallback">
                                                        <input name="file" type="file" ref="fileInput" />
                                                        </div>
                                                    </div>
                                                    <span class="text-xs text-celcom-blue"><i class="fas fa-info-circle"></i> Formatos permitidos: .jpg, .png</span>
                                                </div>
                                                
                                                <div class="imagen-url">
                                                    <h6 class="mb-0">URL Imagen</h6>
                                                    <input class="form-control w-100" name="" id="" rows="2" placeholder="Ingresar URL de imagen">
                                                </div>
                                            </div>
                                            <div class="mt-3">
                                                <div class="mensaje-header">
                                                    <h6 class="mb-0">Pie de foto</h6>
                                                    <span class="text-secondary text-xs">0/0</span>
                                                </div>
                                                <div class="mensaje-box mt-2">
                                                    <textarea class="form-control w-100" name="" id="" rows="5" placeholder="Ingresar pie de página"></textarea>
                                                    <div class="mensaje-box-botones">
                                                        <div class="dropdown px-2">
                                                            <a 
                                                                href="#" 
                                                                class="link-celcom-blue" 
                                                                :class="{ show: showMenuImg }"
                                                                data-bs-toggle="dropdown"
                                                                aria-expanded="false"
                                                                @click="showMenuImg = !showMenuImg"><i class="fa-xs fas fa-terminal"></i>
                                                            </a>
                                                            <ul
                                                                class="px-2 py-3 dropdown-menu dropdown-menu-end me-sm-n4"
                                                                :class="{ show: showMenuImg }"
                                                                aria-labelledby="dropdownCam"
                                                            >
                                                                <li>
                                                                    <a class="dropdown-item border-radius-md" href="#">Opción 1</a>
                                                                </li>
                                                                <li>
                                                                    <a class="dropdown-item border-radius-md" href="#">Opción 2</a>
                                                                </li>
                                                                <li>
                                                                    <a class="dropdown-item border-radius-md" href="#">Opción 3</a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        <a href="" class="link-celcom-blue"><i class="far fa-smile px-2"></i></a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <rawdisplayer class="col-md-6" :value="list1" title="List 1" />
                            <rawdisplayer class="col-md-6" :value="list2" title="List 2" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </template>
  
  <script>
  import draggable from "vuedraggable";
  import rawdisplayer from "./components/rawdisplayer.vue";
  import AccordionItem from "./components/AccordionItem.vue";

  export default {
    name: "chatbotprueba",
    display: "Chatbot Prueba",
    order: 2,
    components: {
      draggable,
      rawdisplayer,
      AccordionItem,
    },
    data() {
        return {
            list1: [
                {
                    name: "Texto",
                    icono: "fas fa-font",
                    id: 1,
                },
                { 
                    name: "Image",
                    icono: "far fa-image",
                    id: 2,
                },
                { 
                    name: "Audio",
                    icono: "fas fa-volume-up",
                    id: 3,
                }
            ],
            list2: [
                { 
                    name: "Juan", 
                    id: 5
                },
            ],
            showMenu: false,
            showMenuImg: false,
            showDiaDuplicate: false,
            showDelete: false
        };
    },
    methods: {
        log: function(evt) {
            window.console.log(evt);
        }
    }
  };
  </script>
  <style scoped>
  .buttons {
    margin-top: 35px;
  }
  .ghost {
    opacity: 0.5;
    background: #c8ebfb;
  }
  .not-draggable {
    cursor: no-drop;
  }
  .link-variation-list .link-variation-item {
    padding: 0 .75rem;
  }
  .link-variation-list .link-variation-item.active {
    border-bottom: 3px solid var(--bs-celcom-blue);
  }
  .mensaje-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .mensaje-box {
    position: relative;
  }
  .mensaje-box-botones {
    position: absolute;
    top: 5px;
    right: 10px;
    display: flex;
    flex-flow: column wrap;
    align-items: flex-end;
  }
  .imagen-url .dropzone {
    min-height: auto;
  }
  .btn-delete {
    opacity: 0;
  }
  .btn-delete.show {
    opacity: 1;
    visibility: visible;
    animation: show-navbar-dropdown 0.25s ease forwards;
  }
  </style>