import { deleteCanal } from "@/config/servicios/canales/servicesCanales";

export const deleteC = (id) => {

    const jsonDataCanalId =
        {
            "channelId": id,
        };
    return deleteCanal(jsonDataCanalId)
        .then(() => {
            console.log("Canal eliminado correctamente");
        })
        .catch((error) => {
            console.error("Error al enviar los datos:", error);
        });
};