<template>
  <div
    class="card multisteps-form__panel p-3 border-radius-xl bg-white"
    data-animation="FadeIn"
  >
    <p class="mb-0 text-sm">Información obligatoria</p>
    <div class="multisteps-form__content">
      <div class="row mt-3">
        <div class="col-12 col-sm-6">
          <label>Nombre Canal</label>
          <argon-input
            id="canalNameAdd"
            v-model="canalName"
            class="multisteps-form__input"
            type="text"
            placeholder="SMS"
          />
          <div v-if="!soloLetra" :class="{ 'is-invalid-d': !soloLetra }">
            <p class="mb-0 text-sm">Solo puede contener letras y un mínimo de 3 caracteres</p>
          </div>
        </div>
        </div>
      <div class="row mt-3">
      <div class="col-12 col-sm-6">
        <label>Estado</label>
        <div class="form-check">
          <input
              class="form-check-input"
              type="checkbox"
              id="estadoCheckboxAdd"
              v-model="active"
          />
          <label class="form-check-label" for="estadoCheckbox">
            {{ active ? 'Activo' : 'No Activo' }}
          </label>
        </div>
      </div>
      </div>
      <div class="button-row d-flex mt-2">
        <argon-button
            :disabled="!(soloLetra)"
            type="button"
            color="dark"
            variant="gradient"
            class="ms-auto js-btn-next"
            @click="$parent.addCanal"
        >Agregar</argon-button>
      </div>
    </div>
  </div>
</template>

<script>
import ArgonInput from "@/components/ArgonInput.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import { soloLetras } from "@/config/validaciones"
export default {
  name: "UserInfo",
  components: {
    ArgonInput,
    ArgonButton,
  },
  data() {
    return {
      canalName: '',
      active: false,
    };
  },
  computed: {
    soloLetra() {
      return soloLetras(this.canalName);
    },
  },
};
</script>
<style scoped>
@import "@/assets/css/validaciones.css";
</style>