// api.js
import axios from 'axios';
import {decryptAES} from "@/config/servicios/campana/util";

const API_BASE_URL = 'https://automata2.celcom.cl/flex/api/';

const urlCustomerId = () => {
// Obtener el valor del local storage
    let encryptedToken = localStorage.getItem('user');
    const userDataString = decryptAES(encryptedToken);

// Convertir la cadena JSON a un objeto JavaScript
    const userData = JSON.parse(userDataString);

// Obtener el valor de customerId
    return userData.customer.customerId;
};
export const listCustomerServ = () => {
    const API_LIST_CONT = 'customer/list/'+ urlCustomerId() + '/admin';
    return axios.post(`${API_BASE_URL}`+ API_LIST_CONT,1);
};

export const agreCustomer = (datos) => {
    const API_LIST_ADD = 'customer/create/'+ urlCustomerId() + '/admin';
    return axios.post(`${API_BASE_URL}`+API_LIST_ADD, datos);
};

export const editCustomer = (datos) => {
    console.log(datos);
    const API_LIST_EDIT = 'customer/edit/'+ urlCustomerId() + '/admin';
    console.log(`${API_BASE_URL}`+API_LIST_EDIT);
    return axios.post(`${API_BASE_URL}`+API_LIST_EDIT, datos);
};


// Agrega más funciones según tus necesidades