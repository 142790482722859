<template>
  <div class="container-fluid py-4">
    <div class="row mt-4">
      <div class="col-12">
        <user-admin-table :user-data="responseUser"/>
      </div>
    </div>
    <modal-delete :is-visible="modalEliminarUser">
      <p class="text-center"><b>¿Estás seguro de que deseas eliminar?</b></p>
      <div class="mt-lg-4 px-3 d-flex justify-content-between">
        <button class="btn m-0 bg-gradient-danger" @click="borrarUsuario">Eliminar</button>
        <button class="btn m-0 bg-gradient-dark" @click="closeModal">Cancelar</button>
      </div>
    </modal-delete>
    <modal :is-visible="modalEditarUser">
      <p><b>Editar perfil de: {{ dataUser.shortName }}</b></p>
      <form>
        <h6 class="heading-small text-muted mb-4">Información de Usuario</h6>

        <div class="row">
          <div class="col-lg-6">
            <label class="form-label">Nombre Corto</label>
            <argon-input
                v-model="dataUser.shortName"
                class="multisteps-form__input"
                type="text"
            />
          </div>
          <div class="col-lg-6">
            <label class="form-label">Correo / Login</label>
            <argon-input
                v-model="dataUser.email"
                class="multisteps-form__input"
                type="email"
            />
          </div>
        </div>

        <div class="row">
          <div class="col-lg-6">
            <label class="form-label">Nombre</label>
            <argon-input
                v-model="dataUser.name"
                class="multisteps-form__input"
                type="text"
            />
          </div>
          <div class="col-lg-6">
            <label class="form-label">Apellido</label>
            <argon-input
                v-model="dataUser.lastName"
                class="multisteps-form__input"
                type="email"
            />
          </div>
        </div>

        <div class="row">
          <div class="col-lg-6" v-if="typeof dataUser.customer!=='undefined'">
            <label class="form-label">RUT</label>
            <argon-input
                v-model="dataUser.customer.rut"
                class="multisteps-form__input"
                type="text"
            />
          </div>
          <div class="col-lg-6" v-if="typeof dataUser.customer!=='undefined'">
            <label class="form-label">Direccion</label>
            <argon-input
                v-model="dataUser.customer.adress"
                class="multisteps-form__input"
                type="email"
            />
          </div>
        </div>

        <div class="row">
          <div class="col-lg-6">
            <label class="form-label">Contraseña</label>
            <argon-input
                v-model="dataUser.password"
                :type="showPassword ? 'text' : 'password'"
                class="multisteps-form__input"
            />
          </div>
          <div class="col-lg-5">
            <label class="form-label">Verificar Contraseña</label>
            <argon-input
                v-model="dataUser.password"
                :type="showPassword ? 'text' : 'password'"
                class="multisteps-form__input"
            />
          </div>
          <div class="col-lg-1">
            <p>&nbsp;</p>
            <span class="mr-auto" @click="showPassword = !showPassword">
                  <i class="fa" :class="[showPassword ? 'fa-eye' : 'fa-eye-slash']" aria-hidden="true"></i>
                </span>
          </div>
        </div>
        <hr class="my-4">
        <div class="row">
          <div class="col-md-4">
            <label class="form-label mt-2">País</label>
            <select
                v-model="selects.simple"
                class="form-control form-select"
                placeholder="Elegir País"
            >
              <option value="" selected disabled>Países</option>
              <option v-for="option in selects.paises"
                      :key="option.name"
                      class="select-danger"
                      :value="option.name"
                      :label="option.name"></option>
            </select>
          </div>
          <div class="col-md-4">
            <label class="form-label mt-2">Idioma</label>
            <select
                v-model="selects.simpleIdioma"
                class="form-control form-select"
            >
              <option value="" selected disabled>Idiomas</option>
              <option v-for="option in selects.idioma"
                      :key="option.name"
                      class="select-danger"
                      :value="option.name"
                      :label="option.name"></option>
            </select>
          </div>
          <div class="col-md-4">
            <label class="form-label mt-2">Zona Horaria</label>
            <select
                v-model="selects.simpleZona"
                class="form-control form-select"
            >
              <option value="" selected disabled>Zonas Horarias</option>
              <option v-for="option in selects.zonaHoraria"
                      :key="option.name"
                      class="select-danger"
                      :value="option.name"
                      :label="option.name"></option>
            </select>
          </div>
        </div>
        <div>
          <hr class="mt-5 mb-4">
          <!-- <h6 class="heading-small text-muted mb-4">Límite SMS</h6>-->
          <argon-alert
              class="alert alert-danger"
              role="alert"
          >
            Los créditos total disponibles: <strong>{{ numberWithCommas((dataUser.customer != null) ? dataUser.customer.flexCredits : 0) }}</strong>
          </argon-alert>
          <!--          <div class="row">
                      <div class="col-md-6 d-flex align-items-center mb-md-5">
                        <input
                            v-model="radio.radio1"
                            type="radio"
                            name="limite"
                            value="checked"
                        /><label class="mb-0">Límite propio</label><i class="btn-tooltip fas fa-question-circle ms-1" title="Consumir créditos de tu límite propio"></i>
                      </div>
                      <div class="col-md-6">
                        <argon-input
                            v-model="dataUser.remainingSms"
                            :style="radio.radio1==='unchecked' ? 'pointer-events: none' : ''"
                            class="multisteps-form__input m-0"
                            type="text"
                        />
                        <small>Restantes</small>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6 d-flex align-items-center">
                        <input
                            v-model="radio.radio1"
                            type="radio"
                            name="limite"
                            value="unchecked"
                        /><label class="mb-0">Límite cuenta master</label>
                      </div>
                    </div>-->
          <div class="modal-footer mt-lg-4 px-0 d-flex justify-content-between">
            <button class="btn m-0 bg-gradient-celcom-orange" @click.prevent="editarInfoCuenta">Editar</button>
            <button class="btn m-0 bg-gradient-dark" @click.prevent="closeModal">Cancelar</button>
          </div>
        </div>
      </form>

    </modal>
  </div>
</template>

<script>
import UserAdminTable from "./components/UserAdminTable.vue";
import {encryptAES, horaAhora, onPermissionsUser} from "@/config/servicios/campana/util";
import swal from "sweetalert2";

import {listUser, customer_user_detele, costumer_user_edit} from "@/config/servicios/campana/user/servicesUserCampanna";
import ModalDelete from "@/components/Modal/ModalDelete.vue";
import ArgonAlert from "@/components/ArgonAlert.vue";
import Modal from "@/components/Modal/Modal.vue";
import ArgonInput from "@/components/ArgonInput.vue";
import axios from "axios";
import Config from "@/config/servicios/campana/config";
export default {
  name: "UserEditAdmin",
  components: {
    ArgonInput, Modal, ArgonAlert,
    ModalDelete,
    UserAdminTable,
  },
  data() {
    return {
      modalEliminarUser: false,
      modalEditarUser: false,
      permisos: {
        create: onPermissionsUser('users.xhtml', 'create'),
        modify: onPermissionsUser('users.xhtml', 'modify'),
        destroy: onPermissionsUser('users.xhtml', 'destroy'),
      },
      responseUser: {},
      dataUser: {},
      selects: {
        simple: '',
        paises: [],
        simpleIdioma: '',
        idioma: [],
        simpleZona: '',
        zonaHoraria: []
      },
      showPassword: false,
    };
  },
  computed: {
    cliente() {
      return this.$store.state.clienteActual;
    }
  },
  mounted() {
    this.listUsers();
    this.obtenerIdiomas();
    this.obtenerInfoCustomer();
  },
  methods: {
    async obtenerInfoCustomer() {

      let self = this;
      //let user = this.dataUser;

      let js = {
        "time_expired":horaAhora(),
        "customer":encodeURIComponent(JSON.stringify(this.cliente))
      }

      var body = new URLSearchParams(); body.append('TOKEN', (encryptAES(js)));

      await axios.post(Config.url_service+Config.costumer_user_available, body, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      })
          .then(function (response) {

            if (response.data.response === "200") {

              self.dataUser = response.data.data;

              self.dataUser.forEach(function(element) {
                if(element.customerUserId === element.customerUserId) {

                  self.selects.paises.push({'name':element.customer.country.name})
                  self.selects.zonaHoraria.push({'name':element.customer.country.gmt_timezone_name+"|"+element.customer.country.gmt_timezone_interval})
                  self.selects.simple = element.customer.country.name
                  self.selects.simpleZona = element.customer.country.gmt_timezone_name+"|"+element.customer.country.gmt_timezone_interval
                  self.selects.simpleIdioma = element.customer.language.name
                  self.adminSi = element;
                }
              })
            } else {
              console.log("No se encontró usuario");
              swal.fire({
                title: 'No se encontró usuario',
                buttonsStyling: false,
                customClass: {
                  confirmButton: 'btn btn-celcom-orange',
                },
                type: 'warning',
                icon: 'warning'
              })
            }
          })
          .catch(function (error) {
            console.log("No se encontró usuario",error);
            swal.fire({
              title: 'No se encontró usuario',
              buttonsStyling: false,
              customClass: {
                confirmButton: 'btn btn-celcom-orange',
              },
              type: 'warning',
              icon: 'warning'
            })
          });
    },
    numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    async listUsers() {
      try {
        let user = this.cliente;
        let js = {
          "time_expired":horaAhora(),
          "customer":encodeURIComponent(JSON.stringify(user))
        }

        var body = new URLSearchParams();
        body.append("TOKEN", encryptAES(js));

        const response = await listUser(body);
        // Almacena la respuesta en la variable de datos
        this.responseUser = response.data.data;
      } catch (error) {
        console.error("Error al obtener datos", error);
      }
    },

    //Inicio de Eliminar un User
    openModalEliminarUser(row) {
      this.dataUser = JSON.parse(JSON.stringify(row));
      this.modalEliminarUser = true;
    },
    async borrarUsuario() {
      let self = this;
      let user = self.cliente;

      let js = {
        "time_expired":horaAhora(),
        "customer":encodeURIComponent(JSON.stringify(user)),
        "customer_user":encodeURIComponent(JSON.stringify(this.dataUser))
      }

      var body = new URLSearchParams(); body.append('TOKEN', (encryptAES(js)));

      await customer_user_detele(body)
          .then(function (response) {
            console.log(response)

            if (response.data.response === "200") {
              console.log(response.data.data);

              self.response = response.data.data;
              self.modalEliminarUser = false;

            } else {
              console.log("No se pudo borrar usuario");
              swal.fire({
                title: 'No se pudo borrar usuario',
                buttonsStyling: false,
                customClass: {
                  confirmButton: 'btn btn-celcom-orange',
                },
                type: 'error',
                icon: 'error',
              })
            }

          })
          .catch(function (error) {
            console.log("No se pudo borrar usuario",error);
            swal.fire({
              title: 'No se pudo borrar usuario',
              buttonsStyling: false,
              customClass: {
                confirmButton: 'btn btn-celcom-orange',
              },
              type: 'error',
              icon: 'error',
            })
          });

    },
    //Fin de Eliminar un User

    //Inicio de editar un User
    openModalEditarUser(row) {
      this.dataUser = JSON.parse(JSON.stringify(row));
      this.modalEditarUser = true;
    },
    async editarInfoCuenta(){
      let self = this;
      if(this.dataUser.name==="" || this.dataUser.lastName==="" || this.dataUser.email==="" || this.dataUser.password==="" || this.dataUser.shortName==="" || (this.dataUser.remainingSms==="" /*&& self.radio.radio1==="checked"*/)){
        await swal.fire({
          title: "Error Debe completar todos los campos",
          buttonsStyling: false,
          customClass: {
            confirmButton: 'btn btn-celcom-orange',
          },
          icon: 'error',
        })
        return false;
      }

      var  zonaTime =  self.selects.simpleZona.split("|");
      this.dataUser.customer.country.gmt_timezone_name=zonaTime[0];
      this.dataUser.customer.country.gmt_timezone_interval=zonaTime[1];

      //this.dataUser.smsAdminUser = (self.radio.radio1==="unchecked") ? "true":"false"

      let js = {
        "time_expired":horaAhora(),
        "customer":encodeURIComponent(JSON.stringify(this.dataUser.customer)),
        "customer_user":encodeURIComponent(JSON.stringify(this.dataUser))
      }

      var body = new URLSearchParams(); body.append('TOKEN', (encryptAES(js)));
      await costumer_user_edit(body)
          .then(function (response) {
            if (response.data.response === "200") {

              self.dataUser = response.data.data;
              self.responseUser = response.data.data;

              self.modalEditarUser = false;

              self.element = response.data.data;
              swal.fire({
                title: '¡Datos editados con éxito!',
                buttonsStyling: false,
                customClass: {
                  confirmButton: 'btn btn-celcom-blue',
                },
                type: 'success',
                icon: 'success',
              })

            } else {
              console.log("No se editó usuario");
              swal.fire({
                title: 'No se editó usuario',
                buttonsStyling: false,
                customClass: {
                  confirmButton: 'btn btn-celcom-orange',
                },
                type: 'error',
                icon: 'error'
              })
            }
          })
          .catch(function (error) {
            console.log("No se editó usuario",error);
            swal.fire({
              title: 'No se editó usuario',
              buttonsStyling: false,
              customClass: {
                confirmButton: 'btn btn-celcom-orange',
              },
              type: 'error',
              icon: 'error'
            })
          });

    },
    //Fin de editar un User

    obtenerIdiomas() {

      let self = this;

      let js = {
        "time_expired":horaAhora(),
      }

      var body = new URLSearchParams(); body.append('TOKEN', (encryptAES(js)));

      axios.post(Config.url_service+Config.language_available, body, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      })
          .then(function (response) {

            if (response.data.response === "200") {

              self.selects.idioma = response.data.data;
              console.log(response.data.data)
            } else {
              console.log("No se encontraron paises");
            }
          })
          .catch(function (error) {
            console.log("No se encontraron paises",error);
          });

    },
    closeModal() {
      this.modalEliminarUser = false;
      this.modalEditarUser = false;
    },
},
};
</script>
<style scoped>
/* Agrega estilos según tus necesidades */
.modal {
  display: none;
}

.modal.is-active {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
}

.modal-close {
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
  background: none;
  border: none;
}
.buttons {
  margin: 8px;
}
</style>