// Lógica de validación del correo electrónico
export const isEmailValid = (email) => {
    const emailRegex = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/;
    return emailRegex.test(email);
};
export const validatePassword = (newPassword) => {
    const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,}$/;
    return passwordRegex.test(newPassword);
};
export const validateConfirmPassword = (newPassword, confirmPassword) => {
    return newPassword === confirmPassword;
};
export const soloLetras = (input) => {
    const alphaRegex = /^(?=.*[A-Za-z])[A-Za-z]{3,}$/;

    return alphaRegex.test(input);
};
export const soloLetras_Num = (input) => {
    const alphaNumRegex = /^[A-Za-z0-9_]{3,}$/;

    return alphaNumRegex.test(input);
};
export const validarPlantilla = (input) => {
    const soloLetrasRegex = /^[a-z0-9_]+$/;

    return soloLetrasRegex.test(input);
};
export const validateMsisdn = (input) => {
    const formatoRegex = /^56\d{9}$/;
    return formatoRegex.test(input);
};
export const validarRut = (input) => {
    const formatoRegex = /^\d{7}-\d$/;
    return formatoRegex.test(input);
};

export const validarVacio = (input) => {
    // Verifica si el valor es nulo, indefinido o vacío
    if (input == null || input.trim() === "") {
        return false;
    }else {
        return true;
    }
};