<template>
    <div class="container-fluid py-5">
      <div class="row">
        <div class="col-12">
          <div class="multisteps-form">
            <!--form panels-->
            <div class="row">
              <div class="col-12 col-lg-10 m-auto">
                <form class="multisteps-form__form">
                  <segment-table :segmentData="response.data" />
                </form>
              </div>
            </div>
          </div>
        </div>
        <modal-delete :is-visible="modalVisible">
          <p><b>¿Estás seguro de que deseas eliminar?</b></p>
          <div class="mt-lg-4 d-flex justify-content-between">
            <button class="btn bg-gradient-danger my-sm-auto mt-2 mb-0" @click="deleteOringen">Eliminar</button>
            <button class="btn bg-gradient-dark my-sm-auto mt-2 mb-0" @click="closeModal">Cancelar</button>
          </div>
        </modal-delete>
        <modal :is-visible="modalEditar">
          <p><b>Edite el Segmento</b></p>
          <div class="multisteps-form__content">
            <div class="row mt-3">
              <div class="col-12 col-sm-6">
                <label>Nombre</label>
                <argon-input
                    id="segmentName"
                    v-model="nameValorEdit"
                    class="multisteps-form__input"
                    type="text"
                    placeholder="Ingrese un nombre"
                />
              </div>
            </div>
            <div class="button-row d-flex mt-2">
              <div class="modal-footer mt-lg-4 d-flex justify-content-between">
                <button class="btn bg-gradient-success mt-2 mb-0" @click="editSegment">Editar</button>
                <button class="btn bg-gradient-dark mt-2 mb-0" @click="closeModal">Cancelar</button>
              </div>
            </div>
          </div>
        </modal>
      </div>
    </div>
  </template>
  
  <script>
  import SegmentTable from "./table/SegmentTable.vue";
  import { deleteOringen} from "@/config/servicios/sftp/servicesSftp";
  import ModalDelete from "@/components/Modal/ModalDelete.vue";
  import Modal from "@/components/Modal/Modal.vue";
 /* import ArgonInput from "@/components/ArgonInput.vue";*/
  import Choices from "choices.js";
  import ArgonInput from "@/components/ArgonInput.vue";
  import {getSegment, editSegment} from "@/config/servicios/segment/servicesSegment";

  export default {
    name: "ListarSegment",
    components: {
      ArgonInput,
      /*ArgonInput,*/
      SegmentTable,
      ModalDelete,
      Modal,
    },
    data() {
      return {
        tipo: null,
        testButton: true,
        nameValorEdit: null,
        modalEditar: false,
        modalVisible: false,
        idSegment: null,
        response: {}, // Aquí almacenarás la respuesta del servicio
      };
    },
    methods: {
      async deleteOringen() {
        if (this.idSource) {
          try {
            await deleteOringen(this.idSource);
            // Almacena la respuesta en la variable de datos
            console.log("Orignen eliminado" );
          } catch (error) {
            console.error("Error al obtener datos", error);
          } finally {
            this.modalVisible = false;
            await this.listSegment();
          }
        }
      },
      closeModal() {
        this.modalVisible = false;
        this.modalEditar = false;
      },
      openModal(id) {
        this.idSegment = id;
        this.modalVisible = true;
      },
      openModalEditar(segment) {
        const segmentData = JSON.parse(JSON.stringify(segment));

        this.idSegment = segmentData.customerTagSegmentId;
        this.nameValorEdit = segmentData.customerTagSegmentName;

        this.modalEditar = true;
      },
      async editSegment() {
        try {
          const response = await editSegment(this.idSegment, this.nameValorEdit);
          // Almacena la respuesta en la variable de datos
          console.log("Segment Editado: " + response);
        } catch (error) {
          console.error("Error al obtener datos", error);
        } finally {
          this.modalEditar = false;
          await this.listSegment();
        }
      },
      async listSegment() {
        try {
          const response = await getSegment();
          // Almacena la respuesta en la variable de datos
          this.response = response.data;
        } catch (error) {
          console.error("Error al obtener datos", error);
        }
      },
    },
    mounted() {
      if (document.getElementById("opcionesTags")) {
        var element = document.getElementById("opcionesTags");
        new Choices(element, {
          searchEnabled: false,
          allowHTML: true,
        });
      }
      this.listSegment(); // Llama a la función al montar el componente
    },
  };
  </script>
<style scoped>
/* Agrega estilos según tus necesidades */
.modal {
  display: none;
}

.modal.is-active {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
}

.modal-close {
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
  background: none;
  border: none;
}
.buttons {
  margin: 8px;
}
</style>