// api.js
import axios from 'axios';
import Config from "@/config/servicios/campana/config";
export const LIST_WSP_ACCOUNT_BY_CUSTOMER = (body) => {
    return axios.post(Config.url_service + Config.LIST_WSP_ACCOUNT_BY_CUSTOMER,body,{
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        }});
};
export const CREATE_WHSTAPP_ACCOUNT_SERVICES = (body) => {
    return axios.post(Config.url_service + Config.CREATE_WHSTAPP_ACCOUNT_SERVICES,body,{
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        }});
};
export const EDIT_WHSTAPP_ACCOUNT_SERVICES = (body) => {
    return axios.post(Config.url_service + Config.EDIT_WHSTAPP_ACCOUNT_SERVICES,body,{
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        }});
};
export const LIST_TEMPLATE_WHATSPP_BY_CUSTOMER = (body) => {
    return axios.post(Config.url_service + Config.LIST_TEMPLATE_WHATSPP_BY_CUSTOMER,body,{
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        }});
};
export const ADD_TEMPLATEWHATSAPP_BY_CUSTOMER = (body) => {
    return axios.post(Config.url_service + Config.ADD_TEMPLATEWHATSAPP_BY_CUSTOMER,body,{
        headers: {
            "Content-Type": "multipart/form-data"
        }});
};
export const DELETE_TEMPLATEWHATSAPP_BY_CUSTOMER = (body) => {
    return axios.post(Config.url_service + Config.DELETE_TEMPLATEWHATSAPP_BY_CUSTOMER,body,{
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        }});
};
// Agrega más funciones según tus necesidades