// data.js
export const llenarDatosCustomer = (id, name, adminCustomer, active, country) => {

    const jsonDataCustomer =
        {
            "customerId": id,
            "customerName": name,
            "adminCustomer": adminCustomer,
            "active": active,
            "country": country,
        };
    return jsonDataCustomer;
};